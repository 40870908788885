import { propagateLayersAndRecalculate, recalculateValuesInLayersWithAspectRatio } from '@prisma/lib/src/utils/layers';
import { ASPECT_RATIO, ASPECT_RATIO_TYPE } from 'constants';

import AspectRatio from 'models/AspectRatio';

/**
 * Retrieves the aspect ratio and its opposite based on the given aspect ratio type.
 *
 * @param {string} aspectRatioType - The type of aspect ratio.
 * @returns {Object} - An object containing the aspect ratio and the opposite aspect ratio.
 */
export function getAspectRatioAndOpposite(aspectRatioType) {
  const aspectRatio = ASPECT_RATIO[aspectRatioType];
  const oppositeAspectRatio =
    ASPECT_RATIO[ASPECT_RATIO_TYPE.BIG === aspectRatioType ? ASPECT_RATIO_TYPE.SMALL : ASPECT_RATIO_TYPE.BIG]; // this only works because we have only two aspect ratios
  return { aspectRatio, oppositeAspectRatio };
}

/**
 * Replaces the aspect ratio in a given page with a new aspect ratio type.
 *
 * @param {Object} page - The page object containing layers and aspect ratios.
 * @param {string} newAspectRatioType - The new aspect ratio type to be applied.
 */
export function replaceAspectRatioInPage(page, newAspectRatioType) {
  const { aspectRatio: newAspectRatio, oppositeAspectRatio: oldAspectRatio } =
    getAspectRatioAndOpposite(newAspectRatioType);
  if (!page.isLocked()) {
    // this means that the aspect ratio is unlocked, so it has overrides for layers depending on the aspect ratio.

    const aspectRatioSaved = page.aspectRatios.find(aspectRatio => newAspectRatio.type === aspectRatio.type);
    if (aspectRatioSaved) {
      const aspectRatioToSave = new AspectRatio(oldAspectRatio);

      const layersToSet = propagateLayersAndRecalculate(
        page.layers,
        aspectRatioSaved.layers,
        oldAspectRatio,
        newAspectRatio,
      );
      // we save the aspect ratio we see in canvas
      aspectRatioToSave.setLayers([...page.layers]);
      aspectRatioToSave.setCurrent(false);

      page.layers = layersToSet;

      // we remove the layers from the saved aspect ratio because we have it in page.layers
      aspectRatioSaved.setCurrent(true);
      aspectRatioSaved.setLayers([]);

      page.aspectRatios = [aspectRatioSaved, aspectRatioToSave]; // we remove the old aspect ratio and we save the one we are replacing
    }
  } else {
    const layersToSet = recalculateValuesInLayersWithAspectRatio(page.layers, oldAspectRatio, newAspectRatio);
    page.layers = layersToSet;
  }
}
