import React from 'react';
import { observer } from 'mobx-react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { Icon, Input, Table } from '@akiunlocks/perseus-ui-components';

import useProjects from './hooks/useProjects';
import Confirm from '../../components/Confirm';
import { AddNewProjectModal } from '../../components/Projects';
import { STATUS } from 'containers/Projects/utils';
import { CONFIRM_NOTES } from 'utils';

import './Projects.scss';

const Projects = observer(() => {
  const {
    CONFIRM_BODIES,
    CONFIRM_TITLES,
    PAGE_SIZE_OPTIONS,
    activeFavoriteFilter,
    addNewProjectLoading,
    archive,
    columns,
    duplicate,
    filters,
    handleAddModalClose,
    handleAddModalCreate,
    newProjectErrors,
    newProjectName,
    pageLimit,
    projects,
    projectsList,
    searchInputOnChange,
    selectedProject,
    setNewProjectName,
    setNewProjectType,
    setPage,
    setPageLimit,
    setShowAddProjectModal,
    showAddProjectModal,
    status,
  } = useProjects();

  const loading = status === STATUS.LOADING;

  const projectArchiveConfirmBody = selectedProject?.perseusHashId
    ? CONFIRM_BODIES.PROJECT_ARCHIVED_WITH_PERSONALIZE
    : CONFIRM_BODIES.PROJECT_ARCHIVED;

  return (
    <div className="bg-light projects-container">
      <Container>
        <Row>
          <h3 className="title">Projects</h3>
        </Row>
        <Row className="pt-5">
          <Col>
            <Row>
              <Col className="searchInput">
                <Input
                  id="search"
                  className="search"
                  label={<Icon name="search" />}
                  controlProps={{
                    placeholder: 'Search',
                    value: filters.name || '',
                    onChange: searchInputOnChange,
                  }}
                />
              </Col>
              <Col>
                <div className="starIcon">
                  <Icon
                    size="30"
                    color="green"
                    name={filters.favorite ? 'star-fill' : 'star'}
                    onClick={activeFavoriteFilter}
                  />
                </div>
              </Col>
              <Col md="auto"></Col>
            </Row>
          </Col>
          <Col xs lg="2">
            <Button className="addButton" size="lg" onClick={() => setShowAddProjectModal(!showAddProjectModal)}>
              New Project
            </Button>
          </Col>
        </Row>
        <Row className="pt-10 pb-10">
          <Table
            id="projects"
            status={status}
            columns={columns}
            data={projectsList}
            initialPageSize={pageLimit}
            totalItemsCount={projects.total}
            onPageChanged={page => setPage(page)}
            selectPageSizeOptions={PAGE_SIZE_OPTIONS}
            onPageSizeChange={({ target: { value } }) => setPageLimit(value)}
          />
        </Row>
      </Container>

      <AddNewProjectModal
        addNewProjectLoading={addNewProjectLoading}
        handleModalClose={handleAddModalClose}
        handleModalCreate={handleAddModalCreate}
        newProjectErrors={newProjectErrors}
        newProjectName={newProjectName}
        setNewProjectName={setNewProjectName}
        setNewProjectType={setNewProjectType}
        showAddProjectModal={showAddProjectModal}
      />

      <Confirm
        handleAccept={archive.handleAccept}
        showModal={archive.showModal}
        body={projectArchiveConfirmBody.replace('{project}', selectedProject?.name)}
        notes={CONFIRM_NOTES.PROJECT_ARCHIVED}
        title={CONFIRM_TITLES.PROJECT_ARCHIVED}
        handleClose={archive.setShowModal}
        loading={loading}
      />

      <Confirm
        handleAccept={duplicate.handleAccept}
        showModal={duplicate.showModal}
        body={CONFIRM_BODIES.PROJECT_DUPLICATED.replace('{project}', selectedProject?.name)}
        title={CONFIRM_TITLES.PROJECT_DUPLICATED}
        handleClose={duplicate.setShowModal}
        loading={loading}
      />
    </div>
  );
});

export default Projects;
