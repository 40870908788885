import { makeAutoObservable } from 'mobx';

const baseUserId = '645120fa1606e5fe2d9573ae';

class UsersStore {
  _user = {};

  constructor(root) {
    makeAutoObservable(this);
    this.root = root;

    const userId = localStorage.getItem('userId');

    if (!userId) {
      localStorage.setItem('userId', baseUserId);
    }

    this._user = {
      _id: userId || baseUserId,
    };
  }

  get user() {
    return this._user;
  }

  getUserId() {
    return this._user.sub;
  }

  setAccessToken = token => {
    this.root.restClient.authentication?.setAccessToken(token);
  };

  setUserInfo = userInfo => {
    if (userInfo) {
      this._user = { ...this._user, ...userInfo };
    }
  };
}

export default UsersStore;
