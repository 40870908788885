import { SENDER_TYPE } from 'constants/chat';
import Message from './Message';

class ImageMessage extends Message {
  constructor(sender, message) {
    super(sender, message.text);
    this.image = message.image || message.custom?.image;
  }

  static botMessage(botMessage) {
    return new ImageMessage(SENDER_TYPE.BOT, botMessage);
  }
}

export default ImageMessage;
