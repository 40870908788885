import { notify, Notification } from '@akiunlocks/perseus-ui-components';

import { reportError } from 'utils/log';

/**
 * Wraps an API request (or many) into a try/catch block in order to display
 * a success notification if everything was executed correctly or an error
 * notification if something failed in API
 * @param {function} fn - Asynchronous function wrapping API request(s)
 * @param {string} message -  Text to be displayed in a toast notification
 * @param {function} options.onError - Callback function to handle errors
 * @returns {Promise<boolean>} - True if the API request(s) was executed correctly,
 * false otherwise
 */
export const apiRequestWithNotifications = async (fn, message, { onError = () => {} } = {}) => {
  try {
    await fn();
    notify(message, Notification.TYPE.SUCCESS);
  } catch (err) {
    reportError(err);
    onError(err);
    return false;
  }
  return true;
};

/**
 * Shows error notification.
 * @param {string} message - Error message.
 */
export function notifyError(message) {
  notify(message, Notification.TYPE.ERROR, { autoClose: false });
}

/**
 * Shows success notification.
 * @param {string} message - Success message.
 */
export function notifySuccess(message) {
  notify(message, Notification.TYPE.SUCCESS);
}
