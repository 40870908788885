import { SENDER_TYPE } from 'constants/chat';

class Message {
  sender;
  senderType;
  text;
  timestamp;

  constructor(sender, text, value) {
    this.sender = sender;
    if (sender === SENDER_TYPE.BOT) {
      this.senderType = sender;
    } else {
      this.senderType = SENDER_TYPE.USER;
    }
    this.text = text;
    this.value = value || text;
    this.timestamp = new Date();
  }

  getPayload() {
    return {
      sender: this.sender,
      message: this.value,
    };
  }

  static userMessage(sender, text, value) {
    return new Message(sender, text, value);
  }

  static botMessage(message = {}) {
    return new Message(SENDER_TYPE.BOT, message.text);
  }
}

export default Message;
