import React from 'react';
import { observer } from 'mobx-react';
import { Col, Form, Row } from 'react-bootstrap';

import { Icon } from '@akiunlocks/perseus-ui-components';

import { DEFAULT_FONT_STYLE, FONT_STYLES_OPTIONS } from 'constants';

import AlignButtons from 'components/Sidebar/components/Align';
import LabeledInput from 'components/Sidebar/components/LabeledInput';
import LabeledSelect from 'components/Sidebar/components/LabeledSelect';
import Shadow from 'components/Sidebar/components/Shadow';
import SidebarPanel from 'components/Sidebar/components/SidebarPanel';
import SidebarItem from 'components/Sidebar/components/SidebarPanel/SidebarItem';
import TextAlignButtons from 'components/Sidebar/components/TextAlign';
import TextDecorationButtons from 'components/Sidebar/components/TextDecoration';
import VerticalAlign from 'components/Sidebar/components/VerticalAlign';

import AdjustTo from './AdjustTo';
import AnchorPoints from '../AnchorPoints';
import CheckboxColorPicker from '../CheckboxColorPicker';
import HideForm from '../HideForm';
import PositionForm from '../PositionForm';
import RotationOpacityForm from '../RotationOpacityForm';
import SizeForm from '../SizeForm';

import useTextboxProperties from './TextboxProperties.hooks';
import { USE_RESPONSIVE } from 'utils/featureFlag';

const TextboxProperties = observer(({ disabled, isTimelineLayersTab }) => {
  const {
    fontsOptions,
    onAdjustOptionsChanged,
    onAlignChange,
    onAngleChange,
    onCharSpacingChange,
    onFontFamilyChange,
    onFontSizeChange,
    onFontStyleChange,
    onHeightChange,
    onHideChange,
    onLeftChange,
    onLineHeightChange,
    onMaxLinesChange,
    onLockProportionsChange,
    onOpacityChange,
    onShadowColorChange,
    onShadowEnabledChanged,
    onShadowOffsetBlurChange,
    onShadowOffsetXChange,
    onShadowOffsetYChange,
    onTextAlignChange,
    onTextColorChange,
    onTextDecorationChange,
    onTopChange,
    onVerticalAlignChange,
    onWidthChange,
    textboxStore,
  } = useTextboxProperties();

  const editDisabled = disabled || !isTimelineLayersTab;

  return (
    <SidebarPanel title="Text">
      <SidebarItem disabled={disabled}>
        <AlignButtons disabled={disabled} onClick={onAlignChange} />
      </SidebarItem>
      <SidebarItem disabled={editDisabled}>
        <SizeForm
          height={textboxStore.height}
          onHeightChange={onHeightChange}
          onLockProportionsChange={onLockProportionsChange}
          onWidthChange={onWidthChange}
          showLockProportions={false}
          uniformScaling={textboxStore.uniformScaling}
          width={textboxStore.width}
        />
      </SidebarItem>
      <SidebarItem disabled={editDisabled}>
        {USE_RESPONSIVE ? (
          <AnchorPoints />
        ) : (
          <PositionForm
            left={textboxStore.unrotatedLeft}
            onLeftChange={onLeftChange}
            onTopChange={onTopChange}
            top={textboxStore.unrotatedTop}
          />
        )}
      </SidebarItem>
      <SidebarItem disabled={editDisabled}>
        <RotationOpacityForm
          angle={textboxStore.angle}
          onAngleChange={onAngleChange}
          onOpacityChange={onOpacityChange}
          opacity={textboxStore.objectOpacity}
        />
      </SidebarItem>
      <SidebarItem disabled={editDisabled}>
        <HideForm hide={textboxStore.hide} onHideChange={onHideChange} />
      </SidebarItem>
      <SidebarItem disabled={editDisabled}>
        <Form.Group>
          <LabeledSelect
            id="font-family"
            label="Font"
            options={fontsOptions}
            value={fontsOptions.find(f => f.label === textboxStore.fontFamily)}
            onChange={onFontFamilyChange}
          />
        </Form.Group>
      </SidebarItem>
      <SidebarItem disabled={editDisabled}>
        <Form.Group>
          <Row>
            <Col xs={4}>
              <Form.Label>Size</Form.Label>
              <LabeledInput
                id="font-size"
                direction="vertical"
                controlProps={{
                  placeholder: '',
                  value: textboxStore.fontSize,
                  type: 'number',
                  step: 1.0,
                  onChange: onFontSizeChange,
                }}
              />
            </Col>
            <Col xs={8}>
              <LabeledSelect
                label="Type"
                id="font-style"
                options={FONT_STYLES_OPTIONS}
                value={FONT_STYLES_OPTIONS.find(f => f.label === textboxStore.fontStyle) || DEFAULT_FONT_STYLE}
                onChange={onFontStyleChange}
              />
            </Col>
          </Row>
        </Form.Group>
      </SidebarItem>
      <SidebarItem disabled={editDisabled}>
        <AdjustTo
          adjustOption={textboxStore.adjustOption}
          maxLines={textboxStore.maxLines}
          onOptionChange={onAdjustOptionsChanged}
          onMaxLinesChange={onMaxLinesChange}
        />
      </SidebarItem>
      <SidebarItem disabled={editDisabled}>
        <Row className="my-1">
          <Col>
            <TextAlignButtons className="me-2" textAlign={textboxStore.textAlign} onChange={onTextAlignChange} />
            <VerticalAlign verticalAlign={textboxStore.verticalAlign} onChange={onVerticalAlignChange} />
          </Col>
        </Row>
      </SidebarItem>
      <SidebarItem disabled={editDisabled}>
        <Row className="mb-2">
          <Col xs={6}>
            <TextDecorationButtons
              textDecoration={textboxStore.getActiveTextDecoration()}
              onChange={onTextDecorationChange}
            />
          </Col>
        </Row>
      </SidebarItem>
      <SidebarItem disabled={editDisabled}>
        <Row className="mb-2">
          <Col>
            <LabeledInput
              id="letter-spacing"
              label={<Icon size={20} name="tb-letter-spacing" />}
              controlProps={{
                placeholder: '',
                type: 'number',
                value: textboxStore.charSpacing / 1000,
                min: 0,
                max: 20,
                step: 0.1,
                onChange: onCharSpacingChange,
              }}
            />
          </Col>
          <Col>
            <LabeledInput
              id="line-height"
              label={<Icon size={20} name="tb-line-height" />}
              controlProps={{
                placeholder: '',
                type: 'number',
                value: textboxStore.lineHeight,
                min: 0,
                max: 5,
                step: 0.1,
                onChange: onLineHeightChange,
              }}
            />
          </Col>
        </Row>
      </SidebarItem>
      <SidebarItem disabled={editDisabled}>
        <CheckboxColorPicker
          checkboxVisible={false}
          id="text-color"
          inline
          label="Color"
          onColorChange={onTextColorChange}
          value={textboxStore.fill}
        />
      </SidebarItem>
      <SidebarItem disabled={editDisabled}>
        <Shadow
          className="mb-2"
          enabled={textboxStore.shadowEnabled}
          onEnabledChanged={onShadowEnabledChanged}
          color={textboxStore.shadowColor}
          onColorChange={onShadowColorChange}
          offsetX={textboxStore.shadowOffsetX}
          onOffsetXChange={onShadowOffsetXChange}
          offsetY={textboxStore.shadowOffsetY}
          onOffsetYChange={onShadowOffsetYChange}
          blur={textboxStore.shadowBlur}
          onBlurChange={onShadowOffsetBlurChange}
        />
      </SidebarItem>
    </SidebarPanel>
  );
});

export default TextboxProperties;
