import { ADJUST_MARGIN, ANIMATION_DURATION, MAX_ZOOM, MIN_ZOOM } from 'constants/editor';
import { HOTKEYS } from 'constants/hotkeys';
import { clip } from 'utils/numbers';

/**
 * Calculates the zoom level to fit an element within the canvas dimensions.
 * @param {number} width - The width of the element to fit.
 * @param {number} height - The height of the element to fit.
 * @param {number} canvasWidth - The width of the canvas.
 * @param {number} canvasHeight - The height of the canvas.
 * @return {number|null} The calculated zoom level, or null if input dimensions are invalid.
 */
export function calculateZoom(width, height, canvasWidth, canvasHeight) {
  if (!width || !height || !canvasWidth || !canvasHeight) {
    return null;
  }

  const scaleWidth = canvasWidth / width;
  const scaleHeight = canvasHeight / height;
  const adjustToWidth = scaleWidth < scaleHeight; // true -> adjust width, false -> adjust height
  const adjustSize = adjustToWidth ? width : height;
  const canvasAdjustSize = adjustToWidth ? canvasWidth : canvasHeight;

  const zoom = (canvasAdjustSize - ADJUST_MARGIN) / adjustSize;
  return clip(zoom, MIN_ZOOM, MAX_ZOOM);
}

/**
 * Given a list of keyframes, checks if they can be moved in the timeline.
 * Keyframes can be from different layers, and they can be unordered.
 * @param {string} arrowKey - Left or right arrow key.
 * @param {array} keyframes - List of keyframes to move.
 * @return {boolean} True if keyframes can be moved, else false.
 */
export function canMoveKeyframes(arrowKey, keyframes) {
  const isLeft = arrowKey === HOTKEYS.ARROW_LEFT;
  const isRight = arrowKey === HOTKEYS.ARROW_RIGHT;
  if (!isLeft && !isRight) {
    return false;
  }
  const times = keyframes.map(({ time }) => time);
  const minTime = Math.min(...times);
  const maxTime = Math.max(...times);
  if ((isLeft && minTime <= 0) || (isRight && maxTime >= ANIMATION_DURATION)) {
    return false;
  }
  return true;
}
