import { fabric } from 'fabric';
import { HASH_MARK_CONFIG, RULER_TYPE, RULER_SIZE, TEXT_CONFIG } from '../constants/ruler.js';

/**
 * Generates an array of values based on the specified start, finish, and step.
 * @param {number} start - The starting value of the array.
 * @param {number} finish - The ending value of the array.
 * @param {number} step - The step size between values in the array.
 * @return {number[]} An array of values ranging from start to finish with the specified step.
 */
export function getRulerArray(start, finish, step) {
  return Array.from({ length: Math.round((finish - start + 1) / step) }, (_, i) => start + i * step);
}

/**
 * Calculates the shift based on the offset, step, and zoom level.
 * @param {number} offset - The offset value.
 * @param {number} step - The step size between values.
 * @param {number} zoomLevel - The zoom level.
 * @return {number} The calculated shift value.
 */
export function getShift(offset, step, zoomLevel) {
  return offset % (step * zoomLevel);
}

/**
 * Gets a hash mark line for a ruler.
 * @param {number} value - The position value for the hash mark.
 * @param {string} rulerType - The type of ruler (`RULER_TYPE.TOP` or `RULER_TYPE.LEFT`).
 * @param {boolean} hasLabel - Indicates whether the ruler has a label.
 * @return {fabric.Line} - A fabric.js Line object representing the hash mark line.
 */
export function getHashMark(value, rulerType, hasLabel) {
  const hashMarkStart = hasLabel ? 0 : 11;
  // eslint-disable-next-line operator-linebreak
  const linePoints =
    rulerType === RULER_TYPE.TOP
      ? [value, hashMarkStart, value, RULER_SIZE]
      : [hashMarkStart, value, RULER_SIZE, value];
  return new fabric.Line(linePoints, HASH_MARK_CONFIG);
}

/**
 * Gets a label for a hash mark on a ruler.
 * @param {number} value - The position value for the hash mark.
 * @param {number} currentIndex - The current index of the hash mark in the entire canvas.
 * @param {number} startIndex - The start index of the main canvas.
 * @param {number} step - The step value between hash marks.
 * @param {string} rulerType - The type of ruler (`RULER_TYPE.TOP` or `RULER_TYPE.LEFT`).
 * @return {fabric.Text} - A fabric.js Text object representing the hash mark label.
 */
export function getHashMarkLabel(value, currentIndex, startIndex, step, rulerType) {
  const label = ((currentIndex - startIndex) * step).toString();
  // eslint-disable-next-line operator-linebreak
  const textConfig =
    rulerType === RULER_TYPE.TOP ? { ...TEXT_CONFIG, left: value + 2 } : { ...TEXT_CONFIG, top: value - 2, angle: -90 };
  return new fabric.Text(label, textConfig);
}
