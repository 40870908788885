import { fabricClone, getUnrotatedPosition } from '@prisma/lib/src/utils/helpers';

import { MODIFIED_ACTIONS } from 'constants/objects';
import { TIMELINE_STEP } from './constants';

/**
 * Checks if the position of the object changed using the unrotated position.
 * @param {Object} original - Object with the original position.
 * @param {Object} target - Object with the actual position.
 * @return {boolean} True if unrotated position changed, else false.
 */
export function checkIfPositionChanged(original, target) {
  // create a temporary original object
  const originalObj = fabricClone(target);
  originalObj.setOptions(original);

  const { x: originalLeft, y: originalTop } = getUnrotatedPosition(originalObj);
  const { x: targetLeft, y: targetTop } = getUnrotatedPosition(target);

  return Math.round(targetTop) !== Math.round(originalTop) || Math.round(targetLeft) !== Math.round(originalLeft);
}

/**
 * Given the time in miliseconds, it rounds it to the nearest 100.
 * @param {number} time - Time in miliseconds
 * @return {number} Rounded time
 */
export function getTimelineStep(time) {
  return Math.round(time / TIMELINE_STEP) * TIMELINE_STEP;
}

/**
 * Scale canvas object proportionally if needed.
 * @param {object} object - Canvas object.
 * @param {MODIFIED_ACTIONS} action - Type of action. Should be scaling type.
 * @param {object} originalScale - Object with the original scaleX and scaleY properties before the scaling was applied.
 */
export function applyUniformScalingIfNeeded(object, action, originalScale) {
  let { scaleX, scaleY } = originalScale;

  // If uniformScaling is true scale object proportionally by setting scaleX and scaleY to be equal
  if (object.uniformScaling && action === MODIFIED_ACTIONS.SCALE_X) {
    object.scaleY = scaleY - (scaleX - object.scaleX);
  }
  if (object.uniformScaling && action === MODIFIED_ACTIONS.SCALE_Y) {
    object.scaleX = scaleX - (scaleY - object.scaleY);
  }
}

/**
 * Format milliseconds to seconds and milliseconds
 * @param {number} time - Milliseconds to format.
 * @return {string} Fomatted time.
 */
export function formatMilliseconds(time) {
  const date = new Date(time);
  const seconds = date.getSeconds().toString().padStart(2, '0');
  const milliseconds = date.getMilliseconds().toString().padStart(3, '0');
  return `${seconds}:${milliseconds} ms`;
}
