import { makeObservable } from 'mobx';

import ShapeStore from './Shape.store';

class CircleStore extends ShapeStore {
  constructor() {
    super();
    makeObservable(this, { ...this.props, ...this.shapeProps });
  }
}

export default CircleStore;
