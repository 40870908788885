import { useCallback } from 'react';

import { useRootStore } from '../store';
import debounce from 'lodash/debounce';

const DELAY = 500;

const useEditorActiveObject = () => {
  const { editor } = useRootStore();
  const { canvas } = editor;

  // After editing the active object we have to fire the 'history' event to update the undo/redo history
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const callModifiedEvent = useCallback(
    debounce(() => {
      canvas.fire('history');
    }, DELAY),
    [canvas],
  );

  const setObjectsAttribute = (objects, ...args) => {
    objects.forEach(object => {
      // ...args can be a pair attribue-vale or an object with props to set
      object.set(...args);
    });
    canvas.renderAll();
    callModifiedEvent();
  };

  const setActiveObjectAttribute = (...args) => {
    setObjectsAttribute(canvas.getActiveObjects(), ...args);
  };

  const setObjectAttribute = (object, ...args) => {
    setObjectsAttribute([object], ...args);
  };

  return { setActiveObjectAttribute, setObjectAttribute };
};

export default useEditorActiveObject;
