import React from 'react';
import { observer } from 'mobx-react';
import { Checkbox } from '@akiunlocks/perseus-ui-components';

import { UNITS } from '@prisma/lib/src/constants/objects';
import { animationTypes, positionOffsetsKeys, typesToFrameKeys } from '@prisma/lib/src/utils/types';

import { useRootStore } from 'store';

import { ANIMATION_KEY_PROPERTY } from 'constants/objects';
import { USE_RESPONSIVE } from 'utils/featureFlag';

import { useCanvas } from 'containers/Editor/hooks/useCanvas';

import AnimationInput from './AnimationInput/AnimationInput';

import {
  getAnimationValueFromInput,
  getAnimationValueFromObject,
  scaledPxToPercentage,
  scalingPercentageToPx,
} from './utilities';

const AnimationValuesContainer = observer(({ activeKeyframe, animationType, disabled, layer, values }) => {
  const { editor } = useRootStore();
  const { timelineSceneStore } = editor;
  const { activeLayer, currentTime } = timelineSceneStore;
  const isHideAnimation = animationType === animationTypes.hide;
  const layerId = layer.id;
  const { retinaScalingFactor } = layer.properties;

  const handleKeyframeEdit = (updatedKey, value, layerId) => {
    if (!activeKeyframe || activeLayer?.id !== layerId) {
      return;
    }
    activeKeyframe.properties[updatedKey] = value;
    editor.canvas.addOrEditKeyframes.call(editor.canvas, activeKeyframe, activeLayer.id);
    editor?.canvas?.engine?.moveTo(currentTime);
  };

  return (
    <>
      {values && (
        <div className="anim-input">
          {typesToFrameKeys[animationType].map(k => {
            const positionOffsetValue = values[positionOffsetsKeys[k]];
            let value = getAnimationValueFromObject(
              k,
              values,
              animationType,
              positionOffsetValue,
              retinaScalingFactor,
              editor?.canvas,
            );

            const property = ANIMATION_KEY_PROPERTY[k];
            const unit = values[`${property}Unit`];
            const { getRelativeValue } = useCanvas(property);
            const isPosition = animationType === animationTypes.position;
            const isScaling = animationType === animationTypes.scale;

            let unitChanged = false;
            if (USE_RESPONSIVE) {
              if (isPosition && unit === UNITS.PERCENTAGE) {
                // as value is in pixels, apply conversion if position should be displayed in percentage
                value = getRelativeValue(value, UNITS.PERCENTAGE);
                unitChanged = true;
              } else if (isScaling && unit === UNITS.PIXELS) {
                // as value is in percentage, apply conversion if scaling should be displayed in pixels
                value = scalingPercentageToPx(values[property], value, retinaScalingFactor);
                unitChanged = true;
              }
            }

            return (
              <div className="values-row" key={k}>
                {isHideAnimation && (
                  <Checkbox
                    id="hide-animation"
                    disabled={disabled}
                    checked={value}
                    onChange={({ target }) => {
                      handleKeyframeEdit(k, target.checked, layerId);
                    }}
                  />
                )}
                {!isHideAnimation && (
                  <AnimationInput
                    animationType={animationType}
                    id={`animation-${layerId}-${k}`}
                    value={value}
                    disabled={disabled}
                    inputType="number"
                    onChange={value => {
                      if (unitChanged) {
                        if (isPosition) {
                          // value was transformed to percentage before, so get pixels value again
                          value = getRelativeValue(value, UNITS.PIXELS);
                        } else if (isScaling) {
                          // value was transformed to pixels before, so get percentage value again
                          value = scaledPxToPercentage(value, values[property], retinaScalingFactor);
                        }
                      }
                      value = getAnimationValueFromInput(
                        value,
                        animationType,
                        positionOffsetValue,
                        retinaScalingFactor,
                        property,
                        values,
                      );
                      handleKeyframeEdit(k, value, layerId);
                    }}
                    property={k}
                    unit={unit}
                  />
                )}
              </div>
            );
          })}
        </div>
      )}
    </>
  );
});

export default AnimationValuesContainer;
