import { useCallback } from 'react';

import { getFontUrl, loadFontIfNeeded } from '@prisma/lib/src/utils/fonts';
import { ADJUST_OPTIONS } from '@prisma/lib/src/constants/text';

import { useRootStore } from 'store';
import { HEIGHT, WIDTH } from 'constants';

import useEditorActiveObject from 'utils/useEditorActiveObject';

import useActiveObjectProperties from '../useActiveObjectProperties';

const useTextboxProperties = () => {
  const { editor } = useRootStore();
  const { activeObject, fontStore, textboxStore } = editor;
  const { setActiveObjectAttribute } = useEditorActiveObject();

  const {
    onAlignChange,
    onAngleChange,
    onHideChange,
    onLeftChange,
    onLockProportionsChange,
    onOpacityChange,
    onShadowColorChange,
    onShadowEnabledChanged,
    onShadowOffsetBlurChange,
    onShadowOffsetXChange,
    onShadowOffsetYChange,
    onTopChange,
    setAttribute,
  } = useActiveObjectProperties(textboxStore);

  /**
   * Set size for the textbox.
   * If uniform scaling is enabled, the opposite attribute will be updated as well.
   * If uniform scaling is disabled, only the attribute will be updated.
   * For textboxes, we don't change the scale, we change the actual width and height.
   *
   * @param {string} attribute - width or height
   * @param {number} value - new size
   * @returns {void}
   */
  const setSize = useCallback(
    (attribute, value) => {
      if (!activeObject) {
        return;
      }
      const newValue = Math.max(value, 1);
      const oppositeAttribute = attribute === WIDTH ? HEIGHT : WIDTH;
      textboxStore.setAttribute(attribute, Math.round(newValue));
      if (textboxStore.uniformScaling) {
        const ratio = activeObject[oppositeAttribute] / activeObject[attribute];
        const oppositeValue = newValue * ratio;
        textboxStore.setAttribute(oppositeAttribute, Math.round(oppositeValue));
        setActiveObjectAttribute({
          [attribute]: newValue,
          [oppositeAttribute]: oppositeValue,
        });
      } else {
        setActiveObjectAttribute({
          [attribute]: newValue,
        });
      }
    },
    [activeObject, textboxStore, setActiveObjectAttribute],
  );

  const recalculateFontSize = useCallback(() => {
    if (activeObject.recalculateFontSize()) {
      textboxStore.setAttribute('fontSize', activeObject.fontSize);
    }
  }, [activeObject, textboxStore]);

  /**
   * Update the max lines for the textbox.
   */
  const updateMaxLines = useCallback(() => {
    textboxStore.setMaxLines(activeObject.getNumberOfTextLines());
    recalculateFontSize();
  }, [textboxStore, activeObject, recalculateFontSize]);

  const onFontFamilyChange = useCallback(
    ({ target }) => {
      const name = target.option.label;
      const fontFileName = target.value;
      const url = getFontUrl({ fontFileName });
      loadFontIfNeeded({ name, text: activeObject?.text, url }, loaded => {
        if (!loaded) {
          editor.notifyNotLoadedFonts([name]);
        }
        setAttribute('fontFamily', name);
        setAttribute('fontFileName', fontFileName);
        updateMaxLines();
      });
    },
    [activeObject?.text, editor, setAttribute, updateMaxLines],
  );

  const onFontSizeChange = useCallback(
    ({ target }) => {
      setAttribute('fontSize', target.value);
      if (activeObject.isIntoNextLine()) {
        textboxStore.setAdjustOption(ADJUST_OPTIONS.FONT_SIZE);
      }
      updateMaxLines();
    },
    [setAttribute, textboxStore, activeObject, updateMaxLines],
  );

  const onFontStyleChange = useCallback(
    ({ target }) => {
      setAttribute('fontStyle', target.value);
    },
    [setAttribute],
  );

  const onHeightChange = useCallback(
    ({ target }) => {
      setSize(HEIGHT, target.value);
    },
    [setSize],
  );

  const onCharSpacingChange = useCallback(
    ({ target }) => {
      setAttribute('charSpacing', target.value * 1000);
      updateMaxLines();
    },
    [setAttribute, updateMaxLines],
  );

  const onLineHeightChange = useCallback(
    ({ target }) => {
      setAttribute('lineHeight', target.value);
    },
    [setAttribute],
  );

  const onAdjustOptionsChanged = useCallback(
    adjustOption => {
      textboxStore.setAdjustOption(adjustOption);
      const options = { maxLines: undefined, maxHeight: undefined };
      if (adjustOption === ADJUST_OPTIONS.LINES) {
        options.maxLines = textboxStore.maxLines;
      } else {
        textboxStore.setMaxLines(activeObject.getNumberOfTextLines());
        if (adjustOption === ADJUST_OPTIONS.TEXTBOX) {
          options.maxHeight = activeObject.height;
        }
      }
      setActiveObjectAttribute(options);
      recalculateFontSize();
    },
    [activeObject, textboxStore, recalculateFontSize, setActiveObjectAttribute],
  );

  const onMaxLinesChange = useCallback(
    ({ target }) => {
      if (Number(target.value) < 1) {
        return;
      }
      textboxStore.setMaxLines(Number(target.value));
      onAdjustOptionsChanged(ADJUST_OPTIONS.LINES);
    },
    [textboxStore, onAdjustOptionsChanged],
  );

  const onTextColorChange = useCallback(
    ({ target }) => {
      setAttribute('fill', target.value);
    },
    [setAttribute],
  );

  const onTextAlignChange = useCallback(
    textAlign => {
      setAttribute('textAlign', textAlign);
    },
    [setAttribute],
  );

  const onTextDecorationChange = useCallback(
    value => {
      if (!activeObject) {
        return;
      }
      textboxStore.setTextDecorationValue(value);
      setActiveObjectAttribute(textboxStore.getTextDecoration());
    },
    [activeObject, setActiveObjectAttribute, textboxStore],
  );

  const onVerticalAlignChange = useCallback(
    textAlign => {
      setAttribute('verticalAlign', textAlign);
    },
    [setAttribute],
  );

  const onWidthChange = useCallback(
    ({ target }) => {
      setSize(WIDTH, target.value);
    },
    [setSize],
  );

  return {
    fontsOptions: fontStore.getOptions(),
    onAdjustOptionsChanged,
    onAlignChange,
    onAngleChange,
    onCharSpacingChange,
    onFontFamilyChange,
    onFontSizeChange,
    onFontStyleChange,
    onHeightChange,
    onHideChange,
    onLeftChange,
    onLineHeightChange,
    onLockProportionsChange,
    onMaxLinesChange,
    onOpacityChange,
    onShadowColorChange,
    onShadowEnabledChanged,
    onShadowOffsetBlurChange,
    onShadowOffsetXChange,
    onShadowOffsetYChange,
    onTextAlignChange,
    onTextColorChange,
    onTextDecorationChange,
    onTopChange,
    onVerticalAlignChange,
    onWidthChange,
    textboxStore,
  };
};

export default useTextboxProperties;
