import { useCallback, useEffect, useState } from 'react';

import { validateProjectSizeName } from 'utils/validations';

const useNameInput = ({ projectSize, projectSizes, onSubmit } = {}) => {
  const [name, setName] = useState(projectSize?.name || '');
  const [validationError, setValidationError] = useState(null);
  const projectSizesList = projectSizes?.filter(ps => ps._id !== projectSize?._id).map(ps => ps.name);

  const reset = useCallback(() => {
    setValidationError(null);
    setName(projectSize?.name || '');
  }, [projectSize]);

  useEffect(() => {
    reset();
  }, [reset]);

  const validate = value => {
    const validationMessage = validateProjectSizeName(value, projectSizesList);
    if (validationMessage) {
      setValidationError({
        message: validationMessage,
        props: { type: 'invalid' },
      });
    } else {
      setValidationError(null);
    }
  };

  const onInputChange = ({ target }) => {
    setName(target.value);
    validate(target.value);
  };

  const onBlur = () => {
    validate(name);
  };

  const onKeyPress = ({ key }) => {
    if (onSubmit && key === 'Enter' && !validationError) {
      onSubmit(name);
    }
  };

  return {
    inputEvents: {
      onBlur,
      onInputChange,
      onKeyPress,
    },
    name,
    reset,
    validationError,
  };
};

export default useNameInput;
