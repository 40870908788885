import PropTypes from 'prop-types';
import React from 'react';
import { ReactKeycloakProvider } from '@react-keycloak/web';

import { keycloak } from 'auth/keycloak';
import Loading from './Loading';
import { useRootStore } from '../store';

const initOptions = { onLoad: 'login-required', checkLoginIframe: false };

const KeycloakProvider = ({ children }) => {
  const { users: usersStore } = useRootStore();

  const onKeycloakTokens = ({ token }) => {
    usersStore.setAccessToken(token);
  };

  return (
    <ReactKeycloakProvider
      authClient={keycloak}
      initOptions={initOptions}
      onTokens={onKeycloakTokens}
      LoadingComponent={<Loading />}
    >
      {children}
    </ReactKeycloakProvider>
  );
};

KeycloakProvider.propTypes = {
  children: PropTypes.any.isRequired,
};

export default KeycloakProvider;
