import React from 'react';
import PropTypes from 'prop-types';

import {
  ACTIVE_SELECTION,
  PRISMA_CIRCLE,
  PRISMA_GROUP,
  PRISMA_GUIDELINE,
  PRISMA_IMAGE,
  PRISMA_MASK,
  PRISMA_RECT,
  PRISMA_TEXT,
} from '@prisma/lib/src/constants';

import { objectsAreFromSameGroup } from 'containers/Editor/stores/utilities/groups';

import CanvasProperties from './components/CanvasProperties';
import GroupProperties from './components/GroupProperties';
import ImageProperties from './components/ImageProperties';
import MaskProperties from './components/MaskProperties';
import MultiSelectProperties from './components/MultiSelectProperties';
import ShapeProperties from './components/ShapeProperties';
import TextboxProperties from './components/TextboxProperties';

const propertiesPanels = {
  [ACTIVE_SELECTION]: MultiSelectProperties,
  [PRISMA_CIRCLE]: ShapeProperties,
  [PRISMA_GROUP]: GroupProperties,
  [PRISMA_GUIDELINE]: CanvasProperties,
  [PRISMA_IMAGE]: ImageProperties,
  [PRISMA_MASK]: MaskProperties,
  [PRISMA_RECT]: ShapeProperties,
  [PRISMA_TEXT]: TextboxProperties,
  undefined: CanvasProperties,
};

const PropertiesPanel = ({ activeObject, isGroupSelected, isTimelineLayersTab }) => {
  const PropertiesPanel = activeObject && isGroupSelected ? GroupProperties : propertiesPanels[activeObject?.type];
  const groupLayersSelected =
    activeObject?.type === ACTIVE_SELECTION && !isGroupSelected && objectsAreFromSameGroup(activeObject.getObjects());

  return (
    <PropertiesPanel
      disabled={!activeObject?.type}
      groupLayersSelected={groupLayersSelected}
      isTimelineLayersTab={isTimelineLayersTab}
    />
  );
};

PropertiesPanel.propTypes = {
  /** Active object. Can be a single object, a group, or an active selection. */
  activeObject: PropTypes.object,
  /** Flag to indicate if a single group is selected. */
  isGroupSelected: PropTypes.bool,
  /** Flag to indicate if current tab is Layers tab. */
  isTimelineLayersTab: PropTypes.bool,
};

PropertiesPanel.defaultProps = {
  activeObject: {},
  isGroupSelected: false,
  isTimelineLayersTab: false,
};

export default PropertiesPanel;
