import { EVENT_TYPE } from 'constants/chat';

class Event {
  eventType;
  sender;
  data;

  constructor(eventType, sender, data) {
    this.eventType = eventType;
    this.sender = sender;
    this.data = data;
  }

  getPayload() {
    return {
      event: this.eventType,
      ...this.data,
    };
  }

  static slotEvent(sender, name, value) {
    return new Event(EVENT_TYPE.SLOT, sender, { name, value });
  }

  static slotEvents(sender, eventsData) {
    return Object.entries(eventsData).map(([name, value]) => {
      return Event.slotEvent(sender, name, value);
    });
  }
}

export default Event;
