import classnames from 'classnames';
import { observer } from 'mobx-react';

import { useRootStore } from 'store';

import { PRISMA_IMAGE } from '@prisma/lib/src/constants';

import { FOLDER } from 'constants/assets';
import { getHiddenRootStyle } from './utilities';

import Actions from './Actions';
import AssetName from './AssetName';
import AssetThumbnail from './AssetThumbnail';
import RenameAsset from './RenameAsset';

const Node = observer(({ node, style }) => {
  const { editor } = useRootStore();
  const { assetStore } = editor;

  const { data, isEditing, isLeaf, isSelected, parent } = node;

  const customStyle = getHiddenRootStyle(node, style);

  const selectedAssetsIds = editor.selectionStore.getSelectedAssetsIds();
  const selected = isSelected || selectedAssetsIds.includes(data.id);

  return (
    <div
      className={classnames('asset', { selected, 'drag-over': assetStore.dragOverId === data.id })}
      data-id={data.id}
      data-type={isLeaf ? PRISMA_IMAGE : FOLDER}
      data-url={data.imageUrl}
      draggable
      style={customStyle}
      onDragLeave={() => assetStore.setDragOverId(null)}
      onDragStart={event => {
        // include dragged asset in selection
        const isMultiSelect = event.metaKey || event.shiftKey;
        editor.selectionStore.addAssetsToSelection([data.id], isMultiSelect);
      }}
      onDragOver={() => {
        const dragOverId = isLeaf ? parent.data.id : data.id;
        assetStore.setDragOverId(dragOverId);
      }}
      onDrop={e => {
        e.stopPropagation();
        node.open(); // ensure parent node is open
        const parentId = isLeaf ? parent.data.id : data.id;
        const selectedAssetIds = editor.selectionStore.getSelectedAssetsIds();
        assetStore.onMove({ dragIds: selectedAssetIds, parentId });
        assetStore.setDragOverId(null);
      }}
    >
      <div className={classnames('row-title', { 'w-100': isEditing })}>
        <AssetThumbnail node={node} />
        <RenameAsset node={node} />
        <AssetName node={node} />
        <Actions node={node} />
      </div>
    </div>
  );
});

export default Node;
