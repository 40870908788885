import { useCallback } from 'react';
import { Notification, notify } from '@akiunlocks/perseus-ui-components';

import { STATUS } from '../utils';
import { useRootStore } from '../../../store';
import { NOTIFY_MESSAGES } from '../../../utils';

const useFavorites = ({ filters, setStatus }) => {
  const {
    projects: { updateProjectFavorite, getProjects },
  } = useRootStore();

  const setCurrentProjectAsFavorite = useCallback(
    async item => {
      await setStatus(STATUS.LOADING);

      try {
        await updateProjectFavorite(item);

        notify(
          !item.favorite ? NOTIFY_MESSAGES.PROJECT_MOVE_TO_FAVORITES : NOTIFY_MESSAGES.PROJECT_MOVE_FROM_FAVORITES,
          Notification.TYPE.SUCCESS,
          {
            autoClose: true,
          },
        );

        getProjects(filters);
      } catch (e) {
        notify(NOTIFY_MESSAGES.ERROR, Notification.TYPE.ERROR, { autoClose: true });

        setStatus(STATUS.SUCCESS);
      }
    },
    [setStatus, updateProjectFavorite, getProjects, filters],
  );

  return {
    setCurrentProjectAsFavorite,
  };
};

export default useFavorites;
