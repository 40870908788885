import { PRISMA_MASK } from '@prisma/lib/src/constants';
import { createActiveSelection, getPositionFromActiveSelection } from '@prisma/lib/src/utils/helpers';
import { findLayerById } from '@prisma/lib/src/utils/layers';

import { ALIGN_HORIZONTAL, ALIGN_VERTICAL, BOTTOM, CANVAS, CENTER, END, MIDDLE, START, TOP } from 'constants/canvas';

import { setChildrenPositionIfGroup } from 'containers/Editor/stores/utilities/groups';

/**
 * Gets bounding rect of an object.
 * @param {object} object - Object.
 * @param {boolean} absolute - Use coordinates without viewportTransform.
 * @return {object} Object with left, top, width, height properties.
 */
export function getBoundingRect(object, absolute = true) {
  return object.getBoundingRect(absolute);
}

/**
 * Sets object position using center origin.
 * @param {object} object - Object.
 * @param {object} position - Object with x and y properties.
 */
export function setCenterPosition(object, position) {
  const originalCenterPoint = object.getCenterPoint();

  object.setPositionByOrigin(position, CENTER, CENTER);
  object.setCoords();

  setChildrenPositionIfGroup(object, originalCenterPoint);
}

/**
 * Sets object position to the container border (left, top, right or bottom) depending on alignment type and type of border.
 * @param {object} object - Object.
 * @param {number} borderPosition - Start or end position of the container.
 * @param {string} alignmentType - Vertical or horizontal.
 * @param {string} borderType - Start or end.
 */
export function moveObjectToContainerBorder(object, borderPosition, alignmentType, borderType) {
  const { height, left, top, width } = getBoundingRect(object);
  const halfHeight = height / 2;
  const halfWidth = width / 2;

  let position;

  if (borderType === START) {
    position =
      alignmentType === ALIGN_HORIZONTAL
        ? { x: borderPosition + halfWidth, y: top + halfHeight } // move to left
        : { x: left + halfWidth, y: borderPosition + halfHeight }; // move to top
  } else {
    position =
      alignmentType === ALIGN_HORIZONTAL
        ? { x: borderPosition - halfWidth, y: top + halfHeight } // move to right
        : { x: left + halfWidth, y: borderPosition - halfHeight }; // move to bottom
  }

  setCenterPosition(object, position);
}

/**
 * Aligns single object to canvas.
 * If object is inside a group, the group limits will be considered as the canvas.
 * @param {object} object - Object.
 * @param {string} alignmentType - Type of alignment.
 */
export function align(object, alignmentType) {
  if (!object?.canvas) {
    return;
  }
  const { canvas } = object;

  // 1. get container position and size
  const banner = canvas._banner;
  const group = findLayerById(canvas.layers, object.groupId)?.target;
  let { height, left, top, width } = group ? group : banner;
  if (group?.group) {
    const position = getPositionFromActiveSelection(group);
    left = position.left;
    top = position.top;
  }

  // 2. align
  const boundingRect = getBoundingRect(object);

  switch (alignmentType) {
    case START:
      moveObjectToContainerBorder(object, left, ALIGN_HORIZONTAL, START);
      break;
    case CENTER:
      const centerX = left + width / 2;
      const objectHalfWidth = boundingRect.width / 2;
      moveObjectToContainerBorder(object, centerX - objectHalfWidth, ALIGN_HORIZONTAL, START);
      break;
    case END:
      moveObjectToContainerBorder(object, left + width, ALIGN_HORIZONTAL, END);
      break;
    case TOP:
      moveObjectToContainerBorder(object, top, ALIGN_VERTICAL, START);
      break;
    case MIDDLE:
      const centerY = top + height / 2;
      const objectHalfHeight = boundingRect.height / 2;
      moveObjectToContainerBorder(object, centerY - objectHalfHeight, ALIGN_VERTICAL, START);
      break;
    case BOTTOM:
      moveObjectToContainerBorder(object, top + height, ALIGN_VERTICAL, END);
      break;
    default:
      break;
  }
}

/**
 * Aligns multiple objects to selection or canvas.
 * @param {array} items - Objects.
 * @param {string} alignmentType - Type of alignment.
 * @param {string} boundary - Selection or canvas.
 */
export function alignMany(items, alignmentType, boundary) {
  if (!items) {
    return;
  }

  // 1. remove mask objects
  const objects = items.filter(({ type }) => type !== PRISMA_MASK);

  if (!objects.length || !objects[0].canvas) {
    return;
  }

  const canvas = objects[0].canvas;

  if (boundary === CANVAS) {
    // align each object individually and return
    canvas.discardActiveObject();
    objects.forEach(o => align(o, alignmentType));
    const selection = createActiveSelection(objects);
    canvas.setActiveObject(selection);
    return;
  }

  // 2. get boundaries of active selection and discard active object to get correct positions
  const activeSelection = canvas.getActiveObject();
  const { height, left, top, width } = getBoundingRect(activeSelection);
  canvas.discardActiveObject();

  // 3. align
  switch (alignmentType) {
    case START:
      objects.forEach(o => {
        moveObjectToContainerBorder(o, left, ALIGN_HORIZONTAL, START);
      });
      break;
    case CENTER:
      const centerX = left + width / 2;
      objects.forEach(o => {
        const objectBoundingRect = getBoundingRect(o);
        const objectHalfWidth = objectBoundingRect.width / 2;
        moveObjectToContainerBorder(o, centerX - objectHalfWidth, ALIGN_HORIZONTAL, START);
      });
      break;
    case END:
      objects.forEach(o => {
        moveObjectToContainerBorder(o, left + width, ALIGN_HORIZONTAL, END);
      });
      break;
    case TOP:
      objects.forEach(o => {
        moveObjectToContainerBorder(o, top, ALIGN_VERTICAL, START);
      });
      break;
    case MIDDLE:
      const centerY = top + height / 2;
      objects.forEach(o => {
        const objectBoundingRect = getBoundingRect(o);
        const objectHalfHeight = objectBoundingRect.height / 2;
        moveObjectToContainerBorder(o, centerY - objectHalfHeight, ALIGN_VERTICAL, START);
      });
      break;
    case BOTTOM:
      objects.forEach(o => {
        moveObjectToContainerBorder(o, top + height, ALIGN_VERTICAL, END);
      });
      break;
    default:
      break;
  }

  const selection = createActiveSelection(objects);
  canvas.setActiveObject(selection);
}
