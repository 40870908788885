import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { Button } from 'react-bootstrap';

import { Row, Col, ButtonGroup, Icon } from '@akiunlocks/perseus-ui-components';
import { statuses } from '@prisma/lib/src/utils/types';

import { LAYERS_TAB } from '../../../../constants';

import RulerDropdown from './RulerDropdown';
import useArtBoardControls from './ArtBoardControls.hooks';

import './ArtBoardControls.scss';

const ArtBoardControls = observer(({ artBoardRef, banner }) => {
  const {
    handlePause,
    handlePlay,
    handleRedo,
    handleStop,
    handleUndo,
    onZoomIn,
    onZoomOut,
    playStatus,
    redoDisabled,
    rulerDropdownSelect,
    timelineTab,
    undoDisabled,
  } = useArtBoardControls({
    banner,
    artBoardRef,
  });

  const isLayersTab = timelineTab === LAYERS_TAB;
  const isPlaying = playStatus === statuses.play;

  useEffect(() => {
    if (isLayersTab && isPlaying) {
      handleStop();
    }
  }, [handleStop, isLayersTab, isPlaying]);

  return (
    <>
      <RulerDropdown onSelect={rulerDropdownSelect} />
      <div className="buttons-wrapper">
        <ButtonGroup className="m-4 ms-8">
          <Button className="btn-light r-210" onClick={handleUndo} disabled={undoDisabled}>
            <Icon name="arrow-counterclockwise" size={20} />
          </Button>
          <Button className="btn-light rn-210" onClick={handleRedo} disabled={redoDisabled}>
            <Icon name="arrow-clockwise" size={20} />
          </Button>
        </ButtonGroup>
      </div>
      <div className="buttons-wrapper right-wrapper">
        <Row className="m-0">
          <Col className="p-0 left-wrapper">
            <Button
              className="btn-light play-button"
              disabled={playStatus === statuses.finished || isLayersTab}
              onClick={handleStop}
            >
              <Icon name="stop-fill" size={20} />
            </Button>
            <Button
              className="btn-light play-button"
              disabled={isLayersTab}
              onClick={e => {
                // blur to avoid conflicts with play/pause hotkey
                e.currentTarget.blur();
                if (isPlaying) {
                  handlePause();
                } else {
                  handlePlay();
                }
              }}
            >
              <Icon name={isPlaying ? 'pause-fill' : 'play-fill'} size={20} />
            </Button>
            <ButtonGroup className="m-4">
              <Button className="btn-light" onClick={onZoomIn}>
                <Icon name="zoom-in" size={20} />
              </Button>
              <Button className="btn-light" onClick={onZoomOut}>
                <Icon name="zoom-out" size={20} />
              </Button>
            </ButtonGroup>
          </Col>
        </Row>
      </div>
    </>
  );
});

export default ArtBoardControls;
