/* eslint-disable no-underscore-dangle */
/* eslint-disable object-shorthand */
/* eslint-disable func-names */
import { fabric } from 'fabric';
import PrismaGuideline from './prismaGuideline.js';

import { GUIDELINE_TYPE, LABEL_MARGIN, LIMIT } from './constants/guidelines.js';

const PrismaVerticalGuideline = fabric.util.createClass(PrismaGuideline, {
  guidelineType: GUIDELINE_TYPE.VERTICAL,
  offsetLeft: 0,

  initialize: function (options = {}) {
    this.callSuper('initialize', [0, -LIMIT, 0, LIMIT], {
      ...options,
      top: -LIMIT,
      lockMovementY: true,
    });
    this._showMoveCursor(true);
  },

  lockMovement: function (lock) {
    this.lockMovementX = lock;
    this.locked = lock;
    this._showMoveCursor(!lock);
  },

  setOffset: function (offset) {
    const { offsetLeft } = offset;
    this.left = this.left - this.offsetLeft + offsetLeft;
    this.offsetLeft = offsetLeft;
  },

  showTooltip: function () {
    const pointer = this.canvas.getPointer();
    const value = Math.round(this.left - this.offsetLeft);
    this.callSuper('showTooltip', `X: ${value}`, {
      text: { originX: 'left', originY: 'left' },
      container: { left: pointer.x + LABEL_MARGIN, top: pointer.y },
    });
  },

  _showMoveCursor: function (show) {
    this._setCursor(show ? 'col-resize' : 'default');
  },
});

fabric.PrismaVerticalGuideline = PrismaVerticalGuideline;

export default PrismaVerticalGuideline;
