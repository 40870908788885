import React from 'react';
import classnames from 'classnames';
import { Button, ComponentWithLoader, Input, Modal, Stack } from '@akiunlocks/perseus-ui-components';

import { getInputInvalidFeedback } from 'utils/form';
import { getAlreadyExportedMessage } from 'components/ProjectPreview/ExportToPerseusModal/utilities';
import { STATUS } from 'containers/Projects/utils';
import Alerts from './Alerts';
import { getPerseusActionLabel } from 'utils/labels';

import './ExportToPerseusModal.scss';

const ExportToPerseusModalView = ({
  alerts,
  exportInProgress,
  handleClose,
  handleExport,
  isRender,
  loading,
  perseusId,
  perseusName,
  perseusNameError,
  setPerseusName,
  show,
}) => {
  const hasErrors = !!alerts?.danger?.length;

  const buttonMessage = isRender ? (perseusId ? 'Render again' : 'Render') : perseusId ? 'Update' : 'Push';

  return (
    <Modal show={show} onHide={handleClose} scrollable>
      <Modal.Header closeButton>
        <Modal.Title>{getPerseusActionLabel(isRender)}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className={classnames({ 'export-loading': loading })}>
          <ComponentWithLoader status={loading ? STATUS.LOADING : STATUS.INITIAL}>
            <Stack gap={3}>
              {!perseusId && (
                <Input
                  id="perseus-id"
                  controlProps={{
                    autoFocus: true,
                    value: perseusName,
                    placeholder: 'Creative name',
                    onChange: ({ target }) => setPerseusName(target.value),
                    isInvalid: !!perseusNameError,
                  }}
                  feedback={getInputInvalidFeedback(perseusNameError)}
                  label="Creative name"
                />
              )}
              {perseusId && <div>{getAlreadyExportedMessage(perseusId, perseusName, isRender)}</div>}
              <Alerts alerts={alerts} isRender={isRender} />
            </Stack>
          </ComponentWithLoader>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary-alt" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          disabled={hasErrors}
          tooltipMessage={hasErrors ? `Cannot ${isRender ? 'render' : 'push'} with errors` : ''}
          onClick={handleExport}
          loading={exportInProgress}
        >
          {buttonMessage}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ExportToPerseusModalView;
