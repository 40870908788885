import React from 'react';

export const Unauthenticated = () => {
  return (
    <div className="m-8">
      <h1>Your session has expired.</h1>
      <h3>Please login again.</h3>
    </div>
  );
};
