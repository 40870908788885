/* eslint-disable no-underscore-dangle */
/* eslint-disable object-shorthand */
/* eslint-disable func-names */
import { fabric } from 'fabric';
import PrismaGuideline from './prismaGuideline.js';

import { GUIDELINE_TYPE, LABEL_MARGIN, LIMIT } from './constants/guidelines.js';

const PrismaHorizontalGuideline = fabric.util.createClass(PrismaGuideline, {
  guidelineType: GUIDELINE_TYPE.HORIZONTAL,
  offsetTop: 0,

  initialize: function (options = {}) {
    this.callSuper('initialize', [-LIMIT, 0, LIMIT, 0], {
      ...options,
      left: -LIMIT,
      lockMovementX: true,
    });
    this._showMoveCursor(true);
  },

  lockMovement: function (lock) {
    this.lockMovementY = lock;
    this.locked = lock;
    this._showMoveCursor(!lock);
  },

  setOffset: function (offset) {
    const { offsetTop } = offset;
    this.top = this.top - this.offsetTop + offsetTop;
    this.offsetTop = offsetTop;
  },

  showTooltip: function () {
    const pointer = this.canvas.getPointer();
    const value = Math.round(this.top - this.offsetTop);
    this.callSuper('showTooltip', `Y: ${value}`, {
      text: { originX: 'top', originY: 'top' },
      container: { left: pointer.x, top: pointer.y + LABEL_MARGIN },
    });
  },

  _showMoveCursor: function (show) {
    this._setCursor(show ? 'row-resize' : 'default');
  },
});

fabric.PrismaHorizontalGuideline = PrismaHorizontalGuideline;

export default PrismaHorizontalGuideline;
