import React from 'react';
import classnames from 'classnames';

import { Popover } from '@akiunlocks/perseus-ui-components';

import { useRootStore } from 'store';

const DELAY = { show: 500 };

const AssetName = ({ node }) => {
  const { editor } = useRootStore();
  const { assetStore } = editor;

  const { data, isEditing, isLeaf } = node;

  if (isEditing) {
    return null;
  }

  return (
    <Popover id={data.id} content={data.name} delay={DELAY}>
      <div
        className={classnames('asset-title', { folder: !isLeaf })}
        onDoubleClick={isLeaf || assetStore.loading ? () => {} : () => node.edit()}
      >
        <p>{data.name}</p>
      </div>
    </Popover>
  );
};

export default AssetName;
