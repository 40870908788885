import { fabric } from 'fabric';
import { useEffect, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';

import { PRISMA_MASK } from '@prisma/lib/src/constants';
import { eventsToKeys, statuses } from '@prisma/lib/src/utils/types';

import { useRootStore } from 'store';

import { LAYERS_TAB, MAX_ZOOM, MIN_ZOOM, RULER_DROPDOWN_EVENTS } from 'constants';
import { COMBINATIONS, HOTKEYS } from 'constants/hotkeys';
import { canMoveKeyframes } from 'components/ArtBoard/utilities';
import { TIMELINE_STEP } from 'components/TimeLineScene/constants';

import { fireModifiedDragEvent } from 'utils/animations';

import useArtBoardControlsUndoRedo from './ArtBoardControlsUndoRedo.hooks';

const useArtBoardControls = ({ artBoardRef }) => {
  const { editor } = useRootStore();
  const { canvasActionsStore, selectionStore, timelineSceneStore } = editor;

  const { handleUndo, handleRedo, undoDisabled, redoDisabled } = useArtBoardControlsUndoRedo();

  const isLayersTab = editor.contextStore.getTimelineTab() === LAYERS_TAB;

  useEffect(() => {
    if (!artBoardRef.current) {
      return;
    }

    const artBoard = artBoardRef.current;
    const resizeObserver = new ResizeObserver(([artBoard]) => {
      const { width, height } = artBoard.contentRect;

      if (!width && !height) {
        return;
      }
    });

    resizeObserver.observe(artBoard);

    return () => {
      resizeObserver.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onZoomIn = () => {
    const newZoom = Math.min(editor.canvas.getZoom() * 1.1, MAX_ZOOM);
    const center = new fabric.Point(editor.canvas.getWidth() / 2, editor.canvas.getHeight() / 2);
    editor.canvas.zoomToPoint(center, newZoom);
    editor.canvas.redrawRulers();
  };
  const onZoomOut = () => {
    const newZoom = Math.max(editor.canvas.getZoom() / 1.1, MIN_ZOOM);
    const center = new fabric.Point(editor.canvas.getWidth() / 2, editor.canvas.getHeight() / 2);
    editor.canvas.zoomToPoint(center, newZoom);
    editor.canvas.redrawRulers();
  };

  const [playStatus, setPlayStatus] = useState(statuses.finished);

  useEffect(() => {
    if (!editor.canvas) return;
    const handlePauseEvent = () => {
      setPlayStatus(statuses.pause);
    };
    const handleEndEvent = () => {
      setPlayStatus(statuses.finished);
    };
    const handleStartEvent = () => {
      setPlayStatus(statuses.play);
    };
    editor.canvas.on(eventsToKeys.onAnimationBegin, handleStartEvent);
    editor.canvas.on(eventsToKeys.onAnimationPause, handlePauseEvent);
    editor.canvas.on(eventsToKeys.onAnimationEnd, handleEndEvent);
    return () => {
      editor.canvas.off(eventsToKeys.onAnimationBegin, handleStartEvent);
      editor.canvas.off(eventsToKeys.onAnimationPause, handlePauseEvent);
      editor.canvas.off(eventsToKeys.onAnimationEnd, handleEndEvent);
    };
  }, [editor.canvas]);

  useEffect(() => {
    const onMouseMove = e => {
      const movementLock = canvasActionsStore.handleMovement(e.clientX, e.clientY);
      if (movementLock) {
        editor.activeObject.set(movementLock);
      }
    };

    const onMouseDown = () => {
      if (!editor.activeObject || !editor.activeObject.selectable) {
        return;
      }
      canvasActionsStore.setIsClickedAndResetTracking(true);
      // update if activeObject is not locked and is in layers tab or scene tab and is not a mask
      const shouldUpdateLock =
        (isLayersTab || editor.activeObject.type !== PRISMA_MASK) && !editor.isActiveObjectMovementLocked();
      if (shouldUpdateLock) {
        editor.setActiveObjectLockMovement(canvasActionsStore.isShiftHold, canvasActionsStore.isShiftHold);
        document.addEventListener('mousemove', onMouseMove);
      }
    };

    const onMouseUp = () => {
      canvasActionsStore.setIsClickedAndResetTracking(false);
      document.removeEventListener('mousemove', onMouseMove);
    };

    document.addEventListener('mousedown', onMouseDown);
    document.addEventListener('mouseup', onMouseUp);

    return () => {
      document.removeEventListener('mousedown', onMouseDown);
      document.removeEventListener('mouseup', onMouseUp);
      document.removeEventListener('mousemove', onMouseMove);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canvasActionsStore, isLayersTab]);

  const handlePlay = () => {
    editor?.canvas?.engine?.play();
    timelineSceneStore.clearActiveAnimation();
  };
  const handleStop = () => {
    editor?.canvas?.engine?.stop();
  };

  const handlePause = () => {
    editor?.canvas?.engine?.pause(TIMELINE_STEP);
  };

  const handleShiftKey = e => {
    const isKeydown = e.type === 'keydown';
    canvasActionsStore.setIsShiftHold(isKeydown);
    canvasActionsStore.resetTracking();
    if (!isKeydown) {
      editor.setActiveObjectLockMovement(false, false);
    }
  };

  const onSpaceBar = playStatus === statuses.play ? handlePause : handlePlay;

  const moveActiveObject = (arrowKey, step) => {
    const object = editor.activeObject;
    if (object) {
      const original = { top: object.top, left: object.left };
      switch (arrowKey) {
        case HOTKEYS.ARROW_UP:
          object.setOptions({ top: object.top - step });
          break;
        case HOTKEYS.ARROW_RIGHT:
          object.setOptions({ left: object.left + step });
          break;
        case HOTKEYS.ARROW_DOWN:
          object.setOptions({ top: object.top + step });
          break;
        case HOTKEYS.ARROW_LEFT:
          object.setOptions({ left: object.left - step });
          break;
        default:
          return;
      }
      const objectStore = editor.getObjectStore(object);
      objectStore?.updatePosition(object); // required to update the properties in the sidebar
      fireModifiedDragEvent(object, original);
      editor.canvas.fire('history'); // required for the undo/redo
      editor.canvas.renderAll();
    }
  };

  const moveKeyframes = (arrowKey, keyframes) => {
    if (!canMoveKeyframes(arrowKey, keyframes)) {
      return;
    }
    const offset = arrowKey === HOTKEYS.ARROW_RIGHT ? TIMELINE_STEP : -TIMELINE_STEP;
    keyframes.forEach(k => (k.time += offset));
    editor.canvas.mergeKeyframes.call(editor.canvas, keyframes);
  };

  const handleArrows = (keyboardEvent, hotkeysEvent) => {
    const arrowKey = hotkeysEvent.keys?.[0];
    if (selectionStore.hasKeyframesSelected()) {
      moveKeyframes(arrowKey, selectionStore.getSelectedComponents());
    } else {
      moveActiveObject(arrowKey, 1);
    }
  };

  const handleShiftArrows = (keyboardEvent, hotkeysEvent) => {
    moveActiveObject(hotkeysEvent.keys?.[0], 10);
  };

  const rulerDropdownSelect = eventKey => {
    const { guidelineStore } = editor;
    switch (eventKey) {
      case RULER_DROPDOWN_EVENTS.ADD_VERTICAL_LINE:
      case RULER_DROPDOWN_EVENTS.ADD_HORIZONTAL_LINE:
        if (guidelineStore.hidden) {
          guidelineStore.showGuidelines(true);
        }
        guidelineStore.addGuideline(eventKey);
        break;
      case RULER_DROPDOWN_EVENTS.SHOW_LINES:
        guidelineStore.showGuidelines(true);
        break;
      case RULER_DROPDOWN_EVENTS.HIDE_LINES:
        guidelineStore.showGuidelines(false);
        break;
      case RULER_DROPDOWN_EVENTS.LOCK_LINES:
        guidelineStore.lockGuidelines(true);
        break;
      case RULER_DROPDOWN_EVENTS.UNLOCK_LINES:
        guidelineStore.lockGuidelines(false);
        break;
      case RULER_DROPDOWN_EVENTS.CLEAR_LINES:
        guidelineStore.removeGuidelines();
        break;
      default:
        break;
    }
  };

  useHotkeys(HOTKEYS.SPACE, isLayersTab ? () => {} : onSpaceBar, { preventDefault: true });
  useHotkeys([COMBINATIONS.ZOOM_IN, COMBINATIONS.ZOOM_IN_2], onZoomIn, { preventDefault: true });
  useHotkeys(COMBINATIONS.ZOOM_OUT, onZoomOut, { preventDefault: true });
  useHotkeys(HOTKEYS.SHIFT, handleShiftKey, { keydown: true, keyup: true });
  useHotkeys([HOTKEYS.ARROW_UP, HOTKEYS.ARROW_RIGHT, HOTKEYS.ARROW_DOWN, HOTKEYS.ARROW_LEFT], handleArrows, {
    preventDefault: true,
  });
  useHotkeys(
    [COMBINATIONS.JUMP_UP, COMBINATIONS.JUMP_RIGHT, COMBINATIONS.JUMP_DOWN, COMBINATIONS.JUMP_LEFT],
    handleShiftArrows,
    { preventDefault: true },
  );

  return {
    handlePause,
    handlePlay,
    handleRedo,
    handleStop,
    handleUndo,
    onZoomIn,
    onZoomOut,
    playStatus,
    redoDisabled,
    rulerDropdownSelect,
    timelineTab: editor.contextStore.getTimelineTab(),
    undoDisabled,
  };
};

export default useArtBoardControls;
