import {
  ALIGN_HORIZONTAL,
  ALIGN_VERTICAL,
  DISTRIBUTE_HORIZONTAL,
  END,
  HEIGHT,
  LEFT,
  START,
  TOP,
  WIDTH,
} from 'constants/canvas';
import { getBoundingRect, moveObjectToContainerBorder, setCenterPosition } from './alignment';

// import { setChildrenPositionIfGroup } from 'containers/Editor/stores/utilities/groups';

/**
 * Sorts a list of objects by the property value of its bounding rect.
 * @param {array} objects - List of objects.
 * @param {string} property - Property name.
 */
function sortObjectsByBoundProperty(objects, property) {
  objects.sort((a, b) => getBoundingRect(a)[property] - getBoundingRect(b)[property]);
}

/**
 * Places objects within the limits of a container, either vertically or horizontally.
 * @param {array} objects - List of objects.
 * @param {object} container - Can be a group object, or the canvas banner.
 * @param {string} distributionType - Vertical or horiontal.
 */
export function placeObjectsInContainer(objects, container, distributionType) {
  // horizontal distribution will use left and top
  // vertical distribution will use top and height
  const positionProp = distributionType === DISTRIBUTE_HORIZONTAL ? LEFT : TOP;
  const dimensionProp = distributionType === DISTRIBUTE_HORIZONTAL ? WIDTH : HEIGHT;
  const alignmentType = distributionType === DISTRIBUTE_HORIZONTAL ? ALIGN_HORIZONTAL : ALIGN_VERTICAL;

  // sort objects
  sortObjectsByBoundProperty(objects, positionProp);

  // place objects inside container
  const containerBound = getBoundingRect(container);
  const containerStart = containerBound[positionProp];
  const containerEnd = containerStart + containerBound[dimensionProp];
  objects.forEach(obj => {
    const boundingRect = getBoundingRect(obj);
    if (boundingRect[positionProp] < containerStart) {
      moveObjectToContainerBorder(obj, containerStart, alignmentType, START);
    }
    if (boundingRect[positionProp] + boundingRect[dimensionProp] > containerEnd) {
      moveObjectToContainerBorder(obj, containerEnd, alignmentType, END);
    }
  });

  // force first and last objects to be in the canvas limits
  moveObjectToContainerBorder(objects[0], containerStart, alignmentType, START);
  moveObjectToContainerBorder(objects[objects.length - 1], containerEnd, alignmentType, END);
}

/**
 * Calculates the spacing between objects depending on distribution type and boundary.
 * @param {array} objects - List of objects.
 * @param {object} container - Container with height and width properties.
 * @param {string} distributionType - Vertical or horiontal.
 * @return {number} Calculated spacing between items. It can be negative.
 */
export function getDistributeSpacing(objects, container, distributionType) {
  const dimensionProp = distributionType === DISTRIBUTE_HORIZONTAL ? WIDTH : HEIGHT;

  // get container size and total items size
  const containerSize = container[dimensionProp];
  const totalItemsSize = objects.reduce((acc, obj) => {
    const size = getBoundingRect(obj)[dimensionProp];
    return acc + size;
  }, 0);

  return (containerSize - totalItemsSize) / (objects.length - 1);
}

/**
 * Given a list of objects and spacing between them, distributes them either vertically or horizontally.
 * @param {array} objects - List of objects.
 * @param {number} spacing - Spacing between objects.
 * @param {string} distributionType - Vertical or horiontal.
 */
export function distributeObjects(objects, spacing, distributionType) {
  // sort object horizontally or vertically
  const positionProp = distributionType === DISTRIBUTE_HORIZONTAL ? LEFT : TOP;
  sortObjectsByBoundProperty(objects, positionProp);

  // first and last object are not changed
  for (let i = 1; i < objects.length - 1; i++) {
    const previousObject = objects[i - 1];
    const previousBound = getBoundingRect(previousObject);

    const thisObject = objects[i];
    const thisBound = getBoundingRect(thisObject);
    const halfHeight = thisBound.height / 2;
    const halfWidth = thisBound.width / 2;

    // each object goes after the previous one, plus the spacing
    const position =
      distributionType === DISTRIBUTE_HORIZONTAL
        ? {
            x: previousBound.left + previousBound.width + halfWidth + spacing,
            y: thisBound.top + halfHeight,
          }
        : {
            x: thisBound.left + halfWidth,
            y: previousBound.top + previousBound.height + halfHeight + spacing,
          };

    setCenterPosition(objects[i], position);
  }
}
