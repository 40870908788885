/**
 * Calculates time using a point and milliseconds by pixel.
 * @param {number} point - Point in timeline.
 * @param {number} msInOnPixel - Milliseconds in a pixel.
 * @return {number} Calculated time.
 */
export function calculateTimeByPoint(point, msInOnPixel) {
  return Math.round(point * msInOnPixel);
}

/**
 * Gets the point from a keyframe.
 *
 * @param {Object} keyframe - The keyframe object.
 * @param {number} msInOnPixel - The number of milliseconds in one pixel.
 * @return {number} The point value.
 */
export function getPointFromKeyframe(keyframe, msInOnPixel) {
  return keyframe ? Math.round(keyframe.time / msInOnPixel) : 0;
}
