import { PRISMA_GROUP, PRISMA_MASK } from '@prisma/lib/src/constants';

import { isSingleGroup } from 'containers/Editor/stores/utilities/groups';

import { getGroups } from '../utilities';

/**
 * Given an active selection, returns the objects to be aligned or distributed depending on layers selected.
 * @param {object} activeSelection - Active selection.
 * @return {array} Objects to align or distribute.
 */
export function getObjectsToArrange(activeSelection) {
  // if single group, return all except the mask
  // this is needed to distribute items inside a group
  if (activeSelection.isGroup && isSingleGroup(activeSelection.getObjects())) {
    return activeSelection.getObjects().filter(o => o.type !== PRISMA_MASK && o.type !== PRISMA_GROUP);
  }

  // if there are groups selected, return them along with regular objects
  const groups = getGroups(activeSelection.getObjects());
  if (groups.length) {
    return activeSelection.getObjects().filter(o => o.type === PRISMA_GROUP || !o.groupId);
  }

  // if no groups, returns all the objects
  return activeSelection.getObjects();
}
