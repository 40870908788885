import { useCallback, useEffect, useRef, useState } from 'react';

import { useRootStore } from 'store';

import { CONFIRM_BODIES, CONFIRM_NOTES, CONFIRM_TITLES } from 'utils';

import { getFileNameFromImageUrl, getParentFolderFromImageUrl } from 'containers/Editor/stores/utilities/assets';

const useAssetLibrary = () => {
  const { editor, projects } = useRootStore();
  const { assetStore, selectionStore } = editor;
  const {
    addFolder,
    assetsToMoveAndOverwrite,
    assetsToUploadAndOverwrite,
    confirmationModal,
    editNode,
    existingAssetsToMove,
    existingAssetsToUpload,
    loading,
    onReplaceAsset,
  } = assetStore;

  const assetsTreeRef = useRef();
  const replaceRef = useRef();
  const uploadRef = useRef();

  const [assetToReplace, setAssetToReplace] = useState(null);

  // move assets

  useEffect(() => {
    if (assetsToMoveAndOverwrite.length && !existingAssetsToMove.length) {
      const parentId = assetsToMoveAndOverwrite[0].parentId;
      const data = { dragIds: assetsToMoveAndOverwrite.map(a => a.dragId), parentId };
      // move and overwrite
      assetStore.onMove(data, true);
      assetStore.setAssetsToMoveAndOverwrite([]);
    }
  }, [assetStore, assetsToMoveAndOverwrite, existingAssetsToMove.length]);

  const closeMoveAndOverwriteAssetModal = useCallback(() => {
    assetStore.setConfirmationModal({});
    assetStore.setExistingAssetsToMove(existingAssetsToMove.slice(1));
  }, [assetStore, existingAssetsToMove]);

  useEffect(() => {
    const [assetToMove] = existingAssetsToMove;
    if (!assetStore.confirmationModal.show && assetToMove) {
      const fileName = getFileNameFromImageUrl(assetToMove.destinationUrl);
      assetStore.setConfirmationModal({
        body: CONFIRM_BODIES.PROJECT_ASSET_MOVE.replace('{name}', fileName),
        handleAccept: () => {
          assetStore.setAssetsToMoveAndOverwrite([...assetsToMoveAndOverwrite, assetToMove]);
          closeMoveAndOverwriteAssetModal();
        },
        handleClose: () => {
          // unselect asset to avoid dragging
          const asset = { _id: assetToMove.dragId, type: assetToMove.type };
          selectionStore.addOrRemoveAsset(asset, true);
          closeMoveAndOverwriteAssetModal();
        },
        notes: CONFIRM_NOTES.PROJECT_ASSET_MOVE,
        showModal: true,
        title: CONFIRM_TITLES.PROJECT_ASSET_MOVE,
      });
    }
  }, [assetStore, assetsToMoveAndOverwrite, closeMoveAndOverwriteAssetModal, existingAssetsToMove, selectionStore]);

  // reupload assets

  useEffect(() => {
    if (assetsToUploadAndOverwrite.length && !existingAssetsToUpload.length) {
      const parentFolder = getParentFolderFromImageUrl(assetsToUploadAndOverwrite[0].imageUrl);
      assetStore.uploadFiles(assetsToUploadAndOverwrite, parentFolder, async files => {
        files.forEach(file => {
          const assetToReplace = projects.assets.find(a => a.imageUrl === file.imageUrl);
          if (assetToReplace) {
            onReplaceAsset(assetToReplace, file, parentFolder, true);
          }
        });
      });
      assetStore.setAssetsToUploadAndOverwrite([]);
    }
  }, [assetStore, assetsToUploadAndOverwrite, existingAssetsToUpload.length, onReplaceAsset, projects.assets]);

  const closeUploadAndOverwriteAssetModal = useCallback(() => {
    assetStore.setConfirmationModal({});
    assetStore.setExistingAssetsToUpload(existingAssetsToUpload.slice(1));
  }, [assetStore, existingAssetsToUpload]);

  useEffect(() => {
    const [assetToUpload] = existingAssetsToUpload;
    if (!assetStore.confirmationModal.show && assetToUpload) {
      const fileName = getFileNameFromImageUrl(assetToUpload.imageUrl);
      assetStore.setConfirmationModal({
        body: CONFIRM_BODIES.PROJECT_ASSET_UPLOAD.replace('{name}', fileName),
        handleAccept: () => {
          assetToUpload.overwrite = true;
          assetStore.setAssetsToUploadAndOverwrite([...assetsToUploadAndOverwrite, assetToUpload]);
          closeUploadAndOverwriteAssetModal();
        },
        handleClose: closeUploadAndOverwriteAssetModal,
        notes: CONFIRM_NOTES.PROJECT_ASSET_UPLOAD,
        showModal: true,
        title: CONFIRM_TITLES.PROJECT_ASSET_UPLOAD,
      });
    }
  }, [
    assetStore,
    assetsToUploadAndOverwrite,
    closeUploadAndOverwriteAssetModal,
    existingAssetsToUpload,
    selectionStore,
  ]);

  return {
    addFolder,
    assetToReplace,
    assetsTreeRef,
    confirmationModal,
    editNode,
    loading,
    onReplaceAsset,
    replaceRef,
    setAssetToReplace,
    uploadRef,
  };
};

export default useAssetLibrary;
