import React from 'react';

import { Icon, Image } from '@akiunlocks/perseus-ui-components';

import { ASSET } from 'constants/assets';

const AssetThumbnail = ({ node }) => {
  const { data, isLeaf, isOpen } = node;

  const iconData = { name: 'image' };
  if (!isLeaf) {
    iconData.color = 'var(--per-secondary)';
    iconData.name = isOpen ? 'fa-folder-open' : 'fa-folder';
    iconData.onClick = () => node.toggle();
  }

  const showImage = data.imageThumbnailUrl && data.type === ASSET;

  return (
    <div className="asset-thumbnail-container" data-type="thumbnail">
      {showImage && (
        <Image
          alt={`${data.name} image`}
          className="asset-image asset-thumbnail"
          draggable={false}
          src={`${data.baseUrl}${data.imageThumbnailUrl}?id=${data._id}`}
        />
      )}
      {!showImage && <Icon className="me-1" {...iconData} />}
    </div>
  );
};

export default AssetThumbnail;
