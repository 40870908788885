import React from 'react';

import { VERTICAL_ALIGN } from '@prisma/lib/src/constants/canvas';

import ExclusiveButtons from '../ExclusiveButtons';

const BUTTONS = [
  { icon: 'md-outline-vertical-align-top', tooltip: 'Align top', value: VERTICAL_ALIGN.TOP },
  { icon: 'md-outline-vertical-align-center', tooltip: 'Align middle', value: VERTICAL_ALIGN.MIDDLE },
  { icon: 'md-outline-vertical-align-bottom', tooltip: 'Align bottom', value: VERTICAL_ALIGN.BOTTOM },
];

const VerticalAlign = ({ className, verticalAlign, onChange }) => {
  return <ExclusiveButtons buttons={BUTTONS} className={className} propValue={verticalAlign} onChange={onChange} />;
};

export default VerticalAlign;
