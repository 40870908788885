export const HOTKEYS = {
  ALT: 'alt',
  CTRL: 'ctrl',
  META: 'meta',
  MOD: 'mod', // listens for ctrl on Windows/Linux and cmd on macOS
  SHIFT: 'shift',

  // Whitespace keys
  ENTER: 'enter',
  TAB: 'tab',
  SPACE: 'space',

  // Navigation keys
  ARROW_UP: 'up',
  ARROW_DOWN: 'down',
  ARROW_LEFT: 'left',
  ARROW_RIGHT: 'right',
  PAGE_UP: 'pageup',
  PAGE_DOWN: 'pagedown',
  ESCAPE: 'esc',

  // Editing keys
  DELETE: 'delete',
  BACKSPACE: 'backspace',
  CLEAR: 'clear',

  // Functions keys
  F1: 'f1',
  F2: 'f2',
};

export const COMBINATIONS = {
  COPY: `${HOTKEYS.MOD}+c`,
  // CUT: `${HOTKEYS.MOD}+x`,
  PASTE: `${HOTKEYS.MOD}+v`,
  UNDO: `${HOTKEYS.MOD}+z`,
  REDO: `${HOTKEYS.MOD}+shift+z,${HOTKEYS.CTRL}+y}`,
  SAVE: `${HOTKEYS.MOD}+s`,
  // DUPLICATE: `${HOTKEYS.MOD}+d`,
  // SELECT_ALL: `${HOTKEYS.MOD}+a`,
  // DESELECT_ALL: `${HOTKEYS.MOD}+shift+a`,
  GROUP: `${HOTKEYS.MOD}+g`,
  UNGROUP: `${HOTKEYS.MOD}+shift+g`,
  JUMP_LEFT: `${HOTKEYS.SHIFT}+left`,
  JUMP_RIGHT: `${HOTKEYS.SHIFT}+right`,
  JUMP_UP: `${HOTKEYS.SHIFT}+up`,
  JUMP_DOWN: `${HOTKEYS.SHIFT}+down`,
  // BRING_FORWARD: `${HOTKEYS.MOD}+up`,
  // SEND_BACKWARD: `${HOTKEYS.MOD}+down`,
  // BRING_TO_FRONT: `${HOTKEYS.MOD}+shift+up`,
  // SEND_TO_BACK: `${HOTKEYS.MOD}+shift+down`,
  // ALIGN_LEFT: `${HOTKEYS.MOD}+shift+l`,
  // ALIGN_RIGHT: `${HOTKEYS.MOD}+shift+r`,
  // ALIGN_TOP: `${HOTKEYS.MOD}+shift+t`,
  // ALIGN_BOTTOM: `${HOTKEYS.MOD}+shift+b`,
  // ALIGN_CENTER: `${HOTKEYS.MOD}+shift+c`,
  // ALIGN_MIDDLE: `${HOTKEYS.MOD}+shift+m`,
  // ALIGN_HORIZONTALLY: `${HOTKEYS.MOD}+shift+h`,
  // ALIGN_VERTICALLY: `${HOTKEYS.MOD}+shift+v`,
  ZOOM_IN: `${HOTKEYS.MOD}+plus`,
  ZOOM_IN_2: `${HOTKEYS.MOD}+equal`,
  ZOOM_OUT: `${HOTKEYS.MOD}+minus`,
  // ZOOM_RESET: `${HOTKEYS.MOD}+0`,
};
