import React from 'react';
import PropTypes from 'prop-types';
import { Col, Form, Row } from 'react-bootstrap';

import LabeledInput from '../../LabeledInput';
import OpacityInput from '../../Opacity';

const RotationOpacityForm = ({ angle, onAngleChange, onOpacityChange, opacity }) => {
  return (
    <Form.Group>
      <Row>
        <Col xs={5}>
          <Form.Label>Rotation</Form.Label>
          <LabeledInput
            gap={0}
            id="rotation"
            controlProps={{
              placeholder: '',
              value: angle,
              type: 'number',
              step: 1.0,
              onChange: onAngleChange,
            }}
          />
        </Col>
        <Col xs={7} className="pe-7">
          <OpacityInput onChange={onOpacityChange} opacity={opacity} />
        </Col>
      </Row>
    </Form.Group>
  );
};

RotationOpacityForm.propTypes = {
  /** Angle value */
  angle: PropTypes.number,
  /** Action to take on angle change */
  onAngleChange: PropTypes.func,
  /** Action to take on opacity change */
  onOpacityChange: PropTypes.func,
  /** Opacity value */
  opacity: PropTypes.number,
};

RotationOpacityForm.defaultProps = {
  angle: 0,
  onAngleChange: () => {},
  onOpacityChange: () => {},
  opacity: 0,
};

export default RotationOpacityForm;
