import { fabric } from 'fabric';

import { scalingIsForbidden, scaleIsProportional, isLocked, isTransformCentered, invertOrigin } from './scale.js';
import { ACTIVE_SELECTION } from '../constants/index.js';

const { controlsUtils } = fabric;
const { wrapWithFixedAnchor, wrapWithFireEvent } = controlsUtils;

/**
 * Applies scaling to an object.
 *
 * @param {Object} target - The element to scale.
 * @param {number} scaleX - The scale factor for the X axis.
 * @param {number} scaleY - The scale factor for the Y axis.
 * @param {string} by - The axis to scale by ('x' or 'y').
 * @returns {boolean} - True if some change happened, false otherwise.
 * @private
 */
export function applyScalingMode(target, scaleX, scaleY, by) {
  const oldScaleX = target.scaleX;
  const oldScaleY = target.scaleY;

  if (!by || target.uniformScaling) {
    target.set('scaleX', scaleX);
    target.set('scaleY', scaleY);
  } else if (by === 'x') {
    target.set('scaleX', scaleX);
  } else if (by === 'y') {
    target.set('scaleY', scaleY);
  }

  return oldScaleX !== target.scaleX || oldScaleY !== target.scaleY;
}

/**
 * Applies scaling to an active selection of objects.
 *
 * @param {Object} target - The active selection to scale.
 * @param {number} scaleX - The scaling factor in the x direction.
 * @param {number} scaleY - The scaling factor in the y direction.
 * @param {string} [by] - The axis to scale by ('x', 'y', or undefined for both).
 *
 * @returns {boolean} True if any objects were modified, false otherwise.
 * @private
 */
export function applyScaleToActiveSelection(target, scaleX, scaleY, by) {
  let modified = false;

  target.getObjects().forEach(obj => {
    const newScaleY = scaleY * obj.scaleY;
    const newScaleX = scaleX * obj.scaleX;
    const centerPoint = obj.getCenterPoint();
    if (applyScalingMode(obj, newScaleX, newScaleY, by)) {
      const newY = scaleY * centerPoint.y;
      const newX = scaleX * centerPoint.x;
      if (!by) {
        obj.setPositionByOrigin(new fabric.Point(newX, newY), 'center', 'center');
      } else if (by === 'x') {
        obj.setPositionByOrigin(new fabric.Point(newX, centerPoint.y), 'center', 'center');
      } else if (by === 'y') {
        obj.setPositionByOrigin(new fabric.Point(centerPoint.x, newY), 'center', 'center');
      }
      modified = true;
    }
  });

  if (!by) {
    target.set('width', scaleX * target.width);
    target.set('height', scaleY * target.height);
  } else if (by === 'x') {
    target.set('width', scaleX * target.width);
  } else if (by === 'y') {
    target.set('height', scaleY * target.height);
  }
  return modified;
}

/**
 * Overwritten from FabricJS and changed to support multiple scaling modes
 *
 * Basic scaling logic, reused with different constrain for scaling X,Y, freely or equally.
 * Needs to be wrapped with `wrapWithFixedAnchor` to be effective
 * @param {Event} eventData javascript event that is doing the transform
 * @param {Object} transform javascript object containing a series of information around the current transform
 * @param {number} x current mouse x position, canvas normalized
 * @param {number} y current mouse y position, canvas normalized
 * @param {Object} options additional information for scaling
 * @param {String} options.by 'x', 'y', 'equally' or '' to indicate type of scaling
 * @return {Boolean} true if some change happened
 * @private
 */
export function scaleObject(eventData, transform, x, y, options = {}) {
  /* eslint-disable no-param-reassign */ // needed because FabricJS original method changes the transform object
  const { target } = transform;
  const { by } = options;
  const scaleProportionally = scaleIsProportional(eventData, target);
  const objectUniformScaling = target.uniformScaling;
  const forbidScaling = scalingIsForbidden(target, by, scaleProportionally);
  let newPoint;
  let scaleX;
  let scaleY;
  let dim;
  let signX;
  let signY;
  if (forbidScaling) {
    return false;
  }

  if (transform.gestureScale) {
    scaleX = transform.scaleX * transform.gestureScale;
    scaleY = transform.scaleY * transform.gestureScale;
  } else {
    newPoint = controlsUtils.getLocalPoint(transform, transform.originX, transform.originY, x, y);
    // use of sign: We use sign to detect change of direction of an action. sign usually change when
    // we cross the origin point with the mouse. So a scale flip for example. There is an issue when scaling
    // by center and scaling using one middle control ( default: mr, mt, ml, mb), the mouse movement can easily
    // cross many time the origin point and flip the object. so we need a way to filter out the noise.
    // This ternary here should be ok to filter out X scaling when we want Y only and vice versa.
    signX = by !== 'y' ? Math.sign(newPoint.x) : 1;
    signY = by !== 'x' ? Math.sign(newPoint.y) : 1;
    if (!transform.signX) {
      transform.signX = signX;
    }
    if (!transform.signY) {
      transform.signY = signY;
    }

    if (isLocked(target, 'lockScalingFlip') && (transform.signX !== signX || transform.signY !== signY)) {
      return false;
    }

    // eslint-disable-next-line no-underscore-dangle
    dim = target._getTransformedDimensions();
    // missing detection of flip and logic to switch the origin
    if (scaleProportionally && !by) {
      // uniform scaling
      const distance = Math.abs(newPoint.x) + Math.abs(newPoint.y);
      const { original } = transform;
      const originalDistance =
        Math.abs((dim.x * original.scaleX) / target.scaleX) + Math.abs((dim.y * original.scaleY) / target.scaleY);
      const scale = distance / originalDistance;
      scaleX = original.scaleX * scale;
      scaleY = original.scaleY * scale;
    } else {
      scaleX = Math.abs((newPoint.x * target.scaleX) / dim.x);
      scaleY = Math.abs((newPoint.y * target.scaleY) / dim.y);

      if (objectUniformScaling) {
        if (by === 'x') {
          scaleY = scaleX;
        } else if (by === 'y') {
          scaleX = scaleY;
        }
      }
    }
    // if we are scaling by center, we need to double the scale
    if (isTransformCentered(transform)) {
      scaleX *= 2;
      scaleY *= 2;
    }
    if (transform.signX !== signX && by !== 'y' && scaleX !== 0) {
      transform.originX = invertOrigin(transform.originX);
      scaleX *= -1;
      transform.signX = signX;
    }
    if (transform.signY !== signY && by !== 'x' && scaleY !== 0) {
      transform.originY = invertOrigin(transform.originY);
      scaleY *= -1;
      transform.signY = signY;
    }
  }

  if (target.type === ACTIVE_SELECTION) {
    return applyScaleToActiveSelection(target, scaleX, scaleY, by);
  }
  return applyScalingMode(target, scaleX, scaleY, by);
  /* eslint-enable no-param-reassign */
}

/**
 * Generic scaling logic, to scale from corners either equally or freely.
 * Needs to be wrapped with `wrapWithFixedAnchor` to be effective
 * @param {Event} eventData javascript event that is doing the transform
 * @param {Object} transform javascript object containing a series of information around the current transform
 * @param {number} x current mouse x position, canvas normalized
 * @param {number} y current mouse y position, canvas normalized
 * @return {Boolean} true if some change happened
 */
export function scaleObjectFromCorner(eventData, transform, x, y) {
  return scaleObject(eventData, transform, x, y);
}

/**
 * Scaling logic for the X axis.
 * Needs to be wrapped with `wrapWithFixedAnchor` to be effective
 * @param {Event} eventData javascript event that is doing the transform
 * @param {Object} transform javascript object containing a series of information around the current transform
 * @param {number} x current mouse x position, canvas normalized
 * @param {number} y current mouse y position, canvas normalized
 * @return {Boolean} true if some change happened
 * @private
 */
export function scaleObjectX(eventData, transform, x, y) {
  return scaleObject(eventData, transform, x, y, { by: 'x' });
}

/**
 * Scaling logic for the Y axis.
 * Needs to be wrapped with `wrapWithFixedAnchor` to be effective
 * @param {Event} eventData javascript event that is doing the transform
 * @param {Object} transform javascript object containing a series of information around the current transform
 * @param {number} x current mouse x position, canvas normalized
 * @param {number} y current mouse y position, canvas normalized
 * @return {Boolean} true if some change happened
 */
function scaleObjectY(eventData, transform, x, y) {
  return scaleObject(eventData, transform, x, y, { by: 'y' });
}

export const scalingEqually = wrapWithFireEvent('scaling', wrapWithFixedAnchor(scaleObjectFromCorner));

export const scalingX = wrapWithFireEvent('scaling', wrapWithFixedAnchor(scaleObjectX));

export const scalingY = wrapWithFireEvent('scaling', wrapWithFixedAnchor(scaleObjectY));
