import { animationTypes } from '@prisma/lib/src/utils/types';

export const DEFAULT_POSITION = { left: 0, top: 0 };

export const MODIFIED_ACTIONS = {
  DRAG: 'drag',
  SCALE: 'scale',
  ROTATE: 'rotate',
  SCALE_X: 'scaleX',
  SCALE_Y: 'scaleY',
};

export const ACTIONS_TO_ANIMATIONS = {
  [MODIFIED_ACTIONS.DRAG]: animationTypes.position,
  [MODIFIED_ACTIONS.SCALE]: animationTypes.scale,
  [MODIFIED_ACTIONS.SCALE_X]: animationTypes.scale,
  [MODIFIED_ACTIONS.SCALE_Y]: animationTypes.scale,
  [MODIFIED_ACTIONS.ROTATE]: animationTypes.rotation,
};

export const ANIMATION_KEY_PROPERTY = {
  x: 'left',
  y: 'top',
  scaleX: 'width',
  scaleY: 'height',
};
