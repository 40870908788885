// TODO: this should be moved to our API at some point
import axios from 'axios';

// const CHAT_MESSAGE_URL = 'http://localhost:5005/webhooks/rest/webhook';
const CHAT_MESSAGE_URL = 'https://droid.stg.a.ki/webhooks/rest/webhook';
const CHAT_EVENT_URL = 'https://droid.stg.a.ki/conversations/{sender}/tracker/events';

export async function sendMessage(message) {
  try {
    const response = await axios.post(CHAT_MESSAGE_URL, message.getPayload());

    const botReply = response.data;
    return botReply;
  } catch (error) {
    console.error(error);
  }
}

export async function sendEvents(events) {
  const sender = events[0].sender; // Assume that all events have the same sender
  const eventPayloads = events.map(event => event.getPayload());
  try {
    const response = await axios.post(CHAT_EVENT_URL.replace('{sender}', sender), eventPayloads);

    const botReply = response.data;
    return botReply;
  } catch (error) {
    console.error(error);
  }
}
