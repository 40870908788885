import { PRISMA_GROUP } from '@prisma/lib/src/constants';

import { findGroup, getGroups } from '../utilities';

/**
 * Updates position of the children if the given object is a group, using the previous center point as reference.
 * @param {object} object - Object.
 * @param {object} previousCenterPoint - Object with x and y properties. This is the position
 */
export function setChildrenPositionIfGroup(object, previousCenterPoint) {
  if (object.type !== PRISMA_GROUP) {
    return;
  }
  const { x, y } = object.getCenterPoint();
  const diffX = x - previousCenterPoint.x;
  const diffY = y - previousCenterPoint.y;
  const childrenObjects = object.canvas.layers.filter(l => l.target.groupId === object.id).map(l => l.target);
  childrenObjects.forEach(child => {
    child.setOptions({ left: child.left + diffX, top: child.top + diffY });
  });
}

/**
 * Checks if all the active objects are part of a single group.
 * @param {array} activeObjects - Array of objects.
 * @return {boolean}
 */
export function isSingleGroup(activeObjects) {
  const groups = getGroups(activeObjects);
  if (groups.length !== 1) {
    return false;
  }
  // check that all objects in group are selected, and all selected objects belong to group
  const group = findGroup(activeObjects);
  const groupObjectIds = group.objectIds;
  const objectIds = activeObjects.filter(({ type }) => type !== PRISMA_GROUP).map(({ id }) => id);
  return groupObjectIds.every(id => objectIds.includes(id)) && objectIds.every(id => groupObjectIds.includes(id));
}

/**
 * Checks if all objects belong to the same group.
 * @param {array} objects - Array of objects.
 * @return {boolean}
 */
export function objectsAreFromSameGroup(objects) {
  if (objects?.length < 2) {
    return false;
  }
  const groupId = objects[0].groupId;
  return objects.every(o => !!o.groupId && o.groupId === groupId);
}
