import React from 'react';
import { observer } from 'mobx-react';

import AlignButtons from 'components/Sidebar/components/Align';
import SidebarPanel from 'components/Sidebar/components/SidebarPanel';
import SidebarItem from 'components/Sidebar/components/SidebarPanel/SidebarItem';

import AnchorPoints from '../AnchorPoints';
import AssetFileProperties from './AssetFileProperties';
import AssetPosition from './AssetPosition';
import HideForm from '../HideForm';
import PositionForm from '../PositionForm';
import RotationOpacityForm from '../RotationOpacityForm';
import SizeForm from '../SizeForm';

import useImageProperties from './ImageProperties.hooks';
import { FF_ASSET_POSITION, USE_RESPONSIVE } from 'utils/featureFlag';

const ImageProperties = observer(({ disabled, isTimelineLayersTab }) => {
  const {
    imageStore,
    onAlignChange,
    onAngleChange,
    onHideChange,
    onLeftChange,
    onLockProportionsChange,
    onOpacityChange,
    onTopChange,
  } = useImageProperties();

  const editDisabled = disabled || !isTimelineLayersTab;

  return (
    <SidebarPanel title="Asset">
      <SidebarItem disabled={disabled}>
        <AlignButtons disabled={disabled} onClick={onAlignChange} />
      </SidebarItem>
      <SidebarItem disabled={editDisabled}>
        <SizeForm
          height={imageStore.height}
          onLockProportionsChange={onLockProportionsChange}
          uniformScaling={imageStore.uniformScaling}
          width={imageStore.width}
        />
      </SidebarItem>
      <SidebarItem disabled={editDisabled}>
        {USE_RESPONSIVE ? (
          <AnchorPoints />
        ) : (
          <PositionForm
            left={imageStore.unrotatedLeft}
            onLeftChange={onLeftChange}
            onTopChange={onTopChange}
            top={imageStore.unrotatedTop}
          />
        )}
      </SidebarItem>
      <SidebarItem disabled={editDisabled}>
        <RotationOpacityForm
          angle={imageStore.angle}
          onAngleChange={onAngleChange}
          onOpacityChange={onOpacityChange}
          opacity={imageStore.objectOpacity}
        />
      </SidebarItem>
      <SidebarItem disabled={editDisabled}>
        <HideForm hide={imageStore.hide} onHideChange={onHideChange} />
      </SidebarItem>
      {FF_ASSET_POSITION && (
        <SidebarItem disabled={editDisabled}>
          <AssetPosition />
        </SidebarItem>
      )}
      <SidebarItem disabled={editDisabled}>
        <AssetFileProperties />
      </SidebarItem>
    </SidebarPanel>
  );
});

export default ImageProperties;
