import React from 'react';
import PropTypes from 'prop-types';
import { Stack } from 'react-bootstrap';
import classnames from 'classnames';

import './SidebarPanel.scss';

function SidebarPanel({ children, className, show, title }) {
  if (!show) {
    return null;
  }

  return (
    <div className={classnames('sidebar-panel', className)}>
      {title && <p className="title">{title}</p>}
      {children && (
        <Stack className="sidebar-panel--items" gap={1}>
          {children}
        </Stack>
      )}
    </div>
  );
}

SidebarPanel.propTypes = {
  /** The children to render. */
  children: PropTypes.node.isRequired,
  /** Whether the panel should be shown or not. */
  show: PropTypes.bool,
  /** The title of the panel. */
  title: PropTypes.string,
};

SidebarPanel.defaultProps = {
  show: true,
  title: '',
};

export default SidebarPanel;
