import React from 'react';
import isEmpty from 'lodash/isEmpty';

import { Alert } from '@akiunlocks/perseus-ui-components';

import { getPerseusActionLabel } from 'utils/labels';

const Alerts = ({ alerts, isRender }) => {
  if (isEmpty(alerts)) {
    return null;
  }

  return (
    <>
      {Object.entries(alerts).map(([type, messages]) => {
        if (isEmpty(messages)) {
          return null;
        }
        return (
          <Alert className="fs-7 m-0" variant={type} key={type}>
            {type === 'danger' ? `Unable to ${getPerseusActionLabel(isRender, true)}:` : 'Remember that: '}
            <ul className="m-0">
              {messages.map(message => (
                <li key={message}>{message}</li>
              ))}
            </ul>
          </Alert>
        );
      })}
    </>
  );
};

export default Alerts;
