import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import { RadioButton } from '@akiunlocks/perseus-ui-components';

import { useRootStore } from 'store';

import { BOUNDARIES, BOUNDARIES_GROUP } from 'constants/canvas';

const Boundaries = observer(({ groupLayersSelected }) => {
  const { editor } = useRootStore();
  const boundaries = groupLayersSelected ? BOUNDARIES_GROUP : BOUNDARIES;
  return (
    <div className="d-flex align-items-center">
      {boundaries.map(option => {
        const { label, value } = option;
        return (
          <div key={`boundary-${label}`} className="d-flex">
            <RadioButton
              className="boundary"
              checked={editor.boundary === value}
              id={`boundary-${label}`}
              inline
              label={label}
              name="boundary"
              onChange={() => editor.setBoundary(value)}
            />
          </div>
        );
      })}
    </div>
  );
});

Boundaries.propTypes = {
  /** Flag to indicate if selected layers belong to same group. */
  groupLayersSelected: PropTypes.bool,
};

Boundaries.defaultProps = {
  groupLayersSelected: false,
};

export default Boundaries;
