import { useCallback } from 'react';

import { useRootStore } from 'store';

import useActiveObjectProperties from '../useActiveObjectProperties';

const useMaskProperties = () => {
  const { editor } = useRootStore();
  const { activeObject, canvas, maskStore } = editor;

  const { onAnchorChange, onAlignChange, onLeftChange, onLockProportionsChange, onTopChange } =
    useActiveObjectProperties(maskStore);

  const onMaskAnchorChange = useCallback(
    (originX, originY) => {
      if (!activeObject) {
        return;
      }
      const props = onAnchorChange(originX, originY);
      const group = canvas.getObjects().find(({ id }) => id === activeObject.groupId);
      group.clipPath?.setOptions(props);
      group.objects.forEach(obj => {
        obj.clipPath?.setOptions(props);
      });
      canvas.renderAll();
    },
    [activeObject, canvas, onAnchorChange],
  );

  const onSetLockedPosition = useCallback(
    lockedPosition => {
      maskStore.setLockedPosition(lockedPosition);
      editor.setMaskLockedPosition();
    },
    [editor, maskStore],
  );

  return {
    maskStore,
    onAlignChange,
    onAnchorChange: onMaskAnchorChange,
    onLeftChange,
    onLockProportionsChange,
    onSetLockedPosition,
    onTopChange,
  };
};

export default useMaskProperties;
