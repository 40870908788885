/**
 * Checks that string value starts with a letter
 * @param {string} value
 * @return {boolean}
 */
export function startsWithLetter(value) {
  const regex = /^[a-zA-Z].*/;
  return regex.test(value);
}

/**
 * Checks that string value starts with a number
 * @param {string} value
 * @return {boolean}
 */
export function startsWithNumber(value) {
  const regex = /^[0-9].*/;
  return regex.test(value);
}

/**
 * Checks that string value has consecutive spaces
 * @param {string} value
 * @return {boolean}
 */
export function hasConsecutiveSpaces(value) {
  const regex = /^.* {2,}.*/;
  return regex.test(value);
}

/**
 * Checks that string value only includes alphanumeric values and the following special characters: ( ) - _ '
 * @param {string} value
 * @return {boolean}
 */
export function validateAlphanumericAndSpecialCharacters(value) {
  const regex = /^([\w ()'-]*)?$/;
  return regex.test(value);
}
