/* eslint-disable object-shorthand */
/* eslint-disable func-names */
import { fabric } from 'fabric';

import { PRISMA_LANDSCAPE } from './constants/index.js';

const REFRESH_ICON_SVG_STRING =
  '<svg xmlns="http://www.w3.org/2000/svg"><path d="M26.475,9.525A12,12,0,1,0,29.6,21h-3.12A9,9,0,1,1,18,9a8.872,8.872,0,0,1,6.33,2.67L19.5,16.5H30V6Z" transform="translate(-6 -6)"/></svg>';

const PrismaLandscape = fabric.util.createClass(fabric.Group, {
  type: PRISMA_LANDSCAPE,

  initialize: function (options = {}) {
    const background = new fabric.Rect({
      fill: '#000000',
      height: options.height,
      originX: 'center',
      originY: 'center',
      strokeWidth: 0,
      width: options.width,
    });

    const label = new fabric.Text('Please rotate your device', {
      fill: '#ffffff',
      fontFamily: 'Lato',
      fontSize: 12,
      originX: 'center',
      originY: 'center',
      top: 20,
    });

    fabric.loadSVGFromString(REFRESH_ICON_SVG_STRING, elements => {
      const icon = fabric.util.groupSVGElements(elements);
      icon.set({
        fill: '#ffffff',
        originX: 'center',
        originY: 'center',
        scaleX: 2,
        scaleY: 2,
        top: -20,
      });

      this.callSuper('initialize', [background, label, icon], {
        hoverCursor: 'default',
        left: 0,
        selectable: false,
        top: 0,
      });
    });
  },
});

fabric.PrismaLandscape = PrismaLandscape;

export default PrismaLandscape;
