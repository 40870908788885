import { makeAutoObservable } from 'mobx';
import cloneDeep from 'lodash/cloneDeep';

import { PrismaKeyframe } from '@prisma/lib';
import { LAYER_TYPES, PRISMA_GROUP, PRISMA_TEXT } from '@prisma/lib/src/constants';
import { createActiveSelection, getPositionFromActiveSelection } from '@prisma/lib/src/utils/helpers';
import { ANIMATIONS_WITH_POSITION } from '@prisma/lib/src/utils/types';

import { CLIPBOARD_LAYERS, CLONE_OFFSET, SHAPE_LAYERS } from 'constants/editor';

import { getGroupLayers, setClonedMaskProperties } from './utilities';

class ClipboardStore {
  clipboard = [];

  constructor(editor) {
    makeAutoObservable(this);
    this.editor = editor;
  }

  copySelectedComponents() {
    const { selectionStore } = this.editor;
    if (selectionStore.hasSelected()) {
      const selected = selectionStore.getSelectedComponents();

      const keyframesSelected = selectionStore.hasKeyframesSelected();
      const layersSelected = selected.every(({ type }) => CLIPBOARD_LAYERS.includes(type));
      const copyAllowed = keyframesSelected || layersSelected;

      if (!copyAllowed) {
        return;
      }

      if (layersSelected) {
        // discard layers to clone correct properties
        this.editor.canvas.discardActiveObject();
      }

      this.clipboard = this._cloneComponents(selected);

      if (layersSelected) {
        // select layers again
        this.editor.contextStore.selectLayers(selected);
      }
    }
  }

  pasteClipboard() {
    // we don't modify the current clipboard
    const cloned = this.clipboard.map(component => {
      return this._clone(component);
    });
    return cloned;
  }

  paste() {
    if (this._isKeyframe()) {
      this.editor.timelineSceneStore.pasteKeyframes(this.pasteClipboard());
    } else {
      // split elements in clipboard in two arrays depending if they are groups or not
      const groups = [];
      const layers = [];
      this.clipboard.forEach(component =>
        component.type === PRISMA_GROUP ? groups.push(component) : layers.push(component),
      );
      const pastedGroups = groups.filter(group => !!group).map(group => this._pasteGroup(group));
      const pastedLayers = this._pasteLayers(layers);
      const addedLayers = [...pastedGroups, ...pastedLayers];

      this.editor.contextStore.selectLayers(addedLayers);
    }
  }

  /**
   * Duplicates pasted group and selects it.
   * @param {object} groupLayer - Group layer to duplicate.
   */
  _pasteGroup(groupLayer) {
    const { canvas, pageStore } = this.editor;

    // 1. get group layers except mask
    const groupLayers = getGroupLayers(groupLayer, canvas.layers);
    // 2. clone layers and remove group properties
    const clonedLayers = this._cloneComponents(groupLayers);
    clonedLayers.forEach(l => {
      l.target.groupId = null;
    });
    // 3. add cloned layers to canvas and select them
    const activeSelection = this._addClonedLayersToCanvas(clonedLayers, pageStore.getNextLayerId());
    this._selectActiveObject(activeSelection, true);
    // 4. group active objects
    this.editor.groupActiveObjects();
    canvas.discardActiveObject();

    const newGroupLayer = canvas.layers.find(({ id }) => id === activeSelection[0].groupId);
    if (newGroupLayer) {
      // 5. copy animations to new group
      const newAnimations = this._getClonedAnimationsWithOffset(groupLayer.animations, newGroupLayer.id);
      this.editor.canvas.editLayer(newGroupLayer.id, { animations: newAnimations });
      // 6. modify new mask with original properties
      setClonedMaskProperties(groupLayer.target, newGroupLayer.target);
    }
    return newGroupLayer;
  }

  /**
   * Checks if selected layers belong to a single group and returns the group id.
   * If there is any different group or no group, null is returned.
   * @return {number} Group id or null.
   */
  _getPasteDestinationGroupId() {
    const selectedLayers = this.editor.selectionStore.getSelectedLayers();
    const groupIds = selectedLayers.reduce((acc, layer) => {
      const groupId = layer.type === PRISMA_GROUP ? layer.id : layer.target?.groupId;
      acc.push(groupId);
      return acc;
    }, []);
    return groupIds.length && groupIds.every(id => id && id === groupIds[0]) ? groupIds[0] : null;
  }

  /**
   * Add pasted layers to the canvas and selects them.
   * @param {array} layers - Layers to duplicate.
   * @return {array} Layers added to the canvas.
   */
  _pasteLayers(layers) {
    if (!layers.length) {
      return [];
    }
    const { canvas, pageStore } = this.editor;

    const destinationGroupId = this._getPasteDestinationGroupId();

    canvas.discardActiveObject();

    const addedLayers = this._addClonedLayersToCanvas(layers, pageStore.getNextLayerId(), destinationGroupId);

    return addedLayers;
  }

  /**
   * Given a list of cloned layers, adds an offset to each one and add them to the canvas.
   * @param {array} clonedLayers - Layers to add.
   * @param {number} newLayerId - New layer id.
   * @param {number} groupId - Optional group id to clone the layers inside it.
   * @return {array} Layers added to the canvas.
   */
  _addClonedLayersToCanvas(clonedLayers, newLayerId, groupId = null) {
    const activeSelection = [];

    const group = this.editor.canvas.layers.find(({ id }) => id === groupId)?.target;
    const maskId = group?.maskId;

    for (let i = 0; i < clonedLayers.length; i++) {
      const layer = clonedLayers[i];
      // we modify the clipboard positions for the next paste
      layer.target.top += CLONE_OFFSET;
      layer.target.left += CLONE_OFFSET;
      layer.target.groupId = groupId;
      layer.target.maskId = maskId;
      layer.animations = this._getClonedAnimationsWithOffset(layer.animations, layer.id);

      // we clone the modified layer
      const layerAdded = this.editor.canvas.addLayerToCanvas(this._clone(layer), newLayerId);
      this.editor.resetGroupSizeIfExists(layerAdded.target.groupId);
      activeSelection.push(layerAdded.target);
    }

    return activeSelection;
  }

  /**
   * Given a list of animations, clones the keyframes, applies offset and assign the new layer id.
   * @param {array} animations - Animations to clone.
   * @param {number} destinationLayerId - Destination layer id.
   * @return {array} Cloned animations.
   */
  _getClonedAnimationsWithOffset(animations, destinationLayerId) {
    return animations.map(animation => {
      return {
        ...animation,
        keyframes: animation.keyframes.map(keyframe => {
          const clonedKeyframe = keyframe.clone();
          clonedKeyframe.layerId = destinationLayerId;
          if (ANIMATIONS_WITH_POSITION.includes(clonedKeyframe.type)) {
            clonedKeyframe.properties.x += CLONE_OFFSET;
            clonedKeyframe.properties.y += CLONE_OFFSET;
          }
          return clonedKeyframe;
        }),
      };
    });
  }

  /**
   * Sets the active object in the canvas, whether it is an active selection or a single layer.
   * @param {array} layers - List of layers.
   * @param {boolean} isActiveSelection - Flag to indicate if it is a single layer or a multi selection.
   */
  _selectActiveObject(layers, isActiveSelection) {
    const { canvas } = this.editor;
    const selection = isActiveSelection ? createActiveSelection(layers) : layers[0];
    canvas.setActiveObject(selection);
  }

  /**
   * Function that will be called when the component has to be cloned.
   * @param {Component} component
   * @return {Component}
   */
  _clone(component) {
    if (CLIPBOARD_LAYERS.includes(component.type)) {
      // layers must be cloned differently because fabric.util.object.clone does not copy the classes
      // and component.clone() does not copy all the properties because the toObject
      const cloned = cloneDeep(component);
      cloned.id = undefined;

      // use custom clone for shape objects
      if (SHAPE_LAYERS.includes(component.type) || PRISMA_TEXT === component.type) {
        cloned.target = cloned.target.clone();
      }
      return cloned;
    }
    return component.clone();
  }

  /**
   * Function to be called to clone many components.
   * If it is an active selection, use the correct coordinates in the objects.
   * @param {array} components - List of components.
   * @return {array} Array of components.
   */
  _cloneComponents(components) {
    // we clone all the selected components one by one
    const cloned = components.map(component => {
      const clone = this._clone(component);
      // targets with group property are part of an activeSelection
      if (clone.target?.group) {
        const { left, top } = getPositionFromActiveSelection(clone.target);
        clone.target.left = left;
        clone.target.top = top;
      }
      return clone;
    });
    return cloned;
  }

  /**
   * Checks if the components in clipboard are groups
   * @return {boolean}
   */
  _isGroup() {
    return this._isLayer() && this.clipboard[0].type === PRISMA_GROUP;
  }

  /**
   * Checks if the components in clipboard are keyframes
   * @return {boolean}
   */
  _isKeyframe() {
    return this.clipboard.length > 0 && this.clipboard[0] instanceof PrismaKeyframe;
  }

  /**
   * Checks if the components in clipboard are layers
   * @return {boolean}
   */
  _isLayer() {
    return this.clipboard.length > 0 && LAYER_TYPES.includes(this.clipboard[0].type);
  }
}

export default ClipboardStore;
