// Overwritten from FabricJS to support multiple scaling modes

import { fabric } from 'fabric';

import { scalingX, scalingY } from './scaleModes.js';

const { skewCursorStyleHandler, scaleCursorStyleHandler, skewHandlerX, skewHandlerY } = fabric.controlsUtils;

function isAltAction(eventData, target) {
  return eventData[target.canvas?.altActionKey];
}

/**
 * Combine skew and scale style handlers to cover fabric standard use case
 * @param {Event} eventData the javascript event that is causing the scale
 * @param {Control} control the control that is interested in the action
 * @param {FabricObject} fabricObject the fabric object that is interested in the action
 * @return {String} a valid css string for the cursor
 */
export const scaleSkewCursorStyleHandler = (eventData, control, fabricObject) => {
  if (isAltAction(eventData, fabricObject)) {
    return skewCursorStyleHandler(eventData, control, fabricObject);
  }
  return scaleCursorStyleHandler(eventData, control, fabricObject);
};
/**
 * Composed action handler to either scale X or skew Y
 * Needs to be wrapped with `wrapWithFixedAnchor` to be effective
 * @param {Event} eventData javascript event that is doing the transform
 * @param {Object} transform javascript object containing a series of information around the current transform
 * @param {number} x current mouse x position, canvas normalized
 * @param {number} y current mouse y position, canvas normalized
 * @return {Boolean} true if some change happened
 */
export const scalingXOrSkewingY = (eventData, transform, x, y) => {
  if (isAltAction(eventData, transform.target)) {
    return skewHandlerY(eventData, transform, x, y);
  }
  return scalingX(eventData, transform, x, y);
};

/**
 * Composed action handler to either scale Y or skew X
 * Needs to be wrapped with `wrapWithFixedAnchor` to be effective
 * @param {Event} eventData javascript event that is doing the transform
 * @param {Object} transform javascript object containing a series of information around the current transform
 * @param {number} x current mouse x position, canvas normalized
 * @param {number} y current mouse y position, canvas normalized
 * @return {Boolean} true if some change happened
 */
export const scalingYOrSkewingX = (eventData, transform, x, y) => {
  if (isAltAction(eventData, transform.target)) {
    return skewHandlerX(eventData, transform, x, y);
  }
  return scalingY(eventData, transform, x, y);
};
