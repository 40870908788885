import { forwardRef, useRef, useState } from 'react';
import { observer } from 'mobx-react';

import { useRootStore } from 'store';
import { DEFAULT_SELECTED_POINT, HALF_KEYFRAME_WIDTH } from 'constants/timeline';
import TimeLineArea from '../TimelineArea';

import './TimeLineRow.scss';

export const TimeLineRow = observer(
  forwardRef(
    (
      {
        seek,
        zoomConfig,
        left,
        onHorizontalScroll,
        handleKeyframeMove,
        isDraggable,
        isAnimatable,
        selectedLayersId,
        top,
      },
      handlerRef,
    ) => {
      const timelineRef = useRef();

      const { editor } = useRootStore();
      const { timelineSceneStore } = editor;

      const [isMoving, setIsMoving] = useState(false);

      const getXValue = e => {
        return Math.floor(e.clientX - timelineRef.current.getBoundingClientRect().x + timelineRef.current.scrollLeft);
      };

      const cancelKeyframeMovement = () => {
        const { activeAnimation, activeLayer, selectedPoint } = timelineSceneStore;
        if (activeLayer && activeAnimation && selectedPoint.oldValue !== selectedPoint.newValue) {
          handleKeyframeMove(activeLayer, selectedPoint.newValue);
        }
        timelineSceneStore.selectedPoint = DEFAULT_SELECTED_POINT;
        stopMoving();
      };

      const stopMoving = () => {
        setIsMoving(false);
        timelineSceneStore.setMovingKeyframeId(null);
        timelineSceneStore.setTempKeyframeData({});
      };

      return (
        <div
          onClick={e => {
            if (isMoving || !isDraggable || !isAnimatable) {
              return;
            }
            const xValue = getXValue(e);
            seek(xValue);
            timelineSceneStore.clearActiveAnimation();
          }}
          onMouseMove={e => {
            // if a temporary keyframe is saved, add it to the selection
            timelineSceneStore.finishKeyframeSelectionOnMove();
            const xValue = getXValue(e);
            if (timelineSceneStore.isMovingKeyframe()) {
              timelineSceneStore.timelineCursorPosition = xValue;
            }
            if (!isMoving || !isDraggable || !isAnimatable) {
              return;
            }
            seek(xValue);
          }}
          onMouseUp={() => {
            stopMoving();
            const { activeKeyframePoint, selectedPoint } = timelineSceneStore;

            if (selectedPoint?.newValue === null) {
              return;
            }

            cancelKeyframeMovement();

            if (activeKeyframePoint === null) {
              return;
            }
            seek(activeKeyframePoint + HALF_KEYFRAME_WIDTH);
          }}
          onMouseEnter={e => {
            // 1 is the left click
            if (e.buttons === 1) {
              return;
            }
            cancelKeyframeMovement();
          }}
          onMouseLeave={() => {
            setIsMoving(false);
          }}
          onScroll={onHorizontalScroll}
          ref={timelineRef}
          className="timeline-accordion-wrapper"
        >
          {isAnimatable && (
            <>
              <span
                style={{ left, top }}
                className="anchor-handler-container"
                onMouseDown={() => {
                  setIsMoving(true);
                }}
                onMouseUp={() => {
                  stopMoving();
                }}
                ref={handlerRef}
              >
                <span className="anchor-handler"></span>
              </span>
              <div
                style={{ left }}
                className="anchor"
                onMouseDown={() => {
                  setIsMoving(true);
                }}
                onMouseUp={() => {
                  stopMoving();
                }}
              ></div>
            </>
          )}
          <TimeLineArea isAnimatable={isAnimatable} selectedLayersId={selectedLayersId} zoomConfig={zoomConfig} />
        </div>
      );
    },
  ),
);
