import { makeAutoObservable } from 'mobx';
import cloneDeep from 'lodash/cloneDeep';
import { recalculateValuesInLayersWithAspectRatio } from '@prisma/lib/src/utils/layers';

import { removeImageLayerFromPages, replaceImageinPages } from './utilities';
import { getAspectRatioAndOpposite } from 'utils/aspectRatios';

const HISTORY_LENGTH = 50;

class HistoryStore {
  actionsHistory = [];
  actionsHistoryIndex = -1;
  undoDisabled = true;
  redoDisabled = true;
  skipNext = false;

  constructor() {
    makeAutoObservable(this);
  }

  /**
   * Clears history and set undo/redo to false.
   */
  clearHistory() {
    this.actionsHistory = [];
    this.actionsHistoryIndex = -1;
    this.undoDisabled = true;
    this.redoDisabled = true;
    this.skipNext = false;
  }

  /**
   * Checks if the current step is at the end of the actions history.
   * @return {boolean} True if the current step is at the end of the history, else false.
   */
  isLastStepOfHistory() {
    return this.actionsHistoryIndex === this.actionsHistory.length - 1;
  }

  /**
   * Checks if the actions history is empty.
   * @return {boolean} True if the actions history is empty, else false.
   */
  isEmpty() {
    return !this.actionsHistory.length;
  }

  setSkipNext(skip) {
    this.skipNext = skip;
  }

  /**
   * Adds an action to the history and update undo/redo statuses.
   * @param {object} - Action to add to the history.
   */
  addToHistory(action) {
    const isHistoryFull = this.actionsHistory.length === HISTORY_LENGTH;
    const isIndexAtTheEnd = this.isLastStepOfHistory();
    // If the history is full and the index is at the end, the oldest action in the history is removed
    const prevActions = this.actionsHistory.slice(
      isIndexAtTheEnd && isHistoryFull ? 1 : 0,
      this.actionsHistoryIndex + 1,
    );
    this.actionsHistory = [...prevActions, cloneDeep(action)];

    if (!isIndexAtTheEnd || !isHistoryFull) {
      this.actionsHistoryIndex += 1;
    }

    this.undoDisabled = this.actionsHistoryIndex <= 0;
    this.redoDisabled = true;
  }

  /**
   * Given an index, returns a copy of the action stored in the history.
   * @param {index} - Index to search in the actions history.
   * @return {object} History action.
   */
  getFromHistory(index) {
    return cloneDeep(this.actionsHistory[index]);
  }

  /**
   * Gets a copy of an action in the history according to actionsHistoryIndex.
   * @return {object} History action.
   */
  getCurrentFromHistory() {
    return this.getFromHistory(this.actionsHistoryIndex);
  }

  /**
   * Moves backwards in the history.
   * @return {object} History action if present, else undefined.
   */
  undo() {
    return this.actionsHistoryIndex > 0 ? this._moveTo(this.actionsHistoryIndex - 1) : undefined;
  }

  /**
   * Moves forward in the history.
   * @return {object} History action if present, else undefined.
   */
  redo() {
    return this.actionsHistoryIndex < this.actionsHistory.length - 1
      ? this._moveTo(this.actionsHistoryIndex + 1)
      : undefined;
  }

  /**
   * Moves to a specific point in the history.
   * @param {index} - Index to move to.
   * @return {object} History action.
   */
  _moveTo(index) {
    this.actionsHistoryIndex = index;

    this.undoDisabled = index <= 0;
    this.redoDisabled = this.actionsHistory.length === 0 || index === this.actionsHistory.length - 1;

    return this.getFromHistory(index);
  }

  /**
   * Updates image properties in layers using a given imageUrl.
   * @param {string} imageUrl - Current image url.
   * @param {object} newAsset - New asset properties.
   */
  updateImageLayers(imageUrl, newAsset) {
    this.actionsHistory.forEach(({ pages }) => {
      replaceImageinPages(pages, imageUrl, newAsset);
    });
  }

  /**
   * Removes image layers using a given imageUrl.
   * @param {string} imageUrl - Image url to be removed.
   */
  removeImageLayer(imageUrl) {
    this.actionsHistory.forEach(step => {
      removeImageLayerFromPages(step.pages, imageUrl);
    });
  }

  /**
   * Recalculates layers for all pages in all steps depending on the aspect ratio.
   */
  updateAspectRatio(aspectRatioType) {
    const { aspectRatio, oppositeAspectRatio } = getAspectRatioAndOpposite(aspectRatioType);
    this.actionsHistory.forEach(step => {
      step.pages.forEach(page => {
        if (page.isLocked()) {
          // means it was locked
          const recalculatedLayers = recalculateValuesInLayersWithAspectRatio(
            page.layers,
            oppositeAspectRatio,
            aspectRatio,
          );
          page.layers = recalculatedLayers;
        }
      });
    });
  }
}

export default HistoryStore;
