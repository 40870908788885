/**
 * Clips a number to be within a specified range.
 * @param {number} value - The value to be clipped.
 * @param {number} min - The minimum limit.
 * @param {number} max - The maximum limit.
 * @return {number} The clipped value.
 */
export function clip(value, min, max) {
  return Math.max(min, Math.min(max, value));
}
