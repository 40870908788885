import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import { Accordion } from '@akiunlocks/perseus-ui-components';

import { useRootStore } from 'store';

import AccordionItem from './AccordionItem';

import './layerAccordion.scss';

export const LayerAccordion = observer(
  ({
    activeKeyframe,
    activeLayer,
    currentTimeLineValue,
    editLayer,
    handlers,
    hasMultipleSelected,
    isAnimatable,
    isDraggable,
    isGroupChild,
    layers,
    selectLayer,
    selectedLayersId,
  }) => {
    const { editor, layerAccordionStore } = useRootStore();
    const { dragItemId, dragOverId } = layerAccordionStore;

    useEffect(() => {
      if (handlers) {
        layerAccordionStore.setHandlers(handlers);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [handlers]);

    function clearDragIds() {
      layerAccordionStore.setDragItemId(null);
      layerAccordionStore.setDragOverId(null);
    }

    return (
      <Accordion
        onDragOver={e => {
          e.preventDefault();
        }}
        onDragEnd={clearDragIds}
        onDrop={() => {
          // if it is a layer from a group, let the parent to handle the drop
          // if drop is not allowed, return
          if (isGroupChild || !layerAccordionStore.isDropAllowed(dragItemId, dragOverId, editor.canvas.layers)) {
            return;
          }
          layerAccordionStore.handlers.drop(dragItemId, dragOverId);
          clearDragIds();
        }}
        className="layer-accordion"
        activeKey={layerAccordionStore.getOpened()}
        onSelect={layerAccordionStore.toggleOpened}
      >
        {layers.map(l => (
          <AccordionItem
            activeKeyframe={activeKeyframe}
            activeLayer={activeLayer}
            currentTimeLineValue={currentTimeLineValue}
            editLayer={editLayer}
            hasMultipleSelected={hasMultipleSelected}
            isAnimatable={isAnimatable}
            isDraggable={isDraggable}
            isGroupChild={isGroupChild}
            key={l.id}
            layer={l}
            selectLayer={selectLayer}
            selectedLayersId={selectedLayersId}
          />
        ))}
      </Accordion>
    );
  },
);
