import { action, makeObservable, observable } from 'mobx';
import { VERTICAL_ALIGN } from '@prisma/lib/src/constants/canvas';
import { ADJUST_OPTIONS } from '@prisma/lib/src/constants/text';

import ObjectStore from './Object.store';

import {
  DEFAULT_FONT,
  DEFAULT_FONT_FILE_NAME,
  DEFAULT_FONT_STYLE,
  DEFAULT_SHADOW,
  DEFAULT_TEXTBOX,
} from '../../../constants';

class TextboxStore extends ObjectStore {
  adjustOption = ADJUST_OPTIONS.FONT_SIZE;
  charSpacing = 0;
  fill = DEFAULT_TEXTBOX.fill;
  fontFamily = DEFAULT_FONT;
  fontFileName = DEFAULT_FONT_FILE_NAME;
  fontSize = 25;
  fontStyle = DEFAULT_FONT_STYLE.value;
  lineHeight = 1;
  linethrough = false;
  maxLines = 1;
  maxLinesEnabled = false;
  overline = false;
  shadowBlur = DEFAULT_SHADOW.blur;
  shadowColor = DEFAULT_SHADOW.color;
  shadowEnabled = false;
  shadowOffsetX = DEFAULT_SHADOW.offsetX;
  shadowOffsetY = DEFAULT_SHADOW.offsetY;
  textAlign = 'left';
  underline = false;
  verticalAlign = VERTICAL_ALIGN.TOP;

  constructor(editor) {
    super();
    this.editor = editor;
    makeObservable(this, {
      ...this.props,
      adjustOption: observable,
      charSpacing: observable,
      fill: observable,
      fontFamily: observable,
      fontFileName: observable,
      fontSize: observable,
      fontStyle: observable,
      getActiveTextDecoration: observable,
      lineHeight: observable,
      linethrough: observable,
      maxLines: observable,
      overline: observable,
      shadowBlur: observable,
      shadowColor: observable,
      shadowEnabled: observable,
      shadowOffsetX: observable,
      shadowOffsetY: observable,
      textAlign: observable,
      underline: observable,
      verticalAlign: observable,
      getShadowProps: action,
      getTextDecoration: action,
      setAdjustOption: action,
      setFontSize: action,
      setMaxLines: action,
      setShadowEnabled: action,
      setShadowProps: action,
      setTextDecorationValue: action,
    });
  }

  initialize(textbox) {
    super.initialize(textbox);

    this.adjustOption = textbox.getAdjustOption();
    this.charSpacing = textbox.charSpacing;
    this.fill = textbox.fill;
    this.fontFamily = textbox.fontFamily || DEFAULT_FONT;
    this.fontFileName = textbox.fontFamily === DEFAULT_FONT ? DEFAULT_FONT_FILE_NAME : textbox.fontFileName;
    this.fontSize = Math.floor(textbox.fontSize);
    this.fontStyle = textbox.fontStyle;
    this.lineHeight = textbox.lineHeight;
    this.linethrough = textbox.linethrough;
    this.maxLines = textbox.maxLines || textbox.getNumberOfTextLines(); // the store will have what the text currently has
    this.overline = textbox.overline;
    this.shadowBlur = textbox.shadow ? textbox.shadow.blur : DEFAULT_SHADOW.blur;
    this.shadowColor = textbox.shadow ? textbox.shadow.color : DEFAULT_SHADOW.color;
    this.shadowEnabled = !!textbox.shadow;
    this.shadowOffsetX = textbox.shadow ? textbox.shadow.offsetX : DEFAULT_SHADOW.offsetX;
    this.shadowOffsetY = textbox.shadow ? textbox.shadow.offsetY : DEFAULT_SHADOW.offsetY;
    this.textAlign = textbox.textAlign;
    this.underline = textbox.underline;
    this.verticalAlign = textbox.verticalAlign;

    this.addEvents(textbox, {
      changed: this.onChange.bind(this),
      resizing: this.onResizing.bind(this),
    });
  }

  onChange() {
    if (this.editor.activeObject.recalculateFontSize()) {
      this.setFontSize(this.editor.activeObject.fontSize);
    }
    if (this.adjustOption !== ADJUST_OPTIONS.LINES) {
      this.setMaxLines(this.editor.activeObject.getNumberOfTextLines());
    }
    this.updateActiveObjectSizeIfNeeded();
  }

  updateActiveObjectSizeIfNeeded() {
    if (!this.editor.activeObject) {
      return;
    }
    const { height, width } = this.editor.activeObject;
    if (height !== this.height || width !== this.width) {
      this.onObjectScaling({ transform: { target: this.editor.activeObject } });
    }
  }

  onResizing(event) {
    const {
      transform: { target },
    } = event;
    this.setMaxLines(target.getNumberOfTextLines());

    this.onObjectScaling(event);
  }

  setAdjustOption(option) {
    this.adjustOption = option;
    if (option === ADJUST_OPTIONS.LINES && !this.maxLines) {
      this.setMaxLines(1);
    }
  }

  setFontSize(fontSize) {
    this.fontSize = fontSize;
  }

  setMaxLines(maxLines = 1) {
    this.maxLines = maxLines;
  }

  getActiveTextDecoration() {
    return Object.keys(this.getTextDecoration()).find(key => this[key]);
  }

  setTextDecorationValue(activeProp) {
    this.overline = activeProp === 'overline';
    this.underline = activeProp === 'underline';
    this.linethrough = activeProp === 'linethrough';
  }

  getTextDecoration() {
    return {
      overline: this.overline,
      underline: this.underline,
      linethrough: this.linethrough,
    };
  }

  setShadowEnabled(enable) {
    this.shadowEnabled = enable;
  }

  getShadowProps() {
    return {
      blur: this.shadowBlur,
      color: this.shadowColor,
      offsetX: this.shadowOffsetX,
      offsetY: this.shadowOffsetY,
    };
  }

  setShadowProps(props = {}) {
    const {
      blur = this.shadowBlur,
      color = this.shadowColor,
      offsetX = this.shadowOffsetX,
      offsetY = this.shadowOffsetY,
    } = props;

    this.shadowBlur = blur;
    this.shadowColor = color;
    this.shadowOffsetX = offsetX;
    this.shadowOffsetY = offsetY;
  }
}

export default TextboxStore;
