import { useEffect } from 'react';

import { useRootStore } from 'store';

import { postProjectInfo } from 'utils/parentWindow';

const useReportProjectInfo = () => {
  const { projects } = useRootStore();
  const { project } = projects;

  useEffect(() => {
    postProjectInfo(project?.name, project?._id, project?.sizes);

    return () => {
      postProjectInfo(undefined, undefined, undefined);
    };
  }, [project?.name, project?._id, project?.sizes]);

  return null;
};

export default useReportProjectInfo;
