import React from 'react';
import { observer } from 'mobx-react';

import { USE_RESPONSIVE } from 'utils/featureFlag';

import AlignButtons from 'components/Sidebar/components/Align';
import Distribute from 'components/Sidebar/components/Distribute/Distribute';
import SidebarPanel from 'components/Sidebar/components/SidebarPanel';
import SidebarItem from 'components/Sidebar/components/SidebarPanel/SidebarItem';

import AnchorPoints from '../AnchorPoints';
import GroupsPanel from '../GroupsPanel';
import HideForm from '../HideForm';
import PositionForm from '../PositionForm';

import useGroupProperties from './GroupProperties.hooks';
import useMultiSelectProperties from '../MultiSelectProperties/MultiSelectProperties.hooks';

const GroupProperties = observer(({ disabled, isTimelineLayersTab }) => {
  const { groupStore, onAlignChange, onHideChange, onLeftChange, onTopChange } = useGroupProperties();
  const { onDistributeChange } = useMultiSelectProperties();

  const editDisabled = disabled || !isTimelineLayersTab;

  return (
    <SidebarPanel title="Group">
      <SidebarItem disabled={disabled}>
        <AlignButtons onClick={onAlignChange} />
      </SidebarItem>

      <SidebarItem disabled={editDisabled}>
        {USE_RESPONSIVE ? (
          <AnchorPoints />
        ) : (
          <PositionForm
            left={groupStore.unrotatedLeft}
            onLeftChange={onLeftChange}
            onTopChange={onTopChange}
            top={groupStore.unrotatedTop}
          />
        )}
      </SidebarItem>
      <SidebarItem disabled={editDisabled}>
        <Distribute className="mb-1" onClick={onDistributeChange} showBoundaries />
      </SidebarItem>
      <SidebarItem disabled={editDisabled}>
        <GroupsPanel />
      </SidebarItem>
      <SidebarItem disabled={editDisabled}>
        <HideForm hide={groupStore.hide} onHideChange={onHideChange} />
      </SidebarItem>
    </SidebarPanel>
  );
});

export default GroupProperties;
