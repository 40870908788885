import React from 'react';
import PropTypes from 'prop-types';
import { Col, Form, Row } from 'react-bootstrap';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import { useRootStore } from 'store';
import ToggleIconButton from 'components/ToggleIconButton';
import PercentageInput from 'components/Sidebar/components/PercentageInput/PercentageInput';
import LabeledInput from 'components/Sidebar/components/LabeledInput';
import useActiveObjectProperties from '../useActiveObjectProperties';

import { USE_RESPONSIVE } from 'utils/featureFlag';

import './SizeForm.scss';

const SizeForm = observer(({ height, onLockProportionsChange, showLockProportions, uniformScaling, width }) => {
  const { editor } = useRootStore();
  const store = editor.getActiveStore();
  const { onUnitChange, onWidthChange, onHeightChange } = useActiveObjectProperties(store);

  const InputComponent = USE_RESPONSIVE ? PercentageInput : LabeledInput;

  return (
    <Form.Group className="size-form">
      <Form.Label>Size</Form.Label>
      <Row>
        <Col>
          <InputComponent
            id="size-w"
            label="W"
            prop="width"
            unit={store.widthUnit}
            controlProps={{
              min: 1,
              step: 1.0,
              max: 10000,
              type: 'number',
              placeholder: '',
              value: width,
              onChange: onWidthChange,
            }}
            onUnitChange={onUnitChange}
          />
        </Col>
        {showLockProportions && (
          <span className={classnames('lock-wrapper', { xs: USE_RESPONSIVE })}>
            <ToggleIconButton
              icons={['unlock', 'lock']}
              className="lock"
              onClick={event => {
                event.preventDefault();
                onLockProportionsChange(!uniformScaling);
              }}
              value={uniformScaling}
              iconSize={14}
            />
          </span>
        )}
        <Col>
          <InputComponent
            id="size-h"
            label="H"
            prop="height"
            unit={store.heightUnit}
            controlProps={{
              min: 1,
              step: 1.0,
              max: 10000,
              type: 'number',
              placeholder: '',
              value: height,
              onChange: onHeightChange,
            }}
            onUnitChange={onUnitChange}
          />
        </Col>
      </Row>
    </Form.Group>
  );
});

SizeForm.propTypes = {
  /** Height value */
  height: PropTypes.number,
  /** Action to take on height change */
  onHeightChange: PropTypes.func,
  /** Action to take on lock proportion change */
  onLockProportionsChange: PropTypes.func,
  /** Action to take on width change */
  onWidthChange: PropTypes.func,
  /** Flag to indicate if lock proportions is shown */
  showLockProportions: PropTypes.bool,
  /** Flag to indicate if size has uniform scaling */
  uniformScaling: PropTypes.bool,
  /** Width value */
  width: PropTypes.number,
};

SizeForm.defaultProps = {
  height: 0,
  onHeightChange: () => {},
  onLockProportionsChange: () => {},
  onWidthChange: () => {},
  showLockProportions: true,
  uniformScaling: false,
  width: 0,
};

export default SizeForm;
