import { makeObservable } from 'mobx';

import { DEFAULT_POSITION } from '../../../constants/objects';

import ObjectStore from './Object.store';

import { findObjectInGroup } from './utilities';

class GroupStore extends ObjectStore {
  editor;

  initialPosition = DEFAULT_POSITION;
  objectsInitialProperties = {};

  constructor(editor) {
    super();
    makeObservable(this, this.props);
    this.editor = editor;
  }

  initialize(group) {
    super.initialize(group);

    const { left, top } = group;
    this.initialPosition = { left, top };

    this._getObjectsInitialProperties(group.objects);
    this.addEvents(group, { mousedblclick: this.onMouseDoubleClick });
  }

  onMouseDoubleClick = event => {
    const { target } = event;

    // pointer coordinates
    const point = target.canvas.getPointer(event);

    const object = findObjectInGroup(point, target.objects);
    if (object) {
      this.editor.contextStore.selectLayer(object);
    }
  };

  // TODO: implement rotation and scaling
  updateObjectsProperties(group) {
    const { left, top } = group;
    const leftDiff = left - this.initialPosition.left;
    const topDiff = top - this.initialPosition.top;
    group.objects?.forEach(object => {
      const initialProps = this.objectsInitialProperties[object.id];
      if (!object.lockedPosition) {
        object.left = initialProps.left + leftDiff;
        object.top = initialProps.top + topDiff;
      }
    });
  }

  _getObjectsInitialProperties(objects) {
    objects?.forEach(object => {
      const { left, top } = object;
      this.objectsInitialProperties[object.id] = { left, top };
    });
  }
}

export default GroupStore;
