import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './SidebarPanel.scss';

const SidebarItem = ({ children, disabled, show }) => {
  if (!show) {
    return null;
  }

  return <div className={classnames({ disabled })}>{children}</div>;
};

SidebarItem.propTypes = {
  /** The children to render. */
  children: PropTypes.node.isRequired,
  /** Whether the element is disabled or not. */
  disabled: PropTypes.bool,
  /** Whether the element must be shown or not. */
  show: PropTypes.bool,
};

SidebarItem.defaultProps = {
  disabled: false,
  show: true,
};

export default SidebarItem;
