import { useRef } from 'react';
import { observer } from 'mobx-react';
import { Card } from 'react-bootstrap';
import { ReactMouseSelect } from 'react-mouse-select';
import classnames from 'classnames';

import { Accordion } from '@akiunlocks/perseus-ui-components';
import { ACTIVE_SELECTION } from '@prisma/lib/src/constants';

import { useRootStore } from 'store';
import { filterGroupAnimations } from 'utils/animations';
import { addAnimationIdentifier } from '../LayerAccordion/utilities';
import { FrameWrapper } from '../Frame/FrameWrapper';
import { KEYFRAME_CLASS_NAME } from '../Frame/constants';

import './TimeLineArea.scss';

export const TimeLineArea = observer(({ isAnimatable, selectedLayersId, zoomConfig }) => {
  const { editor, layerAccordionStore } = useRootStore();

  const containerRef = useRef(null);

  const onFinishSelection = (selectedItems, e) => {
    const selectedKeyframes = editor.getKeyframesByIds(selectedItems.map(item => item.dataset.id));
    editor.contextStore.selectKeyframes(selectedKeyframes, e.metaKey || e.shiftKey || e.ctrlKey);
  };

  return (
    <div ref={containerRef}>
      <Accordion
        className="timeline-accordion"
        style={{ width: zoomConfig.width + 20 }}
        activeKey={layerAccordionStore.getOpened()}
      >
        {editor.getReversedLayers().map(l => {
          const { groupId } = l.properties;
          if (groupId && !layerAccordionStore.isGroupOpen(groupId)) {
            return null; // if the group isn't open don't show children rows
          }
          return (
            <Card
              className={classnames({ selected: selectedLayersId.includes(l.id) })}
              key={`${l.id}timelinerow`}
              onClick={() => {
                if (editor.activeObject?.type !== ACTIVE_SELECTION) {
                  editor.contextStore.selectLayer(l);
                }
              }}
            >
              <Card.Header />
              <Accordion.Collapse eventKey={addAnimationIdentifier(l.id)}>
                <Card.Body className="timeline-body">
                  {isAnimatable &&
                    filterGroupAnimations(l.animations).map(a => {
                      return (
                        <div className="animRow" key={a.type}>
                          <FrameWrapper animation={a} keyframes={a?.keyframes} layer={l} zoomConfig={zoomConfig} />
                        </div>
                      );
                    })}
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          );
        })}
      </Accordion>
      <ReactMouseSelect
        containerRef={containerRef}
        finishSelectionCallback={onFinishSelection}
        itemClassName={KEYFRAME_CLASS_NAME}
        notStartWithSelectableElements
        onClickPreventDefault
      />
    </div>
  );
});
