import axios from 'axios';
import { makeAutoObservable } from 'mobx';
import { Notification, notify } from '@akiunlocks/perseus-ui-components';

import ButtonMessage from 'models/chat/ButtonMessage';
import ImageMessage from 'models/chat/ImageMessage';
import Message from 'models/chat/Message';
import MultipleMessage from 'models/chat/MultipleMessage';
import TemplateMessage from 'models/chat/TemplateMessage';
import Event from 'models/chat/Event';
import { EMPTY_SLOTS, SLOTS, TEMPLATES_FOLDER, MESSAGE_TYPE, CODES } from 'constants/chat';
import { sendEvents, sendMessage } from 'externals/chatServer';

class ChatStore {
  loading = false;
  messages = [];
  slotsData = EMPTY_SLOTS;
  nextMessageType = MESSAGE_TYPE.DEFAULT;
  messageLoading = false;

  samShow = false;
  viewShow = false;
  viewImageSrc = null;

  constructor(editor) {
    makeAutoObservable(this);
    this.editor = editor;
  }

  getUserId() {
    return this.editor.rootStore.users.getUserId();
  }

  addMessage(message) {
    this.messages.push(message);
    this.setNextMessageType(MESSAGE_TYPE.DEFAULT);
  }

  setNextMessageType(type) {
    this.nextMessageType = type;
  }

  setLoading(loading) {
    this.loading = loading;
  }

  setMessageLoading(messageLoading) {
    this.messageLoading = messageLoading;
  }

  setSamShow(show) {
    this.samShow = show;
  }

  setViewShow(show) {
    this.viewShow = show;
  }

  setViewImageSrc(src) {
    this.viewImageSrc = src;
  }

  sendInitialMessage() {
    if (this.messages.length > 0) {
      return;
    }
    this.setLoading(true);
    setTimeout(() => {
      const message = {
        text: "Hi! I'm Percy, and I'm here to help you create your ad. How would you like to start?",
        buttons: [
          { title: 'From a template', payload: '/create_new_ad_from_template' },
          { title: 'From scratch', payload: '/start_from_scratch' },
        ],
      };
      this.addMessage(new ButtonMessage(message));
      this.setLoading(false);
    }, 1000);
  }

  setSegmentedImage(imagePath, maskPath) {
    this.setSamShow(false);
    this.updateSlots({
      [SLOTS.IMAGE_PATH]: imagePath,
      [SLOTS.MASK_PATH]: maskPath,
      [SLOTS.USER_PROMPT]: null,
    });
    this.addUserMessage('I would like to extract this object');
    this.addBotMessage('Please, describe how you would like to fill the space of the extracted object');
    this.setNextMessageType(MESSAGE_TYPE.CUT_AND_INPAINT);
  }

  updateSlot(name, value) {
    this.slotsData[name] = value;
  }

  updateSlots(data) {
    Object.entries(data).forEach(([name, value]) => {
      this.updateSlot(name, value);
    });
  }

  clearSlots() {
    this.slotsData = EMPTY_SLOTS;
  }

  updateSlotsWithActiveObject() {
    const activeObject = this.editor?.activeObject;
    if (activeObject) {
      // TODO: Check here that the other slots values correspond to the activeObject
      this.updateSlots({
        [SLOTS.WIDTH]: `${activeObject.width}`,
        [SLOTS.HEIGHT]: `${activeObject.height}`,
      });
    } else {
      this.clearSlots();
    }
  }

  addUserMessage(text) {
    const message = Message.userMessage(this.getUserId(), text);
    this.addMessage(message);
  }

  addBotMessage(text) {
    const message = Message.botMessage({ text });
    this.addMessage(message);
  }

  buildUserMessage(text, value) {
    let textToSend = text;
    let valueToSend = value;
    // If we have more options, we can use a switch here
    if (this.nextMessageType !== MESSAGE_TYPE.DEFAULT) {
      this.updateSlot(SLOTS.USER_PROMPT, text);
      valueToSend = CODES[this.nextMessageType];

      if (this.nextMessageType === MESSAGE_TYPE.CUT_AND_INPAINT) {
        this.updateSlot(SLOTS.USER_PROMPT, text);
      } else if (this.nextMessageType === MESSAGE_TYPE.AD_FROM_SCRATCH) {
        this.updateSlot(SLOTS.GENERATE_IMAGE_PROMPT, text);
      }
    }
    return Message.userMessage(this.getUserId(), textToSend, valueToSend);
  }

  async sendMessage(text, value) {
    this.setLoading(true);
    this.updateSlotsWithActiveObject();
    const message = this.buildUserMessage(text, value);
    const events = Event.slotEvents(this.getUserId(), this.slotsData);
    this.addMessage(message);
    try {
      await sendEvents(events);
      const response = await sendMessage(message);
      await this.processResponse(response);
      this.clearSlots();
    } catch (error) {
      console.error(error);
      notify('An error has occurred while talking with Percy. Please, try again.', Notification.TYPE.ERROR);
    }
    this.setLoading(false);
  }

  async processResponse(botResponse) {
    if (botResponse) {
      botResponse.forEach(async botMessage => {
        await this.processBotMessage(botMessage);
      });
    }
  }

  async processBotMessage(botMessage) {
    // the factory
    let message;
    if (botMessage.buttons) {
      message = new ButtonMessage(botMessage);
    } else if (botMessage.custom?.type === 'sam_preview') {
      this.setSamShow(true);
    } else if (botMessage.custom?.type === 'ads_generated') {
      const { images = [] } = botMessage.custom;
      const messages = images.map(url => ImageMessage.botMessage({ image: url }));
      message = new MultipleMessage(messages);
    } else if (botMessage.custom?.type === 'object_extracted_from_image') {
      const messages = this.buildExtractedImages(botMessage);
      message = new MultipleMessage(messages);
    } else if (botMessage.custom?.type === 'open_templates') {
      const messages = await this.buildTemplates();
      message = new MultipleMessage(messages);
    } else if (botMessage.image || botMessage.custom?.image) {
      message = ImageMessage.botMessage(botMessage);
    } else if (botMessage.custom?.type === 'ad_from_scratch') {
      this.setNextMessageType(MESSAGE_TYPE.AD_FROM_SCRATCH);
    } else {
      message = Message.botMessage(botMessage);
    }

    if (message) {
      this.addMessage(message);
    }
  }

  buildExtractedImages(message) {
    const { cut_image_url, inpainted_image_url } = message.custom;
    return [
      ImageMessage.botMessage({
        image: inpainted_image_url,
      }),
      ImageMessage.botMessage({
        image: cut_image_url,
      }),
    ];
  }

  async buildTemplates() {
    const messages = [];
    const FILE_NAME = 'data.json';
    try {
      const response = await axios.get(`${TEMPLATES_FOLDER}${FILE_NAME}`);
      const templates = response.data;
      templates.sizes.forEach(size => {
        messages.push(new TemplateMessage(size));
      });
    } catch (error) {
      console.error(error);
    }
    return messages;
  }
}

export default ChatStore;
