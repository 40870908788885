import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Button, Col, Modal, Row } from '@akiunlocks/perseus-ui-components';

import { useRootStore } from '../../../store';

import RedirectBody from 'components/ProjectPreview/CopyToProjectModal/RedirectBody';
import SelectBody from 'components/ProjectPreview/CopyToProjectModal/SelectBody';

import { PROJECT_TYPES } from 'constants/project';

import './CopyToProjectModal.scss';

const CopyToProjectModal = ({ show, handleClose, handleCopyToProject }) => {
  const navigate = useNavigate();
  const { projects } = useRootStore();
  const [projectsList, setProjectsList] = useState([]);
  const [destinationProject, setDestinationProject] = useState(null);
  const [copying, setCopying] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const currentProject = projects.project;
  const isDOOH = currentProject?.type === PROJECT_TYPES.DOOH;

  useEffect(() => {
    const getProjects = async () => {
      const availableProjects = await projects.getAvailableProjectsToCopy(currentProject._id, isDOOH);
      setProjectsList(availableProjects.map(p => ({ label: p.name, value: p._id })));
    };

    if (show) {
      setDestinationProject(null);
      setRedirect(false);
      getProjects();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const onCopyClick = async () => {
    setCopying(true);
    const successful = await handleCopyToProject(destinationProject.value);
    setRedirect(successful);
    setCopying(false);
  };

  const onRedirectClick = () => {
    navigate(`/projects/${destinationProject.value}/preview`);
    handleClose();
  };

  const onCloseClick = () => {
    if (copying) {
      return;
    }
    handleClose();
  };

  const configuration = redirect
    ? {
        body: <RedirectBody />,
        closeLabel: 'Close',
        confirmDisabled: false,
        confirmLabel: 'Go to project',
        onConfirmClick: onRedirectClick,
      }
    : {
        body: (
          <SelectBody
            destinationProject={destinationProject}
            disabled={copying}
            isDOOH={isDOOH}
            projectsList={projectsList}
            setDestinationProject={setDestinationProject}
          />
        ),
        closeLabel: 'Cancel',
        confirmDisabled: !projectsList.length || destinationProject === null,
        confirmLabel: 'Copy',
        onConfirmClick: onCopyClick,
      };

  return (
    <Modal className={classnames({ 'copy-modal': copying })} show={show} onHide={onCloseClick}>
      <Modal.Header closeButton>
        <Modal.Title>Copy composition to another project</Modal.Title>
      </Modal.Header>
      <Modal.Body>{configuration.body}</Modal.Body>
      <Modal.Footer>
        <Row className="flex justify-content-between w-100">
          <Col>
            <Button className="border border-secondary" disabled={copying} onClick={onCloseClick} variant="transparent">
              {configuration.closeLabel}
            </Button>
          </Col>
          <Col className="saveButton">
            <Button disabled={configuration.confirmDisabled} loading={copying} onClick={configuration.onConfirmClick}>
              {configuration.confirmLabel}
            </Button>
          </Col>
        </Row>
      </Modal.Footer>
    </Modal>
  );
};

CopyToProjectModal.propTypes = {
  /** Whether the modal is visible or not */
  show: PropTypes.bool.isRequired,
  /** Function to handle modal close */
  handleClose: PropTypes.func,
  /** Function to handle copying to another project */
  handleCopyToProject: PropTypes.func,
};

CopyToProjectModal.defaultProps = {
  handleClose: () => {},
  handleCopyToProject: () => {},
};

export default CopyToProjectModal;
