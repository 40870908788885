/* eslint-disable object-shorthand */
/* eslint-disable func-names */
import { fabric } from 'fabric';

import { PRISMA_OBJECT, getObjectProps } from './prismaObject.js';
import { PRISMA_MASK_NAME } from './constants/index.js';

export const PrismaMask = fabric.util.createClass(fabric.Rect, {
  ...PRISMA_OBJECT,

  label: PRISMA_MASK_NAME,
  type: 'prisma-mask',

  initialize: function (options = {}) {
    this.callSuper('initialize', {
      ...options,
      absolutePositioned: true,
      fill: '',
      strokeWidth: 0,
    });
  },

  setOffset(offset) {
    const { offsetTop, offsetLeft } = offset;
    this.left = this.left - this.offsetLeft + offsetLeft;
    this.top = this.top - this.offsetTop + offsetTop;
    this.offsetTop = offsetTop;
    this.offsetLeft = offsetLeft;

    // set clipPath offset on window resize
    this.clipPath.left = this.left;
    this.clipPath.top = this.top;
    this.clipPath.offsetTop = this.offsetTop;
    this.clipPath.offsetLeft = this.offsetLeft;
  },

  toObject: function () {
    const obj = {
      ...getObjectProps(this),
      lockedPosition: this.lockedPosition,
    };
    delete obj.clickPlaceholder;
    return obj;
  },
});

PrismaMask.fromObject = function (object, callback) {
  const group = new PrismaMask(object);
  return callback && callback(group);
};

fabric.PrismaMask = PrismaMask;

export default PrismaMask;
