import { observer } from 'mobx-react';
import { Form, RadioButton, RadioButtonMatrix } from '@akiunlocks/perseus-ui-components';

import { SCALING_MODE } from '@prisma/lib/src/constants/images';
import { HORIZONTAL_ALIGN, VERTICAL_ALIGN } from '@prisma/lib/src/constants/canvas';

import useImageProperties from '../ImageProperties.hooks';

import './AssetPosition.scss';

const LABELS = {
  [SCALING_MODE.FIT_TO_100]: 'Fit (up to 100%)',
  [SCALING_MODE.FIT]: 'Fit',
  [SCALING_MODE.CROP]: 'Crop',
  [SCALING_MODE.STRETCH]: 'Stretch',
};

const AssetPosition = observer(() => {
  const { imageStore, onImagePositionChange, onSetScalingMode } = useImageProperties();

  return (
    <div className="asset-position">
      <div className="position">
        <Form.Label>Position</Form.Label>
        <RadioButtonMatrix
          name="image-position"
          onChange={onImagePositionChange}
          x={imageStore.imagePositionX}
          xValues={Object.values(HORIZONTAL_ALIGN)}
          y={imageStore.imagePositionY}
          yValues={Object.values(VERTICAL_ALIGN)}
        />
      </div>
      <div className="scaling-mode">
        <Form.Label>Fit</Form.Label>
        {[SCALING_MODE.FIT_TO_100, SCALING_MODE.FIT, SCALING_MODE.CROP, SCALING_MODE.STRETCH].map(key => {
          return (
            <RadioButton
              checked={imageStore.scalingMode === key}
              id={`scalingMode-${key}`}
              key={key}
              label={LABELS[key]}
              name="scalingMode"
              onChange={() => onSetScalingMode(key)}
            />
          );
        })}
      </div>
    </div>
  );
});

export default AssetPosition;
