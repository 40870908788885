import React from 'react';
import { Spinner } from '@akiunlocks/perseus-ui-components';

import './Loading.scss';

const Loading = () => {
  return (
    <div className="loader">
      <Spinner variant="primary" />
    </div>
  );
};

export default Loading;
