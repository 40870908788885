import { forwardRef, useMemo } from 'react';
import { observer } from 'mobx-react';

import { useRootStore } from 'store';

import { TIMELINE_PADDING } from 'components/TimeLineScene/constants';
import { ANIMATION_DURATION } from 'constants';

import './TimeLineHeader.scss';

const stepLabel = {
  500: 0.001,
  250: 0.001,
  100: 0.001,
  50: 0.001,
};

const fixed = {
  500: 0,
  250: 1,
  100: 1,
  50: 2,
};

const RENDER_EVERY = {
  500: 2,
  250: 2,
  100: 5,
  50: 4,
};

/// @TODO: change to map
const renderPoints = (step, time) => {
  const arr = [];
  const length = time / step;
  for (let i = 0; i < length; i++) {
    const v = +(i * step * stepLabel[step]).toFixed(fixed[step]);
    arr.push(v.toString());
  }
  arr.push(+(arr.length * step * stepLabel[step]).toFixed(fixed[step]));
  return arr;
};

export const TimeLineHeader = observer(
  forwardRef(({ zoomConfig, seek }, ref) => {
    const { editor } = useRootStore();
    const { timelineSceneStore } = editor;

    const points = useMemo(() => renderPoints(zoomConfig.step, ANIMATION_DURATION), [zoomConfig.step]);
    if (!zoomConfig.msInOnPixel) {
      return <div />;
    }
    const spaceBetween = zoomConfig.width / (points.length - 1);

    return (
      <div
        className="timeline-header"
        ref={ref}
        onClick={e => {
          let xValue = Math.floor(e.clientX - ref.current.getBoundingClientRect().x + ref.current.scrollLeft);
          seek(xValue);
          timelineSceneStore.clearActiveKeyframe();
        }}
      >
        <div className="pointWrapper" style={{ width: zoomConfig.width + 20 }}>
          {points.map((el, index) => {
            if (points.length - 1 === index) {
              return (
                <div key={zoomConfig.width} className="point" style={{ left: zoomConfig.width }}>
                  <div className="point-label">{el}s</div>
                  <div className="point-logo" />
                </div>
              );
            }

            let child;
            if (index % RENDER_EVERY[zoomConfig.step] === 0) {
              child = (
                <>
                  <div className="point-label">{el}s</div>
                  <div className="point-logo" />
                </>
              );
            } else {
              child = <div className="line" style={{ marginLeft: TIMELINE_PADDING }} />;
            }
            return (
              <div key={index * spaceBetween} className="point" style={{ left: index * spaceBetween }}>
                {child}
              </div>
            );
          })}
        </div>
      </div>
    );
  }),
);
