import React from 'react';
import classnames from 'classnames';
import { observer } from 'mobx-react';

import { useRootStore } from '../../../../store';

import { checkIfRepeatIsDisabled, getAddKeyframeIcon } from './utilities';

import PrismaIconButton from '../../../Sidebar/components/PrismaIconButton/PrismaIconButton';

import './layerAccordion.scss';

const KeyframeControls = observer(({ activeKeyframe, animation, canAddKeyframe, isActiveKeyframe, layer }) => {
  const { editor, layerAccordionStore } = useRootStore();
  const { selectionStore } = editor;
  const { handlers } = layerAccordionStore;

  const selectedKeyframes = selectionStore.hasKeyframesSelected()
    ? selectionStore.getSelectedComponents().filter(k => k.layerId === layer.id)
    : [];
  const repeatOn = selectedKeyframes.every(k => k.repeat);
  const repeatDisabled = checkIfRepeatIsDisabled(selectedKeyframes, animation);

  const toggleKeyframeRepeatEdit = e => {
    e.stopPropagation();
    if (!selectedKeyframes.length) {
      return;
    }
    selectedKeyframes.forEach(k => {
      k.repeat = !repeatOn;
    });
    editor.canvas.addOrEditKeyframes.call(editor.canvas, selectedKeyframes, layer.id);
  };

  return (
    <div className="keyframe-controls-container">
      <PrismaIconButton
        key="repeat-keyframe"
        icon="md-loop"
        tooltipMessage="Repeat keyframe"
        className={classnames('control', {
          grey: repeatDisabled || !repeatOn,
        })}
        disabled={repeatDisabled}
        onClick={toggleKeyframeRepeatEdit}
        iconSize={14}
      />
      <PrismaIconButton
        className="control"
        key="add-keyframe"
        tooltipMessage="Add keyframe"
        {...getAddKeyframeIcon(isActiveKeyframe, canAddKeyframe)}
        onClick={e => {
          e.stopPropagation();
          handlers.keyframeAdd(animation.type, layer);
        }}
        iconSize={12}
      />
      <PrismaIconButton
        key="remove-keyframe"
        icon="trash3"
        tooltipMessage="Remove keyframe"
        className={classnames('control', {
          red: isActiveKeyframe,
          disabled: !isActiveKeyframe,
        })}
        disabled={!isActiveKeyframe}
        onClick={() => {
          handlers.keyframeRemove(activeKeyframe);
        }}
        iconSize={12}
      />
    </div>
  );
});

export default KeyframeControls;
