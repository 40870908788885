import { PrismaKeyframe } from '@prisma/lib';
import { makeAutoObservable } from 'mobx';

import { ASSET_TYPE, MULTI_SELECTABLE_LAYERS, SELECTABLE_LAYERS } from 'constants';
import { FOLDER } from 'constants/assets';

/**
 * Store that keeps track of the selected components
 * These components should implement some methods that could be defined by an interface but we are not using typscript.
 * We could also create a class and extend it but there are prisma objects that are already a fabric class, so we can't do that.
 *
 * So, the methods that should be implemented are:
 * - compareTypes: compares the type of the component with the type of the component that is being selected
 * - clone: clones the component
 */
class SelectionStore {
  selectedComponents = [];

  constructor(editor) {
    makeAutoObservable(this);
    this.editor = editor;
  }

  setSelectedComponents(selectedComponents) {
    this.selectedComponents = selectedComponents;
  }

  getSelectedComponents() {
    return this.selectedComponents;
  }

  hasSelected() {
    return this.selectedComponents.length > 0;
  }

  hasManySelected() {
    return this.selectedComponents.length > 1;
  }

  hasAssetsSelected() {
    return this.hasSelected() && this.isAssetType(this.selectedComponents[0]);
  }

  hasKeyframesSelected() {
    return this.selectedComponents[0] instanceof PrismaKeyframe;
  }

  hasLayersSelected() {
    return this.hasSelected() && this.isLayerType(this.selectedComponents[0]);
  }

  isLayerType(component) {
    return SELECTABLE_LAYERS.includes(component.type);
  }

  isAssetType(component) {
    return component.type === ASSET_TYPE;
  }

  isFolderType(component) {
    return component.type === FOLDER;
  }

  /**
   * Adds selected components to the store.
   *
   * @param {Array} selectedComponents - The array of components to be added.
   * @param {boolean} isMultiSelect - A flag indicating whether multiple components can be selected.
   */
  addSelectedComponents(selectedComponents, isMultiSelect) {
    if (!isMultiSelect) {
      this.selectedComponents = selectedComponents;
      return;
    }

    // Check if the selected components have the same type as the currently selected components
    if (this.selectedComponents.length === 0 || !this.selectedComponents[0].compareTypes(selectedComponents[0])) {
      this.selectedComponents = selectedComponents;
      return;
    }

    // Filter out the components that are already selected and add the remaining components to the store
    const componentsToAdd = selectedComponents.filter(component => !this.selectedComponents.includes(component));
    this.selectedComponents = [...this.selectedComponents, ...componentsToAdd];
  }

  /**
   * Adds a selected component to the store.
   *
   * @param {Object} selectedComponent - The component to be added.
   * @param {boolean} isMultiSelect - A flag indicating whether multiple components can be selected.
   */
  addSelectedComponent(selectedComponent, isMultiSelect) {
    const compareTypesFn = this.getCompareTypesFn(selectedComponent);
    if (compareTypesFn) {
      selectedComponent.compareTypes = compareTypesFn.bind(selectedComponent);
    }
    this.addSelectedComponents([selectedComponent], isMultiSelect);
  }

  isSelected(component) {
    return this.selectedComponents.includes(component);
  }

  isSelectedById(id) {
    return this.selectedComponents.find(component => component.id === id);
  }

  removeSelectedComponent(selectedComponent) {
    this.selectedComponents = this.selectedComponents.filter(component => component !== selectedComponent);
  }

  removeSelectedComponentById(id) {
    this.selectedComponents = this.selectedComponents.filter(component => component.id !== id);
  }

  clearSelectedComponents() {
    this.selectedComponents = [];
  }

  /**
   * Utility function that checks that the selected component can only live in the scenes tab.
   * For example keyframe or animations.
   * @returns {boolean} true if the selected component is in the scenes tab
   */
  isSelectedInSceneTab() {
    if (this.selectedComponents.length) {
      const first = this.selectedComponents[0];
      return first instanceof PrismaKeyframe; // we can add animations at some point too
    }
    return false;
  }

  getCompareTypesFn(component) {
    if (this.isLayerType(component)) {
      return function (layer) {
        return MULTI_SELECTABLE_LAYERS.includes(this.type) && MULTI_SELECTABLE_LAYERS.includes(layer.type);
      };
    }
    if (this.isAssetType(component) || this.isFolderType(component)) {
      return function (asset) {
        return this.type === asset.type;
      };
    }
    return undefined;
  }

  /** METHODS SPECIFIC FOR LAYERS */

  /**
   * Adds or removes a layer from the selection.
   * When the layer is added, we check if the layer is already selected and if it is, we remove it from the selection.
   *
   * @param {object} layer
   * @param {boolean} isMultiSelect
   * @returns {boolean} true if the layer was added, false if it was removed
   */
  addOrRemoveLayer(layer, isMultiSelect = false) {
    if (isMultiSelect && this.isLayerSelected(layer)) {
      // if there is more than one selected layer, we remove the layer
      if (this.getSelectedLayers().length > 1) {
        this.removeSelectedComponentById(layer.id);
      }
      return false;
    }
    this.addSelectedComponent(layer, isMultiSelect);
    return true;
  }

  getSelectedLayers() {
    return this.selectedComponents.filter(component => this.isLayerType(component));
  }

  isLayerSelected(layer) {
    return this.selectedComponents.find(component => this.isLayerType(component) && layer.id === component.id);
  }

  /**
   * Checks if the layer can be multiselected together with the elements that are already selected.
   *
   * @param {object} layer
   * @param {boolean} isMultiSelect
   * @returns {boolean} true if can be multiselected together with the elements that are already selected, false otherwise
   */
  isMultiSelectLayer(layer, isMultiSelect) {
    if (isMultiSelect && MULTI_SELECTABLE_LAYERS.includes(layer.type)) {
      if (this.selectedComponents.length === 0) {
        return true;
      }

      // if the layer belongs to a group, we can have only siblings selected
      if (layer.properties.groupId || this.selectedComponents[0].properties.groupId) {
        return this.selectedComponents.every(component => component.properties.groupId === layer.properties.groupId);
      }
      return true;
    }
    return false;
  }

  /** END OF METHODS SPECIFIC FOR LAYERS */

  /** METHODS SPECIFIC FOR ASSETS */

  getSelectedAssetsIds() {
    return this.selectedComponents.filter(c => this.isAssetType(c) || this.isFolderType(c)).map(({ _id }) => _id);
  }

  isAssetSelected(asset) {
    return !!this.selectedComponents.find(c => (this.isAssetType(c) || this.isFolderType(c)) && asset._id === c._id);
  }

  /**
   * Adds/removes asset to/from the selection.
   * When the asset is added, we check if it is already selected and if it is, we remove it from the selection.
   * @param {object} asset
   * @param {boolean} isMultiSelect
   * @return {boolean} true if the asset was added, false if it was removed
   */
  addOrRemoveAsset(asset, isMultiSelect = false) {
    if (isMultiSelect && this.isAssetSelected(asset)) {
      this.removeSelectedComponentById(asset._id);
      return false;
    }
    this.addSelectedComponent(asset, isMultiSelect);
    return true;
  }

  /**
   * Adds assets to the selection if they are not present.
   * @param {array} assetIds
   * @param {boolean} isMultiSelect
   */
  addAssetsToSelection(assetIds, isMultiSelect) {
    const assets = this.editor.getAssetsByIds(assetIds);
    assets
      .filter(a => !this.isAssetSelected(a))
      .forEach(a => {
        this.addOrRemoveAsset(a, isMultiSelect);
      });
  }

  /** END OF METHODS SPECIFIC FOR ASSETS */
}

export default SelectionStore;
