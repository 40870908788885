export const UNITS = {
  PERCENTAGE: '%',
  PIXELS: 'px',
};

export const ANCHORS_X = {
  LEFT: 'left',
  CENTER: 'center',
  RIGHT: 'right',
};

export const ANCHORS_Y = {
  TOP: 'top',
  CENTER: 'center',
  BOTTOM: 'bottom',
};
