import { makeAutoObservable } from 'mobx';
import React, { createContext, useContext, useMemo } from 'react';

import EditorStore from '../containers/Editor/stores/Editor.store';
import LayerAccordionStore from '../components/TimeLineScene/components/LayerAccordion/LayerAccordion.store';
import ProjectSizesStore from '../containers/Editor/stores/ProjectSizes.store';
import ProjectsStore from '../containers/Projects/Projects.store';
import SizesStore from '../components/Sizes/Sizes.store';
import UsersStore from './Users.store';
import { createRestClient } from '@prisma/api';

class RootStore {
  constructor() {
    makeAutoObservable(this);

    this.restClient = createRestClient();
    this.editor = new EditorStore(this);
    this.layerAccordionStore = new LayerAccordionStore(this);
    this.projectSizes = new ProjectSizesStore(this);
    this.projects = new ProjectsStore(this);
    this.sizes = new SizesStore(this);
    this.users = new UsersStore(this);
  }
}

export const RootContext = createContext(RootStore);

/* eslint-disable react/prop-types */
export const StoreProvider = ({ children }) => {
  const root = useMemo(() => new RootStore(), []);
  return <RootContext.Provider value={root}>{children}</RootContext.Provider>;
};

export const useRootStore = () => useContext(RootContext);

export default RootStore;
