import { PRISMA_GROUP } from '@prisma/lib/src/constants';
import { ANIMATION_IDENTIFIER, GROUP_IDENTIFIER } from 'constants/timeline';
import { makeAutoObservable } from 'mobx';

const DEFAULT_HANDLERS = {
  drop: () => {},
  easingChange: () => {},
  hide: () => {},
  keyframeAdd: () => {},
  keyframeRemove: () => {},
  lock: () => {},
  repeatChange: () => {},
};

class LayerAccordionStore {
  dragItemId = null;
  dragOverId = null;
  handlers = DEFAULT_HANDLERS;
  opened = new Set();

  constructor() {
    makeAutoObservable(this);
  }

  getDragItemId() {
    return this.dragItemId;
  }

  setDragItemId(dragItemId) {
    this.dragItemId = dragItemId;
  }

  getDragOverId() {
    return this.dragOverId;
  }

  setDragOverId(dragOverId) {
    this.dragOverId = dragOverId;
  }

  setHandlers(handlers) {
    this.handlers = { ...this.handlers, ...handlers };
  }

  /**
   * Returns an array with opened ids.
   * @return {array} opened list.
   */
  getOpened() {
    return Array.from(this.opened);
  }

  /**
   * Add layer to opened list.
   * @param {string} layer - Layer with identifier.
   */
  addOpened = layer => {
    this.opened.add(layer);
  };

  /**
   * If the layer is in the opened list, removes it, else adds it.
   * @param {string} layer - Layer with identifier.
   */
  toggleOpened = layer => {
    if (this.opened.has(layer)) {
      this.opened.delete(layer);
    } else {
      this.opened.add(layer);
    }
  };

  /**
   * Given a layer id, checks if it is in the list of opened groups.
   * @param {number} layerId - Layer id.
   * @return {boolean}
   */
  isGroupOpen(layerId) {
    return this._getOpenedIds(GROUP_IDENTIFIER).includes(layerId);
  }

  /**
   * Given a layer id, checks if it is in the list of opened animations.
   * @param {number} layerId - Layer id.
   * @return {boolean}
   */
  isAnimationOpen(layerId) {
    return this._getOpenedIds(ANIMATION_IDENTIFIER).includes(layerId);
  }

  /**
   * Checks if a layer is before other in a list.
   * @param {number} layerId - Layer id.
   * @param {number} anotherLayerId - Another layer id.
   * @param {array} layers - Layers.
   * @return {boolean}
   */
  isBefore(layerId, anotherLayerId, layers) {
    if (layerId === anotherLayerId) {
      return false;
    }
    const ids = layers.map(({ id }) => id);
    const layerIdIndex = ids.indexOf(layerId);
    const anotherLayerIdIndex = ids.indexOf(anotherLayerId);
    return layerIdIndex < anotherLayerIdIndex;
  }

  /**
   * Checks if a layer can be dragged and dropped over other one.
   * @param {number} selectedId - Selected layer id.
   * @param {number} overId - Over layer id.
   * @param {array} layers - Layers.
   * @return {boolean}
   */
  isDropAllowed(selectedId, overId, layers) {
    const selectedLayer = layers.find(({ id }) => id === selectedId);
    const overLayer = layers.find(({ id }) => id === overId);

    if (selectedId === overId || !selectedLayer || !overLayer) {
      return false;
    }

    // a group cannot be dropped in any other group
    if (selectedLayer.target.type === PRISMA_GROUP && overLayer.target.groupId) {
      return false;
    }

    return true;
  }

  /**
   * Returns a list of ids that match the specified indentifier.
   * @param {string} identifier - ANIMATION_IDENTIFIER or GROUP_IDENTIFIER.
   * @return {array} List of numeric ids.
   */
  _getOpenedIds(identifier) {
    return this.getOpened()
      .filter(layer => layer.endsWith(identifier))
      .map(layer => Number(layer.replace(identifier, '')));
  }
}

export default LayerAccordionStore;
