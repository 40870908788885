import { PRODUCT_SUBTYPES } from 'constants/compositions';
import Page from './Page';

class ProjectSize {
  constructor(projectSize = {}) {
    Object.assign(this, projectSize);
    this.pages = (projectSize.pages || []).map(page => new Page(page));
  }

  isInterstitial() {
    return this.productSubtype === PRODUCT_SUBTYPES.INTERSTITIAL_BANNER;
  }

  getDisplaySize() {
    return this.isInterstitial() ? 'Interstitial' : this.size;
  }
}

export default ProjectSize;
