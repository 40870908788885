export const STATUS = {
  ERROR: 'error',
  INITIAL: 'initial',
  LOADING: 'loading',
  SUCCESS: 'success',
};

export const columns = [
  {
    Header: 'Favorite',
    accessor: 'setFavorite',
  },
  {
    Header: 'Name',
    accessor: 'name',
  },
  {
    Header: 'ID',
    accessor: 'perseusId',
  },
  {
    Header: 'Compositions',
    accessor: 'sizes',
  },
  {
    Header: 'Last changed',
    accessor: 'updatedAt',
  },
  {
    Header: '',
    accessor: 'dots',
  },
];

export const PAGE_SIZE_OPTIONS = [
  {
    value: 5,
    label: 5,
  },
  {
    value: 10,
    label: 10,
  },
  {
    value: 20,
    label: 20,
  },
  {
    value: 50,
    label: 50,
  },
];

export const getFormattedDate = date => new Date(date).toLocaleDateString();

export const getFileNameWithoutExtension = fileName => fileName.split('.').slice(0, -1).join('.');

export const getFileExtension = fileName => fileName.split('.').pop();

export function mapAssetProperties(s3Assets, projectAssets) {
  s3Assets = s3Assets || [];
  projectAssets = projectAssets || [];
  const projectAssetsMap = projectAssets.reduce((acc, a) => ({ ...acc, [a.imageUrl]: a }), {});
  s3Assets.forEach(a => {
    const existing = projectAssetsMap[a.imageUrl];
    if (existing) {
      a.name = existing.name;
      a._id = existing._id;
    }
  });
}

export function areAssetsSynchronized(s3Assets, projectAssets) {
  s3Assets = s3Assets || [];
  projectAssets = projectAssets || [];
  const s3AssetUrls = s3Assets.map(({ imageUrl }) => imageUrl);
  const projectAssetUrls = projectAssets.map(({ imageUrl }) => imageUrl);
  return (
    s3AssetUrls.every(url => projectAssetUrls.includes(url)) && projectAssetUrls.every(url => s3AssetUrls.includes(url))
  );
}
