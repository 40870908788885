import { useState } from 'react';
import { observer } from 'mobx-react';

import { useRootStore } from 'store';
import ToggleIconButton from 'components/ToggleIconButton';

import { ASPECT_RATIO_TYPE } from 'constants/canvas';

import AspectRatioButton from './AspectRatioButton';
import SelectAspectRatioModal from './SelectAspectRatioModal';

import './AspectRatio.scss';

const AspectRatio = observer(() => {
  const { editor } = useRootStore();
  const { pageStore } = editor;
  const [showModal, setShowModal] = useState(false);

  if (!editor.isInterstitial) {
    return null;
  }

  const handleLock = selectedAspectRatio => {
    pageStore.changeAspectRatioLocked(selectedAspectRatio);
    setShowModal(false);
  };

  return (
    <div className="aspect-ratio">
      <AspectRatioButton type={ASPECT_RATIO_TYPE.SMALL} />
      <ToggleIconButton
        icons={['unlock', 'lock']}
        className="icons"
        onClick={() => {
          if (!pageStore.currentAspectRatioLocked) {
            setShowModal(true);
          } else {
            pageStore.changeAspectRatioLocked();
          }
        }}
        value={pageStore.currentAspectRatioLocked}
        iconSize={14}
        tooltipMessage={`${pageStore.currentAspectRatioLocked ? 'Unlock' : 'Lock'}`}
      />
      <AspectRatioButton type={ASPECT_RATIO_TYPE.BIG} />
      <SelectAspectRatioModal
        handleModalClose={() => setShowModal(false)}
        handleModalConfirm={handleLock}
        showModal={showModal}
      />
    </div>
  );
});

export default AspectRatio;
