import React from 'react';

import { Button, Col, Row, Input, Modal, Spinner } from '@akiunlocks/perseus-ui-components';

import { getInputInvalidFeedback } from 'utils/form';

import TypeCheckbox from '../Sizes/TypeCheckbox';

const AddNewProjectModal = ({
  addNewProjectLoading,
  handleModalClose,
  handleModalCreate,
  newProjectErrors,
  newProjectName,
  setNewProjectName,
  setNewProjectType,
  showAddProjectModal,
}) => {
  return (
    <Modal show={showAddProjectModal} onHide={handleModalClose}>
      <Modal.Header closeButton>
        <Modal.Title>New Project</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <h6>Name</h6>
        </Row>
        <Row className="pt-1">
          <Input
            id="projectName"
            label="Select name"
            controlProps={{
              autoFocus: true,
              value: newProjectName,
              placeholder: 'Search',
              onChange: ({ target: { value } }) => setNewProjectName(value),
              isInvalid: !!newProjectErrors.name,
            }}
            feedback={getInputInvalidFeedback(newProjectErrors.name)}
          />
        </Row>
        <Row className="pt-2 m-0">
          <TypeCheckbox onChange={setNewProjectType} />
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Row className="flex justify-content-between w-100">
          <Col>
            <Button variant="transparent" className="border border-secondary" onClick={handleModalClose}>
              Cancel
            </Button>
          </Col>
          <Col className="saveButton">
            <Button variant="primary" onClick={handleModalCreate} disabled={!newProjectName}>
              {addNewProjectLoading ? <Spinner size="sm" /> : 'Create'}
            </Button>
          </Col>
        </Row>
      </Modal.Footer>
    </Modal>
  );
};

export default AddNewProjectModal;
