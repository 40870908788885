import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import { Checkbox } from '@akiunlocks/perseus-ui-components';

const HideForm = ({ hide, onHideChange }) => {
  return (
    <Form.Group className="d-flex gap-1 align-items-center">
      <Form.Label className="mb-0">Hide</Form.Label>
      <Checkbox checked={hide} id="hide" onChange={onHideChange} />
    </Form.Group>
  );
};

HideForm.propTypes = {
  /** Hide value */
  hide: PropTypes.bool,
  /** Action to take on hide change */
  onHideChange: PropTypes.func,
};

HideForm.defaultProps = {
  hide: false,
  onHideChange: () => {},
};

export default HideForm;
