import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { BOTTOM, CENTER, END, MIDDLE, START, TOP } from 'constants/canvas';

import PrismaIconButton from '../PrismaIconButton';
import Boundaries from '../Boundaries';

import './Align.scss';

const ALIGN_OPTIONS = [
  {
    icon: 'align-start',
    tooltipMessage: 'Align left edge',
    alignmentType: START,
  },
  {
    icon: 'align-center',
    tooltipMessage: 'Align horizontal center',
    alignmentType: CENTER,
  },
  {
    icon: 'align-end',
    tooltipMessage: 'Align right edge',
    alignmentType: END,
  },
  {
    className: 'ms-2',
    icon: 'align-top',
    tooltipMessage: 'Align top edge',
    alignmentType: TOP,
  },
  {
    icon: 'align-middle',
    tooltipMessage: 'Align vertical center',
    alignmentType: MIDDLE,
  },
  {
    icon: 'align-bottom',
    tooltipMessage: 'Align bottom edge',
    alignmentType: BOTTOM,
  },
];

const Align = ({ className, groupLayersSelected, onClick, showBoundaries }) => {
  return (
    <div>
      {showBoundaries && (
        <>
          <div className="form-label m-0">Align to</div>
          <Boundaries groupLayersSelected={groupLayersSelected} />
        </>
      )}
      <div className={classnames('align-buttons', className)}>
        {ALIGN_OPTIONS.map(({ className, icon, tooltipMessage, alignmentType }) => (
          <PrismaIconButton
            key={alignmentType}
            className={className}
            iconSize={15}
            icon={icon}
            variant="primary-alt"
            onClick={() => onClick(alignmentType)}
            tooltipMessage={tooltipMessage}
          />
        ))}
      </div>
    </div>
  );
};

Align.propTypes = {
  /** Additional className */
  className: PropTypes.string,
  /** Flag to indicate if selected layers belong to same group. */
  groupLayersSelected: PropTypes.bool,
  /** Action to call on click  */
  onClick: PropTypes.func,
  /** Whether to show the boundaries or not. */
  showBoundaries: PropTypes.bool,
};

Align.defaultProps = {
  className: '',
  groupLayersSelected: false,
  onClick: () => {},
  showBoundaries: false,
};

export default React.memo(Align);
