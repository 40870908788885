/* eslint-disable object-shorthand */
/* eslint-disable func-names */
import { fabric } from 'fabric';

import { PRISMA_CLOSE_BUTTON } from './constants/index.js';

import { closeButtonIcon } from './constants/closeButtonIcon.js';

const SIZE = 30;
const PADDING = 6;

const PrismaCloseButton = fabric.util.createClass(fabric.Image, {
  type: PRISMA_CLOSE_BUTTON,

  initialize: function (element, options = {}) {
    const { top, left, width, ...rest } = options;

    this.callSuper('initialize', element, {
      ...rest,
      top: (top || 0) + PADDING,
      left: (left || 0) + width - SIZE - PADDING,
      hoverCursor: 'pointer',
      selectable: false,
    });
  },
});

PrismaCloseButton.load = function (options, callback) {
  return fabric.util.loadImage(closeButtonIcon, img => {
    if (callback) {
      callback(new PrismaCloseButton(img, options));
    }
  });
};

fabric.PrismaCloseButton = PrismaCloseButton;

export default PrismaCloseButton;
