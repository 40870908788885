import { PRISMA_OBJECT, getObjectProps } from './prismaObject.js';
import { getPositionFromActiveSelection } from './utils/helpers.js';

export const PRISMA_SHAPE = {
  ...PRISMA_OBJECT,

  uniformScaling: false, // default is true for new objects, we will send this param at object creation

  toObject(obj) {
    const ref = obj || this;
    return {
      ...getObjectProps(ref),
      fill: ref.fill,
      maskId: ref.maskId,
      shadow: ref.shadow || null,
      stroke: ref.stroke,
      strokeWidth: ref.strokeWidth,
      uniformScaling: ref.uniformScaling,
    };
  },

  getCloneProperties(obj) {
    const ref = obj || this;
    const { offsetLeft, offsetTop } = ref;
    const { left, top } = ref.group ? getPositionFromActiveSelection(ref) : ref;
    return { left, top, offsetLeft, offsetTop };
  },
};
