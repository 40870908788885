import React from 'react';
import { Input } from '@akiunlocks/perseus-ui-components';

const SizeNameInput = ({ name, inputEvents, validationError }) => {
  const { onInputChange, onKeyPress, onBlur } = inputEvents;
  return (
    <Input
      id="composition-name"
      label="Composition name"
      controlProps={{
        autoFocus: true,
        name: 'composition-name',
        type: 'text',
        placeholder: 'Composition name',
        value: name,
        isInvalid: !!validationError,
        onChange: onInputChange,
        onBlur,
        onKeyPress,
      }}
      feedback={validationError}
    />
  );
};

export default SizeNameInput;
