import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Button, Col } from '@akiunlocks/perseus-ui-components';

import { ASSET_UPLOAD_IN_PROGRESS } from 'constants/perseus';
import { redirectToPersonalizeUpload, redirectToPersonalizeReview } from 'utils/parentWindow';
import { getPerseusActionLabel } from 'utils/labels';

import ExportToPerseusModal from 'components/ProjectPreview/ExportToPerseusModal';
import { FF_RENDER_NON_PERSONALIZED } from 'utils/featureFlag';

const ProjectActions = observer(
  ({ disabled, exportInProgress, isAutoRendered, isDOOH, perseusHashId, setExportInProgress, setPerseusStatus }) => {
    const [isRender, setIsRender] = useState(false);
    const [showExportModal, setShowExportModal] = useState(false);

    const onExportClick = () => {
      setIsRender(false);
      setShowExportModal(true);
    };

    const onRenderClick = () => {
      setIsRender(true);
      setShowExportModal(true);
    };

    const onSuccessfulExport = (hashId, perseusName) => {
      setPerseusStatus(ASSET_UPLOAD_IN_PROGRESS);
      setShowExportModal(false);
      if (isRender) {
        redirectToPersonalizeReview(hashId, perseusName);
      } else {
        redirectToPersonalizeUpload(hashId);
      }
    };

    let renderTooltip = '';
    if (perseusHashId && !isAutoRendered) {
      renderTooltip = 'This project has already been pushed as personalized';
    } else if (isDOOH) {
      renderTooltip = 'DOOH projects cannot be rendered as non-personalized because they require fallback compositions';
    }
    const pushTooltip = perseusHashId && isAutoRendered ? 'This project is already rendered as non-personalized' : '';

    return (
      <>
        <Col className="d-flex flex-row justify-content-end">
          {FF_RENDER_NON_PERSONALIZED && (
            <Button
              className="me-2"
              disabled={disabled || !!renderTooltip}
              onClick={onRenderClick}
              tooltipMessage={renderTooltip}
              variant="outline-primary"
            >
              {getPerseusActionLabel(true)}
            </Button>
          )}
          <Button disabled={disabled || !!pushTooltip} onClick={onExportClick} tooltipMessage={pushTooltip}>
            {getPerseusActionLabel(false)}
          </Button>
        </Col>
        <ExportToPerseusModal
          exportInProgress={exportInProgress}
          handleClose={() => setShowExportModal(false)}
          isRender={isRender}
          onSuccessfulExport={onSuccessfulExport}
          setExportInProgress={setExportInProgress}
          showExportModal={showExportModal}
        />
      </>
    );
  },
);

export default ProjectActions;
