import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import { ACTIVE_SELECTION } from '@prisma/lib/src/constants';

import { useRootStore } from 'store';

import { HALF_KEYFRAME_WIDTH } from 'constants';
import { getTimelineStep } from 'components/TimeLineScene/utilities';

import { Frame } from '.';

import './FrameWrapper.scss';

export const FrameWrapper = observer(({ layer, animation, zoomConfig }) => {
  const { editor } = useRootStore();
  const { selectionStore, timelineSceneStore } = editor;
  const { selectedPoint, timelineCursorPosition } = timelineSceneStore;
  const { msInOnPixel } = zoomConfig;

  const keyframes = animation?.keyframes || [];

  useEffect(() => {
    timelineSceneStore.setMovingKeyframeId(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [animation?.keyframes, msInOnPixel]);

  // Every time the layer or animation props change, we update the store
  useEffect(() => {
    if (timelineSceneStore.activeLayer?.id === layer.id) {
      timelineSceneStore.setActiveLayer(layer);
      if (timelineSceneStore.activeAnimation?.type === animation.type) {
        timelineSceneStore.activeAnimation = animation;
      }
    }
  }, [animation, layer, timelineSceneStore]);

  const handlePointDown = (keyframeId, point, keys) => {
    // if active object is a multi selection, unselect
    if (editor.activeObject?.type === ACTIVE_SELECTION) {
      editor.canvas.discardActiveObject();
    }
    // init keyframe moving and active layer
    timelineSceneStore.setActiveLayer(layer);

    const { ctrlKey, metaKey, shiftKey } = keys;
    // set temporary keyframe data to handle when point up or point move
    const isMultiSelect = ctrlKey || metaKey || shiftKey;
    timelineSceneStore.startKeyframeSelection({ animation, isMultiSelect, keyframeId, point });
  };

  const handlePointUp = () => {
    // finish selection of the temporary keyframe saved on point down
    timelineSceneStore.finishKeyframeSelection();
    timelineSceneStore.setMovingKeyframeId(null);

    const { oldValue, newValue } = timelineSceneStore.selectedPoint;
    if (newValue !== null && oldValue === newValue) {
      timelineSceneStore.activeKeyframePoint = newValue;
    }
  };

  const handlePointMove = v => {
    let point = v - HALF_KEYFRAME_WIDTH;
    if (!timelineSceneStore.isMovingKeyframe() || point === selectedPoint.oldValue) {
      return;
    }
    const { movingKeyframeId } = timelineSceneStore;
    const { msInOnPixel } = zoomConfig;

    point = getTimelineStep(point * msInOnPixel) / msInOnPixel;
    const movingKeyfame = keyframes.find(({ id }) => id === movingKeyframeId);

    if (!movingKeyfame) {
      return;
    }

    if (selectionStore.hasKeyframesSelected()) {
      timelineSceneStore.updateSelectedKeyframesTimes(movingKeyfame.time, point, msInOnPixel);
    }

    timelineSceneStore.selectedPoint = {
      ...selectedPoint,
      newValue: point,
      oldLayer: timelineSceneStore.activeLayer,
      oldAnimation: timelineSceneStore.activeAnimation,
    };

    timelineSceneStore.activeKeyframePoint = point;
  };

  useEffect(() => {
    if (timelineCursorPosition === 0 || timelineCursorPosition) {
      handlePointMove(timelineCursorPosition);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timelineCursorPosition]);

  return (
    <div className="frame">
      <Frame
        key={+new Date()}
        keyframes={keyframes}
        onPointMouseDown={handlePointDown}
        onPointMouseUp={handlePointUp}
        zoomConfig={zoomConfig}
      />
    </div>
  );
});
