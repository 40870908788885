import { useCallback, useEffect, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import isEqual from 'lodash/isEqual';
import { toJS } from 'mobx';

import { LAYERS_TAB } from 'constants';
import { COMBINATIONS } from 'constants/hotkeys';
import { useRootStore } from 'store';

const useArtBoardControlsUndoRedo = () => {
  const {
    editor,
    projects,
    projectSizes: { setUnsavedChanges, setProjectSize, projectSize },
  } = useRootStore();
  const { historyStore, pageStore, timelineSceneStore } = editor;
  const { project, projectAssetsBaseUrl } = projects;
  const { undoDisabled, redoDisabled } = historyStore;
  const [updateHistory, setUpdateHistory] = useState(false);

  const handleCanvasActions = useCallback(() => {
    if (!editor.initialized) {
      return;
    }
    const prevAction = historyStore.getCurrentFromHistory();
    pageStore.updateCurrentPageLayers(editor.getCanvasLayers());

    if (historyStore.skipNext) {
      historyStore.setSkipNext(false);
      return;
    }

    let isUpdated = false;
    const stepToCompare = toJS(pageStore.pages);
    const stepToCompare2 = toJS(prevAction?.pages);
    if (!isEqual(stepToCompare, stepToCompare2)) {
      isUpdated = true;

      if (!historyStore.isEmpty()) {
        // the condition to check when the history is empty, it is because the first time the project is loaded
        // the history is empty, and we don't want to say it is unsaved, it is just the first step in the history
        setUnsavedChanges(true);
      }
    }

    if (isUpdated) {
      historyStore.addToHistory({
        ...projectSize,
        pages: pageStore.pages,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pageStore.pages,
    historyStore,
    projectSize,
    setUnsavedChanges,
    editor,
    pageStore,
    editor.layers,
    historyStore.skipNext,
  ]);

  useEffect(() => {
    if (!updateHistory || !editor?.canvas || !project || !projectSize) {
      return;
    }

    editor.canvas.loadFromSnapshot(projectSize, projectAssetsBaseUrl, () => {
      editor.refreshLayersProperties();
      if (timelineTab !== LAYERS_TAB) {
        editor.canvas.engine?.moveTo(editor.timelineSceneStore.currentTime); // move to current time
      }
    });

    setUpdateHistory(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateHistory]);

  const timelineTab = editor.contextStore.getTimelineTab();
  useEffect(() => {
    /**
     * 'history' and 'object:modified' have the same callback function, but we keep them
     *  separated so that we can disable 'object:modified' and continue calling the function
     *  through the 'history' event.
     */
    if (timelineTab === LAYERS_TAB) {
      editor.canvas?.on('object:modified', handleCanvasActions); // Called after editing an object in the canvas
    } else {
      editor.canvas?.off('object:modified', handleCanvasActions);
    }
    editor.canvas?.on('history', handleCanvasActions);

    return () => {
      editor.canvas?.off({
        'object:modified': handleCanvasActions,
        history: handleCanvasActions,
      });
    };
  }, [editor.canvas, handleCanvasActions, timelineTab]);

  useEffect(() => {
    handleCanvasActions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editor.layers]);

  const handleUndoRedo = projectSize => {
    if (!projectSize) {
      return;
    }
    setProjectSize(projectSize);
    setUpdateHistory(true);
    pageStore.updateProjectSize(projectSize);
    historyStore.setSkipNext(true);
    timelineSceneStore.clearActiveLayer(); // as the layer is deselected from the canvas, clear the selection in the store
  };

  const handleUndo = () => {
    handleUndoRedo(historyStore.undo());
  };

  const handleRedo = () => {
    handleUndoRedo(historyStore.redo());
  };

  useHotkeys(COMBINATIONS.UNDO, handleUndo);
  useHotkeys(COMBINATIONS.REDO, handleRedo);

  return {
    handleUndo,
    handleRedo,
    undoDisabled,
    redoDisabled,
  };
};

export default useArtBoardControlsUndoRedo;
