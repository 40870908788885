import React from 'react';
import classnames from 'classnames';

import CheckboxColorPicker from '../PropertiesPannel/components/CheckboxColorPicker';

const Shadow = props => {
  const classes = classnames(props.className);
  const { enabled, color, offsetX, offsetY, blur } = props;
  const { onEnabledChanged, onColorChange, onOffsetXChange, onOffsetYChange, onBlurChange } = props;

  return (
    <div className={classes}>
      <CheckboxColorPicker
        checked={enabled}
        id="shadow-enabled"
        label="Shadow"
        onCheckboxChange={onEnabledChanged}
        onColorChange={onColorChange}
        value={color}
        inputs={[
          { id: 'shadow-x', label: 'X', onChange: onOffsetXChange, value: offsetX },
          { id: 'shadow-y', label: 'Y', onChange: onOffsetYChange, value: offsetY },
          {
            id: 'shadow-blur',
            label: 'B',
            min: 0,
            onChange: onBlurChange,
            value: blur,
          },
        ]}
      />
    </div>
  );
};

export default React.memo(Shadow);
