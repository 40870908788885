import { useCallback, useMemo } from 'react';

import { PRISMA_CIRCLE } from '@prisma/lib/src/constants';

import { useRootStore } from 'store';

import useEditorActiveObject from 'utils/useEditorActiveObject';
import useActiveObjectProperties from '../useActiveObjectProperties';

const useShapeProperties = () => {
  const { editor } = useRootStore();
  const { activeObject } = editor;
  const { setActiveObjectAttribute } = useEditorActiveObject();

  const store = useMemo(() => {
    const { circleStore, rectStore } = editor;
    return activeObject?.type === PRISMA_CIRCLE ? circleStore : rectStore;
  }, [activeObject?.type, editor]);

  const {
    onAlignChange,
    onAngleChange,
    onHeightChange,
    onHideChange,
    onLeftChange,
    onLockProportionsChange,
    onOpacityChange,
    onShadowColorChange,
    onShadowEnabledChanged,
    onShadowOffsetBlurChange,
    onShadowOffsetXChange,
    onShadowOffsetYChange,
    onTopChange,
    onWidthChange,
    setAttribute,
  } = useActiveObjectProperties(store);

  const onBackgroundColorChange = useCallback(
    ({ target }) => {
      store.setFillEnabled(true);
      setAttribute('fill', target.value);
    },
    [setAttribute, store],
  );

  const onBackgroundColorEnabled = useCallback(
    ({ target }) => {
      store.setFillEnabled(target.checked);
      if (!activeObject) {
        return;
      }
      const fill = store.fillEnabled ? store.fill : '';
      setActiveObjectAttribute('fill', fill);
    },
    [activeObject, setActiveObjectAttribute, store],
  );

  const onBorderEnabled = useCallback(
    ({ target }) => {
      store.setStrokeEnabled(target.checked);
      if (!activeObject) {
        return;
      }
      const stroke = store.strokeEnabled ? store.stroke : '';
      const strokeWidth = store.strokeEnabled ? store.strokeWidth : 0;
      setActiveObjectAttribute('stroke', stroke);
      setActiveObjectAttribute('strokeWidth', strokeWidth);
    },
    [activeObject, setActiveObjectAttribute, store],
  );

  const onBorderColorChange = useCallback(
    ({ target }) => {
      store.setStrokeEnabled(true);
      setAttribute('stroke', target.value);
      if (!activeObject?.strokeWidth) {
        setAttribute('strokeWidth', store.strokeWidth);
      }
    },
    [activeObject?.strokeWidth, setAttribute, store],
  );

  const onBorderWidthChange = useCallback(
    ({ target }) => {
      const value = Number(target.value);
      const hasValue = value > 0;
      store.setStrokeEnabled(hasValue);
      setAttribute('strokeWidth', value);
      if (hasValue && !activeObject?.stroke) {
        setAttribute('stroke', store.stroke);
      }
    },
    [activeObject?.stroke, setAttribute, store],
  );

  const onRectRadiusLockChange = useCallback(
    uniformRadius => {
      setAttribute('uniformRadius', uniformRadius);
    },
    [setAttribute],
  );

  const onRectRadiusXChange = useCallback(
    ({ target }) => {
      const rx = Number(target.value);

      if (store.uniformRadius) {
        const ry = rx - store.rx + store.ry;
        setAttribute('ry', ry);
      }

      setAttribute('rx', rx);
    },
    [setAttribute, store],
  );

  const onRectRadiusYChange = useCallback(
    ({ target }) => {
      const ry = Number(target.value);

      if (store.uniformRadius) {
        const rx = ry - store.ry + store.rx;
        setAttribute('rx', rx);
      }

      setAttribute('ry', ry);
    },
    [setAttribute, store],
  );

  return {
    label: activeObject?.label || 'Shape',
    onAlignChange,
    onAngleChange,
    onBackgroundColorChange,
    onBackgroundColorEnabled,
    onBorderColorChange,
    onBorderEnabled,
    onBorderWidthChange,
    onHeightChange,
    onHideChange,
    onLeftChange,
    onLockProportionsChange,
    onOpacityChange,
    onRectRadiusLockChange,
    onRectRadiusXChange,
    onRectRadiusYChange,
    onShadowColorChange,
    onShadowEnabledChanged,
    onShadowOffsetBlurChange,
    onShadowOffsetXChange,
    onShadowOffsetYChange,
    onTopChange,
    onWidthChange,
    shapeStore: store,
  };
};

export default useShapeProperties;
