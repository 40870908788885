import classnames from 'classnames';
import React, { useRef, useState } from 'react';

import { PrismaPreview, PrismaPreviewButtons, DEVICE_SIZES } from '@akiunlocks/perseus-ui-components';

import { getMaxWidthAndHeight } from 'containers/ProjectPreview/utilities';

import './Preview.scss';

const Preview = ({ prismaUrl, projectSize, baseUrl }) => {
  const containerRef = useRef();
  const [deviceSize, setDeviceSize] = useState(DEVICE_SIZES.LARGE);
  const [isLandscape, setIsLandscape] = useState(false);

  let extraProps;
  const deviceButtonsConfig = {};
  if (projectSize.isInterstitial()) {
    const responsive = isLandscape ? { width: deviceSize.height, height: deviceSize.width } : { ...deviceSize };
    extraProps = { prismaConfig: { clickable: false, isLandscape, responsive } };
    deviceButtonsConfig.value = deviceSize;
  } else {
    extraProps = getMaxWidthAndHeight(containerRef);
  }

  return (
    <div className={classnames('preview', { 'non-interstitial': !projectSize.isInterstitial() })} ref={containerRef}>
      <PrismaPreview
        prismaUrl={prismaUrl}
        prismaProject={projectSize}
        baseUrl={baseUrl}
        deviceButtonsConfig={deviceButtonsConfig}
        {...extraProps}
      />
      {projectSize.isInterstitial() && (
        <div className="preview-controls">
          <PrismaPreviewButtons
            direction="column"
            onChange={value => setDeviceSize(value)}
            onControlClick={key => {
              if (key === 'landscape') {
                setIsLandscape(!isLandscape);
              }
            }}
          />
        </div>
      )}
    </div>
  );
};

export default Preview;
