/* eslint-disable object-shorthand */
/* eslint-disable func-names */
import { fabric } from 'fabric';

import { BORDER_STROKE_WIDTH, MARGINS } from './constants/canvas.js';

const PrismaBanner = fabric.util.createClass(fabric.Group, {
  type: 'prisma-banner',

  offsetTop: 0,
  offsetLeft: 0,

  initialize: function (options = {}) {
    const banner = new fabric.Rect({
      ...options,
      left: 0,
      top: 0,
    });
    this.callSuper('initialize', [banner], {
      ...options,
      width: options.width,
      height: options.height,
      left: options.left,
      top: options.top,
    });
  },

  setOffset: function (offset) {
    this.left = offset.offsetLeft;
    this.top = offset.offsetTop;
  },

  toObject: function () {
    return fabric.util.object.extend(this.callSuper('toObject'), {
      label: this.get('label'),
    });
  },

  getBannerPosition: function () {
    return { top: this.top, left: this.left };
  },

  initializeBorders: function () {
    const leftX = this.left - BORDER_STROKE_WIDTH;
    const rightX = this.left + this.width;
    const topY = this.top - BORDER_STROKE_WIDTH;
    const bottomY = this.top + this.height;

    const options = {
      stroke: '#ccc',
      strokeWidth: BORDER_STROKE_WIDTH,
    };
    this.borders = [
      new fabric.Line([leftX, topY, rightX, topY], { ...options, margin: MARGINS.TOP }),
      new fabric.Line([rightX, topY, rightX, bottomY], { ...options, margin: MARGINS.RIGHT }),
      new fabric.Line([leftX, bottomY, rightX, bottomY], { ...options, margin: MARGINS.BOTTOM }),
      new fabric.Line([leftX, topY, leftX, bottomY], { ...options, margin: MARGINS.LEFT }),
    ];

    return this.borders;
  },
});

fabric.PrismaBanner = PrismaBanner;

export default PrismaBanner;
