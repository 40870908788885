import { makeAutoObservable } from 'mobx';

const MAX_TRACKING = 5; // The number of steps to track

class CanvasActionsStore {
  /**
   * Indicates whether the shift key is pressed.
   */
  isShiftHold = false;

  /**
   * Indicates whether a click action has occurred.
   */
  isClicked = false;

  /**
   * Keeps track of the number of steps or movements since the start until it reaches
   * the maximum allowed value (MAX_TRACKING). It's not necessarily related to each
   * pixel change, but represents the count of steps during a series of movements.
   */
  trackingCounter = 0;

  /**
   * Indicates whether tracking is currently active.
   */
  isTracking = false;

  /**
   * Stores the position { x, y } for tracking.
   */
  startPosition = { x: 0, y: 0 };

  constructor(editor) {
    makeAutoObservable(this);
    this.editor = editor;
  }

  /**
   * Sets the state of the Shift key.
   * @param {boolean} isShiftHold - Whether the Shift key is pressed or not.
   */
  setIsShiftHold(isShiftHold) {
    this.isShiftHold = isShiftHold;
  }

  /**
   * Sets the state of clicking and resets tracking properties.
   * @param {boolean} isClicked - Indicates if a click action has ocurred.
   */
  setIsClickedAndResetTracking(isClicked) {
    this.isClicked = isClicked;
    this.resetTracking();
  }

  /**
   * Resets tracking properties.
   */
  resetTracking() {
    this.startPosition = { x: 0, y: 0 };
    this.trackingCounter = 0;
    this.isTracking = false;
  }

  /**
   * Starts tracking with the given position.
   * @param {object} startPosition - The starting position.
   */
  startTracking(startPosition) {
    this.startPosition = startPosition;
    this.isTracking = true;
  }

  /**
   * Stops tracking.
   */
  stopTracking() {
    this.isTracking = false;
  }

  /**
   * Checks if tracking has not started yet.
   * @return {boolean} True if tracking has not started, else false.
   */
  trackingNotStarted() {
    return this.trackingCounter === 0;
  }

  /**
   * Checks if tracking has reached the maximum allowed steps.
   * @return {boolean} True if tracking ended, else false.
   */
  trackingEnded() {
    return this.trackingCounter >= MAX_TRACKING;
  }

  /**
   * Increases the tracking counter if tracking is active and hasn't reached the maximum.
   */
  increaseTrackingCounter() {
    if (this.isTracking && !this.trackingEnded()) {
      this.trackingCounter += 1;
    }
  }

  /**
   * Given x and y values, calculates the axis lock.
   * @param {number} x - Position in x.
   * @param {number} y - Position in y.
   * @return {object} Object with movement locks.
   */
  getMovementLock(x, y) {
    const deltaX = Math.abs(x - this.startPosition.x);
    const deltaY = Math.abs(y - this.startPosition.y);
    const lockX = deltaY >= deltaX;
    return {
      lockMovementX: lockX,
      lockMovementY: !lockX,
    };
  }

  /**
   * Given x and y values, tracks the movement and if it ended, returns the lock.
   * @param {number} x - Position in x.
   * @param {number} y - Position in y.
   * @return {object} Object with movement locks if ended, else null.
   */
  handleMovement = (x, y) => {
    if (!this.isShiftHold) {
      return null;
    }

    if (!this.isTracking && this.trackingNotStarted()) {
      this.startTracking({ x, y });
      this.increaseTrackingCounter();
    } else if (this.isTracking) {
      if (this.trackingEnded()) {
        this.stopTracking();
        return this.getMovementLock(x, y);
      } else {
        this.increaseTrackingCounter();
      }
    }

    return null;
  };
}

export default CanvasActionsStore;
