import {
  createActiveSelection,
  fabricClone,
  getPositionFromActiveSelection,
  getPositionsFromActiveSelection,
  getUnrotatedPosition,
} from '@prisma/lib/src/utils/helpers';
import { animationTypes } from '@prisma/lib/src/utils/types';
import PrismaKeyframe from '@prisma/lib/src/prismaKeyframe';
import { PRISMA_GROUP } from '@prisma/lib/src/constants';

import { useRootStore } from 'store';

import { ACTIONS_TO_ANIMATIONS, MODIFIED_ACTIONS } from 'constants/objects';
import { TIMELINE_PADDING } from './constants';

import { validateLayerName } from 'utils/validations';

import { calculateTimeByPoint } from './components/Frame/utilities';

const useTimeLineScene = ({ isDraggable, zoomConfig, left, timeLineHeaderRef }) => {
  const { editor } = useRootStore();
  const { selectionStore, timelineSceneStore } = editor;

  const getKeyframeEvents = (store, isActiveSelection = false) => {
    const onObjectMoved = event => {
      const {
        transform: { target, original },
      } = event;

      if (isActiveSelection) {
        const objects = target.getObjects();

        const originalSelection = fabricClone(target);
        originalSelection.setOptions(original);

        // if all the original position are provided use them, else get them from active selection
        const originalObjects = objects.every(({ id }) => original.hasOwnProperty(id))
          ? original
          : getPositionsFromActiveSelection(objects, originalSelection);

        // discard active selection to make objects have their updated properties
        const { canvas } = editor;
        canvas.discardActiveObject();

        // apply offset to each object and add or edit keyframes
        objects.forEach(obj => {
          const originalObj = originalObjects[obj.id];
          timelineSceneStore.applyPosition(originalObj, obj, isActiveSelection);
        });

        // create the active selection again
        const selection = createActiveSelection(objects);
        canvas.setActiveObject(selection);
      } else {
        timelineSceneStore.applyPosition(original, target, isActiveSelection, store);
      }
    };

    const onObjectRotated = event => {
      const {
        transform: { target, original },
      } = event;

      store.updateObjectsProperties?.(target);
      let keyframe = timelineSceneStore.getUpdatedKeyframe({
        rotation: Math.floor(target.angle),
        ...getUnrotatedPosition(target),
      });

      timelineSceneStore.addOrEditActiveLayerKeyframes(keyframe, original, target, animationTypes.rotation);
    };

    const onObjectScaled = event => {
      const {
        transform: { target, action, original },
      } = event;

      if (isActiveSelection) {
        const objects = target.getObjects();

        const originalSelection = fabricClone(target);
        originalSelection.setOptions(original);

        // get original objects
        const originalObjects = {};
        objects.forEach(obj => {
          originalObjects[obj.id] = {
            scaleX: obj.scaleX,
            scaleY: obj.scaleY,
            ...getPositionFromActiveSelection(obj, originalSelection),
          };
        });

        // discard active selection to make objects have their updated properties
        const { canvas } = editor;
        canvas.discardActiveObject();

        // apply difference to each object and add or edit keyframes
        objects.forEach(obj => {
          const originalObj = originalObjects[obj.id];
          timelineSceneStore.applyScaling(originalObj, obj, action, isActiveSelection);
        });

        // create the active selection again
        const selection = createActiveSelection(objects);
        canvas.setActiveObject(selection);
      } else {
        timelineSceneStore.applyScaling(original, target, action, isActiveSelection, store);
      }
    };

    const modified = event => {
      const { action } = event;
      const { DRAG, SCALE, SCALE_X, SCALE_Y, ROTATE } = MODIFIED_ACTIONS;
      const animationType = ACTIONS_TO_ANIMATIONS[action];

      if (!isActiveSelection) {
        timelineSceneStore.selectKeyframeIfPossible(animationType);
      }

      switch (action) {
        case DRAG:
          onObjectMoved(event);
          break;
        case SCALE:
        case SCALE_X:
        case SCALE_Y:
          onObjectScaled(event);
          break;
        case ROTATE:
          onObjectRotated(event);
          break;
        default:
          break;
      }
    };

    const updateActiveLayer = event => {
      const {
        transform: { target },
      } = event;

      if (!isActiveSelection) {
        timelineSceneStore.setActiveLayer({ ...timelineSceneStore.activeLayer, target });

        if (target.type === PRISMA_GROUP) {
          // show updated objects when manipulate group in scene tab
          editor.groupStore.updateObjectsProperties(target);
        }
      }
    };

    return {
      moving: updateActiveLayer,
      rotating: updateActiveLayer,
      scaling: updateActiveLayer,
      modified,
    };
  };

  const handleChangeProp = updateFunc => object => {
    if (!editor?.canvas) {
      return;
    }
    editor.contextStore.selectLayer(object);
    updateFunc(object);
    editor.canvas.renderAll();
    editor.canvas.fire('history');
  };

  const handleEasingChange = (type, easing, layer) =>
    editor.canvas.changeEasing.call(editor.canvas, type, easing, layer);

  const handleKeyframeAdd = (type, layer) => {
    const time = calculateTimeByPoint(left - TIMELINE_PADDING, zoomConfig.msInOnPixel);
    const keyframe = PrismaKeyframe.create(layer.id, type, layer.target, time);
    const updatedLayer = editor.canvas.addOrEditKeyframes.call(editor.canvas, keyframe, layer.id);
    const animation = updatedLayer.animations.find(a => a.type === type);
    timelineSceneStore.setActive({
      layer,
      animation,
      keyframePoint: left - TIMELINE_PADDING,
      keyframe,
    });
  };

  const handleKeyframeMove = (layer, point) => {
    const activeKeyframe = timelineSceneStore.getActiveKeyframe();

    if (!activeKeyframe || !selectionStore.hasKeyframesSelected()) {
      return;
    }

    const keyframes = timelineSceneStore.updateSelectedKeyframesTimes(
      activeKeyframe.time,
      point,
      zoomConfig.msInOnPixel,
    );
    // merge the keyframes but do not update the ids
    editor.canvas.mergeKeyframes.call(editor.canvas, keyframes, false);
  };

  const handleKeyframeRemove = keyframe => {
    editor.removeComponent(keyframe);
  };

  const getCanvasObjectsGroupIds = objectIds => {
    return editor.canvas.layers
      .filter(({ id, target }) => objectIds.includes(id) && target.groupId)
      .map(({ target }) => target.groupId);
  };

  const handleLayerDrop = (selectedId, overId) => {
    if (!editor?.canvas || !isDraggable || !selectedId || !overId) {
      return;
    }

    const groupIds = new Set();
    // get involved groupIds before change
    getCanvasObjectsGroupIds([selectedId, overId]).forEach(id => groupIds.add(id));

    // change order
    editor.canvas.changezOrder.call(editor.canvas, selectedId, overId);

    // make selected layer active
    const selectedLayer = editor.canvas.layers.find(({ id }) => id === selectedId);
    editor.activeObject = selectedLayer?.target;
    editor.contextStore.selectLayer(selectedLayer);

    // get involved groupIds after change
    getCanvasObjectsGroupIds([selectedId, overId]).forEach(id => groupIds.add(id));

    // update selected object group and mask, in case it changed
    editor.updateLayersPropertiesByLayerId(selectedId, {
      groupId: selectedLayer?.target.groupId,
      maskId: selectedLayer?.target.maskId,
    });

    // update object ids of involved groups
    editor.canvas.layers
      .filter(({ id }) => groupIds.has(id))
      .forEach(({ target }) => {
        editor.updateLayersPropertiesByLayerId(target.id, { objectIds: target.objectIds });
      });
  };

  const handleNameChange = (layer, newName) => {
    newName = newName.trim();
    if (!editor?.canvas || layer.name === newName || !validateLayerName(layer.id, newName, editor.canvas.layers)) {
      return;
    }
    editor.canvas.editLayer(layer.id, { name: newName });
  };

  const handleRepeatChange = (type, layer, repeat, infinitely) =>
    editor.canvas.changeRepeat.call(editor.canvas, type, layer, repeat, infinitely);

  const handleScroll = scroll => {
    timeLineHeaderRef.current.scrollLeft = scroll.target.scrollLeft;
  };

  return {
    getKeyframeEvents,
    handleChangeProp,
    handleEasingChange,
    handleKeyframeAdd,
    handleKeyframeMove,
    handleKeyframeRemove,
    handleLayerDrop,
    handleNameChange,
    handleRepeatChange,
    handleScroll,
  };
};

export default useTimeLineScene;
