import React from 'react';
import { observer } from 'mobx-react';

import { Checkbox } from '@akiunlocks/perseus-ui-components';

import { USE_RESPONSIVE } from 'utils/featureFlag';

import AlignButtons from 'components/Sidebar/components/Align';
import SidebarPanel from 'components/Sidebar/components/SidebarPanel';
import SidebarItem from 'components/Sidebar/components/SidebarPanel/SidebarItem';

import useMaskProperties from './MaskProperties.hooks';
import SizeForm from '../SizeForm';
import AnchorPoints from '../AnchorPoints';
import PositionForm from '../PositionForm';

const MaskProperties = observer(({ disabled, isTimelineLayersTab }) => {
  const { maskStore, onAlignChange, onLeftChange, onLockProportionsChange, onSetLockedPosition, onTopChange } =
    useMaskProperties();
  const editDisabled = disabled || !isTimelineLayersTab;

  return (
    <SidebarPanel title="Mask">
      <SidebarItem disabled={editDisabled}>
        <AlignButtons onClick={onAlignChange} />
      </SidebarItem>
      <SidebarItem disabled={editDisabled}>
        <SizeForm
          height={maskStore.height}
          onLockProportionsChange={onLockProportionsChange}
          uniformScaling={maskStore.uniformScaling}
          width={maskStore.width}
        />
      </SidebarItem>
      <SidebarItem disabled={editDisabled}>
        {USE_RESPONSIVE ? (
          <AnchorPoints />
        ) : (
          <PositionForm
            left={maskStore.unrotatedLeft}
            onLeftChange={onLeftChange}
            onTopChange={onTopChange}
            top={maskStore.unrotatedTop}
          />
        )}
      </SidebarItem>
      <SidebarItem disabled={editDisabled}>
        <div className="d-flex align-items-center">
          <Checkbox
            className="fs-7"
            checked={maskStore.lockedPosition}
            disabled={editDisabled}
            id="locked-position"
            label="Lock position"
            onChange={({ target }) => onSetLockedPosition(target.checked)}
          />
        </div>
      </SidebarItem>
    </SidebarPanel>
  );
});

export default MaskProperties;
