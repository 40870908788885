import { observer } from 'mobx-react';
import React, { useCallback, useEffect, useState } from 'react';
import { Container, Nav, Stack } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import classnames from 'classnames';

import { Alert, Button, Col, Icon, ItemButton, Popover, Row, Spinner } from '@akiunlocks/perseus-ui-components';

import { useRootStore } from 'store';

import useInterval from 'hooks/useInterval';
import useReportProjectInfo from 'hooks/ReportProjectInfo.hooks';

import { ASSETS_UPLOADED, ASSETS_UPLOAD_FAILED, ASSET_UPLOAD_IN_PROGRESS, POLLING_INTERVAL } from 'constants/perseus';
import { PROJECT_TYPES } from 'constants/project';

import { CONFIRM_BODIES, CONFIRM_TITLES, NOTIFY_MESSAGES } from 'utils';
import { apiRequestWithNotifications, notifyError, notifySuccess } from 'utils/notifications';
import { formatSize } from 'utils/formatters';
import { getPerseusActionLabel } from 'utils/labels';
import { redirectToPersonalizeUpload } from 'utils/parentWindow';

import Confirm from 'components/Confirm';
import CompositionModal from 'components/ProjectPreview/CompositionModal';
import DuplicateCompositionModal from 'components/ProjectPreview/DuplicateCompositionModal';
import CopyToProjectModal from 'components/ProjectPreview/CopyToProjectModal/CopyToProjectModal';
import Preview from 'components/Preview';
import ProjectActions from './ProjectActions';

import './ProjectPreview.scss';

const ProjectPreview = observer(() => {
  const navigate = useNavigate();
  const { projectId } = useParams();
  const { projects, projectSizes: projectSizesStore, sizes: sizesStore } = useRootStore();
  useReportProjectInfo();

  const { sizes, getSizes } = sizesStore;
  const { getPerseusProject, getProject, project, projectAssetsBaseUrl } = projects;
  const {
    addProjectSizes,
    cloneProjectSize,
    copyProjectSize,
    deleteProjectSize,
    getProjectSizes,
    getTotalSize,
    projectSize,
    projectSizes,
    setProjectSize,
    updateProjectSize,
  } = projectSizesStore;

  const [showNewSizeModal, setShowNewSizeModal] = useState(false);
  const [showDuplicateSizeModal, setShowDuplicateSizeModal] = useState(false);
  const [showCopyToProjectModal, setShowCopyToProjectModal] = useState(false);
  const [showDeleteSizeModal, setShowDeleteSizeModal] = useState(false);
  const [deleteInProgress, setDeleteInProgress] = useState(false);
  const [showEditModal, setEditModal] = useState(false);
  const [saveInProgress, setSaveInProgress] = useState(false);
  const [exportInProgress, setExportInProgress] = useState(null);
  const [perseusStatus, setPerseusStatus] = useState(null);
  const [totalSize, setTotalSize] = useState(0);
  const [totalSizeInProgress, setTotalSizeInProgress] = useState(false);
  const [isLoadingSizes, setIsLoadingSizes] = useState(false);

  const perseusHashId = project?.perseusHashId;
  const pushInProgress = (perseusHashId && perseusStatus === ASSET_UPLOAD_IN_PROGRESS) || exportInProgress;
  const actionsDisabled = pushInProgress || !projectSizes.length;

  const getPerseusStatusAndNotifyIfNeeded = useCallback(
    async perseusHashId => {
      const perseusProjectData = await getPerseusProject(perseusHashId);
      const newStatus = perseusProjectData?.status;
      const isRender = project?.isAutoRendered;

      if (perseusStatus === ASSET_UPLOAD_IN_PROGRESS) {
        if (newStatus === ASSETS_UPLOADED) {
          notifySuccess(`${getPerseusActionLabel(isRender)} finished successfully`);
        }
        if (newStatus === ASSETS_UPLOAD_FAILED) {
          notifyError(`There was an error during ${getPerseusActionLabel(isRender, true)}. Please try again.`);
        }
      }

      setPerseusStatus(newStatus);
    },
    [getPerseusProject, perseusStatus, project?.isAutoRendered],
  );

  useEffect(() => {
    if (perseusHashId) {
      getPerseusStatusAndNotifyIfNeeded(perseusHashId);
    }
  }, [getPerseusStatusAndNotifyIfNeeded, perseusHashId]);

  useEffect(() => {
    const fetchProjectSizes = async () => {
      setIsLoadingSizes(true);
      await getProjectSizes({ projectId });
      setIsLoadingSizes(false);
    };
    getSizes();
    getProject(projectId);
    if (projectSize?.projectId !== projectId) {
      fetchProjectSizes();
    }
  }, [getProject, getProjectSizes, getSizes, projectId, projectSize?.projectId]);

  useEffect(() => {
    if (projectSize?.projectId !== projectId) {
      // if projectId changes, select first project size if exists, else null
      setProjectSize(projectSizes.length ? projectSizes[0] : null);
    }
  }, [projectId, projectSize?.projectId, projectSizes, setProjectSize]);

  useEffect(() => {
    const fetchTotalSize = async () => {
      setTotalSizeInProgress(true);
      const totalSize = await getTotalSize(projectSize._id);
      setTotalSize(totalSize);
      setTotalSizeInProgress(false);
    };

    if (projectSize?._id) {
      fetchTotalSize();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectSize?._id]);

  const backToProjectsList = () => navigate(`/projects`);
  const navigateToComposition = id => navigate(`/projects/${projectId}/sizes/${id}`);
  const onCopyToProjectClick = () => setShowCopyToProjectModal(true);
  const onDeleteClick = () => setShowDeleteSizeModal(true);
  const onDuplicateClick = () => setShowDuplicateSizeModal(true);
  const onNewCanvasClick = () => setShowNewSizeModal(true);
  const onEditClick = () => setEditModal(true);
  const onSizeClick = size => setProjectSize(size);

  const handleCreate = async data => {
    setSaveInProgress(true);
    const newProjectSize = await addProjectSizes({ ...data, projectId });
    setShowNewSizeModal(false);
    setSaveInProgress(false);
    getProjectSizes({ projectId });
    setProjectSize(newProjectSize); // move to the new size
  };

  const handleDuplicate = async data => {
    return apiRequestWithNotifications(
      async () => {
        const { result } = await cloneProjectSize(data);
        if (result?._id) {
          setShowDuplicateSizeModal(false);
          await getProjectSizes({ projectId });
          setProjectSize(result);
        }
      },
      NOTIFY_MESSAGES.DUPLICATE_COMPOSITION_SUCCESS,
      {
        onError: () => notifyError(NOTIFY_MESSAGES.DUPLICATE_COMPOSITION_ERROR),
      },
    );
  };

  const handleCopyToProject = async destinationProjectId => {
    return copyProjectSize(projectSize._id, projectSize.projectId, destinationProjectId);
  };

  const handleEdit = async ({ name, productType, productSubtype }) => {
    setSaveInProgress(true);
    if (
      projectSize.name !== name ||
      projectSize.productType !== productType ||
      projectSize.productSubtype !== productSubtype
    ) {
      await updateProjectSize(projectSize._id, { name, productType, productSubtype });
    }
    setEditModal(false);
    setSaveInProgress(false);
    getProjectSizes({ projectId });
  };

  const handleDelete = async () => {
    setDeleteInProgress(true);
    const result = await deleteProjectSize(projectSize._id, perseusHashId);
    if (result.error) {
      notifyError(result.message);
    } else {
      await getProjectSizes({ projectId });
      projectSizesStore.setProjectSize(null);
    }
    setDeleteInProgress(false);
    setShowDeleteSizeModal(false);
  };

  useInterval(
    () => {
      getPerseusStatusAndNotifyIfNeeded(perseusHashId);
    },
    pushInProgress ? POLLING_INTERVAL : null,
  );

  const sizeActions = [
    {
      disabled: actionsDisabled,
      icon: 'pencil',
      label: 'Edit',
      onClick: () => navigateToComposition(projectSize._id),
    },
    {
      disabled: actionsDisabled,
      icon: 'union',
      label: 'Duplicate',
      onClick: () => onDuplicateClick(projectSize),
    },
    {
      disabled: actionsDisabled,
      icon: 'clipboard',
      label: 'Copy to project',
      onClick: () => onCopyToProjectClick(projectSize),
    },
    {
      className: 'text-danger border-danger',
      disabled: actionsDisabled,
      icon: 'trash3',
      label: 'Delete',
      onClick: () => onDeleteClick(projectSize),
    },
  ];

  return (
    <Container fluid className="App project-preview">
      <Stack className="h-100">
        <Row className="flex-nowrap h-100">
          <Col className="sizes flex-grow-0 p-0">
            <p className="title">Compositions</p>
            <Nav className="nav">
              {isLoadingSizes ? (
                <Spinner className="mx-4 my-2" size="sm" variant="primary" />
              ) : (
                projectSizes.map(size => (
                  <Nav.Link key={size._id} active={size._id === projectSize?._id} onClick={() => onSizeClick(size)}>
                    <span>{size.name}</span>
                  </Nav.Link>
                ))
              )}
              <Nav.Link
                className={classnames('align-items-center', { disabled: pushInProgress })}
                onClick={() => {
                  if (pushInProgress) {
                    return;
                  }
                  onNewCanvasClick();
                }}
              >
                <Icon className="me-1" name="plus-lg" size={14} />
                <span>New composition</span>
              </Nav.Link>
            </Nav>
          </Col>
          <Col className="mx-2">
            <Stack className="h-100 pb-4">
              <div className="mt-4 d-flex align-items-center">
                {project?.name ? (
                  <div className="project-name">{project.name}</div>
                ) : (
                  <Spinner size="sm" variant="primary" />
                )}
                <Popover
                  id="personalize-tooltip"
                  content={perseusHashId ? 'Go to Personalize' : 'Not pushed to Personalize'}
                  placement="bottom"
                >
                  <Icon
                    className="ms-2"
                    name="stack"
                    size={14}
                    color="#f99e47"
                    disabled={!perseusHashId}
                    onClick={() => redirectToPersonalizeUpload(perseusHashId)}
                  />
                </Popover>
              </div>
              {pushInProgress && (
                <Alert variant="warning" className="fs-7 m-0 mt-2">
                  Changes cannot be made to this project since Push to Personalize is in progress. Please wait a few
                  seconds.
                </Alert>
              )}
              <Stack className="project-size-name" direction="horizontal" gap={1}>
                <div>{projectSize?.name}</div>
                {!!projectSize?.name && (
                  <Popover id="edit-name" content="Edit composition name and type">
                    <span className={classnames('edit', { disabled: pushInProgress })} onClick={onEditClick}>
                      <Icon color="var(--per-primary)" name="pencil" size={14} />
                    </span>
                  </Popover>
                )}
              </Stack>
              <Stack className="size-controls" direction="horizontal" gap={3}>
                {sizeActions.map(({ className, disabled, icon, label, onClick }) => (
                  <ItemButton
                    className={`text-secondary border border-secondary ${className}`}
                    disabled={disabled}
                    icon={icon}
                    iconSize={16}
                    key={label}
                    label={label}
                    onClick={onClick}
                    variant="light"
                  />
                ))}
              </Stack>
              {projectSize && (
                <Preview
                  key={projectSize._id}
                  prismaUrl={`${process.env.REACT_APP_PRISMA_LIB_URL}prisma.min.js`}
                  projectSize={projectSize}
                  baseUrl={projectAssetsBaseUrl}
                />
              )}
              <Stack>
                <div className="project-size">
                  <div>{projectSize?.getDisplaySize()}</div>
                  <div>
                    Total size: {totalSizeInProgress ? <Spinner size="sm" variant="primary" /> : formatSize(totalSize)}
                  </div>
                </div>
              </Stack>
              <Row className="mt-2">
                <Col className="d-flex flex-row">
                  <Row className="projects-button-container">
                    <Button onClick={backToProjectsList} variant="light">
                      <div className="projects-button">
                        <Icon size="15" name="fa-home" />
                        <span className="button-label">Projects</span>
                      </div>
                    </Button>
                  </Row>
                </Col>
                <ProjectActions
                  disabled={actionsDisabled}
                  exportInProgress={exportInProgress}
                  isAutoRendered={project?.isAutoRendered}
                  isDOOH={project?.type === PROJECT_TYPES.DOOH}
                  perseusHashId={perseusHashId}
                  setExportInProgress={setExportInProgress}
                  setPerseusStatus={setPerseusStatus}
                />
              </Row>
            </Stack>
          </Col>
        </Row>
      </Stack>

      <CompositionModal
        key={`composition-${projectSize?._id}`}
        handleClose={() => {
          setShowNewSizeModal(false);
          setEditModal(false);
          setSaveInProgress(false);
        }}
        handleAccept={showEditModal ? handleEdit : handleCreate}
        projectSize={showEditModal ? projectSize : undefined}
        projectSizes={projectSizes}
        projectType={project?.type}
        saveInProgress={saveInProgress}
        show={showNewSizeModal || showEditModal}
        sizes={sizes}
      />

      <DuplicateCompositionModal
        key={`duplicate-${projectSize?._id}`}
        sizes={sizes}
        selectedSize={projectSize}
        projectSizes={projectSizes}
        show={showDuplicateSizeModal}
        handleClose={() => setShowDuplicateSizeModal(false)}
        handleDuplicate={handleDuplicate}
      />

      <CopyToProjectModal
        key={`copy-${projectSize?._id}`}
        show={showCopyToProjectModal}
        handleClose={() => setShowCopyToProjectModal(false)}
        handleCopyToProject={handleCopyToProject}
      />

      <Confirm
        handleAccept={handleDelete}
        loading={deleteInProgress}
        showModal={showDeleteSizeModal}
        key={`delete-${projectSize?._id}`}
        handleClose={setShowDeleteSizeModal}
        title={CONFIRM_TITLES.PROJECT_SIZE_ARCHIVED}
        body={CONFIRM_BODIES.PROJECT_SIZE_ARCHIVED.replace('{name}', projectSize?.name)}
      />
    </Container>
  );
});

export default ProjectPreview;
