import React from 'react';
import { useAccordionButton } from '@akiunlocks/perseus-ui-components';

import { useRootStore } from '../../../../store';

import { addGroupIdentifier } from './utilities';
import { ICON_SIZE } from 'constants';

import ToggleIconButton from '../../../ToggleIconButton';

import './layerAccordion.scss';

const ToggleGroup = ({ layerId }) => {
  const { layerAccordionStore } = useRootStore();

  const decoratedOnClick = useAccordionButton(addGroupIdentifier(layerId));

  return (
    <ToggleIconButton
      icons={['chevron-down', 'chevron-up']}
      key="collapse-group"
      className="layer-control"
      onClick={e => {
        e.stopPropagation();
        decoratedOnClick();
      }}
      iconSize={ICON_SIZE}
      value={layerAccordionStore.isGroupOpen(layerId)}
    />
  );
};

export default ToggleGroup;
