import { useCallback } from 'react';

import { useRootStore } from 'store';

import { findGroup, getObjectOffset } from 'containers/Editor/stores/utilities';
import { getUpdatedLeft, getUpdatedTop } from 'utils/helpers';

import useEditorActiveObject from 'utils/useEditorActiveObject';
import useActiveObjectProperties from '../useActiveObjectProperties';

const useGroupProperties = () => {
  const { editor } = useRootStore();
  const { activeObject, canvas, groupStore } = editor;
  const { setActiveObjectAttribute } = useEditorActiveObject();

  const { onAlignChange, onAnchorChange, onUnitChange } = useActiveObjectProperties(groupStore);

  const setGroupAttribute = useCallback(
    (attribute, value) => {
      if (!activeObject) {
        return;
      }
      groupStore.setAttribute(attribute, value);
      setActiveObjectAttribute(attribute, value);
    },
    [activeObject, groupStore, setActiveObjectAttribute],
  );

  const discardActiveSelectionAndGetGroup = useCallback(
    activeSelection => {
      // discard active selection to get correct position of items
      const objects = activeSelection.getObjects();
      canvas.discardActiveObject();
      // get group object
      return findGroup(objects);
    },
    [canvas],
  );

  const onHideChange = useCallback(
    ({ target }) => {
      setGroupAttribute('hide', target.checked);
      canvas.fire('history');
    },
    [setGroupAttribute, canvas],
  );

  const onGroupAnchorChange = useCallback(
    (originX, originY) => {
      if (!activeObject) {
        return;
      }
      const group = discardActiveSelectionAndGetGroup(activeObject);
      onAnchorChange(originX, originY, group);
      canvas.setActiveObject(group);
    },
    [activeObject, canvas, discardActiveSelectionAndGetGroup, onAnchorChange],
  );

  const onGroupPositionXChanged = useCallback(
    ({ target }, unit) => {
      if (!activeObject) {
        return;
      }
      const group = findGroup(activeObject.getObjects());
      const { offsetLeft } = getObjectOffset(group);
      const left = getUpdatedLeft(target.value, group.originX, canvas);
      onUnitChange('left', unit, group);
      activeObject.setOptions({ left: left + offsetLeft, originX: group.originX });
      groupStore.updatePosition(activeObject);
      canvas.renderAll();
    },
    [activeObject, canvas, groupStore, onUnitChange],
  );

  const onGroupPositionYChanged = useCallback(
    ({ target }, unit) => {
      if (!activeObject) {
        return;
      }
      const group = findGroup(activeObject.getObjects());
      const { offsetTop } = getObjectOffset(group);
      const top = getUpdatedTop(target.value, group.originY, canvas);
      onUnitChange('top', unit, group);
      activeObject.setOptions({ top: top + offsetTop, originY: group.originY });
      groupStore.updatePosition(activeObject);
      canvas.renderAll();
    },
    [activeObject, canvas, groupStore, onUnitChange],
  );

  const onGroupUnitChange = useCallback(
    (prop, unit) => {
      if (!activeObject) {
        return;
      }
      const group = findGroup(activeObject.getObjects());
      onUnitChange(prop, unit, group);
    },
    [activeObject, onUnitChange],
  );

  return {
    groupStore,
    onAlignChange,
    onHideChange,
    onAnchorChange: onGroupAnchorChange,
    onLeftChange: onGroupPositionXChanged,
    onPositionXChanged: onGroupPositionXChanged,
    onPositionYChanged: onGroupPositionYChanged,
    onTopChange: onGroupPositionYChanged,
    onUnitChange: onGroupUnitChange,
  };
};

export default useGroupProperties;
