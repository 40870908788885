import { ANCHORS_X, ANCHORS_Y } from '@prisma/lib/src/constants/objects';

/**
 * Update object locks
 * @param {object} obj Object to update
 * @param {boolean} locked Boolean to lock or unlock
 * @param {boolean} forceShowControls Boolean to force to show controls
 */
export const updateObjectLocks = (obj, locked, forceShowControls) => {
  obj.lockMovementX = locked;
  obj.lockMovementY = locked;
  obj.lockRotation = locked;
  obj.lockScalingX = locked;
  obj.lockScalingY = locked;
  obj.lockScalingFlip = locked;
  obj.hasControls = !locked || forceShowControls;
};

/**
 * Modifies the object to be editable in the canvas or not
 * @param {object} obj
 * @param {boolean} editable
 */
export const setEditable = (obj, editable) => {
  obj.selectable = editable;
  updateObjectLocks(obj, !editable);
};

export function getAnchorPosition(object) {
  const { originX, originY, left, top, canvas } = object;
  const { _bannerWidth, _bannerHeight } = canvas;

  let x = left;
  let y = top;
  if (originX === ANCHORS_X.CENTER) {
    x = left - Math.round(_bannerWidth / 2);
  } else if (originX === ANCHORS_X.RIGHT) {
    x = _bannerWidth - left;
  }
  if (originY === ANCHORS_Y.CENTER) {
    y = top - Math.round(_bannerHeight / 2);
  } else if (originY === ANCHORS_Y.BOTTOM) {
    y = _bannerHeight - top;
  }

  return { x, y };
}

/**
 * Given a position X value and originX of an object, gets the left position of the object in the canvas.
 * @param {string|number} value - Position X.
 * @param {string} originX - Horizontal origin: 'left', 'center' or 'right'.
 * @param {object} canvas - Object canvas.
 * @return {number} Updated left value.
 */
export function getUpdatedLeft(value, originX, canvas) {
  const { _bannerWidth } = canvas;
  const newValue = Number(value);
  let left = newValue;
  if (originX === ANCHORS_X.CENTER) {
    left = newValue + Math.round(_bannerWidth / 2);
  } else if (originX === ANCHORS_X.RIGHT) {
    left = _bannerWidth - newValue;
  }
  return left;
}

/**
 * Given a position Y value and originY of an object, gets the top position of the object in the canvas.
 * @param {string|number} value - Position Y.
 * @param {string} originY - Vertical origin: 'top', 'center' or 'bottom'.
 * @param {object} canvas - Object canvas.
 * @return {number} Updated top value.
 */
export function getUpdatedTop(value, originY, canvas) {
  const { _bannerHeight } = canvas;
  const newValue = Number(value);
  let top = newValue;
  if (originY === ANCHORS_Y.CENTER) {
    top = newValue + Math.round(_bannerHeight / 2);
  } else if (originY === ANCHORS_Y.BOTTOM) {
    top = _bannerHeight - newValue;
  }
  return top;
}
