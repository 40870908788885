export const CDN_URL = 'https://perseus-fonts.prod.a.ki/';
export const FONTS_JSON_URL = `${CDN_URL}fonts.json`;

// this is for backwards compatibility only
// actual list will be obtained from S3 (FONTS_JSON_URL)
export const FONTS = [
  { name: 'Arial', url: `${CDN_URL}Arial.ttf` },
  { name: 'Arvo', url: `${CDN_URL}Arvo.ttf` },
  { name: 'Avenir Next', url: `${CDN_URL}AvenirNext.ttc` },
  { name: 'Courier New', url: `${CDN_URL}CourierNew.woff2` },
  { name: 'Futura', url: `${CDN_URL}Futura.ttc` },
  { name: 'Gotham', url: `${CDN_URL}Gotham.otf` },
  { name: 'Helvetica Neue', url: `${CDN_URL}HelveticaNeue.woff2` },
  { name: 'Lato', url: `${CDN_URL}Lato.woff2` },
  { name: 'Montserrat', url: `${CDN_URL}Montserrat.ttf` },
  { name: 'Nunito', url: `${CDN_URL}Nunito.ttf` },
  { name: 'Playfair Display', url: `${CDN_URL}PlayfairDisplay.ttf` },
  { name: 'Proxima Nova', url: `${CDN_URL}ProximaNova.otf` },
  { name: 'Times New Roman', url: `${CDN_URL}TimesNewRoman.woff2` },
  { name: 'Trade Gothic', url: `${CDN_URL}TradeGothic.ttf` },
  { name: 'Verdana', url: `${CDN_URL}Verdana.woff2` },
];
