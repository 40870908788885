import { useCallback } from 'react';
import { fabric } from 'fabric';

import { PRISMA_TEXT } from '@prisma/lib/src/constants';
import { getOriginPosition } from '@prisma/lib/src/utils/helpers';

import { useRootStore } from 'store';
import { HEIGHT, WIDTH } from 'constants';
import { getUpdatedLeft, getUpdatedTop } from 'utils/helpers';

import { recalculatePositionAnimations } from 'utils/animations';
import useEditorActiveObject from 'utils/useEditorActiveObject';

import { getObjectOffset } from 'containers/Editor/stores/utilities';

const useActiveObjectProperties = store => {
  const { editor } = useRootStore();
  const { activeObject, canvas, contextStore } = editor;
  const { setActiveObjectAttribute, setObjectAttribute } = useEditorActiveObject();

  const setAttribute = useCallback(
    (attribute, value) => {
      if (!activeObject) {
        return;
      }
      store.setAttribute(attribute, value);
      setActiveObjectAttribute(attribute, value);

      if (activeObject.type === PRISMA_TEXT) {
        store.updateActiveObjectSizeIfNeeded();
      }
    },
    [activeObject, store, setActiveObjectAttribute],
  );

  const applyUnrotatedPosition = useCallback(
    (angle, left, top) => {
      activeObject.setOptions({ left, top, angle: 0 });
      activeObject.rotate(angle);
      // call this to render and update history
      setActiveObjectAttribute('angle', angle);
    },
    [activeObject, setActiveObjectAttribute],
  );

  const onAlignChange = useCallback(
    alignmentType => {
      // we have to build original object before calling setAlignment because top and left values will change
      const original = { top: activeObject.top, left: activeObject.left };
      editor.setAlignment(activeObject, alignmentType);
      store.updatePosition(activeObject);
      contextStore.alignElements(activeObject, original);
      canvas.fire('history');
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [canvas, activeObject, store],
  );

  const onAnchorChange = useCallback(
    (originX, originY, object = activeObject) => {
      if (!object) {
        return;
      }

      const previousOriginX = object.originX;
      const previousOriginY = object.originY;

      const position = getOriginPosition(object, originX, originY);
      store.setAttribute('originX', originX);
      store.setAttribute('originY', originY);
      const props = { left: position.x, top: position.y, originX, originY };
      setObjectAttribute(object, props);
      store.updatePosition(object);

      recalculatePositionAnimations(object, previousOriginX, previousOriginY);

      return props;
    },
    [activeObject, setObjectAttribute, store],
  );

  const onAngleChange = useCallback(
    ({ target }) => {
      const angle = Math.floor(target.value);
      const { offsetLeft, offsetTop } = getObjectOffset(activeObject);
      applyUnrotatedPosition(
        angle,
        Math.floor(store.unrotatedLeft) + offsetLeft,
        Math.floor(store.unrotatedTop) + offsetTop,
      );
      store.setAngle(angle);
      store.updatePosition(activeObject);
    },
    [activeObject, applyUnrotatedPosition, store],
  );

  const onUnitChange = useCallback(
    (prop, unit, object = activeObject) => {
      if (!object) {
        return;
      }
      store.setUnit(prop, unit);
      setObjectAttribute(object, { [`${prop}Unit`]: unit });
    },
    [activeObject, setObjectAttribute, store],
  );

  const onHeightChange = useCallback(
    ({ target }, unit) => {
      if (!activeObject) {
        return;
      }

      const strokeWidth = store.strokeEnabled ? store.strokeWidth : 0;
      const value = Math.max(target.value, 1) - strokeWidth;
      const height = Math.min(value, 10000);

      const newScaleY = height / activeObject.height;
      const newScaleX = store.uniformScaling
        ? activeObject.scaleX + newScaleY - activeObject.scaleY
        : activeObject.scaleX;
      const width = store.uniformScaling ? Math.floor(activeObject.width * newScaleX) : store.width;

      onUnitChange(HEIGHT, unit);

      store.setAttribute(WIDTH, Math.floor(width));
      store.setAttribute(HEIGHT, Math.floor(value));
      setActiveObjectAttribute({
        scaleY: newScaleY,
        scaleX: newScaleX,
      });
    },
    [activeObject, store, onUnitChange, setActiveObjectAttribute],
  );

  const onHideChange = useCallback(
    ({ target }) => {
      setAttribute('hide', target.checked);
      canvas.fire('history');
    },
    [setAttribute, canvas],
  );

  const onLeftChange = useCallback(
    ({ target }) => {
      if (!activeObject) {
        return;
      }
      const { offsetLeft, offsetTop } = getObjectOffset(activeObject);
      const { angle } = activeObject;
      const left = Math.floor(target.value) + offsetLeft;
      const top = Math.floor(store.unrotatedTop) + offsetTop;
      applyUnrotatedPosition(angle, left, top);
      store.updatePosition(activeObject);
    },
    [activeObject, applyUnrotatedPosition, store],
  );

  const onPositionXChanged = useCallback(
    ({ target }, unit) => {
      if (!activeObject) {
        return;
      }
      const left = getUpdatedLeft(target.value, activeObject.originX, canvas);
      onUnitChange('left', unit);
      onLeftChange({ target: { value: left } });
    },
    [activeObject, canvas, onUnitChange, onLeftChange],
  );

  const onLockProportionsChange = useCallback(
    uniformScaling => {
      setAttribute('uniformScaling', uniformScaling);
    },
    [setAttribute],
  );

  const onOpacityChange = useCallback(
    ({ target }) => {
      setAttribute('objectOpacity', target.value);
    },
    [setAttribute],
  );

  const onShadowChange = useCallback(
    shadowProps => {
      if (!activeObject) {
        return;
      }
      store.setShadowProps(shadowProps);
      const shadow = store.shadowEnabled ? new fabric.Shadow(store.getShadowProps()) : null;
      setActiveObjectAttribute('shadow', shadow);
    },
    [activeObject, setActiveObjectAttribute, store],
  );

  const onShadowColorChange = useCallback(
    ({ target }) => {
      store.setShadowEnabled(true);
      onShadowChange({
        color: target.value,
      });
    },
    [onShadowChange, store],
  );

  const onShadowEnabledChanged = useCallback(
    ({ target }) => {
      store.setShadowEnabled(target.checked);
      onShadowChange();
    },
    [onShadowChange, store],
  );

  const onShadowOffsetBlurChange = useCallback(
    ({ target }) => {
      store.setShadowEnabled(true);
      onShadowChange({
        blur: Number(target.value),
      });
    },
    [onShadowChange, store],
  );

  const onShadowOffsetXChange = useCallback(
    ({ target }) => {
      store.setShadowEnabled(true);
      onShadowChange({
        offsetX: Number(target.value),
      });
    },
    [onShadowChange, store],
  );

  const onShadowOffsetYChange = useCallback(
    ({ target }) => {
      store.setShadowEnabled(true);
      onShadowChange({
        offsetY: Number(target.value),
      });
    },
    [onShadowChange, store],
  );

  const onTopChange = useCallback(
    ({ target }) => {
      if (!activeObject) {
        return;
      }
      const { offsetLeft, offsetTop } = getObjectOffset(activeObject);
      const { angle } = activeObject;
      const left = Math.floor(store.unrotatedLeft) + offsetLeft;
      const top = Math.floor(target.value) + offsetTop;
      applyUnrotatedPosition(angle, left, top);
      store.updatePosition(activeObject);
    },
    [activeObject, applyUnrotatedPosition, store],
  );

  const onPositionYChanged = useCallback(
    ({ target }, unit) => {
      if (!activeObject) {
        return;
      }
      const top = getUpdatedTop(target.value, activeObject.originY, canvas);
      onUnitChange('top', unit);
      onTopChange({ target: { value: top } });
    },
    [activeObject, canvas, onUnitChange, onTopChange],
  );

  const onWidthChange = useCallback(
    ({ target }, unit) => {
      if (!activeObject) {
        return;
      }
      const strokeWidth = store.strokeEnabled ? store.strokeWidth : 0;
      const value = Math.max(target.value, 1) - strokeWidth;
      const width = Math.min(value, 10000);

      const newScaleX = width / activeObject.width;
      const newScaleY = store.uniformScaling
        ? activeObject.scaleY + newScaleX - activeObject.scaleX
        : activeObject.scaleY;
      const height = store.uniformScaling ? Math.floor(activeObject.height * newScaleY) : store.height;

      onUnitChange(WIDTH, unit);

      store.setAttribute(HEIGHT, Math.floor(height));
      store.setAttribute(WIDTH, Math.floor(value));
      setActiveObjectAttribute({
        scaleX: newScaleX,
        scaleY: newScaleY,
      });
    },
    [activeObject, store, onUnitChange, setActiveObjectAttribute],
  );

  return {
    onAlignChange,
    onAnchorChange,
    onAngleChange,
    onHeightChange,
    onHideChange,
    onLeftChange,
    onLockProportionsChange,
    onOpacityChange,
    onPositionXChanged,
    onPositionYChanged,
    onShadowColorChange,
    onShadowEnabledChanged,
    onShadowOffsetBlurChange,
    onShadowOffsetXChange,
    onShadowOffsetYChange,
    onTopChange,
    onUnitChange,
    onWidthChange,
    setAttribute,
  };
};

export default useActiveObjectProperties;
