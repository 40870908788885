import React from 'react';
import { useAccordionButton } from '@akiunlocks/perseus-ui-components';
import { addAnimationIdentifier, getToggleAnimationTooltip } from './utilities';

import { ICON_SIZE } from 'constants';

import PrismaIconButton from '../../../Sidebar/components/PrismaIconButton/PrismaIconButton';

import './layerAccordion.scss';

const ToggleAnimation = ({ disabled, isOpen, layerId }) => {
  const decoratedOnClick = useAccordionButton(addAnimationIdentifier(layerId));

  return (
    <PrismaIconButton
      icon={isOpen && !disabled ? 'PiShootingStarFill' : 'PiShootingStar'}
      disabled={disabled}
      tooltipMessage={getToggleAnimationTooltip(disabled, isOpen)}
      className="toggle-control"
      onClick={e => {
        e.stopPropagation();
        decoratedOnClick();
      }}
      iconSize={ICON_SIZE}
    />
  );
};

export default ToggleAnimation;
