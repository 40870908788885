export const projectsPath = '/api/v1/projects';

export const projectsMethods = ['addAssets', 'copyAssets', 'create', 'find', 'findAll', 'get', 'patch', 'setAssets'];

export const projectsClient = client => {
  const connection = client.get('connection');

  client.use(projectsPath, connection.service(projectsPath), {
    methods: projectsMethods,
  });
};
