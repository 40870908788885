// Overwritten from FabricJS to support multiple scaling modes

export function isLocked(target, by) {
  return target[`lockScaling${by.toUpperCase()}`];
}

export function isTransformCentered(transform) {
  return transform.originX === 'center' && transform.originY === 'center';
}

/**
 * Inspect event and fabricObject properties to understand if the scaling action
 * @param {Event} eventData from the user action
 * @param {FabricObject} fabricObject the fabric object about to scale
 * @return {Boolean} true if scale is proportional
 */
export function scaleIsProportional(eventData, fabricObject) {
  const { canvas } = fabricObject;
  const uniformIsToggled = eventData[canvas.uniScaleKey];
  return (canvas.uniformScaling && !uniformIsToggled) || (!canvas.uniformScaling && uniformIsToggled);
}

/**
 * Inspect fabricObject to understand if the current scaling action is allowed
 * @param {FabricObject} fabricObject the fabric object about to scale
 * @param {String} by 'x' or 'y' or ''
 * @param {Boolean} scaleProportionally true if we are trying to scale proportionally
 * @return {Boolean} true if scaling is not allowed at current conditions
 */
export function scalingIsForbidden(fabricObject, by, scaleProportionally) {
  const lockX = isLocked(fabricObject, 'x');
  const lockY = isLocked(fabricObject, 'y');
  if (lockX && lockY) {
    return true;
  }
  if (!by && (lockX || lockY) && scaleProportionally) {
    return true;
  }
  if (lockX && by === 'x') {
    return true;
  }
  if (lockY && by === 'y') {
    return true;
  }
  return false;
}

/**
 * Resolves origin value relative to center
 * @private
 * @param {TOriginX | TOriginY} originValue originX / originY
 * @returns number
 */
export function resolveOrigin(originValue) {
  const originOffset = {
    left: -0.5,
    top: -0.5,
    center: 0,
    bottom: 0.5,
    right: 0.5,
  };
  return typeof originValue === 'string' ? originOffset[originValue] : originValue - 0.5;
}

/**
 * Inverts origin value relative to center
 */
export function invertOrigin(origin) {
  return -resolveOrigin(origin) + 0.5;
}
