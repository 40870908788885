import React, { useEffect, useState } from 'react';
import { Button, Col, Modal, Row, Stack } from '@akiunlocks/perseus-ui-components';

import { DEFAULT_INTERSTITIAL_BANNER_SIZE } from 'constants/canvas';
import { PRODUCT_SUBTYPES, PRODUCT_SUBTYPE_OPTIONS, PRODUCT_TYPES, PRODUCT_TYPE_OPTIONS } from 'constants/compositions';

import {
  checkDisabledProduct,
  checkDisabledProductSubtype,
  getDefaultSubtype,
  getDefaultType,
} from 'components/ProjectPreview/CompositionModal/utilities';

import Sizes from '../../Sizes';
import NameInput from 'components/Sizes/NameInput/NameInput';
import useNameInput from 'components/Sizes/NameInput/useNameInput';
import RadioOption from './RadioOption';

import './CompositionModal.scss';

const CompositionModal = ({
  handleClose,
  handleAccept,
  projectSize,
  projectSizes,
  projectType,
  saveInProgress,
  show,
  sizes,
}) => {
  const [selectedSize, setSelectedSize] = useState(null);
  const [selectedType, setSelectedType] = useState(PRODUCT_TYPES.DISPLAY);
  const [selectedSubtype, setSelectedSubtype] = useState(PRODUCT_SUBTYPES.STATIC_BANNER);
  const { name, inputEvents, reset, validationError } = useNameInput({ projectSize, projectSizes });
  const isInterstitial = selectedSubtype === PRODUCT_SUBTYPES.INTERSTITIAL_BANNER;
  const editingInterstitial = projectSize && isInterstitial;

  useEffect(() => {
    if (!show) {
      return;
    }

    // the default values change according to the projectType
    let selectedType = getDefaultType(projectType);
    let selectedSubtype = getDefaultSubtype(selectedType);
    let selectedSize = null;

    if (projectSize?.productType && projectSize?.productSubtype) {
      // use current values if projectSize is present and have type and subtype
      selectedType = projectSize.productType;
      selectedSubtype = projectSize.productSubtype;
    }
    if (projectSize?.size) {
      selectedSize = projectSize.size;
    } else if (projectSize?.width && projectSize?.height) {
      selectedSize = `${projectSize.width}x${projectSize.height}`;
    }

    setSelectedType(selectedType);
    setSelectedSubtype(selectedSubtype);
    setSelectedSize(selectedSize);
  }, [projectType, projectSize, show]);

  const onClose = () => {
    reset();
    handleClose();
  };

  return (
    <Modal className="composition-modal" show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>{projectSize ? 'Edit composition' : 'New composition'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Stack gap={3}>
          <Col className="form-col">
            <div className="fs-7">Name</div>
            <NameInput name={name} inputEvents={inputEvents} validationError={validationError} />
          </Col>
          <Col className="form-col">
            <div className="fs-7">Product</div>
            <div>
              {PRODUCT_TYPE_OPTIONS.map(option => (
                <RadioOption
                  checkDisabledFn={value => checkDisabledProduct(projectType, value, editingInterstitial)}
                  group="product-type"
                  key={`product-type-${option.value}`}
                  onChange={value => {
                    setSelectedType(value);
                    setSelectedSubtype(getDefaultSubtype(value));
                    if (isInterstitial) {
                      // reset size when changing from interstitial banner
                      setSelectedSize(null);
                    }
                  }}
                  option={option}
                  selectedValue={selectedType}
                />
              ))}
            </div>
          </Col>
          {selectedType !== PRODUCT_TYPES.DOOH && ( // DOOH only has one subtype
            <Col className="form-col">
              <div className="fs-7">Format</div>
              <div>
                {PRODUCT_SUBTYPE_OPTIONS[selectedType].map(option => (
                  <RadioOption
                    checkDisabledFn={value => checkDisabledProductSubtype(value, !!projectSize, editingInterstitial)}
                    group="product-subtype"
                    key={`product-subtype-${option.value}`}
                    onChange={value => {
                      if (isInterstitial) {
                        // reset size when changing from interstitial banner
                        setSelectedSize(null);
                      } else if (value === PRODUCT_SUBTYPES.INTERSTITIAL_BANNER) {
                        setSelectedSize(DEFAULT_INTERSTITIAL_BANNER_SIZE);
                      }
                      setSelectedSubtype(value);
                    }}
                    option={option}
                    selectedValue={selectedSubtype}
                  />
                ))}
              </div>
            </Col>
          )}
          {!isInterstitial && (
            <Col className="form-col">
              <div className="fs-7">Size</div>
              <Sizes data={sizes} disabled={!!projectSize} id="sizes" onChange={setSelectedSize} value={selectedSize} />
            </Col>
          )}
        </Stack>
      </Modal.Body>
      <Modal.Footer>
        <Row className="flex justify-content-between w-100">
          <Col>
            <Button variant="transparent" className="border border-secondary" onClick={onClose}>
              Cancel
            </Button>
          </Col>
          <Col className="saveButton">
            <Button
              variant="primary"
              disabled={(selectedSize === null && !isInterstitial) || !!validationError}
              loading={saveInProgress}
              onClick={() => {
                handleAccept({
                  name,
                  size: selectedSize,
                  productType: selectedType,
                  productSubtype: selectedSubtype,
                });
              }}
            >
              {projectSize ? 'Save' : 'Create'}
            </Button>
          </Col>
        </Row>
      </Modal.Footer>
    </Modal>
  );
};

export default CompositionModal;
