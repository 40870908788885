import React from 'react';
import classnames from 'classnames';
import { observer } from 'mobx-react';
import { Col, Nav, Row, Tab } from 'react-bootstrap';
import capitalize from 'lodash/capitalize';

import { Icon, ItemButton, FileSelector } from '@akiunlocks/perseus-ui-components';
import { animationTypes } from '@prisma/lib/src/utils/types';
import { ACTIVE_SELECTION, PRISMA_CIRCLE, PRISMA_GROUP, PRISMA_RECT, PRISMA_TEXT } from '@prisma/lib/src/constants';

import {
  ANIMATABLE_LAYERS,
  DELETABLE_LAYERS,
  EDIT_TAB,
  GROUP_DISABLED_ANIMATIONS,
  LIBRARY_TAB,
} from 'constants/editor';

import AssetLibrary from './components/AssetLibrary';
import Delete from './components/PropertiesPannel/components/Delete';
import PrismaIconButton from './components/PrismaIconButton';
import PropertiesPanel from './components/PropertiesPannel';
import SidebarPanel from './components/SidebarPanel';
import SidebarItem from './components/SidebarPanel/SidebarItem';

import useSidebar from './Sidebar.hooks';

import './Sidebar.scss';

const animationTypesIcons = {
  [animationTypes.opacity]: 'droplet-half',
  [animationTypes.position]: 'arrows-move',
  [animationTypes.scale]: 'fa-expand-arrows-alt',
  [animationTypes.rotation]: 'arrow-clockwise',
  [animationTypes.hide]: 'eye',
};

const Sidebar = observer(() => {
  const {
    uploadFiles,
    activeTab,
    addTextbox,
    addRect,
    addCircle,
    sidebarRef,
    draggedOver,
    setActiveTab,
    activeObject,
    addAnimation,
    existingAnimations,
    isTimelineLayersTab,
    isGroupSelected,
  } = useSidebar();

  const activeObjectType = activeObject?.type;
  const isActiveSelection = activeObjectType === ACTIVE_SELECTION;
  const isGroup = activeObjectType === PRISMA_GROUP;
  const showDelete = DELETABLE_LAYERS.includes(activeObjectType) || isActiveSelection;

  const contentClasses = classnames('sidebar-content', 'px-0', {
    active: !!activeTab,
  });

  const SIDEBAR_BUTTONS = [
    {
      dataType: PRISMA_TEXT,
      icon: 'fonts',
      label: 'Text',
      onClick: addTextbox,
    },
    {
      dataType: PRISMA_RECT,
      icon: 'square',
      label: 'Rectangle',
      onClick: addRect,
    },
    {
      dataType: PRISMA_CIRCLE,
      icon: 'circle',
      label: 'Circle',
      onClick: addCircle,
    },
  ];

  return (
    <Tab.Container mountOnEnter activeKey={activeTab} onSelect={setActiveTab}>
      <Row className="sidebar m-0 flex-nowrap" ref={sidebarRef}>
        <Col className="sidebar-buttons flex-grow-0 px-0">
          <Nav variant="pills" className="flex-column">
            <Nav.Item>
              <Nav.Link eventKey={LIBRARY_TAB}>
                <Icon name="bi-images" size={26} />
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey={EDIT_TAB}>
                <Icon name="pencil-square" size={22} />
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Col>
        <Col className={contentClasses}>
          <Tab.Content>
            <Tab.Pane eventKey={LIBRARY_TAB}>
              <SidebarPanel title="Asset Library">
                <SidebarItem show={draggedOver}>
                  <FileSelector
                    multiple
                    className="px-0 asset-upload"
                    accept={['image/*']}
                    onNewFiles={uploadFiles}
                    filesListConfiguration={{ show: false }}
                  />
                </SidebarItem>
                <SidebarItem show={!draggedOver}>
                  <AssetLibrary onUpload={({ target }) => uploadFiles(Array.from(target.files))} />
                </SidebarItem>
              </SidebarPanel>
              <SidebarPanel>
                {SIDEBAR_BUTTONS.map(({ dataType, icon, label, onClick }) => (
                  <SidebarItem key={dataType}>
                    <ItemButton
                      className="w-100 justify-content-center text-secondary border border-secondary"
                      data-type={dataType}
                      draggable
                      icon={icon}
                      iconSize={22}
                      key={dataType}
                      label={label}
                      onClick={onClick}
                      variant="light"
                    />
                  </SidebarItem>
                ))}
              </SidebarPanel>
            </Tab.Pane>
            <Tab.Pane eventKey={EDIT_TAB}>
              <PropertiesPanel
                activeObject={activeObject}
                isGroupSelected={isGroupSelected}
                isTimelineLayersTab={isTimelineLayersTab}
              />
              <SidebarPanel title="Animations" show={activeObject && ANIMATABLE_LAYERS.includes(activeObject.type)}>
                <SidebarItem disabled={isTimelineLayersTab || (isActiveSelection && !activeObject?.isGroup)}>
                  <div className="anim-row">
                    {Object.values(animationTypes).map(t => {
                      // TODO: implement group scale and rotation
                      const disabled = isGroup && GROUP_DISABLED_ANIMATIONS.includes(t);
                      const hasAnimationType = existingAnimations.includes(t);
                      return (
                        <PrismaIconButton
                          tooltipMessage={`${capitalize(t)}${disabled ? ' (not supported yet)' : ''}`}
                          disabled={disabled}
                          key={t}
                          icon={animationTypesIcons[t]}
                          className={`anim-type-control ${hasAnimationType ? 'existing' : ''}`}
                          onClick={() => {
                            if (hasAnimationType) return;
                            addAnimation(activeObject, t);
                          }}
                          iconSize={18}
                        />
                      );
                    })}
                  </div>
                </SidebarItem>
              </SidebarPanel>
              <Delete show={showDelete} />
            </Tab.Pane>
          </Tab.Content>
        </Col>
      </Row>
    </Tab.Container>
  );
});

export default Sidebar;
