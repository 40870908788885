import { makeAutoObservable } from 'mobx';

import { fabricClone } from '@prisma/lib/src/utils/helpers';
import { PRISMA_GUIDELINE } from '@prisma/lib/src/constants';

import PrismaHorizontalGuideline from '@prisma/lib/src/prismaHorizontalGuideline';
import PrismaVerticalGuideline from '@prisma/lib/src/prismaVerticalGuideline';

import { RULER_DROPDOWN_EVENTS } from 'constants/editor';

class GuidelineStore {
  hidden = false;
  locked = false;

  constructor(editor) {
    makeAutoObservable(this);
    this.editor = editor;
  }

  /**
   * Sets the visibility of guidelines.
   * @param {boolean} hidden - Indicates whether the guidelines should be hidden.
   */
  setHidden(hidden) {
    this.hidden = hidden;
  }

  /**
   * Sets the lock state of guidelines.
   * @param {boolean} locked - Indicates whether the guidelines should be locked.
   */
  setLocked(locked) {
    this.locked = locked;
  }

  /**
   * Adds a vertical or horizontal guideline based on the specified event key.
   * @param {string} eventKey - The key corresponding to the type of guideline to add.
   */
  addGuideline(eventKey) {
    const { canvas } = this.editor;
    let guideline;

    switch (eventKey) {
      case RULER_DROPDOWN_EVENTS.ADD_VERTICAL_LINE:
        const offsetLeft = canvas.offsetLeft;
        guideline = new PrismaVerticalGuideline({ left: offsetLeft, offsetLeft });
        break;
      case RULER_DROPDOWN_EVENTS.ADD_HORIZONTAL_LINE:
        const offsetTop = canvas.offsetTop;
        guideline = new PrismaHorizontalGuideline({ top: offsetTop, offsetTop });
        break;
      default:
        // if the event key is not recognized, do nothing
        return;
    }

    canvas.add(guideline);
    canvas.overlayImage.addWithUpdate(fabricClone(guideline));

    // lock the new guideline if guidelines are locked
    if (this.locked) {
      guideline.lockMovement(true);
    }
  }

  /**
   * Retrieves all guidelines on the canvas.
   * @return {fabric.Object[]} An array containing all guideline objects.
   */
  getGuidelines() {
    return this.editor.canvas.getObjects(PRISMA_GUIDELINE);
  }

  /**
   * Retrieves all overlay guidelines on the canvas.
   * @return {fabric.Object[]} An array containing all overlay guideline objects.
   */
  getOverlayGuidelines() {
    const guidelines = this.getGuidelines();
    const guidelinesIds = guidelines.map(g => g.id);
    return this.editor.canvas.overlayImage
      .getObjects()
      .filter(o => o.type === PRISMA_GUIDELINE && guidelinesIds.includes(o.id));
  }

  /**
   * Locks or unlocks all guidelines on the canvas.
   * @param {boolean} lock - Indicates whether to lock or unlock the guidelines.
   */
  lockGuidelines(lock) {
    this.getGuidelines().forEach(g => g.lockMovement(lock));
    this.editor.canvas.renderAll();
    this.setLocked(lock);
  }

  /**
   * Removes guidelines from the canvas.
   * @param {boolean} removeFromCanvas - Whether to remove the guideline objects from the canvas.
   * @param {boolean} removeFromOverlay - Whether to remove the guideline objects from the overlay.
   */
  removeGuidelines(removeFromCanvas = true, removeFromOverlay = true) {
    const { canvas } = this.editor;
    if (removeFromOverlay) {
      const overlayGuidelines = this.getOverlayGuidelines();
      canvas.overlayImage.remove(...overlayGuidelines);
      canvas.overlayImage.removeWithUpdate();
    }
    if (removeFromCanvas) {
      canvas.remove(...this.getGuidelines());
      canvas.renderAll();
    }
  }

  /**
   * Shows or hides guidelines on the canvas.
   * @param {boolean} show - Indicates whether to show or hide the guidelines.
   */
  showGuidelines(show) {
    if (!show) {
      this.removeGuidelines(false, true);
    }
    this.getGuidelines().forEach(g => {
      g.set({ visible: show });
      if (show) {
        this.editor.canvas.overlayImage.addWithUpdate(fabricClone(g));
      }
    });
    this.editor.canvas.renderAll();
    this.setHidden(!show);
  }
}

export default GuidelineStore;
