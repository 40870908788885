import { action, makeObservable, observable } from 'mobx';

import ObjectStore from './Object.store';

class MaskStore extends ObjectStore {
  lockedPosition = false;

  constructor() {
    super();
    makeObservable(this, {
      ...this.props,
      lockedPosition: observable,
      setLockedPosition: action,
    });
  }

  initialize(mask) {
    super.initialize(mask);

    this.lockedPosition = !!mask.lockedPosition;
  }

  onObjectModified(event) {
    super.onObjectModified(event);

    const {
      transform: { target },
    } = event;
    target.canvas.updateMaskAnimations(target);
  }

  setLockedPosition(lockedPosition) {
    this.lockedPosition = lockedPosition;
  }
}

export default MaskStore;
