import { makeObservable, observable } from 'mobx';

import ShapeStore from './Shape.store';

class RectStore extends ShapeStore {
  rx = 0;
  ry = 0;
  uniformRadius = true;

  constructor() {
    super();
    makeObservable(this, {
      ...this.props,
      ...this.shapeProps,
      rx: observable,
      ry: observable,
      uniformRadius: observable,
    });
  }

  initialize(rect) {
    super.initialize(rect);

    this.rx = rect.rx;
    this.ry = rect.ry;
    this.uniformRadius = rect.uniformRadius;
  }
}

export default RectStore;
