import React from 'react';

import { checkIfCanAddKeyframe, checkIfKeyframeIsActive } from './utilities';

import { filterGroupAnimations } from '../../../../utils/animations';

import AnimationControls from './AnimationControls';
import AnimationValuesContainer from './AnimationValuesContainer';
import KeyframeControls from './KeyframeControls';

import './layerAccordion.scss';

const AnimationsList = ({ activeKeyframe, activeLayer, currentTimeLineValue, hasMultipleSelected, layer }) => {
  return filterGroupAnimations(layer.animations).map(a => {
    const isActiveKeyframe = checkIfKeyframeIsActive(layer.id, a.type, activeKeyframe, hasMultipleSelected);
    const canAddKeyframe = checkIfCanAddKeyframe(currentTimeLineValue, a);

    return (
      <div className="anim-wrapper" key={a.type}>
        <div className="anim-row">
          <div className="left-block">
            <div className="anim-title type">
              <span>{a.type}</span>
            </div>
            <AnimationValuesContainer
              activeKeyframe={activeKeyframe}
              animationType={a.type}
              disabled={!isActiveKeyframe}
              layer={layer}
              values={layer.id === activeLayer?.id ? activeLayer.target : layer.target}
            />
          </div>
          <KeyframeControls
            activeKeyframe={activeKeyframe}
            animation={a}
            canAddKeyframe={canAddKeyframe}
            isActiveKeyframe={isActiveKeyframe}
            layer={layer}
          />
        </div>
        <AnimationControls animation={a} layer={layer} />
      </div>
    );
  });
};

export default AnimationsList;
