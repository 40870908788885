import { action, observable } from 'mobx';
import { PrismaGroup } from '@prisma/lib';
import { getOriginPosition, getUnrotatedPosition } from '@prisma/lib/src/utils/helpers';
import { ANCHORS_X, ANCHORS_Y, UNITS } from '@prisma/lib/src/constants/objects';

import { findGroup, getObjectOffset } from './utilities';

class ObjectStore {
  left = 0;
  top = 0;
  unrotatedLeft = 0;
  unrotatedTop = 0;
  width = 0;
  height = 0;
  angle = 0;
  objectOpacity = 100;
  hide = false;
  uniformScaling = false;
  events = new Set();
  originX = ANCHORS_X.LEFT;
  originY = ANCHORS_Y.TOP;

  leftUnit = UNITS.PIXELS;
  widthUnit = UNITS.PIXELS;
  topUnit = UNITS.PIXELS;
  heightUnit = UNITS.PIXELS;

  // observable properties to use in inherited classes
  props = {
    left: observable,
    top: observable,
    unrotatedLeft: observable,
    unrotatedTop: observable,
    width: observable,
    height: observable,
    angle: observable,
    objectOpacity: observable,
    hide: observable,
    uniformScaling: observable,
    events: observable,
    originX: observable,
    originY: observable,
    leftUnit: observable,
    topUnit: observable,
    widthUnit: observable,
    heightUnit: observable,
    initialize: action,
    addEvents: action,
    onObjectScaling: action,
    onObjectMoving: action,
    onObjectRotating: action,
    setAngle: action,
    setAttribute: action,
    updatePosition: action,
  };

  initialize(object) {
    this.width = Math.floor(object.width * object.scaleX);
    this.height = Math.floor(object.height * object.scaleY);
    this.angle = object.angle;
    this.objectOpacity = object.objectOpacity;
    this.hide = object.hide;
    this.uniformScaling = object.uniformScaling;
    this.originX = object.originX;
    this.originY = object.originY;

    // set pixels when unit is not present
    this.leftUnit = object.leftUnit || UNITS.PIXELS;
    this.topUnit = object.topUnit || UNITS.PIXELS;
    this.widthUnit = object.widthUnit || UNITS.PIXELS;
    this.heightUnit = object.heightUnit || UNITS.PIXELS;

    this.updatePosition(object);

    this.addEvents(object, {
      scaling: this.onObjectScaling,
      moving: this.onObjectMoving,
      rotating: this.onObjectRotating,
      modified: this.onObjectModified,
    });
  }

  addEvents(object, eventsObj) {
    Object.entries(eventsObj).forEach(([name, fn]) => {
      if (this.events.has(name)) {
        object.off(name); // This removes all the events asociated with that 'name'
      }
      this.events.add(name);
      object.on(name, fn);
    });
  }

  removeEvents(object, eventNames) {
    eventNames.forEach(([name]) => {
      if (this.events.has(name)) {
        object.off(name); // This removes all the events asociated with that 'name'
        this.events.delete(name);
      }
    });
  }

  onObjectScaling = event => {
    const {
      transform: { target },
    } = event;
    this.width = Math.floor(target.width * target.scaleX);
    this.height = Math.floor(target.height * target.scaleY);
    this.updatePosition(target);
  };

  onObjectMoving = event => {
    const {
      transform: { target },
    } = event;

    this.updatePosition(target);
  };

  onObjectRotating = event => {
    this.angle = Math.floor(event.transform.target.angle);
    this.updatePosition(event.transform.target);
  };

  onObjectModified = event => {
    const object = event.transform?.target;

    if (!object) {
      return;
    }

    // if object belongs to a group, calculate the new group size
    const group = object.canvas.layers.find(({ id }) => id === object.groupId)?.target;
    if (group) {
      // trigger edit layer to recalculate group animations if needed
      // and reset size
      object.canvas.editLayer(object.id, {});
      PrismaGroup.resetSize(group);
    }
  };

  setAngle(angle) {
    this.angle = angle;
  }

  setAttribute(attribute, value) {
    this[attribute] = value;
  }

  setUnit(prop, unit) {
    this.setAttribute(`${prop}Unit`, unit);
  }

  updatePosition = object => {
    const { offsetLeft, offsetTop } = getObjectOffset(object);
    this.left = Math.floor(object.left - offsetLeft);
    this.top = Math.floor(object.top - offsetTop);

    let position;
    if (object.isGroup) {
      const group = findGroup(object.getObjects());
      const { originX, originY } = group;
      position = getOriginPosition(object, originX, originY);
    } else {
      position = getUnrotatedPosition(object);
    }

    this.unrotatedLeft = Math.floor(position.x - offsetLeft);
    this.unrotatedTop = Math.floor(position.y - offsetTop);
  };
}

export default ObjectStore;
