import { useCallback } from 'react';
import PropTypes from 'prop-types';

import { useRootStore } from 'store';
import { getUnitValue } from 'utils/responsive';

export const useCanvas = property => {
  const { editor } = useRootStore();
  const { canvas } = editor;
  const { _bannerHeight, _bannerWidth } = canvas;

  const getXValue = useCallback(
    (value, unit) => {
      return getUnitValue(value, _bannerWidth, unit);
    },
    [_bannerWidth],
  );

  const getYValue = useCallback(
    (value, unit) => {
      return getUnitValue(value, _bannerHeight, unit);
    },
    [_bannerHeight],
  );

  const getRelativeValue = useCallback(
    (value, unit) => {
      if (property === 'left' || property === 'width') {
        return getXValue(value, unit);
      }
      return getYValue(value, unit);
    },
    [getXValue, getYValue, property],
  );

  return {
    bannerHeight: _bannerHeight,
    bannerWidht: _bannerWidth,
    getRelativeValue,
    getXValue,
    getYValue,
  };
};

useCanvas.propTypes = {
  /** Prop */
  property: PropTypes.oneOf(['left', 'top', 'width', 'height']),
};
