import React from 'react';
import PropTypes from 'prop-types';

import { Checkbox, Form } from '@akiunlocks/perseus-ui-components';

import ColorPicker from '../../ColorPicker';
import LabeledInput from '../../LabeledInput';

import './CheckboxColorPicker.scss';

const CheckboxColorPicker = ({
  checkboxDisabled,
  checkboxVisible,
  checked,
  id,
  inline,
  inputs,
  label,
  onCheckboxChange,
  onColorChange,
  value,
}) => {
  return (
    <div>
      {!inline && <Form.Label>{label}</Form.Label>}
      <div className="d-flex gap-2 align-items-center">
        <div className="d-flex gap-1 align-items-center">
          {checkboxVisible && (
            <Checkbox checked={checked} disabled={checkboxDisabled} id={id} onChange={onCheckboxChange} />
          )}
          {inline && <Form.Label className="mb-0">{label}</Form.Label>}
          <ColorPicker label={label} value={value} onChange={onColorChange} />
        </div>
        {!!inputs?.length && (
          <div className="d-flex gap-1">
            {inputs.map(({ id, label, min, onChange = () => {}, value = '' }) => (
              <LabeledInput
                className="picker-input"
                gap={1}
                id={id}
                key={id}
                label={label}
                size="xs"
                controlProps={{
                  min: !isNaN(min) ? min : undefined,
                  onChange,
                  placeholder: '',
                  type: 'number',
                  value,
                }}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

CheckboxColorPicker.propTypes = {
  /** Flag to indicate if checkbox is disabled or not */
  checkboxDisabled: PropTypes.bool,
  /** Flag to indicate if checkbox is visible or not */
  checkboxVisible: PropTypes.bool,
  /** Flag to indicate if checkbox is checked or not */
  checked: PropTypes.bool,
  /** Checkbox id */
  id: PropTypes.string,
  /** Flag to indicate if use inline display */
  inline: PropTypes.bool,
  /** Optional inputs to show next to the picker */
  inputs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      label: PropTypes.string,
      min: PropTypes.number,
      onChange: PropTypes.func,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
  ),
  /** Color picker label */
  label: PropTypes.string,
  /** Action to be called when checkbox value changes */
  onCheckboxChange: PropTypes.func,
  /** Action to be called when color picker value changes */
  onColorChange: PropTypes.func,
  /** Color picker value */
  value: PropTypes.string,
};

CheckboxColorPicker.defaultProps = {
  checkboxDisabled: false,
  checkboxVisible: true,
  checked: false,
  id: '',
  inline: false,
  label: '',
  onCheckboxChange: () => {},
  onColorChange: () => {},
  value: '',
};

export default CheckboxColorPicker;
