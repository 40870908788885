import React from 'react';
import { FormLabel } from 'react-bootstrap';
import { Slider } from '@akiunlocks/perseus-ui-components';

import './Opacity.scss';

const Opacity = ({ opacity = 0, onChange = () => {} }) => {
  return (
    <>
      <FormLabel className="mb-2" htmlFor="opacity">
        Opacity
      </FormLabel>
      <Slider
        className="opacity-slider"
        name="opacity"
        value={opacity}
        marks={{
          0: '0%',
          100: '100%',
        }}
        min={0}
        max={100}
        onChange={onChange}
      />
    </>
  );
};

export default React.memo(Opacity);
