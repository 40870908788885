import isEmpty from 'lodash/isEmpty';

import { ASSET, FOLDER, FOLDERS } from 'constants/assets';
import { ALPHANUMERIC_AND_SPECIAL_CHARACTERS, ENTER_NAME } from 'constants/validations';

import { validateAlphanumericAndSpecialCharacters } from 'utils/strings';

// TreeModel helpers

/**
 * Finds a tree model node by id.
 * @param {object} node - Tree model node to start the search.
 * @param {string} id - Node id.
 * @return {object} Object if found, else undefined.
 */
export function findById(node, id) {
  return node.first(n => n.model.id === id);
}

/**
 * Gets a tree model node path string.
 * @param {object} node - Tree model node.
 * @return {string} Path string.
 */
export function getNodePathStr(node) {
  return node
    .getPath()
    .map(n => n.model.id)
    .join('/');
}

/**
 * Sorts a tree model node alphabetically and recursively.
 * @param {object} node - Tree model node.
 * @return {object} Sorted node.
 */
export function sortNodeTree(node) {
  if (node.type === ASSET) {
    return node;
  }
  // directory
  const children = node.children
    .slice()
    .sort((a, b) => b.type.localeCompare(a.type) || a.name.localeCompare(b.name))
    .map(sortNodeTree);
  return {
    ...node,
    children,
  };
}

/**
 * Validates if a node can be moved to a given parent.
 * @param {object} sourceNode - Source node.
 * @param {object} parentNode - Parent to move to.
 * @return {boolean}
 */
export function validateMove(sourceNode, parentNode) {
  if (!sourceNode || !parentNode) {
    return false;
  }

  // prevent folder to be set on a child folder of its.
  // destination can not be child of the source.
  const parentNodePathId = getNodePathStr(parentNode);
  const sourceNodePathId = getNodePathStr(sourceNode);
  if (parentNodePathId.startsWith(sourceNodePathId)) {
    return false;
  }

  // prevent to move to same folder
  const sourceFolder = getParentFolderFromImageUrl(sourceNode.model.imageUrl);
  const destinationFolder = parentNode.model.imageUrl || FOLDERS.ASSETS;
  if (sourceFolder === destinationFolder) {
    return false;
  }

  return true;
}

// TreeApi helpers

/**
 * Renames a node, scrolls to it, and select it.
 * @param {object} node - Node.
 * @param {string} name - New name.
 */
export function renameAndSelect(node, name) {
  node.submit(name);
  node.tree.scrollTo(node.data.id);
  node.select();
}

// Assets tree format helpers

/**
 * Given an S3 key split in parts, and the index of one of those parts, builds a folder asset that can be used in a tree structure.
 * @param {array} pathParts - Parts of the S3 path (split by slashes).
 * @param {number} index - Index of the current part.
 * @return {object} Folder node.
 */
function buildFolderNode(pathParts, index) {
  const part = pathParts[index];
  const imageUrl = `${pathParts.slice(0, index + 1).join('/')}/`;
  return {
    children: [],
    id: imageUrl,
    imageUrl,
    name: part,
    partName: part,
    size: 0,
    type: FOLDER,
  };
}

/**
 * Maps the properties of existing asset in the project (if found) into an asset obtained from S3.
 * @param {object} node - Tree node.
 * @param {array} assets - Assets list to search.
 * @param {object} currentAsset - Current asset (from S3) being analized.
 * @return {object} Node with updated properties.
 */
function mapExistingAsset(node, assets, currentAsset) {
  const existingAsset = assets.find(a => a.imageUrl === node.imageUrl);
  if (existingAsset) {
    // map if exact match is found in project assets
    node = { ...node, ...existingAsset };
  } else {
    // if node is not present in assets by imageUrl, it means it is being created, so we add loading property
    node.loading = currentAsset.loading;
  }
  return node;
}

/**
 * Given a list of assets, transform it to tree structure based on S3 path (imageUrl).
 * @param {array} assets - List of assets.
 * @return {array} Same assets but organized in a tree form.
 */
export function assetsToTree(assets) {
  if (!assets?.length || assets.every(asset => !asset.imageUrl)) {
    return [];
  }

  const tree = { name: 'root', children: [] };

  assets.forEach(asset => {
    const pathParts = asset.imageUrl.split('/');
    let currentNode = tree;

    pathParts
      .filter(p => p)
      .forEach((part, index) => {
        let existingNode = currentNode.children.find(node => node.partName === part);
        if (!existingNode) {
          const isFolder = !part.includes('.'); // folders should not have dots on the name
          if (isFolder) {
            existingNode = buildFolderNode(pathParts, index);
          } else {
            existingNode = { partName: part, ...asset };
          }
          existingNode = mapExistingAsset(existingNode, assets, asset);
          currentNode.children.push(existingNode);
        }

        if (asset.type === FOLDER) {
          existingNode.children = existingNode.children || [];
        }

        currentNode = existingNode;
      });
  });
  // root -> assets -> children
  const assetsTree = tree.children[0].children;

  return assetsTree;
}

/**
 * Given an imageUrl from an asset, gets the folder that contains it.
 * @param {string} imageUrl - Asset image url.
 * @param {string} type - Asset type (asset or folder).
 * @return {string} Asset folder.
 */
export function getParentFolderFromImageUrl(imageUrl, type = ASSET) {
  // remove image part of the path to get folder
  const parts = imageUrl.split('/');
  const slice = type === ASSET ? -1 : -2;
  return `${parts.slice(0, slice).join('/')}/`;
}

/**
 * Given an imageUrl from an asset, gets the file name part.
 * @param {string} imageUrl - Asset image url.
 * @return {string} File name.
 */
export function getFileNameFromImageUrl(imageUrl) {
  // get only the file name part of the path
  const parts = imageUrl.split('/');
  return parts[parts.length - 1];
}

/**
 * Given an imageUrl gets the folder name.
 * @param {string} imageUrl - Asset image url.
 * @return {string} Folder name.
 */
export function getFolderNameFromImageUrl(imageUrl) {
  const parts = imageUrl.split('/');
  return `${parts[parts.length - 2]}/`;
}

/**
 * Given an asset gets the file or folder name depending on asset type.
 * @param {object} asset - Asset.
 * @return {string} File or folder name.
 */
export function getAssetName(asset) {
  const { imageUrl, type } = asset;
  return type === ASSET ? getFileNameFromImageUrl(imageUrl) : getFolderNameFromImageUrl(imageUrl);
}

/**
 * Checks if asset object is a folder.
 * @param {object} asset - Asset.
 * @return {boolean}  True if asset has folder type, else false.
 */
export function isFolderType(asset) {
  return asset.type === FOLDER;
}

/**
 * Sanitize name for a file or folder in S3.
 * This is the same logic applied in s3.hooks for sanitizeName.
 * They should return the same result.
 * @param {string} name - File or folder name to sanitize.
 * @return {string} Sanitized name.
 */
function sanitizeName(name) {
  const sanitized = name.trim().toLowerCase();
  const allowedSet = new Set("abcdefghijklmnopqrstuvwxyz0123456789()-_'@");
  const replacementChar = '_';
  // replaces any character not present in allowedSet for an underscore
  return sanitized
    .split('')
    .map(char => (allowedSet.has(char) ? char : replacementChar))
    .join('');
}

/**
 * Sanitize name for a folder in S3.
 * @param {string} name - Folder name to sanitize.
 * @return {string} Sanitized name.
 */
export function sanitizeFolderName(name) {
  return sanitizeName(name);
}

/**
 * Sanitize name for a file in S3.
 * @param {string} name - File name to sanitize.
 * @return {string} Sanitized name.
 */
export const sanitizeFileName = name => {
  const parts = name.split('.');
  const fileName = parts[0];
  const extension = parts[1];
  const sanitized = sanitizeName(fileName);
  return `${sanitized}.${extension}`; // add original extension
};

/**
 * Checks if folder name is valid.
 * @param {string} name - Folder name.
 * @return {object} Object with validation result and error message if not valid.
 */
export function validateFolderName(name) {
  name = name.trim();
  const result = { valid: false, error: null };
  if (isEmpty(name)) {
    result.error = ENTER_NAME;
    return result;
  }
  if (!validateAlphanumericAndSpecialCharacters(name)) {
    result.error = ALPHANUMERIC_AND_SPECIAL_CHARACTERS;
    return result;
  }
  result.valid = true;
  return result;
}

/**
 * Given an asset imageUrl, gets the parent folder excluding the initial 'assets/' folder.
 * @param {string} imageUrl - Image url.
 * @return {string} Parent folder.
 */
function getAssetParentFolder(imageUrl) {
  return getParentFolderFromImageUrl(imageUrl.replace(FOLDERS.ASSETS, ''));
}

/**
 * Given a list of assets, builds an array of options to be used in a dropdown.
 * Options are grouped by asset folders.
 * @param {array} assets - Assets list.
 * @return {array} Assets options. They include label, value, and size.
 */
export function buildAssetsOptions(assets) {
  const options = [];
  const ungroupedOptions = [];
  assets
    .filter(({ hide, type }) => type === ASSET && !hide)
    .forEach(({ imageUrl, name, size }) => {
      const parentFolder = getAssetParentFolder(imageUrl);
      const option = { label: name, value: imageUrl, size };
      if (parentFolder === '/') {
        // save ungrouped options for later
        ungroupedOptions.push(option);
        return;
      }
      const group = options.find(g => g.label === parentFolder);
      if (group) {
        group.options.push(option);
      } else {
        options.push({ label: parentFolder, options: [option] });
      }
    });
  // add ungrouped options at the end
  if (ungroupedOptions.length) {
    if (options.length) {
      // if there are other groups, add a group with empty label
      options.push({ label: '', options: ungroupedOptions });
    } else {
      // if there are no groups, add options directly
      options.push(...ungroupedOptions);
    }
  }
  return options;
}

/**
 * Given an asset option, and a selected value, adds the asset folder to the label if needed.
 * @param {object} option - Asset option to evaluate.
 * @param {object} selectedValue - Selected value.
 * @return {string} Option label.
 */
export function getOptionLabelWithFolderIfSelected(option, selectedValue) {
  if (!selectedValue || option.value !== selectedValue) {
    return option.label;
  }
  const parentFolder = getAssetParentFolder(selectedValue);
  if (parentFolder === '/') {
    return option.label;
  }
  return `${parentFolder}${option.label}`;
}
