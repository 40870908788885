import isEmpty from 'lodash/isEmpty';

import { Notification, notify } from '@akiunlocks/perseus-ui-components';
import { PRISMA_MASK_NAME } from '@prisma/lib/src/constants';

import { ALPHANUMERIC_AND_SPECIAL_CHARACTERS, ENTER_NAME } from 'constants/validations';
import {
  hasConsecutiveSpaces,
  startsWithLetter,
  startsWithNumber,
  validateAlphanumericAndSpecialCharacters,
} from './strings';

/**
 * Checks if layer name is valid.
 * @param {string} name - Layer name.
 * @return {object} Object with validation result and error message if not valid.
 */
function getLayerNameValidation(name) {
  name = name.trim();
  const result = { valid: false, error: null };
  if (isEmpty(name)) {
    result.error = 'Layer name is required, please enter a value.';
    return result;
  }
  if (!startsWithLetter(name) && !startsWithNumber(name)) {
    result.error = 'Name should start with an alphanumeric value';
    return result;
  }
  if (hasConsecutiveSpaces(name)) {
    result.error = 'Name cannot have consecutive spaces';
    return result;
  }
  if (!validateAlphanumericAndSpecialCharacters(name)) {
    result.error = ALPHANUMERIC_AND_SPECIAL_CHARACTERS;
    return result;
  }
  if (name.toLowerCase() === PRISMA_MASK_NAME.toLowerCase()) {
    result.error = `'${PRISMA_MASK_NAME}' is a reserved layer name, please enter a different one.`;
    return result;
  }
  result.valid = true;
  return result;
}

/**
 * Validates if new layer name is empty, reserved name, or if it already exists in a list of layers.
 * @param {number} layerId - Layer id that is being updated.
 * @param {string} name - Name to validate.
 * @param {array} layers - List of layers.
 * @return {boolean} True if name is valid, else false.
 */
export function validateLayerName(layerId, name, layers) {
  const validation = getLayerNameValidation(name);
  if (!validation.valid) {
    notify(validation.error, Notification.TYPE.ERROR);
    return false;
  }
  const duplicated = layers.filter(l => l.id !== layerId).find(l => l.name.toLowerCase() === name.toLowerCase());
  if (!!duplicated) {
    notify(
      `There is already a layer with the name '${duplicated.name}', please enter a different value.`,
      Notification.TYPE.ERROR,
    );
    return false;
  }
  return true;
}

/**
 * Validates if project or composition name is a valid name.
 * @param {string} name - Project size name.
 * @return {object} Object with validation result and error message if not valid.
 */
function validateProjectOrCompositionName(name) {
  name = name.trim();
  const result = { valid: false, error: null };
  if (isEmpty(name)) {
    result.error = ENTER_NAME;
    return result;
  }
  result.valid = true;
  return result;
}

/**
 * Validates if project size is a valid name just as we do with project and layer names.
 * It also checks with the list of project sizes names to see if it already exists.
 * @param {string} name - Project size name.
 * @param {array} sizesNamesList - List of project sizes names.
 * @returns {string} Error message if not valid, else undefined.
 */
export function validateProjectSizeName(name, sizesNamesList = []) {
  const validation = validateProjectOrCompositionName(name);
  if (!validation.valid) {
    return validation.error;
  } else if (sizesNamesList.find(sizeName => sizeName.toLowerCase() === name.toLowerCase())) {
    return 'A composition with this name already exists';
  }
  return;
}

/**
 * Validates if project name is a valid name.
 * @param {string} name - Project name.
 * @return {object} Object with validation result and error message if not valid.
 */
export function validateProjectName(name) {
  return validateProjectOrCompositionName(name);
}
