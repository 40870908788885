import React from 'react';
import PropTypes from 'prop-types';

import { ADJUST_OPTIONS } from '@prisma/lib/src/constants/text';
import { Popover, RadioButton, Icon, Form } from '@akiunlocks/perseus-ui-components';

import LabeledInput from '../../../LabeledInput';

import './AdjustTo.scss';

const OPTIONS = [
  {
    label: 'Font size',
    value: ADJUST_OPTIONS.FONT_SIZE,
    info: 'Textbox increases in height to preserve the font size.',
  },
  {
    label: 'Textbox',
    value: ADJUST_OPTIONS.TEXTBOX,
    info: 'Font size decreases to preserve the textbox size.',
  },
  {
    label: 'Lines',
    value: ADJUST_OPTIONS.LINES,
    info: 'Font size decreases to preserve the maximum number of lines.',
  },
];

const AdjustTo = ({ adjustOption, maxLines, onOptionChange, onMaxLinesChange }) => {
  return (
    <div className="adjust-to">
      <Form.Label>Text adjusts to:</Form.Label>
      {OPTIONS.map(option => {
        const { label, value, info } = option;
        return (
          <div key={value} className="option d-flex align-items-center">
            <RadioButton
              checked={value === adjustOption}
              id={value}
              inline
              label={label}
              name={label}
              onChange={() => onOptionChange(value)}
            />
            {value === ADJUST_OPTIONS.LINES && (
              <LabeledInput
                id="max-lines-value"
                className="me-1"
                size="xs"
                controlProps={{
                  min: 1,
                  onChange: onMaxLinesChange,
                  placeholder: '',
                  type: 'number',
                  value: maxLines,
                }}
              />
            )}
            <Popover content={info} id={`info-${value}`}>
              <Icon className="d-flex info" name="question-circle" />
            </Popover>
          </div>
        );
      })}
    </div>
  );
};

AdjustTo.propTypes = {
  /** Max lines value */
  maxLines: PropTypes.number,
  /** Action to be called when max lines value changes */
  onMaxLinesChange: PropTypes.func,
  /** Action to be called when radio value changes */
  onOptionChange: PropTypes.func,
  /** Adjust value */
  value: PropTypes.number,
};

AdjustTo.defaultProps = {
  maxLines: 1,
  onMaxLinesChange: () => {},
  onOptionChange: () => {},
};

export default AdjustTo;
