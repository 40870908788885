import React, { useRef, useState } from 'react';

import { Icon, Popover, Spinner } from '@akiunlocks/perseus-ui-components';

import { useRootStore } from 'store';

import { CONFIRM_BODIES, CONFIRM_NOTES, CONFIRM_TITLES } from 'utils';

import Confirm from 'components/Confirm';

import AssetFileInput from 'components/Sidebar/components/AssetFileInput';

const Actions = ({ node }) => {
  const { editor } = useRootStore();
  const { assetStore } = editor;

  const uploadRef = useRef();

  const [confirmModalData, setConfirmModalData] = useState({});

  const { data, isEditing, isLeaf } = node;
  const { isRoot } = data;
  const isFolder = !isLeaf;
  const hasChildren = isFolder && !!data.children?.length;

  const closeConfirmModal = () => {
    setConfirmModalData({ showModal: false });
  };

  const onDeleteClick = () => {
    const { PROJECT_ASSET_DELETE, PROJECT_FOLDER_DELETE } = CONFIRM_BODIES;
    const bodyTemplate = isFolder ? PROJECT_FOLDER_DELETE : PROJECT_ASSET_DELETE;

    const modalData = {
      body: bodyTemplate.replace('{name}', data.name),
      handleAccept: () => {
        if (!isFolder) {
          assetStore.onDeleteAsset(data);
        } else {
          assetStore.deleteFolder(data.id);
        }
        closeConfirmModal();
      },
      handleClose: closeConfirmModal,
      notes: isFolder ? [] : CONFIRM_NOTES.PROJECT_ASSET_DELETE,
      showModal: true,
      title: isFolder ? CONFIRM_TITLES.PROJECT_FOLDER_DELETE : CONFIRM_TITLES.PROJECT_ASSET_DELETE,
    };

    setConfirmModalData(modalData);
  };

  const onReplaceClick = () =>
    setConfirmModalData({
      body: CONFIRM_BODIES.PROJECT_ASSET_REPLACE.replace('{name}', data.name),
      handleAccept: () => {
        node.tree.props.onReplace(data);
        closeConfirmModal();
      },
      handleClose: closeConfirmModal,
      notes: CONFIRM_NOTES.PROJECT_ASSET_REPLACE,
      showModal: true,
      title: CONFIRM_TITLES.PROJECT_ASSET_REPLACE,
    });

  const onNodeEdit = () => {
    node.edit();
  };

  const actions = [
    {
      disabled: assetStore.loading,
      iconName: 'bi-image-add',
      iconSize: 15,
      id: 'add-asset',
      onClick: () => {
        uploadRef.current?.click();
      },
      show: isFolder,
      tooltipMessage: 'Add asset',
    },
    {
      disabled: assetStore.loading,
      iconName: 'bi-folder-plus',
      id: 'add-folder',
      onClick: () => {
        const newFolder = assetStore.addFolder(data.id);
        if (newFolder) {
          node.open(); // ensure parent node is open
          assetStore.editNode(newFolder.model.id, node.tree);
        }
      },
      show: isFolder,
      tooltipMessage: 'Add folder',
    },
    {
      disabled: assetStore.loading,
      iconName: 'pencil',
      iconSize: 11,
      id: 'rename-folder',
      onClick: onNodeEdit,
      show: isFolder && !isRoot,
      tooltipMessage: 'Rename folder',
    },
    {
      disabled: assetStore.loading,
      iconName: 'bi-refresh',
      iconSize: 18,
      id: 'replace',
      onClick: onReplaceClick,
      show: !isRoot && !isFolder,
      tooltipMessage: 'Replace',
    },
    {
      className: 'text-danger',
      disabled: hasChildren || assetStore.loading,
      iconName: 'trash',
      iconSize: 12,
      id: 'delete',
      onClick: onDeleteClick,
      show: !isRoot,
      tooltipMessage: hasChildren ? 'Cannot delete a folder with children' : 'Delete',
    },
  ];

  if (isEditing) {
    return null;
  }

  if (data.loading) {
    return <Spinner size="sm" className="me-1" variant="primary" />;
  }

  return (
    <>
      <AssetFileInput
        multiple
        onChange={({ target }) => assetStore.uploadFiles(Array.from(target.files), node.data.imageUrl)}
        ref={uploadRef}
      />
      <div className="actions" data-testid="actions">
        {actions
          .filter(({ show }) => show)
          .map(({ className, disabled, iconName, iconSize, id, onClick, tooltipMessage }) => (
            <Popover id={id} key={id} content={tooltipMessage}>
              <Icon className={className} disabled={disabled} name={iconName} onClick={onClick} size={iconSize || 14} />
            </Popover>
          ))}
      </div>
      <Confirm {...confirmModalData} />
    </>
  );
};

export default Actions;
