import { makeAutoObservable } from 'mobx';

import { DEFAULT_TIME_LINE_TAB, LAYERS_TAB } from '../../../constants';
import { createActiveSelection } from '@prisma/lib/src/utils/helpers';
import { fireModifiedDragEvent } from 'utils/animations';

// State machine events
export const EVENTS = {
  ALIGN_CHANGED: 'ALIGN_CHANGED',
  ASSET_SELECTED: 'ASSET_SELECTED',
  ASSETS_SELECTED: 'ASSETS_SELECTED',
  CANVAS_LAYER_SELECTED: 'CANVAS_LAYER_SELECTED',
  DELETE_SELECTED: 'DELETE_SELECTED',
  KEYFRAME_SELECTED: 'KEYFRAME_SELECTED',
  KEYFRAMES_SELECTED: 'KEYFRAMES_SELECTED',
  KEYFRAME_UNSELECTED: 'KEYFRAME_UNSELECTED',
  LAYER_SELECTED: 'LAYER_SELECTED',
  LAYERS_SELECTED: 'LAYERS_SELECTED',
  PASTE: 'PASTE',
  TIMELINE_TAB_CHANGED: 'TIMELINE_TAB_CHANGED',
};

class ContextStore {
  timelineTab = DEFAULT_TIME_LINE_TAB;

  constructor(editor) {
    makeAutoObservable(this);
    this.editor = editor;
  }

  getTimelineTab() {
    return this.timelineTab;
  }

  deleteSelected() {
    this.handleEvent(EVENTS.DELETE_SELECTED);
  }

  setTimelineTab(tab) {
    if (tab === this.timelineTab) {
      return;
    }
    this.timelineTab = tab;
    this.handleEvent(EVENTS.TIMELINE_TAB_CHANGED, { tab });
  }

  getIsLayersTabSelected() {
    return this.timelineTab === LAYERS_TAB;
  }

  selectLayer = (layer, isMultiSelect = false) => {
    this.handleEvent(EVENTS.LAYER_SELECTED, { layer, isMultiSelect });
  };

  /**
   * Selects a list of layers in the editor.
   * It only adds layers to the selection, it does not remove
   * @param {array} layers
   */
  selectLayers = layers => {
    if (!layers?.length) {
      return;
    } else if (layers.length === 1) {
      this.selectLayer(layers[0]);
    } else {
      this.handleEvent(EVENTS.LAYERS_SELECTED, { layers });
    }
  };

  selectLayersFromCanvas = layers => {
    this.handleEvent(EVENTS.CANVAS_LAYER_SELECTED, { layers });
  };

  selectKeyframe(keyframe, isMultiSelect = false) {
    this.handleEvent(EVENTS.KEYFRAME_SELECTED, { keyframe, isMultiSelect });
  }

  unselectKeyframe(keyframe) {
    this.handleEvent(EVENTS.KEYFRAME_UNSELECTED, { keyframe });
  }

  selectKeyframes(keyframes, isMultiSelect = false) {
    this.handleEvent(EVENTS.KEYFRAMES_SELECTED, { keyframes, isMultiSelect });
  }

  selectAsset(asset, isMultiSelect = false) {
    this.handleEvent(EVENTS.ASSET_SELECTED, { asset, isMultiSelect });
  }

  /**
   * Selects a list of assets.
   * It only adds assets to the selection, it does not remove.
   * @param {array} assets
   */
  selectAssets = assets => {
    if (assets.length === 1) {
      this.selectAsset(assets[0]);
    } else {
      this.handleEvent(EVENTS.ASSETS_SELECTED, { assets });
    }
  };

  paste() {
    this.handleEvent(EVENTS.PASTE);
  }

  alignElements(object, original) {
    this.handleEvent(EVENTS.ALIGN_CHANGED, { object, original });
  }

  handleEvent(event, eventData) {
    const { selectionStore, timelineSceneStore } = this.editor;

    switch (event) {
      case EVENTS.ALIGN_CHANGED:
        const { object, original } = eventData;
        if (!this.getIsLayersTabSelected()) {
          fireModifiedDragEvent(object, original);
        }
        break;
      case EVENTS.ASSET_SELECTED:
        selectionStore.addOrRemoveAsset(eventData.asset, eventData.isMultiSelect);
        break;
      case EVENTS.ASSETS_SELECTED:
        selectionStore.clearSelectedComponents();
        eventData.assets.forEach(asset => selectionStore.addOrRemoveAsset(asset, true));
        break;
      case EVENTS.CANVAS_LAYER_SELECTED:
        const { layers } = eventData;
        selectionStore.clearSelectedComponents();
        if (layers.length === 0) {
          break;
        }
        layers.forEach(layer => selectionStore.addOrRemoveLayer(layer, true));
        break;
      case EVENTS.DELETE_SELECTED:
        const selectedComponents = selectionStore.getSelectedComponents();
        if (selectedComponents.length) {
          this.editor.removeComponents(selectedComponents);
          if (selectionStore.isSelectedInSceneTab()) {
            timelineSceneStore.clearActiveAnimation();
          } else {
            timelineSceneStore.clearActiveLayer();
          }
        } else {
          // if there was no selected components, we try to delete the active object
          this.editor.deleteActiveObject();
        }
        break;
      case EVENTS.KEYFRAME_SELECTED:
        selectionStore.addSelectedComponent(eventData.keyframe, eventData.isMultiSelect);
        break;
      case EVENTS.KEYFRAMES_SELECTED:
        selectionStore.addSelectedComponents(eventData.keyframes, eventData.isMultiSelect);
        break;
      case EVENTS.KEYFRAME_UNSELECTED:
        selectionStore.removeSelectedComponent(eventData.keyframe, eventData.isMultiSelect);
        break;
      case EVENTS.LAYER_SELECTED:
        const layer = this.editor.getEditorLayerByTarget(eventData.layer);
        const isMultiselect = selectionStore.isMultiSelectLayer(layer, eventData.isMultiSelect);
        if (selectionStore.addOrRemoveLayer(layer, isMultiselect)) {
          this.editor.setActiveObject(layer.target, isMultiselect);
        } else {
          this.editor.discardObjectFromSelection(layer.target);
        }
        break;
      case EVENTS.LAYERS_SELECTED:
        selectionStore.clearSelectedComponents();
        const layersToAdd = eventData.layers.map(layer => this.editor.getEditorLayerByTarget(layer));
        layersToAdd.forEach(layer => selectionStore.addOrRemoveLayer(layer, true));
        const selection = createActiveSelection(layersToAdd.map(l => l.target));
        selection.canvas.setActiveObject(selection);
        break;
      case EVENTS.PASTE:
        this.editor.clipboardStore.paste();
        break;
      case EVENTS.TIMELINE_TAB_CHANGED:
        const timelineTab = eventData.tab;

        this.editor.updateActiveTimeLineTab(timelineTab);
        if (timelineTab === LAYERS_TAB && selectionStore.isSelectedInSceneTab()) {
          timelineSceneStore.clearActiveAnimation();
          selectionStore.clearSelectedComponents();
        }
        break;
      default:
        console.log('Unknown event:', event);
        break;
    }
  }
}

export default ContextStore;
