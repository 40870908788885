import React, { useEffect, useRef } from 'react';
import { Icon, Popover } from '@akiunlocks/perseus-ui-components';

const DotsMenu = ({ confirmArchive, handleDuplicate, handleRedirect, handleRename, project = null }) => {
  const menuRef = useRef();
  const personalizeDisabled = !project?.perseusHashId;
  const actions = [
    {
      message: personalizeDisabled ? 'Not pushed to Personalize' : 'Go to Personalize',
      name: 'stack',
      disabled: personalizeDisabled,
      onClick: handleRedirect,
    },
    {
      message: 'Rename',
      name: 'pencil',
      disabled: false,
      onClick: handleRename,
    },
    {
      message: 'Duplicate',
      name: 'bi-duplicate',
      disabled: false,
      onClick: handleDuplicate,
    },
    {
      message: 'Archive',
      name: 'archive',
      disabled: false,
      onClick: confirmArchive,
    },
  ];

  useEffect(() => {
    menuRef.current.parentNode.className = 'dots-menu-container';
  }, []);

  return (
    <div ref={menuRef} className="dots-block-actions">
      {actions.map(({ message, name, disabled, onClick }) => (
        <div className="action" key={name}>
          <Popover id={name} content={message}>
            <Icon name={name} disabled={disabled} onClick={() => onClick(project)} />
          </Popover>
        </div>
      ))}
    </div>
  );
};
export default DotsMenu;
