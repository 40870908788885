import { makeAutoObservable } from 'mobx';
import { v4 as uuidv4 } from 'uuid';
import TreeModel from 'tree-model-improved';

import { Notification, notify } from '@akiunlocks/perseus-ui-components';

import { PRISMA_IMAGE } from '@prisma/lib/src/constants';
import { findLayerById } from '@prisma/lib/src/utils/layers';

import { ASSET, FILE_EXISTS, FOLDER, FOLDERS, FOLDER_EXISTS, ROOT_NODE } from 'constants/assets';

import { getPromisesResults } from 'utils/promises';
import {
  assetsToTree,
  buildAssetsOptions,
  findById,
  getAssetName,
  sanitizeFileName,
  sanitizeFolderName,
  sortNodeTree,
  validateMove,
} from './utilities/assets';
import { getImageSizeFromFile, replaceObjectImageSrc } from 'components/Sidebar/utilities';

class AssetStore {
  root = null;

  loading = false;
  tempNodes = {};

  confirmationModal = {};

  existingAssetsToMove = [];
  assetsToMoveAndOverwrite = [];

  existingAssetsToUpload = [];
  assetsToUploadAndOverwrite = [];

  // helper to move assets or folders
  dragOverId = null;

  constructor(editor) {
    makeAutoObservable(this);
    this.editor = editor;
    this.buildTree([ROOT_NODE]);
  }

  getTreeData = () => {
    const { assets, projectAssetsBaseUrl } = this.editor.rootStore.projects;
    this.setLoading(assets.some(a => a.loading));
    return [
      {
        ...ROOT_NODE,
        children: assetsToTree(assets.filter(a => !a.hide).map(a => ({ ...a, baseUrl: projectAssetsBaseUrl }))),
      },
    ];
  };

  setDragOverId = dragOverId => {
    this.dragOverId = dragOverId;
  };

  setLoading = loading => {
    this.loading = loading;
  };

  setExistingAssetsToMove = assets => {
    this.existingAssetsToMove = assets;
  };

  setAssetsToMoveAndOverwrite = assets => {
    this.assetsToMoveAndOverwrite = assets;
  };

  setExistingAssetsToUpload = assets => {
    this.existingAssetsToUpload = assets;
  };

  setAssetsToUploadAndOverwrite = assets => {
    this.assetsToUploadAndOverwrite = assets;
  };

  setConfirmationModal(confirmationModal) {
    this.confirmationModal = confirmationModal;
  }

  buildTree = data => {
    this.root = new TreeModel().parse(sortNodeTree(data[0]));
  };

  find = id => {
    return findById(this.root, id);
  };

  update = () => {
    const newTree = [sortNodeTree(this.root.model)];
    this.buildTree(newTree);
  };

  onMove = ({ dragIds, parentId }, overwrite) => {
    const isMoveAllowed = parentId === '0' || parentId === this.dragOverId || overwrite;
    const isDraggingToItself = dragIds.includes(parentId);
    if (this.loading || !isMoveAllowed || isDraggingToItself) {
      return;
    }

    this.setDragOverId(null);

    const parent = parentId ? this.find(parentId) : this.root;
    if (!parent) {
      return;
    }

    const { selectionStore } = this.editor;
    const { projects } = this.editor.rootStore;

    if (selectionStore.hasAssetsSelected()) {
      // assets selected with react mouse are not included in dragIds
      // by doing this, we ensure all the selected assets are moved
      const selectedIds = [...dragIds, ...selectionStore.getSelectedAssetsIds()];
      dragIds = [...new Set(selectedIds)];
    }

    const destinationFolder = parent.model.imageUrl || FOLDERS.ASSETS;
    const assetsToMove = [];
    const foldersToMove = [];

    for (const dragId of dragIds) {
      const source = this.find(dragId);

      if (!validateMove(source, parent)) {
        continue;
      }

      const { imageUrl, name, type } = source.model;
      const isAsset = type === ASSET;

      const assetName = getAssetName(source.model);
      const destinationUrl = `${destinationFolder}${assetName}`;
      const existing = projects.assets.find(a => a.imageUrl === destinationUrl);
      if (existing) {
        if (!isAsset) {
          // do not show error if folder was moved to the same parent.
          if (source.parent.model.id !== parent.model.id) {
            notify(
              `Could not move '${source.model.name}'. Folder already exists in destination.`,
              Notification.TYPE.ERROR,
            );
          }
          continue;
        }
        if (overwrite) {
          const existingNode = this.find(existing.id);
          existingNode?.drop();
        } else {
          this.setExistingAssetsToMove([...this.existingAssetsToMove, { destinationUrl, dragId, parentId, type }]);
          continue;
        }
      }
      const newItem = new TreeModel().parse(source.model);
      newItem.model.loading = true;
      parent.addChild(newItem);
      source.drop();

      if (isAsset) {
        assetsToMove.push({ imageUrl, name, overwrite });
      } else {
        foldersToMove.push({ imageUrl, name, overwrite });
      }
    }

    this.update();

    if (assetsToMove.length || foldersToMove.length) {
      this.setLoading(true);
    }
    if (assetsToMove.length) {
      this.moveAssetsToFolder(assetsToMove, destinationFolder);
    } else if (foldersToMove.length) {
      this.moveFoldersToFolder(foldersToMove, destinationFolder);
    }
  };

  addTempNode = data => {
    this.tempNodes[data.id] = data;
  };

  deleteTempNode = id => {
    delete this.tempNodes[id];
  };

  isTempNode = id => {
    return !!this.tempNodes[id];
  };

  /**
   * If the given node is a temporary node, drops it, else reset to previous state.
   * @param {object} node - TreeApi node.
   */
  dropOrResetNode = node => {
    // if it is a temporary node drop it, else reset
    if (this.isTempNode(node.id)) {
      const treeNode = this.find(node.id);
      treeNode.drop();
      this.update();
      this.deleteTempNode(node.id);
    } else {
      node.reset();
    }
  };

  /**
   * Checks if a folder already exists in project assets and returns it.
   * @param {string} name - Folder name.
   * @param {string} parentFolder - Parent folder.
   * @return {object} Folder asset if exists, else undefined.
   */
  findFolderAsset = (name, parentFolder) => {
    const { projects } = this.editor.rootStore;
    const sanitizedFolderName = sanitizeFolderName(name);
    const imageUrl = `${parentFolder}${sanitizedFolderName}/`;
    return projects.assets.find(a => a.imageUrl === imageUrl);
  };

  /**
   * Shows error notification for existing folder.
   * @param {string} name - Folder name.
   */
  notifyExistingFolder = name => {
    notify(`Folder '${name}' already exists.`, Notification.TYPE.ERROR);
  };

  /**
   * Renames asset and saves it to database.
   * @param {string} name - Folder name.
   */
  renameAssetAndSave = (assetId, name) => {
    const { projects } = this.editor.rootStore;
    const updatedAssets = projects.assets.map(a => {
      if (a.id === assetId) {
        a.name = name;
      }
      return a;
    });
    projects.setAssets({ assets: updatedAssets, id: projects.getProjectId() });
  };

  onRename = async ({ id, name }) => {
    const node = this.find(id);
    if (!node || node.model.type !== FOLDER || node.model.name === name) {
      return;
    }

    const parentModel = node.parent.model;
    const parentFolder = parentModel.isRoot ? FOLDERS.ASSETS : parentModel.imageUrl;

    node.model.name = name;
    node.model.loading = true;

    this.setLoading(true);

    // if it is a temporary node, create the folder, else rename it.
    if (this.isTempNode(id)) {
      this.deleteTempNode(id);
      this.createFolder(name, parentFolder);
    } else {
      const existingFolder = this.findFolderAsset(name, parentFolder);
      if (existingFolder && existingFolder.id === id) {
        // if the name results in the same folder path, just rename the asset and save
        this.renameAssetAndSave(id, name);
      } else {
        this.renameFolder(name, node.model.imageUrl);
      }
    }

    this.update();
  };

  editNode = (id, tree) => {
    // this setTimeout is a workaround to find the node after creation
    // TODO: remove timeout
    setTimeout(() => {
      const nodeToEdit = tree.get(id);
      if (nodeToEdit) {
        nodeToEdit.edit();
      }
    }, 0);
  };

  addNode = (parentId, type) => {
    const parentNode = this.find(parentId);
    if (parentNode) {
      const newNode = {
        id: uuidv4(),
        name: '',
        type,
      };
      if (type === FOLDER) {
        newNode.children = [];
      }
      const newItem = new TreeModel().parse(newNode);
      parentNode.addChild(newItem);
      this.update();
      this.addTempNode(newItem.model);
      return newItem;
    }
    return null;
  };

  addFolder = (parentId = '0') => {
    return this.addNode(parentId, FOLDER);
  };

  onSelect = nodes => {
    if (!nodes?.length) {
      return;
    }
    const folderNodes = nodes.filter(n => n.data.type === FOLDER);
    if (folderNodes.length) {
      // if any folder is selected, select only folders in the highest level
      // and deselect other folders or assets
      const topLevel = Math.min(...folderNodes.map(({ level }) => level));
      nodes.filter(n => n.level !== topLevel || n.data.type === ASSET).forEach(a => a.tree.deselect(a.id));
      nodes = folderNodes.filter(a => a.level === topLevel);
    }
    const { isOnlySelection } = nodes[0];
    if (isOnlySelection) {
      this.editor.contextStore.selectAsset(nodes[0].data);
    } else {
      this.editor.contextStore.selectAssets(nodes.map(({ data }) => data));
    }
  };

  onDeleteAsset = async asset => {
    const { editor } = this;
    const { historyStore } = editor;
    const { projects, projectSizes: projectSizesStore } = editor.rootStore;
    const { projectSize, projectSizes, removeImageLayerAndSaveProjectSizes } = projectSizesStore;

    this.editor.setCanvasLoading(true);
    const removed = await projects.removeProjectAsset(projects.project?._id, asset._id);
    if (!removed) {
      editor.setCanvasLoading(false);
      return;
    }

    // remove asset in current composition and save using canvas layers
    const layersToRemove = editor.canvas.layers.filter(
      l => l.type === PRISMA_IMAGE && l.properties.imageUrl === asset.imageUrl,
    );
    if (layersToRemove.length) {
      layersToRemove.forEach(layer => {
        editor.canvas.removeLayer(layer.target);
      });
      await editor.saveProjectSize(projectSize._id);
    }
    // remove image layer in history
    historyStore.removeImageLayer(asset.imageUrl);
    // remove asset in the rest of compositions
    const projectSizesToUpdate = projectSizes.filter(({ _id }) => _id !== projectSize._id);
    await removeImageLayerAndSaveProjectSizes(projectSizesToUpdate, asset.imageUrl);
    editor.setCanvasLoading(false);
  };

  onReplaceAsset = async (asset, newAssetFile, folder = FOLDERS.ASSETS, isReupload = false) => {
    const { editor } = this;
    const { historyStore } = editor;
    const { projects, projectSizes: projectSizesStore } = editor.rootStore;
    const { assets } = projects;
    const { projectSize, projectSizes, updateImageLayersAndSaveProjectSizes } = projectSizesStore;
    const projectId = projects.getProjectId();

    // discard active object to have the correct positions
    editor.canvas.discardActiveObject();
    editor.canvas.renderAll();

    editor.setCanvasLoading(true);

    const assetData = await projects.getAssetDataFromFile(newAssetFile, folder);

    const isSameAssetName = assetData.imageUrl === asset.imageUrl;
    if (isSameAssetName) {
      newAssetFile.overwrite = true;
    }

    // check existing only on replacement, because on upload it is already validated
    if (!isReupload) {
      const alreadyExists = assets.find(a => a._id !== asset._id && a.imageUrl === assetData.imageUrl);
      if (alreadyExists) {
        this.onReplaceAssetError(
          asset,
          `Could not replace ${asset.name} with ${newAssetFile.name}: File already exists.`,
        );
        return;
      }
    }

    projects.hideAsset(asset.id);

    // try to upload file
    let result;
    try {
      result = await this.uploadFile(newAssetFile, folder);
    } catch (res) {
      this.onReplaceAssetError(asset, `Could not upload ${res.file.name}: ${res.error.message}`);
      return;
    }

    const { height, width } = await getImageSizeFromFile(result.file);
    const newAsset = {
      height,
      imageUrl: result.asset.imageUrl,
      timestamp: isSameAssetName ? new Date().getTime() : 0,
      width,
    };

    // replace asset in current composition and save using canvas layers
    await this.replaceAssetInCanvas(asset, newAsset);
    await editor.saveProjectSize(projectSize._id);

    // replace image layers properties in history
    historyStore.updateImageLayers(asset.imageUrl, newAsset);

    // replace asset in the rest of compositions
    const projectSizesToUpdate = projectSizes.filter(({ _id }) => _id !== projectSize._id);
    await updateImageLayersAndSaveProjectSizes(projectSizesToUpdate, asset.imageUrl, newAsset);

    if (!isSameAssetName) {
      // delete old asset and save list
      await this.onDeleteAsset(asset);
    }
    projects.addAssets({ assets: [result.file], id: projectId });

    editor.setCanvasLoading(false);
  };

  onReplaceAssetError = (asset, message) => {
    this.editor.setCanvasLoading(false);
    asset.hide = false;
    notify(message, Notification.TYPE.ERROR);
  };

  uploadFile = (file, folder = FOLDERS.ASSETS) => {
    const { projects } = this.editor.rootStore;
    const projectId = projects.getProjectId();

    return new Promise(async (resolve, reject) => {
      try {
        const asset = await projects.addProjectAsset(file, projectId, file.overwrite, folder);
        file.imageUrl = asset.imageUrl;
        resolve({ asset, file });
      } catch (error) {
        const sanitizedFileName = sanitizeFileName(file.name);
        file.imageUrl = `${folder}${sanitizedFileName}`;
        reject({ error, file });
      }
    });
  };

  uploadFileByUrl = url => {
    const { projects } = this.editor.rootStore;
    return new Promise(async (resolve, reject) => {
      try {
        const response = await projects.addProjectAssetByUrl(url, projects.getProjectId());
        resolve(response);
      } catch (error) {
        reject({ error, url });
      }
    });
  };

  uploadFileFromUrl = (url, callback) => {
    this.uploadFiles([url], FOLDERS.ASSETS, callback, this.uploadFileByUrl);
  };

  uploadFiles = (files, folder = FOLDERS.ASSETS, callback = undefined, uploadFunction = this.uploadFile) => {
    const { projects } = this.editor.rootStore;

    const uploadFilePromises = files.map(f => uploadFunction(f, folder));
    Promise.allSettled(uploadFilePromises).then(responses => {
      const [erroredFiles, successfulFiles] = getPromisesResults(responses, 'file');
      if (erroredFiles.length > 0) {
        const repeatedFiles = erroredFiles.filter(reason => reason.error?.message === FILE_EXISTS);
        const otherFiles = erroredFiles.filter(reason => reason.error?.message !== FILE_EXISTS);
        if (repeatedFiles.length > 0) {
          this.setExistingAssetsToUpload(repeatedFiles.map(reason => reason.file));
        }
        otherFiles.forEach(reason => {
          notify(`Could not upload ${reason.file.name}: ${reason.error.message}`, Notification.TYPE.ERROR);
        });
      }
      const total = successfulFiles.length;
      if (total > 0) {
        if (total === 1) {
          notify(`${successfulFiles[0].name} was successfully uploaded`);
        } else if (total < files.length) {
          notify(`${total} files were successfully uploaded`);
        } else {
          notify('All files were successfully uploaded');
        }
        projects.addAssets({ assets: successfulFiles, id: projects.getProjectId() });
      }

      callback && callback(successfulFiles);
    });
  };

  /**
   * Replaces image source of layers using a given asset and updates layers properties.
   * @param {object} asset - Current asset.
   * @param {object} newAsset - New asset.
   * @return {Promise}
   */
  replaceAssetInCanvas = (asset, newAsset) => {
    const { projects } = this.editor.rootStore;
    return Promise.all(
      this.editor.canvas.layers
        .filter(l => l.type === PRISMA_IMAGE && l.target.imageUrl === asset.imageUrl)
        .map(async layer => {
          await replaceObjectImageSrc(layer.target, `${projects.projectAssetsBaseUrl}${newAsset.imageUrl}`);
          layer.target.setOptions(newAsset);
          layer.properties = { ...layer.properties, ...newAsset };
          this.editor.updateLayersPropertiesByLayerId(layer.id, { ...newAsset });
        }),
    );
  };

  /**
   * Given a list of moved assets update their imageUrls in the canvas.
   * @param {array} movedAssets - Moved assets. Each one should have the oldImageUrl and imageUrl properties.
   * @return {Promise}
   */
  replaceMovedAssetsInCanvas = movedAssets => {
    return Promise.all(
      movedAssets.map(async ({ imageUrl, oldImageUrl }) => {
        const oldAsset = { imageUrl: oldImageUrl };
        const newAsset = { imageUrl };
        await this.replaceAssetInCanvas(oldAsset, newAsset);
      }),
    );
  };

  // Asset folders

  /**
   * Creates folder in the project.
   * @param {string} name - Name of the new folder.
   * @param {string} parentFolder - Path of the parent folder.
   */
  createFolder = async (name, parentFolder) => {
    const { projects } = this.editor.rootStore;
    const projectId = projects.getProjectId();

    try {
      const createdFolder = await projects.addProjectFolder(projectId, name, parentFolder);
      projects.addAssets({ assets: [createdFolder], id: projectId });
    } catch (error) {
      this.notifyUpdateFolderError(name, 'create', error.message);
    }
  };

  notifyUpdateFolderError(name, action, message = FOLDER_EXISTS) {
    notify(`Could not ${action} '${name}'. ${message}`, Notification.TYPE.ERROR);
  }

  /**
   * Removes folder from the project.
   * @param {string} id - Folder id.
   */
  deleteFolder = async id => {
    const { projects } = this.editor.rootStore;
    await projects.removeProjectAsset(projects.project?._id, id);
  };

  /**
   * Moves all the assets from a list to a given folder.
   * @param {array} assets - List of asset.
   * @param {string} folder - Destination folder.
   */
  moveAssetsToFolder = async (assets, folder) => {
    const moveAssetPromises = assets.map(a => this.moveAsset(a, folder));
    Promise.allSettled(moveAssetPromises).then(async responses => {
      const [assetsWithError, movedAssets] = getPromisesResults(responses);
      assetsWithError.forEach(reason => {
        notify(`Could not move ${reason.asset.name}: ${reason.error.message}`, Notification.TYPE.ERROR);
      });
      this.updateMovedAssetsInProject(movedAssets);
    });
  };

  updateMovedAssetsInProject = async movedAssets => {
    if (!movedAssets?.length) {
      return;
    }

    const { editor } = this;
    const { historyStore } = editor;
    const { projects, projectSizes: projectSizesStore } = editor.rootStore;
    const { projectSize, projectSizes, replaceMovedAssetsInCompositions } = projectSizesStore;

    const oldImageUrls = movedAssets.map(a => a.oldImageUrl);
    const filteredAssets = projects.assets.filter(a => !oldImageUrls.includes(a.imageUrl));
    const newAssets = [...filteredAssets, ...movedAssets];
    projects.setAssets({ assets: newAssets, id: projects.getProjectId() });

    editor.setCanvasLoading(true);
    await this.replaceMovedAssetsInCanvas(movedAssets);
    await editor.saveProjectSize(projectSize._id);

    // replace images in history
    movedAssets.forEach(a => {
      const newAsset = { imageUrl: a.imageUrl };
      historyStore.updateImageLayers(a.oldImageUrl, newAsset);
    });

    const projectSizesToUpdate = projectSizes.filter(({ _id }) => _id !== projectSize._id);
    await replaceMovedAssetsInCompositions(movedAssets, projectSizesToUpdate);
    editor.setCanvasLoading(false);
  };

  /**
   * Moves one asset to a given folder.
   * @param {object} asset - Asset.
   * @param {string} asset.imageUrl - Asset image url.
   * @param {string} asset.overwrite - Flag to overwrite asset.
   * @param {string} folder - Destination folder.
   * @return {Promise}
   */
  moveAsset = (asset, folder) => {
    const { projects } = this.editor.rootStore;
    return new Promise(async (resolve, reject) => {
      try {
        const movedAsset = await projects.moveAssetToFolder(asset.imageUrl, folder, asset.overwrite);
        resolve({ data: movedAsset });
      } catch (error) {
        reject({ error, asset });
      }
    });
  };

  moveFoldersToFolder = async (folderAssets, folder) => {
    const moveFoldersPromises = folderAssets.map(a => this.moveFolder(a, folder));
    Promise.allSettled(moveFoldersPromises).then(async responses => {
      const [foldersWithError, movedAssets] = getPromisesResults(responses);
      foldersWithError.forEach(reason => {
        notify(`Could not move ${reason.assetFolder.name}: ${reason.error.message}`, Notification.TYPE.ERROR);
      });
      // merge all the assets into a single array
      const allAssets = movedAssets.flat();
      this.updateMovedAssetsInProject(allAssets);
    });
  };

  moveFolder = (assetFolder, folder) => {
    const { projects } = this.editor.rootStore;
    return new Promise(async (resolve, reject) => {
      try {
        const movedAssets = await projects.moveFolderToFolder(assetFolder.imageUrl, folder);
        resolve({ data: movedAssets });
      } catch (error) {
        reject({ error, assetFolder });
      }
    });
  };

  renameFolder = async (name, imageUrl) => {
    const { projects } = this.editor.rootStore;
    try {
      const movedAssets = await projects.renameProjectFolder(name, imageUrl);
      this.updateMovedAssetsInProject(movedAssets);
    } catch (error) {
      this.notifyUpdateFolderError(name, 'rename', error.message);
    }
  };

  /**
   * Gets assets options to be used in a dropdown.
   * @returns {array} Assets options grouped by folders.
   */
  getAssetsOptions = () => {
    const { assets } = this.editor.rootStore.projects;
    return buildAssetsOptions(assets);
  };

  /**
   * Gets the selected asset option based on active object.
   * @returns {object} Selected asset if found, else undefined.
   */
  getSelectedAsset = () => {
    const { activeObject } = this.editor;
    let selected;
    this.getAssetsOptions().forEach(option => {
      // asset option can be grouped by folders, so we look into children first
      // if asset was not found in children, check the option value itself
      const children = option.options;
      const found = children?.find(({ value }) => value === activeObject?.imageUrl);
      if (found) {
        selected = found;
      } else if (option.value === activeObject?.imageUrl) {
        selected = option;
      }
    });
    return selected;
  };

  /**
   * Replaces the asset source of the active object.
   * @param {string} imageUrl - The imageUrl of the new asset. It should be obtained from assets list.
   */
  replaceActiveAssetSource = async imageUrl => {
    const { activeObject, canvas, contextStore } = this.editor;
    const { assets } = this.editor.rootStore.projects;
    if (activeObject?.type !== PRISMA_IMAGE || !assets.find(a => a.imageUrl === imageUrl)) {
      return;
    }

    this.editor.setCanvasLoading(true);

    const layer = findLayerById(canvas.layers, activeObject.id);
    const newAsset = { imageUrl };
    await this.editor.replaceAssetInLayer(layer, newAsset);

    // select layer again to see updated values
    canvas.discardActiveObject();
    contextStore.selectLayer(layer);

    canvas.fire('history');

    this.editor.setCanvasLoading(false);
  };
}

export default AssetStore;
