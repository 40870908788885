import PrismaKeyframe from '../prismaKeyframe.js';
import { ANIMATIONS_WITH_POSITION, animationTypes, frameKeysToFabricProperties } from './types.js';
import { MAX_REPEAT_TIMES } from '../constants/animations.js';

/**
 * Adds or subtracts offset values to animations that have position properties.
 * Round new values if needed.
 * @param {array} animations - List of animations.
 * @param {number} offsetLeft - Offset X to be added or subtracted.
 * @param {number} offsetTop - Offset Y to be added or subtracted.
 * @param {boolean} shouldRound - Flag to indicate if result should be rounded.
 * @return {array} New list of animations with updated values.
 */
function updateOffsetsToAnimations(animations, offsetLeft, offsetTop, shouldRound = false) {
  if (!animations?.length) {
    return [];
  }
  return animations.map(a => {
    const keyframes = a.keyframes.map(keyframe => {
      const properties = { ...keyframe.properties };
      if (ANIMATIONS_WITH_POSITION.includes(a.type)) {
        properties.x += offsetLeft;
        properties.y += offsetTop;
        if (shouldRound) {
          properties.x = Math.round(properties.x); // is round really needed?
          properties.y = Math.round(properties.y);
        }
      }
      return new PrismaKeyframe({ ...keyframe, properties });
    });
    return { ...a, keyframes };
  });
}

/**
 * Adds offset values to animations that have position properties.
 * @param {array} animations - List of animations.
 * @param {number} offsetLeft - Offset X to be added.
 * @param {number} offsetTop - Offset Y to be added.
 * @return {array} New list of animations with updated values.
 */
export function addOffsetsToAnimations(animations, offsetLeft = 0, offsetTop = 0) {
  return updateOffsetsToAnimations(animations, offsetLeft, offsetTop);
}

/**
 * Subtracts offset values to animations that have position properties.
 * @param {array} animations - List of animations.
 * @param {number} offsetLeft - Offset X to be subtracted.
 * @param {number} offsetTop - Offset Y to be subtracted.
 * @return {array} New list of animations with updated values.
 */
export function removeOffsetsFromAnimations(animations, offsetLeft = 0, offsetTop = 0) {
  return updateOffsetsToAnimations(animations, -offsetLeft, -offsetTop, true);
}

/**
 * Adds a fabric property to a keyframe properties object.
 * @param {object} object - Object to add property.
 * @param {string} propKey - Property key.
 * @param {any} value - Value to add.
 * @return {object} Object with the added property
 */
export function addFabricProperty(object, propKey, value) {
  const fabricProp = frameKeysToFabricProperties[propKey];
  // eslint-disable-next-line no-param-reassign
  object[fabricProp] = value;
  return object;
}

/**
 * Given a certain time, gets the previous, current and next keyframes.
 * @param {number} time - Time to search.
 * @param {array} keyframes - List of keyframes.
 * @return {array} Object with keyframes if they are present, if not the values will be undefined.
 */
export const getNearKeyframes = (time, keyframes) => {
  if (!keyframes?.length) {
    return {};
  }

  const numOfPrevKeyframes = keyframes.filter(k => k.time < time).length;
  const currentIndex = keyframes.findIndex(k => k.time === time);
  const currentIndexFound = currentIndex !== -1;

  return {
    previous: keyframes[currentIndexFound ? currentIndex - 1 : numOfPrevKeyframes - 1],
    current: keyframes[currentIndex],
    next: keyframes[currentIndexFound ? currentIndex + 1 : numOfPrevKeyframes],
  };
};

/**
 * Given a list of animation types, move rotation to the end if exists.
 * @param {array} types - List of animation types.
 */
export function moveRotationAtTheEnd(types) {
  const index = types.indexOf(animationTypes.rotation);
  if (index !== -1) {
    types.splice(index, 1);
    types.push(animationTypes.rotation);
  }
}

/**
 * Given an animation and its layer, calculate the necessary keyframes to play the animation
 * @param {object} anim - Animation.
 * @param {object} layer - Layer.
 * @return {array} - Array of keyframes.
 */
export function calculateKeyframesFromAnimaton(anim, layer) {
  const keyframes = [];
  const repeatKeyframes = anim.keyframes.filter(k => k.repeat); // Keyframes to repeat
  const firstRepeatKeyframe = repeatKeyframes[0];

  let repeatTimes = 1;
  if (repeatKeyframes.length) {
    repeatTimes = anim.repeatInfinitely ? MAX_REPEAT_TIMES : anim.repeat;
  }

  new Array(repeatTimes).fill(null).forEach(() => {
    let keyframesToAdd = anim.keyframes;
    let timeShift = 0;
    const lastKeyframe = keyframes[keyframes.length - 1];

    if (lastKeyframe && repeatKeyframes.length) {
      timeShift = lastKeyframe.time - firstRepeatKeyframe.time;
      keyframesToAdd = repeatKeyframes;
    }

    const keyframesGroup = keyframesToAdd.map(
      k =>
        new PrismaKeyframe({
          ...k,
          time: k.time + timeShift,
          layerId: layer.id,
          type: anim.type,
          target: layer.target,
          easing: anim.easing,
        }),
    );

    keyframes.push(...keyframesGroup);
  });

  return keyframes;
}
