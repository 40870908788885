import { useKeycloak } from '@react-keycloak/web';

/**
 * Compares the given roles with the user roles and returns the
 * authorized ones
 * @param {array} roles A list of roles.
 */
export default function useAuthorizedRoles(roles) {
  const { keycloak } = useKeycloak();

  if (keycloak && roles.length) {
    return roles.filter(r => keycloak.hasResourceRole(r));
  }

  return [];
}
