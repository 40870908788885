import React from 'react';

import './ColorPicker.scss';

const ColorPicker = ({ onChange = () => {}, value }) => {
  return (
    <div className="color-picker">
      <input type="color" value={value} id="color-picker" onChange={onChange} />
    </div>
  );
};

export default ColorPicker;
