/* eslint-disable */
import { fabric } from 'fabric';

import { DEFAULT_BACKGROUND_COLOR, PRISMA_BANNER } from './constants/canvas.js';
import { RULER_SIZE, RULER_TYPE } from './constants/ruler.js';
import {
  ACTIVE_SELECTION,
  PRISMA_CLOSE_BUTTON,
  PRISMA_GROUP,
  PRISMA_GUIDELINE,
  PRISMA_LANDSCAPE,
  PRISMA_MASK,
} from './constants/index.js';

import { fabricClone, getEmptyAnimation, getUnrotatedPosition, resetObjectPropertiesByType } from './utils/helpers.js';
import { animationTypes, eventsToKeys, perseusToPrismaMapping, prismaToPerseusMapping } from './utils/types.js';

import AnimationEngine from './animationEngineV2.js';
import PrismaCloseButton from './prismaCloseButton.js';
import PrismaBanner from './prismaBanner.js';
import PrismaGroup from './prismaGroup.js';
import PrismaKeyframe from './prismaKeyframe.js';
import PrismaRuler from './prismaRuler.js';

import { addOffsetsToAnimations, getNearKeyframes, removeOffsetsFromAnimations } from './utils/animations.js';
import { findLayerById, generateLayerName, handleChangeGroupZOrder } from './utils/layers.js';

// Fabric configuration to disable copy/paste styles
// it is a static variable, seems weird to add it here, but it is the way to set it
// I'm choosing the prisma canvas because it is the one that it has more sense
fabric.disableStyleCopyPaste = true;

const PrismaCanvas = fabric.util.createClass(fabric.Canvas, {
  type: 'prisma-canvas',

  _observer: null,
  _banner: null,
  _bannerWidth: 0,
  _bannerHeight: 0,
  _parentElement: null,
  _wrapperElement: null,
  _canvasElement: null,
  _rulerOptionsElement: null,
  _topRulerElement: null,
  _leftRulerElement: null,
  offsetTop: 0,
  offsetLeft: 0,

  layers: [],
  keyframes: [],
  engine: null,
  selectionKey: ['ctrlKey', 'metaKey'],
  clickPlaceholder: 'perseus_placeholder_click_id_canvas',

  initialize: function (el, serialized) {
    const json = typeof serialized === 'string' ? JSON.parse(serialized) : fabricClone(serialized);
    this._parentElement = fabric.util.getById(el);
    this._bannerWidth = json.width;
    this._bannerHeight = json.height;
    this.fireMiddleClick = true;
    this.isInterstitial = json.isInterstitial;
    this._recalculateOffsets();
    this._initializeCanvas();
    this._initializeResizeObserver();
    this.callSuper('initialize', this._canvasElement, json);
  },

  _initializeOverlay: function (width, height) {
    const top = this.offsetTop;
    const left = this.offsetLeft;

    this._banner = new PrismaBanner({
      top,
      left,
      width,
      height,
      fill: DEFAULT_BACKGROUND_COLOR,
      hoverCursor: 'default',
      selectable: false,
      shadow: new fabric.Shadow({
        color: '#00000029',
        blur: 6,
      }),
      excludeFromExport: true,
    });
    this.add(this._banner);
    this.moveTo(this._banner, 0);
    this._loadOverlayImage();

    if (this.isInterstitial) {
      PrismaCloseButton.load({ top, left, width }, closeButton => {
        this._closeButton = closeButton;
        this._loadOverlayImage();
        this.renderAll();
      });
    } else {
      this._loadOverlayImage();
    }
  },

  _recalculateOffsets: function () {
    const { width, height } = this.getAvailableSize();
    this.offsetLeft = (width - this._bannerWidth) / 2;
    this.offsetTop = (height - this._bannerHeight) / 2;
  },

  _loadOverlayImage: function () {
    const guidelines = this._objects.filter(({ type }) => type === PRISMA_GUIDELINE).map(g => fabricClone(g));
    const overlayObjects = [...this._banner.initializeBorders(), ...guidelines];
    if (this._closeButton) {
      overlayObjects.push(fabricClone(this._closeButton));
    }
    this.setOverlayImage(new fabric.Group(overlayObjects));
    this.overlayImage.objectCaching = false;
  },

  redrawRulers: function () {
    const viewport = this.viewportTransform;

    const scaleX = viewport[0];
    const scaleY = viewport[3];
    const transformX = viewport[4];
    const transformY = viewport[5];

    const offsetLeftWithScale = ((this.getWidth() - this._bannerWidth) / 2) * scaleX + transformX;
    const offsetTopWithScale = ((this.getHeight() - this._bannerHeight) / 2) * scaleY + transformY;

    this.topRuler.redraw(this.getWidth(), offsetLeftWithScale, this.getZoom());
    this.leftRuler.redraw(this.getHeight(), offsetTopWithScale, this.getZoom());
  },

  _initializeCanvas: function () {
    this._canvasElement = fabric.util.getById('prisma-canvas');
    if (this._canvasElement) {
      return this._canvasElement;
    }

    this._rulerOptionsElement = fabric.util.makeElement('div', { class: 'ruler-options' });
    this._topRulerElement = fabric.util.makeElement('canvas', { id: 'prisma-top-ruler', class: 'top-ruler' });
    this._leftRulerElement = fabric.util.makeElement('canvas', { id: 'prisma-left-ruler', class: 'left-ruler' });
    this._canvasElement = fabric.util.makeElement('canvas', { id: 'prisma-canvas', class: 'main-canvas' });

    this._wrapperElement = fabric.util.makeElement('div', { class: 'canvas-wrapper' });
    this._wrapperElement.appendChild(this._rulerOptionsElement);
    this._wrapperElement.appendChild(this._topRulerElement);
    this._wrapperElement.appendChild(this._leftRulerElement);
    this._wrapperElement.appendChild(this._canvasElement);

    fabric.util.setStyle(this._wrapperElement, {
      position: 'absolute',
      display: 'grid',
      'grid-template-columns': `${RULER_SIZE}px 1fr`,
      'grid-template-rows': `${RULER_SIZE}px 1fr`,
      'grid-template-areas': '"ruler-options top-ruler" "left-ruler main-canvas"',
    });
    this._parentElement.appendChild(this._wrapperElement);

    this.topRuler = new PrismaRuler('prisma-top-ruler', { rulerType: RULER_TYPE.TOP, height: RULER_SIZE });
    this.leftRuler = new PrismaRuler('prisma-left-ruler', { rulerType: RULER_TYPE.LEFT, width: RULER_SIZE });

    return this._canvasElement;
  },

  _initializeResizeObserver: function () {
    this._observer = new ResizeObserver(([parent]) => {
      // Wrap in requestAnimationFrame to avoid this error:  ResizeObserver loop limit exceeded
      // https://stackoverflow.com/a/58701523
      window.requestAnimationFrame(() => {
        const { width, height } = parent.contentRect;
        if (!width && !height) {
          return;
        }

        // unselect any object first to avoid conflicts
        this.discardActiveObject();

        const availableWidth = width - RULER_SIZE;
        const availableHeight = height - RULER_SIZE;

        this.setWidth(availableWidth);
        this.setHeight(availableHeight);

        this.topRuler.setWidth(availableWidth);
        this.leftRuler.setHeight(availableHeight);

        if (this._banner) {
          const offsetTop = (height - this._bannerHeight - RULER_SIZE) / 2;
          const offsetLeft = (width - this._bannerWidth - RULER_SIZE) / 2;

          this._objects.forEach(obj => {
            obj.setOffset?.({
              offsetTop,
              offsetLeft,
            });
            obj.setCoords?.();
          });

          const offsetTopDiff = this.offsetTop ? offsetTop - this.offsetTop : 0;
          const offsetLeftDiff = this.offsetLeft ? offsetLeft - this.offsetLeft : 0;

          this.overlayImage.left = this.overlayImage.left + offsetLeftDiff;
          this.overlayImage.top = this.overlayImage.top + offsetTopDiff;

          const layers = this.layers.map(layer => {
            layer.animations = addOffsetsToAnimations(layer.animations, offsetLeftDiff, offsetTopDiff);
            return layer;
          });
          this.setLayers(layers);
          this.offsetTop = offsetTop;
          this.offsetLeft = offsetLeft;
        }

        this.redrawRulers();

        try {
          this.renderAll();
        } catch (e) {}
      });
    });

    this._observer.observe(this._parentElement);
  },

  _initializeGroups: function (objects) {
    const groups = objects.filter(({ type }) => type === PRISMA_GROUP);
    groups.forEach(group => {
      const groupObjects = objects.filter(({ id }) => group.objectIds.includes(id));
      const mask = groupObjects.find(({ type }) => type === PRISMA_MASK);
      group.setMask(mask);
      group.maskId = mask?.id;
      groupObjects.forEach(obj => {
        obj.groupId = group.id;
        if (obj.type !== PRISMA_MASK) {
          obj.maskId = mask?.id;
        }
      });
      group.setObjects(groupObjects);
      PrismaGroup.resetSize(group);
    });
  },

  _initializeLayers: function (objects) {
    const layers = objects.map(o => {
      let animations = addOffsetsToAnimations(o.animations, o.offsetLeft, o.offsetTop);
      animations = animations.map(a => {
        a.keyframes = a.keyframes.map(kf => new PrismaKeyframe({ ...kf, layerId: o.id, type: a.type }));
        return a;
      });

      return {
        id: o.id,
        target: o,
        name: o.name,
        type: o.type,
        animations,
        properties: o.toObject(),
      };
    });
    this.setLayers(layers);
  },

  dispose: function () {
    this._observer?.disconnect();
    this.callSuper('dispose');
    this._wrapperElement.removeChild(this._rulerOptionsElement);
    this._wrapperElement.removeChild(this._topRulerElement);
    this._wrapperElement.removeChild(this._leftRulerElement);
    this._wrapperElement.removeChild(this._canvasElement);
    this._parentElement.removeChild(this._wrapperElement);
    return this;
  },

  getPage: function (json) {
    // TODO: refactor function when implementing pages
    // for now we only have one page, this function should receive another parameter to load a specific page
    return json.pages?.[0];
  },

  loadFromPrismaJSON: function (serialized, baseUrl, callback) {
    if (!serialized) {
      return;
    }

    const json = typeof serialized === 'string' ? JSON.parse(serialized) : fabricClone(serialized);
    const objects = this._getObjectsFromJSON(json, baseUrl);
    this.loadFromJSON({ objects }, () => {
      this._initializeOverlay(json.width, json.height);
      this.engine = new AnimationEngine({
        renderAll: this.renderAll.bind(this),
        ease: fabric.util.ease,
        onTick: tick => this.fire(eventsToKeys.onTick, tick),
        onAnimationBegin: () => this.fire(eventsToKeys.onAnimationBegin),
        onAnimationEnd: () => this.fire(eventsToKeys.onAnimationEnd),
        onAnimationPaused: () => this.fire(eventsToKeys.onAnimationPause),
      });
      this._initializeGroups(this.getObjects());
      this._initializeLayers(this.getObjects());
      this.renderAll(); // Not rendering cause weird effects on groups
      callback && callback();
    });
  },

  loadFromSnapshot: function (json, baseUrl, callback) {
    if (!json) {
      return;
    }
    const objects = this._getObjectsFromJSON(json, baseUrl);

    this.loadFromJSON({ objects }, () => {
      // Move the banner to the beginning of the array
      const bannerIndex = this._objects.findIndex(({ type }) => type === PRISMA_BANNER);
      this._objects.splice(0, 0, this._objects.splice(bannerIndex, 1)[0]);

      this._initializeGroups(this.getObjects());
      this._initializeLayers(this.getObjects());
      this._loadOverlayImage();

      this.renderAll(); // Not rendering cause weird effects on groups

      callback && callback();
    });
  },

  _getObjectsFromJSON: function (json, baseUrl) {
    const layers = this.getPage(json)?.layers || [];

    return layers.map(layer => {
      return {
        id: layer.id,
        type: perseusToPrismaMapping[layer.type] || layer.type,
        name: layer.name,
        animations: layer.animations,
        ...layer.properties,
        angle: layer.properties.rotation,
        offsetTop: this.offsetTop,
        offsetLeft: this.offsetLeft,
        top: this.offsetTop + layer.properties.y,
        left: this.offsetLeft + layer.properties.x,
        baseUrl,
      };
    });
  },

  getObjects: function (type) {
    if (typeof type === 'undefined') {
      // these types are filtered because they are not considered as layers
      const fixedObjects = [PRISMA_BANNER, PRISMA_CLOSE_BUTTON, PRISMA_GUIDELINE, PRISMA_LANDSCAPE];
      return this._objects.filter(o => !fixedObjects.includes(o.type));
    }
    return this._objects.filter(function (o) {
      return o.type === type;
    });
  },

  toObject: function (overwriteProperties = false) {
    const { objects } = this.callSuper('toObject');

    return {
      layers: this.layers.map(l => {
        const offsetLeft = l.target.offsetLeft;
        const offsetTop = l.target.offsetTop;
        const animations = removeOffsetsFromAnimations(l.animations, offsetLeft, offsetTop);
        return {
          id: l.id,
          name: l.name || '',
          type: prismaToPerseusMapping[l.type] || l.type,
          animations: animations,
          properties: overwriteProperties ? objects.find(o => o.id === l.id) : l.properties,
        };
      }),
    };
  },

  toJSON: function () {
    return this.toObject();
  },

  setLayers: function (layers) {
    this.layers = layers;
    this.keyframes = this.engine.calculateKeyframesForAnimation(layers);
    this.fire(eventsToKeys.onLayersChange, this.layers);
  },

  changezOrder: function (selectedId, overId) {
    if (selectedId === overId) {
      // same layer, do nothing
      return;
    }

    const selectedLayer = findLayerById(this.layers, selectedId);
    const overLayer = findLayerById(this.layers, overId);
    const selectedObj = selectedLayer?.target;
    const overObj = overLayer?.target;
    if (!selectedObj || !overObj || selectedObj.type === PRISMA_MASK) {
      // at least one layer object does not exist
      // or selected object is a mask
      return;
    }

    let updatedLayers = this.layers;
    const updatedGroups = handleChangeGroupZOrder(selectedLayer, overLayer, updatedLayers);

    if (updatedGroups.length) {
      updatedGroups.forEach(group => PrismaGroup.resetSize(group));
      // remove empty groups
      const emptyGroups = this._getEmptyGroups(updatedLayers);
      updatedLayers = this._removeEmptyGroups(updatedLayers, emptyGroups);
    }

    const filteredLayers = updatedLayers.filter(l => l.id !== selectedId);
    // get indexes
    const selectedLayerIndex = updatedLayers.findIndex(l => l.id === selectedId);
    const overIdLayerIndex = updatedLayers.findIndex(l => l.id === overId);
    // get layers
    this.layers = [
      ...filteredLayers.slice(0, overIdLayerIndex),
      updatedLayers[selectedLayerIndex],
      ...filteredLayers.slice(overIdLayerIndex),
    ];

    this.sortLayersIndex();
  },

  sortLayersIndex: function () {
    // 1. get layers not belonging to a group
    let orderedLayers = this.layers.filter(({ target }) => !target.groupId);

    // 2. get groups
    const groupLayers = this.layers.filter(({ target }) => target.type === PRISMA_GROUP);
    groupLayers.forEach(groupLayer => {
      const index = orderedLayers.findIndex(({ id }) => id === groupLayer.id);
      if (index !== -1) {
        const childLayers = [];
        groupLayer.target.objects.forEach(o => {
          const childLayer = this.layers.find(({ id }) => o.id === id);
          if (childLayer) {
            childLayers.push(childLayer);
            // child layer should not be present in the main list
            // this can happen when layers are sorted but groupId was not assigned yet
            // to avoid duplicates, we remove the child layer from the main list
            orderedLayers = orderedLayers.filter(({ id }) => id !== childLayer.id);
          }
        });
        // 3. insert group objects right after group
        orderedLayers.splice(index, 0, ...childLayers);
      }
    });

    // 4. order indexes in canvas
    orderedLayers.forEach((layer, index) => {
      layer.target.moveTo(index + 1);
    });

    // 5. set ordered layers
    this.setLayers(orderedLayers);
  },

  addLayerToCanvas(layer, newId = 1) {
    const object = layer.target;
    const name = generateLayerName(object, this.layers);
    const biggestId = this.layers.reduce((acc, { id }) => (id > acc ? id : acc), 0);
    // newId is the new id coming from the pages, the canvas could have more layers with bigger ids
    // in the future, when we implement pages we could solve this if we have the canvas updated in the pages
    // but this is a safety check to avoid repeated ids if we don't have the layers updated in the pages
    const id = newId <= biggestId ? biggestId + 1 : newId;

    // set new props and reset placeholders to generate them with correct id in toObject() method
    object.setOptions({ id, name, clickPlaceholder: null, imagePlaceholder: null, textPlaceholder: null });
    object.offsetLeft = this.offsetLeft;
    object.offsetTop = this.offsetTop;

    layer.id = id;
    layer.name = name;
    layer.animations.forEach(animation =>
      animation.keyframes.forEach(keyframe => {
        keyframe.layerId = id;
      }),
    );
    layer.properties = layer.target.toObject();

    if (object.groupId) {
      const group = this.layers.find(layer => layer.id === object.groupId);
      if (group) {
        group.target.addObject(object);
      }
    }

    this.setLayers([...this.layers, layer]);
    this.sortLayersIndex();
    this.add(object);
    this.moveTo(object, this.layers.length);
    this.fire(eventsToKeys.onLayerCreate, layer);
    return layer;
  },

  /**
   * It creates a new layer and adds it to the canvas.
   * @param {*} object target object
   * @param {number} newId new layer id
   * @returns created layer
   */
  addLayer: function (object, newId) {
    const layer = {
      animations: [],
      target: object,
      type: object.type,
    };
    return this.addLayerToCanvas(layer, newId);
  },

  _cleanupUnlinkedGroupAnimations: function (updatedLayers) {
    updatedLayers.forEach(layer => {
      if (layer.type !== PRISMA_GROUP && !layer.target.groupId) {
        layer.animations = layer.animations.filter(({ isGroupAnimation }) => !isGroupAnimation);
      }
    });
    return updatedLayers;
  },

  removeObjectsFromLayers: function (objects, layers) {
    objects.forEach(obj => {
      this.remove(obj);
      layers = layers.filter(l => l.target.id !== obj.id);
    });
    return layers;
  },

  /**
   * It removes objects if they are in a group. It returns the groups that need to be updated because an object was deleted
   * @param {array} objects objects to be removed
   * @param {array} layers canvas layers
   * @returns {array} groups to be updated
   */
  removeGroupLayersIfNeeded: function (objects, layers) {
    const groupsToUpdate = [];
    objects.forEach(object => {
      const group = layers.find(({ target }) => target.id === object.groupId)?.target;
      if (group) {
        group.removeObject(object);
        groupsToUpdate.push(group);
      }
    });
    return groupsToUpdate;
  },

  /**
   * It removes empty groups from canvas layers. It returns the updated layers. and it modifies the groupsToUpdate array
   * @param {array} layers canvas layers
   * @param {array} emptyGroups groups to be removed
   * @return {array} updated layers
   */
  _removeEmptyGroups: function (layers, emptyGroups) {
    emptyGroups.forEach(({ target }) => {
      layers = this.removeObjectsFromLayers([target.objects[0], target], layers);
    });
    return layers;
  },

  removeLayer: function (object, shouldRemoveChildren = true) {
    if (!object) {
      return;
    }

    let updatedLayers = this.layers;

    const objectsToRemove = [object];
    if (object.type === ACTIVE_SELECTION || (object.type === PRISMA_GROUP && shouldRemoveChildren)) {
      objectsToRemove.push(...object.getObjects());
    }

    updatedLayers = this.removeObjectsFromLayers(objectsToRemove, updatedLayers);

    // if the layer belongs to a group, remove it also from the group, and get the affected groups
    let groupsToUpdate = this.removeGroupLayersIfNeeded(objectsToRemove, updatedLayers);
    if (groupsToUpdate.length) {
      // remove empty groups if any
      const emptyGroups = this._getEmptyGroups(updatedLayers);
      updatedLayers = this._removeEmptyGroups(updatedLayers, emptyGroups);
      // empty groups will be removed, so we don't need to resize them later
      groupsToUpdate = groupsToUpdate.filter(({ id }) => !emptyGroups.find(group => group.id === id));
    }

    if (object.type === PRISMA_GROUP && !shouldRemoveChildren) {
      // remove any group animation to children left
      updatedLayers = this._cleanupUnlinkedGroupAnimations(updatedLayers);
    }

    // we set the canvas layers before resizing because we may have old layers
    this.setLayers(updatedLayers);

    if (groupsToUpdate.length) {
      groupsToUpdate.forEach(group => PrismaGroup.resetSize(group));
    }
    // this.fire(eventsToKeys.onLayerRemoved); commented because I don't see it as needed, undo/redo works fine
  },

  _updateLayerInList: function (layer, layers) {
    const index = layers.findIndex(({ id }) => layer.id === id);
    if (index === -1) {
      return layers;
    }
    return [...layers.slice(0, index), layer, ...layers.slice(index + 1)];
  },

  /**
   * Given a group and its child, calculates the relative position of the child and updates the keyframes of position animation.
   * @param {object} groupLayer - Group layer.
   * @param {object} childLayer - Child layer.
   * @param {array} keyframes - Position animation keyframes.
   * @return {array} The updated keyframes.
   */
  _getGroupChildPositionKeyframes: function (groupLayer, childLayer, keyframes) {
    const group = groupLayer.target;
    const childObj = childLayer.target;

    // get child object relative position
    const { x, y } = getUnrotatedPosition(group);
    // initial position of the group, using the anchor point of the child
    const initialGroupPosition = group.translateToGivenOrigin(
      { x, y },
      group.originX,
      group.originY,
      childObj.originX,
      childObj.originY,
    );
    // initial child position
    const childUnrotatedPosition = getUnrotatedPosition(childObj);
    // get offsets using the anchor point of the child
    const diffLeft = childUnrotatedPosition.x - initialGroupPosition.x;
    const diffTop = childUnrotatedPosition.y - initialGroupPosition.y;

    // update keyframes points
    return keyframes.map(keyframe => {
      const keyframePosition = group.translateToGivenOrigin(
        { x: keyframe.properties.x, y: keyframe.properties.y },
        group.originX,
        group.originY,
        childObj.originX,
        childObj.originY,
      );
      const childKeyframe = keyframe.clone();
      childKeyframe.layerId = childLayer.id;
      childKeyframe.properties = { x: keyframePosition.x + diffLeft, y: keyframePosition.y + diffTop };
      return childKeyframe;
    });
  },

  _getGroupChildAnimation: function (groupLayer, childLayer, initialAnimation) {
    // initially use the same animation
    const newChildAnimation = {
      ...initialAnimation,
      isGroupAnimation: true,
    };
    // if animation is position update keyframe points
    if (initialAnimation.type === animationTypes.position) {
      newChildAnimation.keyframes = this._getGroupChildPositionKeyframes(
        groupLayer,
        childLayer,
        initialAnimation.keyframes,
      );
    }
    return newChildAnimation;
  },

  // if active layer is a group, apply default animations to objects inside
  _applyGroupAnimationsToChildrenIfNeeded: function (activeLayer, updatedLayers) {
    if (activeLayer.type === PRISMA_GROUP && activeLayer.animations) {
      const childrenLayers = updatedLayers.filter(({ target }) => target.groupId === activeLayer.id);
      childrenLayers.forEach(childLayer => {
        // init childAnimations with regular animations (not from group)
        const childAnimations = childLayer.animations.filter(a => !a.isGroupAnimation);
        // override group animations into children
        activeLayer.animations.forEach(animation => {
          if (childAnimations.find(({ type }) => type === animation.type)) {
            return; // if animation was already set, do not override
          }
          const newChildAnimation = this._getGroupChildAnimation(activeLayer, childLayer, animation);
          childAnimations.push(newChildAnimation);
        });

        const updatedChildLayer = { ...childLayer, animations: childAnimations };
        updatedLayers = this._updateLayerInList(updatedChildLayer, updatedLayers);
      });
    }
    return updatedLayers;
  },

  // if active layer belongs to a group and some animations are deleted, restore the group animations
  _resetParentGroupAnimationsIfNeeded: function (activeLayer, updatedLayers) {
    if (activeLayer.target.groupId) {
      // get group
      const groupLayer = updatedLayers.find(({ target }) => target.id === activeLayer.target.groupId);
      // init not group animations of the child
      const childAnimations = activeLayer.animations.filter(a => !a.isGroupAnimation);
      const childAnimationsTypes = [...new Set(childAnimations.map(({ type }) => type))];
      // build child animations
      groupLayer.animations
        .filter(({ type }) => !childAnimationsTypes.includes(type)) // excluded the ones already added
        .forEach(animation => {
          const newChildAnimation = this._getGroupChildAnimation(groupLayer, activeLayer, animation);
          childAnimations.push(newChildAnimation);
        });

      activeLayer = { ...activeLayer, animations: childAnimations };
    }
    return activeLayer;
  },

  // if any mask layer has lockedPosition, remove position animation
  _removeMaskPositionAnimationIfNeeded: function (updatedLayers) {
    updatedLayers.forEach(layer => {
      if (!!layer.target.lockedPosition) {
        layer.animations = layer.animations.filter(({ type }) => type !== animationTypes.position);
      }
    });
    return updatedLayers;
  },

  editLayer: function (id, props) {
    let activeLayer = this.layers.find(l => l.id === id);
    if (!activeLayer) {
      return;
    }

    let updatedLayers = [...this.layers];

    let updatedLayer = { ...activeLayer, ...props };
    updatedLayer = this._resetParentGroupAnimationsIfNeeded(updatedLayer, updatedLayers);

    updatedLayers = this._updateLayerInList(updatedLayer, updatedLayers);
    updatedLayers = this._applyGroupAnimationsToChildrenIfNeeded(updatedLayer, updatedLayers);
    updatedLayers = this._removeMaskPositionAnimationIfNeeded(updatedLayers);

    this.setLayers(updatedLayers);
    this.fire(eventsToKeys.onLayerUpdate, updatedLayer);

    return updatedLayer;
  },

  updateMaskAnimations: function (mask) {
    const layer = this.layers.find(l => l.id === mask.id);
    const newAnimations = mask.lockedPosition
      ? [...layer.animations.filter(a => a.type !== animationTypes.position)]
      : []; // we set empty animations so it takes all from the group
    this.editLayer(mask.id, { animations: newAnimations });
  },

  changeEasing: function (type, easing, layer) {
    this.editLayer(layer.id, {
      animations: [
        ...layer.animations.map(anim => {
          if (anim.type === type) {
            return {
              ...anim,
              easing,
            };
          }
          return anim;
        }),
      ],
    });
  },

  changeRepeat: function (type, layer, repeat, repeatInfinitely = false) {
    this.editLayer(layer.id, {
      animations: [
        ...layer.animations.map(anim => {
          if (anim.type === type) {
            return {
              ...anim,
              repeat,
              repeatInfinitely,
            };
          }
          return anim;
        }),
      ],
    });
  },

  /**
   * Given a list of keyframes merges the keyframes in the corresponding layer and animation.
   * If a keyframe is merged in the same time than other, it is replaced.
   * @param {array} keyframes - List of keyframes to merge.
   * @return {array} The updated layers.
   */
  mergeKeyframes: function (keyframes) {
    const layersIdsToUpdate = keyframes.reduce((acc, keyframe) => {
      acc.add(keyframe.layerId);
      return acc;
    }, new Set());

    const layersToUpdate = this.layers.filter(({ id }) => layersIdsToUpdate.has(id));
    const updatedLayers = layersToUpdate.map(layer => this.mergeKeyframesInLayer(keyframes, layer, false));
    return updatedLayers;
  },

  /**
   * Given a list of keyframes and a layer, merges the keyframes in the corresponding animation type.
   * If a keyframe is merged in the same time than other, it is replaced.
   * @param {array} keyframes - List of keyframes to merge.
   * @param {object} layer - Layer to update.
   * @param {boolean} shouldUpdateIds - Flag to indicate if new keyframes should have new ids generated or not.
   * For example, copy/paste needs new ids, but keyframes movement doesn't.
   * @return {object} The updated layer.
   */
  mergeKeyframesInLayer: function (keyframes, layer, shouldUpdateIds = true) {
    // for each animation we will check every keyframe
    const animationsUpdated = layer.animations.map(animation => {
      let updatedKeyframes = [...animation.keyframes];
      keyframes
        .filter(keyframe => keyframe.layerId === layer.id && keyframe.type === animation.type)
        .forEach(keyframe => {
          const newKeyframes = updatedKeyframes.filter(({ time }) => time !== keyframe.time); // replace keyframe with same time if exists
          const keyframeToMerge = shouldUpdateIds ? keyframe.clone() : keyframe;
          // we merge all the other keyframes with the new one, that could be already in the same time
          newKeyframes.push(keyframeToMerge);
          newKeyframes.sort((a, b) => a.time - b.time);
          updatedKeyframes = newKeyframes;
        });
      return { ...animation, keyframes: updatedKeyframes };
    });
    return this.editLayer(layer.id, { animations: animationsUpdated });
  },

  /**
   * Add or update keyframes from the specified layer. If some keyframe belongs
   * to an animation not present in the layer, the animation is added too.
   * @param {PrismaKeyframe|Array} keyframes - One or a list of keyframes to add or edit.
   * @param {number} layerId - Id of the layer to update.
   * @return {object} The updated layer.
   */
  addOrEditKeyframes: function (keyframes, layerId) {
    const updatedKeyframes = Array.isArray(keyframes) ? keyframes : [keyframes];
    const keyframesIds = updatedKeyframes.map(({ id }) => id);
    const layer = this.layers.find(({ id }) => id === layerId);

    // delete group animations if any, only for the involved animation types
    const updatedKeyframesTypes = [...new Set(updatedKeyframes.map(({ type }) => type))];
    const layerAnimations = [...layer.animations].filter(
      a => !a.isGroupAnimation || !updatedKeyframesTypes.includes(a.type),
    );

    const currentAnimationTypes = [...new Set(layerAnimations.map(({ type }) => type))];
    const newAnimations = [];

    // Add new animations if it's necessary
    updatedKeyframes.forEach(({ type }) => {
      const newAnimationTypes = newAnimations.map(({ type }) => type);
      if (!currentAnimationTypes.includes(type) && !newAnimationTypes.includes(type)) {
        newAnimations.push(getEmptyAnimation(type));
      }
    });

    // Add or edit keyframes
    const animations = [...newAnimations, ...layerAnimations].map(anim => {
      if (updatedKeyframes.some(({ type }) => type === anim.type)) {
        const animKeyframes = anim.keyframes.filter(({ id }) => !keyframesIds.includes(id)); // Not edited keyframes
        const sameTypeKeyframes = updatedKeyframes.filter(({ type }) => type === anim.type);
        animKeyframes.push(...sameTypeKeyframes);
        animKeyframes.sort((a, b) => a.time - b.time);
        return { ...anim, keyframes: animKeyframes };
      }
      return anim;
    });

    return this.editLayer(layer.id, { animations });
  },

  /**
   * Resets layer properties when it belongs to a group.
   * @param {object} layer - Layer.
   * @param {object} parentGroupLayer - Group layer.
   * @param {string} animationType - Animation type.
   * @param {number} time - Current time in timeline. Used to calculate the properties at that time if animation is removed.
   */
  resetLayerPropertiesFromGroup(layer, parentGroupLayer, animationType, time) {
    const parentGroupAnimation = parentGroupLayer.animations.find(a => a.type === animationType);
    if (parentGroupAnimation) {
      const keyframes = getNearKeyframes(time, parentGroupAnimation.keyframes);
      const newProps = this.engine.calculateNewProps(time, keyframes);
      if (animationType === animationTypes.position) {
        // apply layer offset relative to group
        const offsetX = parentGroupLayer.properties.x + layer.properties.x;
        const offsetY = parentGroupLayer.properties.y + layer.properties.y;
        newProps.left += offsetX;
        newProps.top += offsetY;
      }
      layer.target.setOptions(newProps);
    } else {
      // reset properties based on layer properties
      resetObjectPropertiesByType(layer.target, layer.properties, animationType);
    }
  },

  /**
   * When animation is removed, resets the layer and object properties.
   * @param {object} layer - Layer.
   * @param {string} animationType - Animation type.
   * @param {number} time - Current time in timeline. Used to calculate the properties at that time if animation is removed.
   */
  resetLayerPropertiesOnAnimationRemove: function (layer, animationType, time) {
    const object = layer.target;
    const parentGroupLayer = findLayerById(this.layers, object.groupId);
    if (parentGroupLayer) {
      this.resetLayerPropertiesFromGroup(layer, parentGroupLayer, animationType, time);
    } else {
      // object does not belong to a group
      const layersToUpdate = [layer];
      if (object.type === PRISMA_GROUP) {
        // if object is a group, restore also children layers without animations
        const childrenWithoutAnimations = this.layers.filter(l => {
          const animationsByType = l.animations.filter(({ type }) => type === animationType);
          const allAnimationsAreFromGroup = animationsByType.every(a => a.isGroupAnimation);
          return object.objectIds.includes(l.id) && (!animationsByType.length || allAnimationsAreFromGroup);
        });
        layersToUpdate.push(...childrenWithoutAnimations);
      }
      layersToUpdate.forEach(l => {
        resetObjectPropertiesByType(l.target, l.properties, animationType);
      });
    }
  },

  /**
   * Removes keyframe.
   * @param {PrismaKeyframe} keyframe - Keyframe to remove.
   * @param {number} currentTime - Current time in timeline. Used to calculate the properties at that time if animation is removed.
   * @return {object} The updated layer.
   */
  removeKeyframe: function (keyframe, currentTime) {
    const layer = this.layers.find(l => l.id === keyframe.layerId);
    const animation = layer.animations.find(a => a.type === keyframe.type);
    if (!animation || !animation.keyframes.length) {
      return;
    }
    let updatedAnimations = [];
    // remove whole animation if there is one keyframe left
    if (animation.keyframes.length === 1) {
      updatedAnimations = [...layer.animations.filter(a => a.type !== keyframe.type)];
      this.resetLayerPropertiesOnAnimationRemove(layer, keyframe.type, currentTime);
    } else {
      updatedAnimations = [
        ...layer.animations.map(anim => {
          if (anim.type === keyframe.type) {
            return {
              ...anim,
              keyframes: [...anim.keyframes.filter(k => k.id !== keyframe.id)],
            };
          }
          return anim;
        }),
      ];
    }

    return this.editLayer(layer.id, {
      animations: updatedAnimations,
    });
  },

  _getEmptyGroups: function (layers) {
    return layers.filter(({ target, type }) => type === PRISMA_GROUP && target.isEmpty());
  },

  /**
   * Gets the available canvas size, removing the ruler size.
   * @return {Object} An object containing the available width and height if can be calculated, otherwise width and height will be null.
   */
  getAvailableSize: function () {
    if (!this._parentElement) {
      return { width: null, height: null };
    }
    const { width, height } = this._parentElement.getBoundingClientRect();
    return { width: width - RULER_SIZE, height: height - RULER_SIZE };
  },

  /**
   * Recalculates layers and animations based on the new size, and updates the banner accordingly.
   *
   * @param {number} newWidth - The new width to resize the canvas to.
   * @param {number} newHeight - The new height to resize the canvas to.
   * @returns {Object[]} The recalculated layers with updated animations.
   */
  resizeCanvas: function (newWidth, newHeight) {
    this._bannerHeight = newHeight;
    this._bannerWidth = newWidth;
    if (this._banner) {
      this.remove(this._banner);
    }
    this._recalculateOffsets();
    this._initializeOverlay(newWidth, newHeight);
    this.redrawRulers();
  },
});

fabric.PrismaCanvas = PrismaCanvas;

export default PrismaCanvas;
