import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';

import { Col, Row } from '@akiunlocks/perseus-ui-components';

import LabeledInput from 'components/Sidebar/components/LabeledInput';
import ToggleIconButton from 'components/ToggleIconButton';

import './RectRadiusForm.scss';

const RectRadiusForm = ({ onLockRadiusChange, onRxChange, onRyChange, rx, ry, uniformRadius }) => {
  return (
    <Form.Group className="rect-radius-form">
      <Form.Label>Radius</Form.Label>
      <Row>
        <Col>
          <LabeledInput
            className="mb-1"
            id="radius-x"
            label="Rx"
            controlProps={{
              min: 0,
              onChange: onRxChange,
              placeholder: '',
              step: 1.0,
              type: 'number',
              value: rx,
            }}
          />
        </Col>
        <span className="lock-wrapper">
          <ToggleIconButton
            icons={['unlock', 'lock']}
            className="lock"
            onClick={event => {
              event.preventDefault();
              onLockRadiusChange(!uniformRadius);
            }}
            value={uniformRadius}
            iconSize={14}
          />
        </span>
        <Col>
          <LabeledInput
            className="mb-1"
            id="radius-y"
            label="Ry"
            controlProps={{
              min: 0,
              onChange: onRyChange,
              placeholder: '',
              step: 1.0,
              type: 'number',
              value: ry,
            }}
          />
        </Col>
      </Row>
    </Form.Group>
  );
};

RectRadiusForm.propTypes = {
  /** Action to take on lock radius change */
  onLockRadiusChange: PropTypes.func,
  /** Action to take on radius X change */
  onRxChange: PropTypes.func,
  /** Action to take on radius Y change */
  onRyChange: PropTypes.func,
  /** Radius X */
  rx: PropTypes.number,
  /** Radius Y */
  ry: PropTypes.number,
  /** Flag to indicate if rectangle has uniform radius */
  uniformRadius: PropTypes.bool,
};

RectRadiusForm.defaultProps = {
  onLockRadiusChange: () => {},
  onRxChange: () => {},
  onRyChange: () => {},
  rx: 0,
  ry: 0,
  uniformRadius: true,
};

export default RectRadiusForm;
