import { useEffect } from 'react';

import { useRootStore } from '../../../store';
import { columns, PAGE_SIZE_OPTIONS } from '../utils';
import { CONFIRM_BODIES, CONFIRM_TITLES } from '../../../utils';

import useTable from './useTable';
import useFilters from './useFilters';
import useDotsMenu from './useDotsMenu';
import useFavorites from './useFavorites';
import useAddProjectModal from './useAddProjectModal';

const useProjects = () => {
  const { projects: projectsStore, projectSizes: projectSizesStore } = useRootStore();
  const { projects } = projectsStore;

  const {
    status,
    setPage,
    filters,
    pageLimit,
    setStatus,
    projectsList,
    setPageLimit,
    setProjectsList,
    searchInputOnChange,
    activeFavoriteFilter,
  } = useFilters();

  const { archive, duplicate, handleRedirect, rename, selectedProject } = useDotsMenu({
    filters,
    setStatus,
  });

  const {
    newProjectErrors,
    newProjectName,
    setNewProjectName,
    setNewProjectType,
    showAddProjectModal,
    handleAddModalClose,
    handleAddModalCreate,
    addNewProjectLoading,
    setShowAddProjectModal,
  } = useAddProjectModal({ setStatus });

  const { setCurrentProjectAsFavorite } = useFavorites({
    filters,
    setStatus,
  });

  const { getTableRow } = useTable({
    archive,
    duplicate,
    handleRedirect,
    rename,
    setCurrentProjectAsFavorite,
  });

  useEffect(() => {
    setProjectsList(projects.data ? projects.data.map((item, key) => getTableRow(item, key)) : []);
  }, [getTableRow, projects, setProjectsList]);

  useEffect(() => {
    // on projects page load, reset any selected project or project sizes data from stores
    projectsStore.setProject(null);
    projectsStore.assets = [];
    projectSizesStore.setProjectSizes([]);
    projectSizesStore.setProjectSize(null);
  }, [projectSizesStore, projectsStore]);

  return {
    CONFIRM_BODIES,
    CONFIRM_TITLES,
    PAGE_SIZE_OPTIONS,
    activeFavoriteFilter,
    addNewProjectLoading,
    archive,
    columns,
    duplicate,
    filters,
    handleAddModalClose,
    handleAddModalCreate,
    newProjectErrors,
    newProjectName,
    pageLimit,
    projects,
    projectsList,
    searchInputOnChange,
    selectedProject,
    setNewProjectName,
    setNewProjectType,
    setPage,
    setPageLimit,
    setShowAddProjectModal,
    showAddProjectModal,
    status,
  };
};

export default useProjects;
