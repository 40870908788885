import React from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';
import { animationTypes } from '@prisma/lib/src/utils/types';
import { MAX_REPEAT_TIMES } from '@prisma/lib/src/constants/animations';
import { Checkbox, Icon, Select, Popover } from '@akiunlocks/perseus-ui-components';

import { useRootStore } from '../../../../store';

import { getRepeatProps } from './utilities';

import AnimationInput from './AnimationInput/AnimationInput';

import { easingOptions } from 'constants';

import './layerAccordion.scss';

const AnimationControls = observer(({ animation, layer }) => {
  const { editor, layerAccordionStore } = useRootStore();
  const { handlers } = layerAccordionStore;

  const allKeyframesWithRepeat = animation.keyframes.every(k => k.repeat);
  const allKeyframesWithoutRepeat = animation.keyframes.every(k => !k.repeat);

  const updateAllKeyframesRepeat = repeat => {
    animation.keyframes.forEach(k => {
      k.repeat = repeat;
      editor.canvas.addOrEditKeyframes.call(editor.canvas, k, layer.id);
    });
  };

  const onRepeatInputChange = v => {
    const oldValue = animation.repeat;
    const newValue = Math.max(1, Math.min(+v, MAX_REPEAT_TIMES));

    if (newValue === oldValue) {
      return;
    }

    handlers.repeatChange(animation.type, layer, newValue);

    if (oldValue === 1 && allKeyframesWithoutRepeat) {
      // If any keyframe has repeat and the repeat value increases, set all key frames to repeat
      updateAllKeyframesRepeat(true);
    } else if (newValue === 1 && allKeyframesWithRepeat) {
      // If all keyframes have repeat and the repeat value goes to 1, clear all keyframes repeat
      updateAllKeyframesRepeat(false);
    }
  };

  const onRepeatInfinitelyChange = () => {
    const repeatInfinitely = !animation.repeatInfinitely;

    if (repeatInfinitely && allKeyframesWithoutRepeat) {
      updateAllKeyframesRepeat(true);
    } else if (!repeatInfinitely && allKeyframesWithRepeat && animation.repeat === 1) {
      updateAllKeyframesRepeat(false);
    }

    handlers.repeatChange(animation.type, layer, animation.repeat, repeatInfinitely);
  };

  return (
    <div className="anim-controls">
      {animation.type !== animationTypes.hide && (
        <div className="left-block">
          <div className="anim-title">
            <span>Easing:</span>
          </div>
          <Select
            id={`easing-${layer.id}-${animation.type}`}
            size="xs"
            className="easing-select"
            onChange={event => {
              handlers.easingChange(animation.type, event.target.value, layer);
            }}
            value={easingOptions.find(option => option.value === animation.easing)}
            options={easingOptions}
          />
        </div>
      )}
      <div className="left-block repeat">
        <div className="anim-title">
          <Icon
            className={classnames({
              'loop-icon': animation.repeat > 1 || animation.repeatInfinitely,
            })}
            name="md-loop"
            size={20}
          />
          <span>Repeat</span>
        </div>
        <AnimationInput
          id={`repeat-${layer.id}-${animation.type}`}
          {...getRepeatProps(animation)}
          onChange={onRepeatInputChange}
        />
        <Popover id="repeat-infinitely-tooltip" content="Repeat infinitely">
          <div className="repeat-infinitely">
            <Checkbox
              className="checkbox"
              checked={!!animation.repeatInfinitely}
              id="repeat-infinitely"
              onChange={onRepeatInfinitelyChange}
            />
            <Icon name="infinity" size={16} />
          </div>
        </Popover>
      </div>
    </div>
  );
});

export default AnimationControls;
