import { ANCHORS_X, ANCHORS_Y } from '@prisma/lib/src/constants/objects';
import { getUnrotatedPosition } from '@prisma/lib/src/utils/helpers';
import { animationTypes, frameKeysToFabricProperties } from '@prisma/lib/src/utils/types';
import { TIMELINE_STEP } from 'components/TimeLineScene/constants';
import { ANIMATION_KEY_PROPERTY } from 'constants/objects';
import { ANIMATION_IDENTIFIER, GROUP_IDENTIFIER } from 'constants/timeline';
import { USE_RESPONSIVE } from 'utils/featureFlag';
import { getAnchorPosition } from 'utils/helpers';

/**
 * Checks if a keyframe can be added to a specific point in the timeline.
 * @param {number} currentTimeLineValue - Current value in the timeline.
 * @param {object} animation - Animation to check.
 * @return {boolean} True if keyframe can be added, else false.
 */
export function checkIfCanAddKeyframe(currentTimeLineValue, animation) {
  return animation.keyframes.every(
    // check if the timeline cursor is not to close to a keyframe
    ({ time }) => currentTimeLineValue <= time - TIMELINE_STEP || currentTimeLineValue >= time + TIMELINE_STEP,
  );
}

/**
 * Given a scaled value and the original property value, gets the scaled percentage.
 * @param {number} scaledValue - Scaled value.
 * @param {number} originalSize - Original size.
 * @param {number} retinaScalingFactor - Factor for retina scaling.
 * @return {number} Percentage value.
 */
export function scaledPxToPercentage(scaledValue, originalSize, retinaScalingFactor = 1) {
  return parseFloat((((scaledValue * 100) / originalSize) * retinaScalingFactor).toFixed(2));
}

/**
 * Given an object property value, and the applied scaling percentage, gets the final property value in pixels.
 * @param {number} value - Scale value.
 * @param {number} retinaScalingFactor - Factor for retina scaling.
 * @return {number} Pixel value.
 */
export function scalingPercentageToPx(value, percentage, retinaScalingFactor = 1) {
  return Math.round((value * percentage) / 100 / retinaScalingFactor);
}

/**
 * Calculates animation value from object properties.
 * When animation is position, offset is subtracted and value is rounded.
 * When animation is scaling, value is multiplied by 100.
 * @param {string} frameKey - Keyframe property name.
 * @param {object} object - object in canvas.
 * @param {string} animationType - Animation type.
 * @param {number} positionOffsetValue - Offset value (only needed for position animation). For example: offsetX, offsetY.
 * @param {number} retinaScalingFactor - Factor for retina scaling.
 * @param {object} canvas - Canvas object.
 * @return {number} Animation value.
 */
export function getAnimationValueFromObject(
  frameKey,
  object,
  animationType,
  positionOffsetValue = 0,
  retinaScalingFactor = 1,
  canvas,
) {
  const value = object[frameKeysToFabricProperties[frameKey]];

  switch (animationType) {
    case animationTypes.position:
      const unrotatedValue = getUnrotatedPosition(object)[frameKey];
      const positionValue = Math.round(unrotatedValue - positionOffsetValue);
      if (USE_RESPONSIVE) {
        const property = ANIMATION_KEY_PROPERTY[frameKey];
        const position = getAnchorPosition({ canvas, ...object, [property]: positionValue });
        return position[frameKey];
      }
      return positionValue;
    case animationTypes.scale:
      return parseFloat((value * 100 * retinaScalingFactor).toFixed(2));
    case animationTypes.rotation:
      return parseFloat(value.toFixed(2));
    case animationTypes.opacity:
      return parseInt(value);
    default:
      return value;
  }
}

/**
 * Calculates position animation value from user input and object anchor point.
 * @param {string} value - User input value.
 * @param {string} property - Property to update.
 * @param {object} object - Object being updated.
 * @return {number} Animation value using object anchor point.
 */
function getAnchorPointPositionAnimationValue(value, property, object) {
  const { canvas, originX, originY } = object;
  const { _bannerHeight, _bannerWidth } = canvas;
  if (property === ANIMATION_KEY_PROPERTY.x) {
    if (originX === ANCHORS_X.RIGHT) {
      value = _bannerWidth - value;
    } else if (originX === ANCHORS_X.CENTER) {
      value = _bannerWidth / 2 + value;
    }
  }
  if (property === ANIMATION_KEY_PROPERTY.y) {
    if (originY === ANCHORS_Y.BOTTOM) {
      value = _bannerHeight - value;
    } else if (originY === ANCHORS_Y.CENTER) {
      value = _bannerHeight / 2 + value;
    }
  }
  return value;
}

/**
 * Calculates animation value from user input.
 * When animation is position, offset is added.
 * When animation is scaling, value is divided by 100.
 * @param {string} value - User input value.
 * @param {string} animationType - Animation type.
 * @param {number} positionOffsetValue - Offset value (only needed for position animation). For example: offsetX, offsetY.
 * @param {number} retinaScalingFactor - Factor for retina scaling.
 * @param {string} property - Property to update.
 * @param {object} object - Object being updated.
 * @return {number} Animation value.
 */
export function getAnimationValueFromInput(
  value,
  animationType,
  positionOffsetValue,
  retinaScalingFactor = 1,
  property,
  object,
) {
  value = parseFloat(value);

  if (animationType === animationTypes.position) {
    if (USE_RESPONSIVE) {
      value = getAnchorPointPositionAnimationValue(value, property, object);
    }
    value += positionOffsetValue;
  }
  if (animationType === animationTypes.scale) {
    value = value / (100 * retinaScalingFactor);
  }

  return value;
}

/**
 * Gets the correct keyframe icon.
 * @param {boolean} isHovered - Whether the keyframe is hovered or not.
 * @param {boolean} canAdd - Whether a new keyframe can be added or not.
 * @return {object} Keyframe icon.
 */
export function getAddKeyframeIcon(isHovered, canAdd) {
  if (isHovered) {
    return {
      icon: 'diamond-fill',
      disabled: true,
    };
  }
  if (canAdd) {
    return {
      icon: 'diamond',
      disabled: false,
    };
  }
  if (!isHovered && !canAdd) {
    return {
      icon: 'diamond',
      disabled: true,
      className: 'control disabled',
    };
  }
}

/**
 * Checks if animations should be shown.
 * @param {Array} animations - Animations list.
 * @return {boolean} True if animations should be shown, else false.
 */
export function shouldShowAnimations(animations) {
  if (!animations) {
    return false;
  }
  return animations.some(({ isGroupAnimation }) => !isGroupAnimation);
}

/**
 * Checks if a keyframe is active.
 * @param {number} layerId - Layer id.
 * @param {string} animationType - Animation type.
 * @param {object} keyframe - keyframe.
 * @param {boolean} hasMultipleSelected - Whether multiple keyframes are selected or not.
 * @return {boolean} True if the keyframe is active, else false.
 */
export function checkIfKeyframeIsActive(layerId, animationType, keyframe, hasMultipleSelected) {
  return !!(keyframe && keyframe.layerId === layerId && keyframe.type === animationType && !hasMultipleSelected);
}

/**
 * Maps a group of objects to layers.
 * @param {object} layer - Layer.
 * @param {Array} layers - All layers.
 * @return {Array} Group layers.
 */
export function mapGroupObjectsToLayers(layer, layers) {
  const groupLayers = [];
  layer.target.objectIds.forEach(id => {
    const child = layers.find(l => id === l.id);
    if (child) {
      groupLayers.push(child);
    }
  });
  return groupLayers.reverse();
}

/**
 * Gets props for animation repeat input.
 * @param {object} animation - Animation.
 * @return {object} Props for animation repeat input.
 */
export function getRepeatProps(animation) {
  if (animation.repeatInfinitely) {
    return {
      value: 'inf',
      inputType: 'text',
      disabled: true,
    };
  }
  return {
    value: animation.repeat,
    inputType: 'number',
    disabled: false,
  };
}

/**
 * Checks if the repeat animation should be disabled or not.
 * @param {array} selectedKeyframes - Selected keyframes.
 * @param {object} animation - Animation.
 * @return {boolean} Whether the repeat should be disabled or not.
 */
export function checkIfRepeatIsDisabled(selectedKeyframes, animation) {
  const selectedKeyframesIds = selectedKeyframes.map(k => k.id);
  return !animation.keyframes.some(k => selectedKeyframesIds.includes(k.id));
}

/**
 * Adds animation identifier to the layer id.
 * @param {number} layerId - Layer id.
 * @return {string} Layet id with animation identifier.
 */
export function addAnimationIdentifier(layerId) {
  return `${layerId}${ANIMATION_IDENTIFIER}`;
}

/**
 * Adds group identifier to the layer id.
 * @param {number} layerId - Layer id.
 * @return {string} Layet id with group identifier.
 */
export function addGroupIdentifier(layerId) {
  return `${layerId}${GROUP_IDENTIFIER}`;
}

/**
 * Gets the corresponding tooltip for toggle animation button.
 * @param {boolean} disabled - Whether the animations are disabled or not.
 * @param {boolean} isOpen - Whether the animations are open or not.
 * @return {string} Tooltip message.
 */
export function getToggleAnimationTooltip(disabled, isOpen) {
  if (disabled) {
    return 'This layer does not have any animation';
  }
  if (isOpen) {
    return 'Hide animations';
  }
  return 'Show animations';
}
