import { action, observable } from 'mobx';

import { PER_PRIMARY } from '@prisma/lib/src/constants/canvas';

import ObjectStore from './Object.store';

import { DEFAULT_SHADOW } from '../../../constants';
import { DEFAULT_SHAPE } from 'constants/shapes';

class ShapeStore extends ObjectStore {
  fill = '';
  fillEnabled = false;
  shadowBlur = DEFAULT_SHADOW.blur;
  shadowColor = DEFAULT_SHADOW.color;
  shadowEnabled = false;
  shadowOffsetX = DEFAULT_SHADOW.offsetX;
  shadowOffsetY = DEFAULT_SHADOW.offsetY;
  stroke = DEFAULT_SHAPE.stroke;
  strokeWidth = 1;
  strokeEnabled = false;

  // observable properties to use in inherited classes
  shapeProps = {
    fill: observable,
    fillEnabled: observable,
    shadowBlur: observable,
    shadowColor: observable,
    shadowEnabled: observable,
    shadowOffsetX: observable,
    shadowOffsetY: observable,
    stroke: observable,
    strokeWidth: observable,
    strokeEnabled: observable,
    setFillEnabled: action,
    setShadowEnabled: action,
    getShadowProps: action,
    setShadowProps: action,
    setStrokeEnabled: action,
  };

  initialize(object) {
    super.initialize(object);

    this.fill = object.fill || PER_PRIMARY; // this is only to show in color picker input
    this.fillEnabled = !!object.fill;
    this.shadowBlur = object.shadow ? object.shadow.blur : DEFAULT_SHADOW.blur;
    this.shadowColor = object.shadow ? object.shadow.color : DEFAULT_SHADOW.color;
    this.shadowEnabled = !!object.shadow;
    this.shadowOffsetX = object.shadow ? object.shadow.offsetX : DEFAULT_SHADOW.offsetX;
    this.shadowOffsetY = object.shadow ? object.shadow.offsetY : DEFAULT_SHADOW.offsetY;
    this.strokeEnabled = !!object.stroke && !!object.strokeWidth;
    this.stroke = this.strokeEnabled ? object.stroke : DEFAULT_SHAPE.stroke; // this is only to show in color picker input
    this.strokeWidth = this.strokeEnabled ? object.strokeWidth : 1;
  }

  setFillEnabled(enable) {
    this.fillEnabled = enable;
  }

  setShadowEnabled(enable) {
    this.shadowEnabled = enable;
  }

  getShadowProps() {
    return {
      blur: this.shadowBlur,
      color: this.shadowColor,
      offsetX: this.shadowOffsetX,
      offsetY: this.shadowOffsetY,
    };
  }

  setShadowProps(props = {}) {
    const {
      blur = this.shadowBlur,
      color = this.shadowColor,
      offsetX = this.shadowOffsetX,
      offsetY = this.shadowOffsetY,
    } = props;

    this.shadowBlur = blur;
    this.shadowColor = color;
    this.shadowOffsetX = offsetX;
    this.shadowOffsetY = offsetY;
  }

  setStrokeEnabled(enable) {
    this.strokeEnabled = enable;
  }
}

export default ShapeStore;
