export const DEFAULT_FONT = 'Arial';
export const DEFAULT_FONT_FILE_NAME = 'Arial.ttf';

export const FONT_STYLES_OPTIONS = [
  {
    label: 'Normal',
    value: 'Normal',
  },
  {
    label: 'Italic',
    value: 'Italic',
  },
  {
    label: 'Oblique',
    value: 'Oblique',
  },
  {
    label: 'Bold',
    value: 'Bold',
  },
];
export const DEFAULT_FONT_STYLE = FONT_STYLES_OPTIONS[0];

export const DEFAULT_SHADOW = {
  color: '#000000',
  blur: 5,
  offsetX: 5,
  offsetY: 5,
};

export const DEFAULT_TEXTBOX = {
  width: 250,
  height: 34,
  angle: 0,
  objectOpacity: 100,
  fontSize: 25,
  fontFamily: DEFAULT_FONT,
  fontFileName: DEFAULT_FONT_FILE_NAME,
  fontStyle: DEFAULT_FONT_STYLE.value,
  textAlign: 'left',
  charSpacing: 0,
  lineHeight: 1,
  underline: false,
  linethrough: false,
  overline: false,
  fill: '#000000',
  shadow: null,
};
