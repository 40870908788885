/* eslint-disable object-shorthand */
/* eslint-disable func-names */
import { fabric } from 'fabric';

import { PRISMA_CIRCLE } from './constants/index.js';
import { PRISMA_SHAPE } from './prismaShape.js';

export const PrismaCircle = fabric.util.createClass(fabric.Circle, {
  ...PRISMA_SHAPE,

  label: 'Circle',
  type: PRISMA_CIRCLE,

  initialize: function (options) {
    this.callSuper('initialize', {
      ...options,
      strokeUniform: true, // keep border strokeWidth on scaling
    });
  },

  toObject: function () {
    return {
      ...PRISMA_SHAPE.toObject(this),
      radius: this.radius,
    };
  },

  clone() {
    return new PrismaCircle({
      ...this.toObject(),
      ...PRISMA_SHAPE.getCloneProperties(this),
    });
  },
});

PrismaCircle.fromObject = function (object, callback) {
  const rect = new PrismaCircle(object);
  return callback && callback(rect);
};

fabric.PrismaCircle = PrismaCircle;

export default PrismaCircle;
