import { useCallback, useState } from 'react';

import { ACTIVE_SELECTION, PRISMA_GROUP } from '@prisma/lib/src/constants';

import { useRootStore } from 'store';

import useEditorActiveObject from 'utils/useEditorActiveObject';
import { getPositionsFromActiveSelection } from '@prisma/lib/src/utils/helpers';

const useMultiSelectProperties = () => {
  const { editor } = useRootStore();
  const { activeObject, canvas, contextStore } = editor;
  const { setActiveObjectAttribute } = useEditorActiveObject();

  const [hide, setHide] = useState(false);

  const alignDisabled =
    !contextStore.getIsLayersTabSelected() && activeObject.getObjects().some(({ type }) => type === PRISMA_GROUP);

  const onAlignChange = useCallback(
    alignmentType => {
      // we have to build original object before calling setAlignment because top and left values will change
      const originals = getPositionsFromActiveSelection(activeObject.getObjects(), activeObject);

      editor.setAlignment(activeObject, alignmentType);

      contextStore.alignElements(activeObject, originals);

      // TODO eng: set top and left in store
      canvas.fire('history');
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editor, canvas, activeObject],
  );

  const onDistributeChange = useCallback(
    (distributionType, boundary) => {
      if (activeObject?.type !== ACTIVE_SELECTION) {
        return;
      }
      editor.distributeObjects(activeObject, distributionType, boundary);
      canvas.fire('history');
    },
    [activeObject, canvas, editor],
  );

  const onHideChange = useCallback(
    ({ target }) => {
      setHide(target.checked);
      setActiveObjectAttribute('hide', target.checked);
      canvas.fire('history');
    },
    [setActiveObjectAttribute, canvas],
  );

  return {
    alignDisabled,
    hide,
    onAlignChange,
    onDistributeChange,
    onHideChange,
  };
};

export default useMultiSelectProperties;
