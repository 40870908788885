/* eslint-disable object-shorthand */
/* eslint-disable func-names */
import { fabric } from 'fabric';

import { ALLOWED_STEPS_PX, DEFAULT_RULER_SIZE, RULER_TYPE } from './constants/ruler.js';
import { getHashMark, getHashMarkLabel, getRulerArray, getShift } from './utils/ruler.js';

const PrismaRuler = fabric.util.createClass(fabric.StaticCanvas, {
  type: 'prisma-ruler',

  initialize: function (element, options = {}) {
    this.callSuper('initialize', element, options);
    this.rulerType = options.rulerType || RULER_TYPE.TOP;
    this.scrollOffset = options.scrollOffset || 0;
  },

  redraw: function (size, offset, zoomLevel = 1) {
    this.clear();

    // the ruler step is calculated based on the zoom level and the allowed values
    let step = Math.round(1 / zoomLevel) * 10;
    step = ALLOWED_STEPS_PX.reduce((acc, curr) => (Math.abs(curr - step) < Math.abs(acc - step) ? curr : acc));
    // shift is the distance from the start of the ruler to the first hash mark
    const shift = getShift(offset, step, zoomLevel);
    // hashMarks is an array of hash marks positions
    const hashMarks = getRulerArray(shift, size || DEFAULT_RULER_SIZE, step * zoomLevel);
    // index of the position 0 on the canvas
    let canvasStartIndex = offset / (step * zoomLevel);
    canvasStartIndex = offset > 0 ? Math.floor(canvasStartIndex) : Math.ceil(canvasStartIndex);

    // draw hash marks and labels
    hashMarks.forEach((i, idx) => {
      const hasLabel = (canvasStartIndex - idx) % 10 === 0;
      this.add(getHashMark(i, this.rulerType, hasLabel));

      if (hasLabel) {
        this.add(getHashMarkLabel(i, idx, canvasStartIndex, step, this.rulerType));
      }
    });
  },
});

fabric.PrismaRuler = PrismaRuler;

export default PrismaRuler;
