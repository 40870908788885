import {
  PRISMA_CIRCLE,
  PRISMA_GROUP,
  PRISMA_IMAGE,
  PRISMA_MASK,
  PRISMA_RECT,
  PRISMA_TEXT,
} from '../constants/index.js';

export const FABRIC_OBJECT_PROPERTIES = {
  LEFT: 'left',
  TOP: 'top',
  OPACITY: 'opacity',
  OBJECT_OPACITY: 'objectOpacity',
  SCALE_X: 'scaleX',
  SCALE_Y: 'scaleY',
  ANGLE: 'angle',
  HIDE: 'hide',
};

export const animationTypes = {
  position: 'position',
  opacity: 'opacity',
  scale: 'scale',
  rotation: 'rotation',
  hide: 'hide',
};

export const ANIMATIONS_WITH_POSITION = [animationTypes.position, animationTypes.rotation];

export const typesToFrameKeys = {
  position: ['x', 'y'],
  opacity: ['objectOpacity'],
  scale: ['scaleX', 'scaleY'],
  rotation: ['rotation'],
  hide: ['hide'],
};

export const frameKeysToFabricProperties = {
  x: FABRIC_OBJECT_PROPERTIES.LEFT,
  y: FABRIC_OBJECT_PROPERTIES.TOP,
  objectOpacity: FABRIC_OBJECT_PROPERTIES.OBJECT_OPACITY,
  scaleX: FABRIC_OBJECT_PROPERTIES.SCALE_X,
  scaleY: FABRIC_OBJECT_PROPERTIES.SCALE_Y,
  rotation: FABRIC_OBJECT_PROPERTIES.ANGLE,
  hide: FABRIC_OBJECT_PROPERTIES.HIDE,
};

export const positionOffsetsKeys = {
  x: 'offsetLeft',
  y: 'offsetTop',
};

export const typesToFabricTypes = {
  image: 'Image',
  textbox: 'Textbox',
  text: 'Textbox',
  rect: 'Rect',
  circle: 'Circle',
};

export const statuses = {
  play: 'play',
  pause: 'pause',
  finished: 'finished',
};

export const eventsToKeys = {
  onLayerCreate: 'layer:created',
  onLayerUpdate: 'layer:update',
  onLayerRemoved: 'layer:removed',
  onLayersChange: 'layers:change',
  onTick: 'animation:tick',
  onAnimationBegin: 'animation:started',
  onAnimationPause: 'animation:paused',
  onAnimationEnd: 'animation:ended',
};

export const keysToEvents = {
  'layer:created': 'onLayerCreate',
  'layer:updated': 'onLayerUpdate',
  'layer:removed': 'onLayerRemove',
  'layers:change': 'onLayersChange',
  'animation:tick': 'onTick',
  'animation:started': 'onAnimationBegin',
  'animation:paused': 'onAnimationPause',
  'animation:ended': 'onAnimationEnd',
};

export const perseusToPrismaMapping = {
  circle: PRISMA_CIRCLE,
  group: PRISMA_GROUP,
  image: PRISMA_IMAGE,
  mask: PRISMA_MASK,
  rectangle: PRISMA_RECT,
  text: PRISMA_TEXT,
};

export const prismaToPerseusMapping = Object.entries(perseusToPrismaMapping).reduce(
  (acc, [key, value]) => ({ ...acc, [value]: key }),
  {},
);
