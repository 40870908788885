import React from 'react';
import { observer } from 'mobx-react';
import { Col, Container, Row, Stack } from 'react-bootstrap';

import Sidebar from '../../components/Sidebar';
import ArtBoard from '../../components/ArtBoard';
import Timeline from '../../components/Timeline';
import ProjectControls from '../../components/ProjectControls';

import useEditor from './Editor.hooks';
import useEditorHotkeys from './EditorHotkeys.hooks';
import useReportProjectInfo from 'hooks/ReportProjectInfo.hooks';

// import ChatBot from 'components/ChatBot/ChatBot'; // Commenting it so we reduce the bundle size

import './Editor.scss';

const Editor = observer(() => {
  const { handleDrop, handleDragOver, handleDragLeave } = useEditor();
  useEditorHotkeys();
  useReportProjectInfo();

  return (
    <Container fluid className="App" onDrop={handleDrop} onDragOver={handleDragOver} onDragLeave={handleDragLeave}>
      <Stack className="editor">
        <Row className="flex-grow-1 flex-nowrap">
          <Col className="flex-grow-0 p-0">
            <Sidebar />
          </Col>
          <Col className="p-0">
            <ArtBoard />
          </Col>
        </Row>
        <Row>
          <Col className="p-0">
            <Timeline />
          </Col>
        </Row>
        <Row className="bottom-row">
          <Col className="p-0">
            <ProjectControls />
          </Col>
        </Row>
      </Stack>
      {/* <ChatBot />) */}
    </Container>
  );
});

export default Editor;
