import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import AlignButtons from 'components/Sidebar/components/Align';
import Distribute from 'components/Sidebar/components/Distribute/Distribute';
import SidebarPanel from 'components/Sidebar/components/SidebarPanel';
import SidebarItem from 'components/Sidebar/components/SidebarPanel/SidebarItem';

import GroupsPanel from '../GroupsPanel';
import HideForm from '../HideForm';

import useMultiSelectProperties from './MultiSelectProperties.hooks';

const MultiSelectProperties = observer(({ disabled, groupLayersSelected, isTimelineLayersTab }) => {
  const { alignDisabled, hide, onAlignChange, onDistributeChange, onHideChange } = useMultiSelectProperties();

  return (
    <SidebarPanel title="Multi-selection">
      <SidebarItem disabled={alignDisabled || disabled}>
        <AlignButtons
          className="mb-1"
          groupLayersSelected={groupLayersSelected}
          onClick={onAlignChange}
          showBoundaries
        />
      </SidebarItem>
      <SidebarItem disabled={disabled || !isTimelineLayersTab}>
        <Distribute className="mb-1" onClick={onDistributeChange} />
      </SidebarItem>
      <SidebarItem>
        <GroupsPanel />
      </SidebarItem>
      <SidebarItem>
        <HideForm hide={hide} onHideChange={onHideChange} />
      </SidebarItem>
    </SidebarPanel>
  );
});

MultiSelectProperties.propTypes = {
  /** Flag to indicate if properties panel is disabled. */
  disabled: PropTypes.bool,
  /** Flag to indicate if selected layers belong to same group. */
  groupLayersSelected: PropTypes.bool,
  /** Flag to indicate if current tab is Layers tab. */
  isTimelineLayersTab: PropTypes.bool,
};

MultiSelectProperties.defaultProps = {
  disabled: false,
  isGroupSelected: false,
  isTimelineLayersTab: false,
};

export default MultiSelectProperties;
