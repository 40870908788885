import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Col, Row, Button, Icon } from '@akiunlocks/perseus-ui-components';

import { useRootStore } from '../../store';
import useSaveProject from 'utils/useSaveProject';

import { CONFIRM_BODIES, CONFIRM_TITLES } from '../../utils';

import Confirm from '../Confirm';

import './ProjectControls.scss';

const ProjectControls = observer(() => {
  const navigate = useNavigate();
  const { saveProject } = useSaveProject();
  const { projectId, projectSizeId } = useParams();
  const { editor, projectSizes } = useRootStore();
  const { savingProject } = editor;
  const { projectSize, unsavedChanges } = projectSizes;

  const [redirect, setRedirect] = useState('');
  const [showConfirm, setShowConfirm] = useState(false);

  const redirectToProjects = () => {
    if (!unsavedChanges) {
      navigate('/projects');
    }

    setShowConfirm(true);
    setRedirect('/projects');
  };
  const redirectToPreview = () => {
    if (!unsavedChanges) {
      navigate(`/projects/${projectId}/preview`);
    }

    setShowConfirm(true);
    setRedirect(`/projects/${projectId}/preview`);
  };

  const handleAccept = () => {
    projectSizes.setUnsavedChanges(false);
    navigate(redirect);
  };

  const handleClose = () => {
    setRedirect('');
    setShowConfirm(false);
  };

  return (
    <Row className="project-controls m-0">
      <Col className="p-0">
        <Button className="m-4" variant="light" disabled={savingProject} onClick={redirectToProjects}>
          <div className="projects-button-container">
            <Icon size="15" name="fa-home" />
            <span className="button-label">Projects</span>
          </div>
        </Button>
      </Col>
      <Col className="p-0">
        <Button className="m-4" variant="light" loading={savingProject} onClick={() => saveProject(projectSizeId)}>
          Save
        </Button>
        <Button className="m-4" disabled={savingProject} onClick={redirectToPreview}>
          Preview
        </Button>
      </Col>

      <Confirm
        showModal={showConfirm}
        handleClose={handleClose}
        handleAccept={handleAccept}
        title={CONFIRM_TITLES.PROJECT_SIZE_REDIRECT}
        body={CONFIRM_BODIES.PROJECT_SIZE_REDIRECT_SAVE.replace('{name}', projectSize?.name)}
      />
    </Row>
  );
});

export default ProjectControls;
