import React, { forwardRef, useEffect, useRef } from 'react';
import { observer } from 'mobx-react';
import { Tree } from 'react-arborist';
import { ReactMouseSelect } from 'react-mouse-select';

import { useRootStore } from 'store';

import { ASSET_CLASS_NAME } from 'components/TimeLineScene/components/Frame/constants';

import Node from './Node';
import RenderDragPreview from './RenderDragPreview';

import './AssetsTree.scss';

const AssetsTree = observer(
  forwardRef(({ height, indent = 15, onReplace, width }, ref) => {
    const { editor, projects } = useRootStore();
    const { assetStore } = editor;

    const containerRef = useRef();

    const assetsSignature = JSON.stringify(projects.assets);
    useEffect(() => {
      assetStore.buildTree(assetStore.getTreeData());
    }, [assetStore, assetsSignature]);

    const onFinishSelection = selectedItems => {
      const selectedIds = selectedItems.map(item => item.dataset.id);
      editor.selectionStore.addAssetsToSelection(selectedIds, true);

      const selectedAssetsIds = editor.selectionStore.getSelectedAssetsIds();
      const tree = ref.current;
      assetStore.onSelect(tree.visibleNodes.filter(node => selectedAssetsIds.includes(node.id)));
    };

    return (
      <div
        className="asset-tree-container"
        onDrop={() => {
          const selectedAssetIds = editor.selectionStore.getSelectedAssetsIds();
          assetStore.onMove({ dragIds: selectedAssetIds, parentId: '0' });
        }}
        onMouseLeave={() => {
          assetStore.setDragOverId(null);
        }}
        ref={containerRef}
      >
        <Tree
          className="assets-tree"
          data={[assetStore.root.model]}
          height={height}
          indent={indent}
          onMove={assetStore.onMove}
          onRename={assetStore.onRename}
          onReplace={onReplace}
          onSelect={assetStore.onSelect}
          ref={ref}
          renderCursor={() => null} // do not show line on drag
          renderDragPreview={RenderDragPreview}
          width={width}
        >
          {Node}
        </Tree>
        <ReactMouseSelect
          containerRef={containerRef}
          finishSelectionCallback={onFinishSelection}
          itemClassName={ASSET_CLASS_NAME}
          notStartWithSelectableElements
          onClickPreventDefault
          selectedItemClassName="mouse-selected"
          startSelectionCallback={e => {
            if (!e.metaKey && !e.shiftKey) {
              const tree = ref.current;
              tree?.deselectAll();
              editor.selectionStore.clearSelectedComponents();
            }
          }}
        />
      </div>
    );
  }),
);

export default AssetsTree;
