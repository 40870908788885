import { PER_PRIMARY } from '@prisma/lib/src/constants/canvas';

export const DEFAULT_SHAPE = {
  width: 100,
  height: 100,
  fill: PER_PRIMARY,
  strokeWidth: 0,
  stroke: '#000000',
  uniformScaling: true,
};

export const DEFAULT_RECT = DEFAULT_SHAPE;

export const DEFAULT_CIRCLE = {
  ...DEFAULT_SHAPE,
  radius: 25,
  scaleX: 2,
  scaleY: 2,
};
