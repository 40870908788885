export const GUIDELINE_TYPE = {
  VERTICAL: 'vertical',
  HORIZONTAL: 'horizontal',
};

export const LIMIT = 5000;

export const LINE_OPTIONS = {
  stroke: '#98d235',
  strokeWidth: 1,
  padding: 2,
};

export const LABEL_MARGIN = 10;

export const LABEL_FONT_SIZE = 12;

export const LABEL_STROKE_WIDTH = 10;

export const LABEL_OPTIONS = {
  backgroundColor: '#000000',
  fill: '#ffffff',
  fontFamily: 'Arial',
};
