import { v4 as uuidv4 } from 'uuid';
import { SERVICES } from '@prisma/api';
import { makeAutoObservable, runInAction, toJS } from 'mobx';
import { Notification, notify } from '@akiunlocks/perseus-ui-components';

import { ASSET, FOLDER, FOLDERS } from 'constants/assets';
import { PROJECT_TYPES } from 'constants/project';
import { areAssetsSynchronized, getFileExtension, getFileNameWithoutExtension, mapAssetProperties } from './utils';
import { validateDuplicatesAndRenameIfNeeded } from '@prisma/lib/src/utils/layers';
import {
  getFileNameFromImageUrl,
  getFolderNameFromImageUrl,
  getParentFolderFromImageUrl,
  sanitizeFileName,
  sanitizeFolderName,
} from 'containers/Editor/stores/utilities/assets';

class ProjectsStore {
  _perseusService;
  _S3Service;
  _service;
  _project = null;
  _projects = {};
  _assets = [];
  _projectAssetsBaseUrl = window.origin;

  constructor(root) {
    makeAutoObservable(this);

    this._S3Service = root.restClient.service(SERVICES.S3);
    this._service = root.restClient.service(SERVICES.projects);
    this._perseusService = root.restClient.service(SERVICES.perseus);
  }

  get assets() {
    return toJS(this._assets.map(a => ({ ...a, id: a._id, type: a.type || ASSET })));
  }

  set assets(assets) {
    return (this._assets = assets);
  }

  addProjectAssetByUrl = async (url, projectId) => {
    const pathname = new URL(url).pathname;

    const fileExtension = getFileExtension(pathname);
    const fileName = validateDuplicatesAndRenameIfNeeded(
      'image',
      this.assets.map(a => a.name),
    );
    const completeFileName = `${fileName}.${fileExtension}`;

    const tempAsset = {
      imageUrl: '',
      _id: uuidv4(),
      loading: true,
      imageThumbnailUrl: '',
      name: completeFileName,
    };

    this.assets = [
      tempAsset,
      ...this.assets.map(a =>
        a.name.toLowerCase() === fileName.toLowerCase()
          ? {
              ...a,
              hide: true,
            }
          : a,
      ),
    ];

    try {
      const uploadedFile = await this._S3Service.createFromUrl({
        projectId,
        url,
        fileName: completeFileName,
      });
      const asset = { ...tempAsset, ...uploadedFile.result.asset };
      runInAction(() => {
        const filteredAssets = this.assets.filter(a => a.name !== fileName).filter(a => a._id !== tempAsset._id);
        this.assets = [asset, ...filteredAssets];
      });

      return { asset, file: uploadedFile.result.file };
    } catch (e) {
      runInAction(() => {
        this.assets = [...this.assets.filter(a => a._id !== tempAsset._id).map(({ hide, ...a }) => a)];
      });
      throw e;
    }
  };

  addProjectAsset = async (file, projectId, overwrite, folder = FOLDERS.ASSETS) => {
    // create file data
    const data = new FormData();
    data.append('file', file);
    data.append('projectId', projectId);
    if (overwrite) {
      data.append('overwrite', 'true');
    }
    data.append('folder', folder);

    // create temp asset
    const tempAssetId = uuidv4();
    const fileName = getFileNameWithoutExtension(file.name);
    const tempAsset = {
      _id: tempAssetId,
      imageThumbnailUrl: '',
      imageUrl: `${folder}${sanitizeFileName(file.name)}`,
      loading: true,
      name: fileName,
      size: file.size,
      type: ASSET,
    };

    // add temp asset to list
    this.addTempAssetToAssetsList(tempAsset);

    try {
      // upload asset
      const uploadedFile = await this._S3Service.create(data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      const result = { ...tempAsset, ...uploadedFile.result };
      runInAction(() => {
        // remove temp asset from assets list
        const filteredAssets = this.assets.filter(a => a._id !== tempAsset._id);
        this.assets = [result, ...filteredAssets];
      });
      return result;
    } catch (e) {
      runInAction(() => {
        // remove hide property from original asset and return list without temp asset
        this.assets = [...this.assets.filter(a => a._id !== tempAsset._id).map(({ hide, ...a }) => a)];
      });
      throw e;
    }
  };

  hideAsset = assetId => {
    this.assets = [...this.assets.map(a => ({ ...a, hide: a.id === assetId }))];
  };

  /**
   * Adds assets to a project.
   * @param {object} data - The data object containing the assets and the project ID.
   * @param {array} data.assets - The array of assets to be added.
   * @param {string} data.id - The ID of the project to which the assets belong.
   * @throws {Error} Throws an error if an error occurs while adding the assets.
   */
  addAssets = async data => {
    const { assets, id } = data;
    const imageUrlsToAdd = assets.map(({ imageUrl }) => imageUrl);
    const assetsBefore = [
      // filter added assets and remove hide property
      ...this.assets.filter(a => !a.hide && !imageUrlsToAdd.includes(a.imageUrl)).map(({ hide, ...a }) => a),
    ];
    try {
      const result = await this._service.addAssets({
        assets: assets.map(a => ({ imageUrl: a.imageUrl, name: a.name, size: a.size })),
        id,
      });
      this.assets = result.assets;
    } catch (e) {
      this.restoreAssetsAndNotifyError(assetsBefore, 'adding');
      throw e; // we should start logging in honeybadger
    }
  };

  /**
   * Sets assets to a project.
   * @param {object} data - The data object containing the assets and the project ID.
   * @param {array} data.assets - The array of assets to be set.
   * @param {string} data.id - The ID of the project to which the assets belong.
   * @throws {Error} Throws an error if an error occurs while adding the assets.
   */
  setAssets = async data => {
    const assetsBefore = [...this.assets];
    const { assets, id } = data;
    try {
      const result = await this._service.setAssets({ assets, id });
      this.assets = result.assets;
    } catch (e) {
      this.restoreAssetsAndNotifyError(assetsBefore);
      throw e; // we should start logging in honeybadger
    }
  };

  /**
   * Removes project asset.
   * Also works for folders, which internally are assets.
   * @param {string} projectId - Project id.
   * @param {string} assetId - Asset id.
   * @throws {Error} Throws an error if an error occurs while deleting the assets.
   */
  removeProjectAsset = async (projectId, assetId) => {
    const assetsBefore = [...this.assets];
    try {
      this.assets = [...this.assets.filter(({ _id }) => _id !== assetId)];
      await this._S3Service.remove(projectId, { query: { assetId } });
      return true;
    } catch (e) {
      this.restoreAssetsAndNotifyError(assetsBefore, 'deleting');
      return false;
    }
  };

  /**
   * Moves asset to folder.
   * @param {string} imageUrl - Image url of asset to be moved.
   * @param {string} folder - Destination folder.
   * @param {boolean} overwrite - Flag to indicate if asset should be overwritten.
   * @throws {Error} Throws an error if an error occurs while deleting the assets.
   */
  moveAssetToFolder = async (imageUrl, folder, overwrite) => {
    const assetsBefore = [...this.assets];
    try {
      const projectId = this.getProjectId();
      const fileName = getFileNameFromImageUrl(imageUrl);
      const sourceFolder = getParentFolderFromImageUrl(imageUrl);
      const result = await this._S3Service.moveAsset({
        projectId,
        fileName,
        sourceFolder,
        destinationFolder: folder,
        overwrite,
      });
      const asset = this.assets.find(a => a.imageUrl === imageUrl);
      const id = uuidv4();
      const movedAsset = { ...asset, ...result.asset, _id: id, id };
      return movedAsset;
    } catch (e) {
      this.restoreAssetsAndNotifyError(assetsBefore, 'moving');
      throw e;
    }
  };

  moveFolderToFolder = async (imageUrl, folder) => {
    const assetsBefore = [...this.assets];
    try {
      const projectId = this.getProjectId();
      const folderName = getFolderNameFromImageUrl(imageUrl);
      const sourceFolder = getParentFolderFromImageUrl(imageUrl);
      const result = await this._S3Service.moveFolder({
        projectId,
        folderName,
        sourceFolder,
        destinationFolder: folder,
      });

      const movedAssets = result.movedAssets.map(movedAsset => {
        const asset = this.assets.find(a => a.imageUrl === movedAsset.oldImageUrl);
        const id = uuidv4();
        return { ...asset, ...movedAsset, _id: id, id };
      });
      return movedAssets;
    } catch (e) {
      this.restoreAssetsAndNotifyError(assetsBefore, 'moving');
      throw e;
    }
  };

  renameProjectFolder = async (newName, imageUrl) => {
    const assetsBefore = [...this.assets];
    try {
      const projectId = this.getProjectId();
      const parentFolder = getParentFolderFromImageUrl(imageUrl, FOLDER);
      const newFolderName = `${sanitizeFolderName(newName)}/`;
      const result = await this._S3Service.moveFolder({
        projectId,
        folderName: newFolderName,
        sourceFolder: imageUrl,
        destinationFolder: parentFolder,
      });
      const movedAssets = result.movedAssets.map(movedAsset => {
        const asset = this.assets.find(a => a.imageUrl === movedAsset.oldImageUrl);
        const id = uuidv4();
        // rename original folder
        if (asset.imageUrl === imageUrl) {
          asset.name = newName;
        }
        return { ...asset, ...movedAsset, _id: id, id };
      });
      return movedAssets;
    } catch (e) {
      this.restoreAssetsAndNotifyError(assetsBefore, 'renaming');
      throw e;
    }
  };

  restoreAssetsAndNotifyError = (assets, action = 'updating') => {
    this.assets = assets;
    notify(`An error has occurred while ${action} asset. Please, try again.`, Notification.TYPE.ERROR);
  };

  get projects() {
    return toJS(this._projects);
  }

  setProjects = projects => {
    return (this._projects = projects);
  };

  showProjectRenameInput = id => {
    this._setProjectRenameInput(id, true);
    return true;
  };

  cancelProjectRenameInput = id => {
    this._setProjectRenameInput(id, false);
  };

  addProject = async (project, userId) => {
    return this._service.create({ userId, ...project });
  };

  get project() {
    return toJS(this._project);
  }

  setProject(project) {
    this._project = project;
  }

  getProjectId = () => {
    return this.project?._id;
  };

  get projectAssetsBaseUrl() {
    return toJS(this._projectAssetsBaseUrl);
  }

  getProject = async projectId => {
    const project = await this._service.get(projectId);

    runInAction(() => {
      this.setProject(project);
      this._projectAssetsBaseUrl = `${window.origin}/storage/projects/${projectId}/`;
    });

    // get assets from S3 and
    const s3Assets = await this._S3Service.getAssets({ projectId });

    // map properties from DB
    mapAssetProperties(s3Assets, project.assets);

    // synchronize assets from S3 if needed
    // assets should always be synchronized with S3
    if (!areAssetsSynchronized(s3Assets, project.assets)) {
      await this._service.patch(projectId, {
        ...project,
        assets: s3Assets.map(asset => ({ ...asset, _id: uuidv4() })),
      });
    }

    this.assets = s3Assets;
  };

  getProjects = async query => {
    const projects = await this._service.find({ query });

    runInAction(() => {
      this._projects = projects;
    });
  };

  getAllProjectsList = async () => {
    return this._service.findAll();
  };

  getAvailableProjectsToCopy = async (exclude, isDOOH) => {
    // if is DOOH, only show DOOH projects, otherwise show all except DOOH (non-DOOH and old projects without type)
    return this._service.findAll({
      query: {
        _id: { $ne: exclude },
        type: isDOOH ? PROJECT_TYPES.DOOH : { $ne: PROJECT_TYPES.DOOH },
      },
    });
  };

  updateProjectFavorite = async ({ sizes, ...project }) => {
    return this._service.patch(project._id, {
      ...project,
      favorite: !project.favorite,
    });
  };

  updateProjectName = async ({ sizes, rename, ...project }) => {
    return this._service.patch(project._id, project);
  };

  moveProjectToArchive = async project => {
    try {
      if (project.perseusHashId) {
        const deleteCompositeSet = await this._perseusService.deleteCompositeSet({
          perseusHashId: project.perseusHashId,
        });
        if (deleteCompositeSet.error === true) {
          return { error: true, message: deleteCompositeSet.message }; // api error or validation messages
        }
      }
      return this._service.patch(project._id, { ...project, deletedAt: new Date() });
    } catch (e) {
      return { error: true, message: e.message };
    }
  };

  duplicateProject = async ({ _id, name, favorite, type }, userId) => {
    const original = await this._service.get(_id);
    const duplicated = await this._service.create({
      name,
      userId,
      favorite,
      type: type || PROJECT_TYPES.RICH_MEDIA_PERSONALIZED, // default to personalized for old projects
    });
    if (original.assets?.length) {
      await this._service.patch(duplicated._id, {
        ...duplicated,
        assets: original.assets.map(asset => ({ ...asset, _id: uuidv4() })),
      });
    }
    return duplicated;
  };

  /**
   * Copies project assets from one project to another using the specified asset mapping.
   * @param {string} projectIdFrom - The id of the project from which assets will be copied.
   * @param {string} projectIdTo - The id of the project to which assets will be copied.
   * @param {Object} assetsMap - The mapping of assets to be copied, specifying the relationship between source and destination.
   * @returns {object} The updated project.
   */
  copyProjectAssets = async (sourceProjectId, destinationProjectId, assetsData) => {
    await this._S3Service.copyAssets({ sourceProjectId, destinationProjectId, assetsData });
    return await this._service.copyAssets({ sourceProjectId, destinationProjectId, assetsData });
  };

  /**
   * Gets asset data from a file before it is uploaded
   * @param {File} file
   * @return {object} Object with asset data (name, imageUrl, imageThumbnailUrl, etc).
   */
  getAssetDataFromFile = async (file, folder) => {
    const { name } = file;
    return await this._S3Service.getAssetDataFromFileName({ name, folder });
  };

  exportToPerseus = async (perseusName, isRender) => {
    const result = await this._perseusService.create({
      projectId: this.project._id,
      perseusName,
      isRender,
    });
    if (!this.project.perseusHashId && result.data?.hashId) {
      // if it didn't have a perseus hash id before, add it
      await this._service.patch(this.project._id, {
        ...this.project,
        perseusHashId: result.data.hashId,
        isAutoRendered: isRender,
      });
      this.setProject({ ...this.project, perseusHashId: result.data.hashId, isAutoRendered: isRender });
    }
    return result;
  };

  getPerseusProject = async perseusHashId => {
    try {
      return await this._perseusService.get(perseusHashId);
    } catch (e) {
      return null;
    }
  };

  /**
   * Changes the project rename property in the store by id to be able to rename it or not
   * Ridiculous method, a UI property is being set in this data store. I don't know man, I don't know.
   * I mean, you can do it, but, really?
   *
   * @param {string} projectId
   * @param {boolean} rename
   */
  _setProjectRenameInput(projectId, renameValue) {
    runInAction(() => {
      this._projects = {
        ...this._projects,
        data: this._projects.data.map(({ rename, ...project }) =>
          projectId === project._id
            ? {
                ...project,
                rename: renameValue,
              }
            : project,
        ),
      };
    });
  }

  // Asset folders

  addProjectFolder = async (projectId, name, pathToFolder = FOLDERS.ASSETS, overwrite = false) => {
    // create temp folder
    const tempFolderId = uuidv4();
    const imageUrl = `${pathToFolder}${sanitizeFolderName(name)}/`;
    const tempFolder = {
      _id: tempFolderId,
      imageThumbnailUrl: '',
      imageUrl,
      loading: true,
      name: name,
      size: 0,
      type: FOLDER,
    };

    // add temp folder to list
    this.addTempAssetToAssetsList(tempFolder);

    const data = { projectId, name, pathToFolder, overwrite };
    try {
      const createdFolder = await this._S3Service.createFolder(data);
      const result = { ...tempFolder, ...createdFolder.result };
      runInAction(() => {
        // remove temp folder from assets list
        const filteredAssets = this.assets.filter(a => a._id !== tempFolder._id);
        this.assets = [result, ...filteredAssets];
      });

      return result;
    } catch (e) {
      runInAction(() => {
        this.assets = [...this.assets.filter(a => a._id !== tempFolder._id).map(({ hide, ...a }) => a)];
      });
      throw e;
    }
  };

  addTempAssetToAssetsList(tempAsset) {
    this.assets = [
      tempAsset,
      ...this.assets.map(a => {
        const isSameAsset = a.imageUrl === tempAsset.imageUrl;
        // if asset exists, hide the existing one until new is loaded
        return isSameAsset ? { ...a, hide: true } : a;
      }),
    ];
  }
}

export default ProjectsStore;
