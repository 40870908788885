import React from 'react';
import { observer } from 'mobx-react';

import AlignButtons from 'components/Sidebar/components/Align';
import Shadow from 'components/Sidebar/components/Shadow';
import SidebarPanel from 'components/Sidebar/components/SidebarPanel';
import SidebarItem from 'components/Sidebar/components/SidebarPanel/SidebarItem';

import RectStore from 'containers/Editor/stores/Rect.store';

import AnchorPoints from '../AnchorPoints';
import CheckboxColorPicker from '../CheckboxColorPicker';
import HideForm from '../HideForm';
import PositionForm from '../PositionForm';
import RectRadiusForm from '../RectRadiusForm';
import RotationOpacityForm from '../RotationOpacityForm';
import SizeForm from '../SizeForm';

import useShapeProperties from './ShapeProperties.hooks';
import { USE_RESPONSIVE } from 'utils/featureFlag';

const ShapeProperties = observer(({ disabled, isTimelineLayersTab }) => {
  const {
    label,
    onAlignChange,
    onAngleChange,
    onBackgroundColorChange,
    onBackgroundColorEnabled,
    onBorderColorChange,
    onBorderEnabled,
    onBorderWidthChange,
    onHideChange,
    onLeftChange,
    onLockProportionsChange,
    onOpacityChange,
    onRectRadiusLockChange,
    onRectRadiusXChange,
    onRectRadiusYChange,
    onShadowColorChange,
    onShadowEnabledChanged,
    onShadowOffsetBlurChange,
    onShadowOffsetXChange,
    onShadowOffsetYChange,
    onTopChange,
    shapeStore,
  } = useShapeProperties();

  const isRectangle = shapeStore instanceof RectStore;
  const strokeWidth = shapeStore.strokeEnabled ? shapeStore.strokeWidth : 0;

  const editDisabled = disabled || !isTimelineLayersTab;

  return (
    <SidebarPanel title={label}>
      <SidebarItem disabled={disabled}>
        <AlignButtons onClick={onAlignChange} />
      </SidebarItem>
      <SidebarItem disabled={editDisabled}>
        <SizeForm
          height={shapeStore.height + strokeWidth}
          onLockProportionsChange={onLockProportionsChange}
          uniformScaling={shapeStore.uniformScaling}
          width={shapeStore.width + strokeWidth}
        />
      </SidebarItem>
      <SidebarItem disabled={editDisabled}>
        {USE_RESPONSIVE ? (
          <AnchorPoints />
        ) : (
          <PositionForm
            left={shapeStore.unrotatedLeft}
            onLeftChange={onLeftChange}
            onTopChange={onTopChange}
            top={shapeStore.unrotatedTop}
          />
        )}
      </SidebarItem>
      <SidebarItem disabled={editDisabled}>
        <RotationOpacityForm
          angle={shapeStore.angle}
          onAngleChange={onAngleChange}
          onOpacityChange={onOpacityChange}
          opacity={shapeStore.objectOpacity}
        />
      </SidebarItem>
      <SidebarItem disabled={editDisabled}>
        <HideForm hide={shapeStore.hide} onHideChange={onHideChange} />
      </SidebarItem>
      <SidebarItem disabled={editDisabled} show={isRectangle}>
        <RectRadiusForm
          onLockRadiusChange={onRectRadiusLockChange}
          onRxChange={onRectRadiusXChange}
          onRyChange={onRectRadiusYChange}
          rx={shapeStore.rx}
          ry={shapeStore.ry}
          uniformRadius={shapeStore.uniformRadius}
        />
      </SidebarItem>
      <SidebarItem disabled={editDisabled}>
        <CheckboxColorPicker
          checked={shapeStore.fillEnabled}
          id="shape-fill"
          inline
          label="Fill"
          onCheckboxChange={onBackgroundColorEnabled}
          onColorChange={onBackgroundColorChange}
          value={shapeStore.fill}
        />
      </SidebarItem>
      <SidebarItem disabled={editDisabled}>
        <CheckboxColorPicker
          checked={shapeStore.strokeEnabled}
          id="shape-border-color"
          inputs={[
            {
              id: 'width',
              label: 'Width',
              min: 1,
              onChange: onBorderWidthChange,
              value: shapeStore.strokeWidth,
            },
          ]}
          label="Border"
          onCheckboxChange={onBorderEnabled}
          onColorChange={onBorderColorChange}
          value={shapeStore.stroke}
        />
      </SidebarItem>
      <SidebarItem disabled={editDisabled}>
        <Shadow
          className="mb-2"
          enabled={shapeStore.shadowEnabled}
          onEnabledChanged={onShadowEnabledChanged}
          color={shapeStore.shadowColor}
          onColorChange={onShadowColorChange}
          offsetX={shapeStore.shadowOffsetX}
          onOffsetXChange={onShadowOffsetXChange}
          offsetY={shapeStore.shadowOffsetY}
          onOffsetYChange={onShadowOffsetYChange}
          blur={shapeStore.shadowBlur}
          onBlurChange={onShadowOffsetBlurChange}
        />
      </SidebarItem>
    </SidebarPanel>
  );
});

export default ShapeProperties;
