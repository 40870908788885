import React, { useRef, useState } from 'react';
import { Tabs, Tab, Slider, Icon } from '@akiunlocks/perseus-ui-components';
import { observer } from 'mobx-react';

import TimeLineScene from '../TimeLineScene';
import { useRootStore } from '../../store';
import { getTimelineTabName } from '../../containers/Editor/stores/utilities';
import { TIMELINE_PADDING } from 'components/TimeLineScene/constants';
import { ANIMATION_DURATION } from 'constants';

import './Timeline.scss';

const TYPE_OF_STEPS = [500, 250, 100, 50];
export const WIDTH_TO_STEPS = [1400, 1800, 3400, 6000];
const DEFAULT_ZOOM_CONFIG = {
  width: WIDTH_TO_STEPS[0],
  step: TYPE_OF_STEPS[0],
  msInOnPixel: ANIMATION_DURATION / WIDTH_TO_STEPS[0],
};

const TIMELINE_HEADER_HEIGHT = 30;
const BOTTOM_BAR_HEIGHT = 70;
const SIDEBAR_HEIGHT = 125;

const Timeline = observer(() => {
  const { editor } = useRootStore();
  const [left, setLeft] = useState(TIMELINE_PADDING);
  const [sliderValue, setSliderValue] = useState(0);
  const [zoomConfig, setZoomConfig] = useState(DEFAULT_ZOOM_CONFIG);
  const timelineRef = useRef();
  const resizerRef = useRef();

  const handleZoomChange = e => {
    if (!editor?.canvas?.engine?.moveTo) return;
    editor?.canvas?.engine?.moveTo(0);
    setZoomConfig({
      msInOnPixel: ANIMATION_DURATION / WIDTH_TO_STEPS[e.target.value],
      step: TYPE_OF_STEPS[e.target.value],
      width: WIDTH_TO_STEPS[e.target.value],
    });
    setSliderValue(e.target.value);
  };

  const onMouseMove = e => {
    const MAX_HEIGHT = window.innerHeight - BOTTOM_BAR_HEIGHT - SIDEBAR_HEIGHT;
    document.body.style.cursor = 'row-resize';
    const resizerPosition = resizerRef.current.getBoundingClientRect().top;
    const difference = e.clientY - resizerPosition;
    const newHeight = Math.min(
      Math.max(timelineRef.current.getBoundingClientRect().height - difference, TIMELINE_HEADER_HEIGHT),
      MAX_HEIGHT,
    );
    timelineRef.current.style.height = `${newHeight}px`;
  };

  const onMouseUp = () => {
    window.removeEventListener('mousemove', onMouseMove);
    document.body.style.cursor = 'auto';
  };

  const handleMouseDown = e => {
    if (timelineRef && resizerRef) {
      window.addEventListener('mousemove', onMouseMove);
      window.addEventListener('mouseup', onMouseUp);
    }
  };

  return (
    <div className="timeline" ref={timelineRef}>
      <div className="resizer" onMouseDown={handleMouseDown} ref={resizerRef} />
      <Tabs
        unmountOnExit
        activeKey={editor.contextStore.getTimelineTab()}
        onSelect={props => editor.contextStore.setTimelineTab(props)}
      >
        <Tab eventKey={getTimelineTabName()} title="Layers">
          <TimeLineScene isDraggable left={left} setLeft={setLeft} zoomConfig={zoomConfig} />
        </Tab>
        <Tab eventKey={getTimelineTabName(1)} title="Scene 1" disabled={!editor.canvas}>
          <TimeLineScene isDraggable left={left} setLeft={setLeft} zoomConfig={zoomConfig} />
        </Tab>
      </Tabs>
      <div className="slider-wrapper">
        <Icon
          name="zoom-out"
          onClick={() => {
            if (sliderValue === 0) return;
            handleZoomChange({ target: { value: sliderValue - 1 } });
          }}
        />
        <Slider
          className={'zoom-slider'}
          onChange={handleZoomChange}
          value={sliderValue}
          max={3}
          min={0}
          name="test"
          step={1}
        />
        <Icon
          name="zoom-in"
          onClick={() => {
            if (sliderValue === 3) return;
            handleZoomChange({ target: { value: sliderValue + 1 } });
          }}
        />
      </div>
    </div>
  );
});

export default Timeline;
