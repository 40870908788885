import React from 'react';
import { Checkbox } from '@akiunlocks/perseus-ui-components';

import { PROJECT_TYPES } from 'constants/project';

const TypeCheckbox = ({ onChange }) => {
  const handleOnChange = ({ target }) => {
    onChange(target.checked ? PROJECT_TYPES.DOOH : PROJECT_TYPES.RICH_MEDIA_PERSONALIZED);
  };

  return <Checkbox label="This is a DOOH project" id="project-type" onChange={handleOnChange} />;
};

export default TypeCheckbox;
