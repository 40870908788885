import { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { PRISMA_CIRCLE, PRISMA_IMAGE, PRISMA_RECT, PRISMA_TEXT } from '@prisma/lib/src/constants';
import { Notification, notify } from '@akiunlocks/perseus-ui-components';

import { useRootStore } from 'store';

import { ASSET, FOLDER } from 'constants/assets';
import { DEFAULT_TIME_LINE_TAB } from 'constants/editor';

import { NOTIFY_MESSAGES } from '../../utils';
import { calculateZoom } from 'components/ArtBoard/utilities';

import { apiRequestWithNotifications } from 'utils/notifications';

const useArtBoard = () => {
  const navigate = useNavigate();
  const artBoardRef = useRef(null);
  const { projectId, projectSizeId } = useParams();
  const { sizes: sizesStore, editor, projects, projectSizes: projectSizesStore } = useRootStore();
  const { assetStore } = editor;

  const { sizes: defaultSizes, getSizes } = sizesStore;
  const { project, getProject } = projects;
  const {
    projectSize,
    projectSizes,
    getProjectSize,
    setProjectSize,
    getProjectSizes,
    addProjectSizes,
    cloneProjectSize,
  } = projectSizesStore;

  useEffect(() => {
    getSizes();
    getProject(projectId);
  }, [getProject, getSizes, projectId]);

  useEffect(() => {
    getProjectSize(projectSizeId);
    getProjectSizes({ projectId });
  }, [getProjectSize, getProjectSizes, projectId, projectSizeId, setProjectSize]);

  const [selectedSize, setSelectedSize] = useState(null);
  const [artBoardName, setArtBoardName] = useState('');
  const [projectSizesList, setProjectSizesList] = useState([]);
  const [showAddSizeModal, setShowAddSizeModal] = useState(false);
  const [showDuplicateSizeModal, setShowDuplicateSizeModal] = useState(false);

  useEffect(() => {
    if (projectSizes.length === 0 || !projectSize) {
      return;
    }

    const sizes = projectSizes.map(ps => ({
      value: ps._id,
      label: ps.name,
    }));

    setProjectSizesList(sizes);

    const found = projectSizes.find(ps => ps._id === projectSize._id);

    setSelectedSize(found);
  }, [projectSize, projectSizes]);

  const sizeId = useMemo(() => {
    return project && projectSize ? projectSize._id : '';
  }, [project, projectSize]);

  useEffect(() => {
    if (sizeId) {
      editor.loadCanvas(projectSize);
      editor.contextStore.setTimelineTab(DEFAULT_TIME_LINE_TAB);

      if (!projectSize || !editor.canvas) {
        return;
      }

      const canvasSize = editor.canvas.getAvailableSize();
      const zoom = calculateZoom(projectSize.width, projectSize.height, canvasSize.width, canvasSize.height);
      if (zoom) {
        editor.canvas.zoomToPoint({ x: canvasSize.width / 2, y: canvasSize.height / 2 }, zoom);
      }

      return () => {
        editor.canvas.dispose();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sizeId]);

  useEffect(() => {
    if (!editor.canvas || !project || !projectSize) {
      return;
    }

    setArtBoardName(`${projectSize.name} - ${project.name}`);
    editor.loadProjectSize(projectSize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sizeId]);

  useEffect(() => {
    const artBoard = artBoardRef.current;
    if (!artBoard) {
      return;
    }

    const handleDrop = event => {
      // skip file drop events
      if (event.dataTransfer.files.length) {
        return;
      }
      // skip drop events from items with unknown type
      const droppedItemType = event.dataTransfer.getData('text/plain');
      if (!droppedItemType) {
        return;
      }

      event.preventDefault();
      event.stopPropagation();

      editor.setDraggedOver(false);

      const offset = editor.canvas.getElement().getBoundingClientRect();
      const y = event.clientY - (offset.top + editor.dragOffsetY);
      const x = event.clientX - (offset.left + editor.dragOffsetX);

      editor.contextStore.setTimelineTab(DEFAULT_TIME_LINE_TAB);

      switch (droppedItemType) {
        case PRISMA_TEXT:
          editor.addTextbox({
            top: y,
            left: x,
          });
          break;
        case PRISMA_RECT:
          editor.addRect({
            top: y,
            left: x,
          });
          break;
        case PRISMA_CIRCLE:
          editor.addCircle({
            top: y,
            left: x,
          });
          break;
        case PRISMA_IMAGE:
          editor.addSelectedAssets(x, y);
          break;
        case FOLDER:
          const { selectionStore } = editor;
          const selectedAssetIds = selectionStore.getSelectedAssetsIds();
          selectionStore.clearSelectedComponents();
          selectedAssetIds.forEach(id => {
            const folder = assetStore.find(id);
            const childAssets = folder.model.children.filter(({ type }) => type === ASSET);
            childAssets.forEach(asset => selectionStore.addOrRemoveAsset(asset, true));
          });
          editor.addSelectedAssets(x, y);
          break;
        default:
          console.error(`Unknown ItemType: ${droppedItemType}`);
      }
    };

    artBoard.addEventListener('drop', handleDrop);

    return () => {
      artBoard.removeEventListener('drop', handleDrop);
    };
  });

  const onProjectSizeSelected = async value => {
    editor?.canvas?.engine?.stop();
    const found = projectSizes.find(ps => ps._id === value);
    setSelectedSize(found);

    navigate(`/projects/${projectId}/sizes/${value}`);
  };

  const handleCreate = async data => {
    apiRequestWithNotifications(
      async () => {
        const newProjectSize = await addProjectSizes({ ...data, projectId });
        setShowAddSizeModal(false);
        navigate(`/projects/${projectId}/sizes/${newProjectSize._id}`);
      },
      NOTIFY_MESSAGES.PROJECT_SIZE_CREATED,
      {
        onError: () => notify(NOTIFY_MESSAGES.ERROR, Notification.TYPE.ERROR),
      },
    );
  };

  const handleDuplicate = async data => {
    return apiRequestWithNotifications(
      async () => {
        const { result } = await cloneProjectSize(data);
        setShowDuplicateSizeModal(false);
        navigate(`/projects/${projectId}/sizes/${result._id}`);
      },
      NOTIFY_MESSAGES.DUPLICATE_COMPOSITION_SUCCESS,
      {
        onError: () => notify(NOTIFY_MESSAGES.DUPLICATE_COMPOSITION_ERROR, Notification.TYPE.ERROR),
      },
    );
  };

  return {
    artBoardName,
    artBoardRef,
    defaultSizes,
    handleCreate,
    selectedSize,
    projectSizes,
    projectSizeId,
    handleDuplicate,
    projectSizesList,
    showAddSizeModal,
    setShowAddSizeModal,
    onProjectSizeSelected,
    showDuplicateSizeModal,
    setShowDuplicateSizeModal,
  };
};

export default useArtBoard;
