import React, { forwardRef } from 'react';

const AssetFileInput = forwardRef(({ multiple, onChange }, ref) => {
  return (
    <input
      accept="image/*"
      aria-label="asset-file-input"
      className="d-none"
      multiple={multiple}
      onChange={e => {
        onChange(e);
        e.target.value = null; // clear input value
      }}
      ref={ref}
      type="file"
    />
  );
});

export default AssetFileInput;
