import React, { useState } from 'react';

import { Input } from '@akiunlocks/perseus-ui-components';

import { useRootStore } from 'store';

import { getInputInvalidFeedback } from 'utils/form';

import { ASSET, FOLDER, FOLDERS } from 'constants/assets';

import { renameAndSelect, validateFolderName } from 'containers/Editor/stores/utilities/assets';

const RenameAsset = ({ node }) => {
  const { editor } = useRootStore();
  const { assetStore } = editor;

  const { data, isEditing, isLeaf } = node;

  const [nameError, setNameError] = useState();

  if (!isEditing) {
    return null;
  }

  const defaultValue = data.name || `Untitled ${isLeaf ? ASSET : FOLDER}`;

  const parentFolder = node.parent.data.imageUrl || FOLDERS.ASSETS;

  const onNameChange = (name, userSaved) => {
    // if name has not changed, submit to move out of editing state
    // onRename will detect that name is the same, and it will do nothing
    if (name && node.data.name === name) {
      renameAndSelect(node, name);
      return;
    }
    // if name was not saved by user (for example onBlur)
    if (!userSaved) {
      assetStore.dropOrResetNode(node);
      return;
    }
    const validation = validateFolderName(name);
    if (!validation.valid) {
      setNameError(validation.error);
      return;
    }
    const existingFolder = assetStore.findFolderAsset(name, parentFolder);
    if (existingFolder && existingFolder.id !== node.id) {
      assetStore.notifyExistingFolder(name);
      return;
    }
    renameAndSelect(node, name);
  };

  return (
    <div className="w-100">
      <Input
        controlProps={{
          autoFocus: true,
          defaultValue,
          isInvalid: !!nameError,
          onBlur: e => {
            onNameChange(e.currentTarget.value, false);
          },
          onFocus: e => {
            setNameError('');
            e.currentTarget.select();
          },
          onKeyDown: e => {
            setNameError('');
            switch (e.key) {
              case 'Enter':
                onNameChange(e.currentTarget.value, true);
                break;
              case 'Escape':
                if (!data.name && assetStore.isTempNode(node.id)) {
                  assetStore.dropOrResetNode(node);
                  return;
                }
                node.reset(); // reset to original name
                break;
              default:
                break;
            }
          },
          placeholder: '',
        }}
        feedback={getInputInvalidFeedback(nameError)}
        id="rename-input"
        size="sm"
      />
    </div>
  );
};

export default RenameAsset;
