import { PROJECT_TYPES } from 'constants/project';
import { PRODUCT_TYPES, PRODUCT_SUBTYPES } from 'constants/compositions';

const { DISPLAY, RICH_MEDIA } = PRODUCT_TYPES;
const { ANIMATED_BANNER, BANNER, STATIC_BANNER } = PRODUCT_SUBTYPES;

/**
 * Gets the message for a project that has been exported.
 * @param {string} perseusId - Perseus project hash id.
 * @param {string} perseusName - Perseus project name.
 * @param {boolean} isRender - Flag to indicate if it is a render or a push to personalize
 * @return {string} Already exported message.
 */
export function getAlreadyExportedMessage(perseusId, perseusName, isRender) {
  const projectInfo = perseusName ? `name '${perseusName}' (id ${perseusId})` : `id ${perseusId}`;
  return `This project was already ${isRender ? 'rendered' : 'pushed'} with ${projectInfo}. Do you want to ${
    isRender ? 're-render' : 'update'
  } it?`;
}

/** Generates the list of warning or errors based on the project and compositions.
 * If the project has errors, it won't return warnings.
 * @param {Object} project - The project object.
 * @param {Array} compositions - A list of composition objects.
 * @param {boolean} isRender - Flag to indicate if it is a render or a push to personalize
 * @return {Object} An object containing the 'warning' and 'danger' messages.
 */
export function getExportAlerts(project, compositions, isRender) {
  const alerts = {
    danger: getExportErrors({ ...project, isAutoRendered: project.isAutoRendered || isRender }, compositions),
  };
  if (!alerts.danger.length) {
    alerts.warning = getExportWarnings(project, compositions, isRender);
  }
  return alerts;
}

/**
 * Generates a list of warnings messages based on the project type and compositions provided.
 * @param {Object} project - The project object.
 * @param {Array} compositions - A list of composition objects.
 * @param {boolean} isRender - Flag to indicate if it is a render or a push to personalize
 * @return {Array} A list of warning messages.
 */
export function getExportWarnings(project, compositions, isRender) {
  const warnings = [];

  if (!project?.perseusHashId && isRender) {
    warnings.push('This project will be rendered skipping all personalization screens.');
  }

  if (project?.type === PROJECT_TYPES.DOOH) {
    warnings.push('DOOH creatives require fallbacks to be trafficked.');
  }

  if (!isRender) {
    const hasDisplayAnimated = compositions?.some(
      c => c.productType === DISPLAY && c.productSubtype === ANIMATED_BANNER,
    );
    if (hasDisplayAnimated) {
      warnings.push('Display Animated compositions cannot be personalized.');
    }

    const hasRichMediaBanner = compositions?.some(c => c.productType === RICH_MEDIA && c.productSubtype === BANNER);
    if (hasRichMediaBanner) {
      warnings.push('Rich Media Banners require personalization.');
    }
  }

  return warnings;
}

/**
 * Generates a list of error messages based on the compositions provided.
 * @param {Array} compositions - A list of composition objects.
 * @return {Array} A list of error messages.
 */
export function getExportErrors(project, compositions) {
  const errors = [];

  compositions?.forEach(({ name, productType, productSubtype, animationExists }) => {
    if (productType === DISPLAY) {
      if (productSubtype === STATIC_BANNER && animationExists) {
        errors.push(`"${name}" is a Display Static composition and thus it cannot have animations.`);
      } else if (productSubtype === ANIMATED_BANNER && !animationExists) {
        errors.push(`"${name}" is a Display Animated composition and thus it requires animations.`);
      }
    } else if (productType === RICH_MEDIA) {
      if (project.isAutoRendered) {
        errors.push(`"${name}" is a Rich Media composition and thus it cannot be rendered as non-personalized.`);
      }
      if (productSubtype === BANNER && !animationExists) {
        errors.push(`"${name}" is a Rich Media Banner composition and thus it requires animations.`);
      }
    }
  });

  return errors;
}
