import { makeAutoObservable } from 'mobx';

import { findObjectInGroup } from './utilities';

// Store that represents a single group and its objects
class ActiveSelectionStore {
  editor;
  events = new Set();

  constructor(editor) {
    makeAutoObservable(this);
    this.editor = editor;
  }

  initialize(activeSelection) {
    this.addEvents(activeSelection, {
      mousedblclick: this.onMouseDoubleClick,
    });

    if (activeSelection.isGroup) {
      this.editor.groupStore.updatePosition(activeSelection);
      // these events are added to update group properties only
      this.addEvents(activeSelection, {
        moving: this.onGroupMoving,
        rotating: this.onGroupRotating,
        scaling: this.onGroupScaling,
      });
    }
  }

  addEvents(object, eventsObj) {
    Object.entries(eventsObj).forEach(([name, fn]) => {
      if (this.events.has(name)) {
        object.off(name); // This removes all the events asociated with that 'name'
      }
      this.events.add(name);
      object.on(name, fn);
    });
  }

  removeEvents(object, eventNames) {
    eventNames.forEach(([name]) => {
      if (this.events.has(name)) {
        object.off(name); // This removes all the events asociated with that 'name'
        this.events.delete(name);
      }
    });
  }

  onGroupMoving = event => {
    this.editor.groupStore.onObjectMoving(event);
  };

  onGroupRotating = event => {
    this.editor.groupStore.onObjectRotating(event);
  };

  onGroupScaling = event => {
    this.editor.groupStore.onObjectScaling(event);
  };

  onMouseDoubleClick = event => {
    const { target } = event;

    // pointer coordinates
    const point = target.canvas.getPointer(event);

    const object = findObjectInGroup(point, target.getObjects());
    if (object) {
      this.editor.contextStore.selectLayer(object);
    }
  };
}

export default ActiveSelectionStore;
