import { DISPLAY_TYPE, SENDER_TYPE } from 'constants/chat';
import Message from './Message';

class MultipleMessage extends Message {
  displayType = DISPLAY_TYPE.CAROUSEL;

  constructor(messages) {
    super(SENDER_TYPE.BOT); // it is always a bot
    this.messages = messages;
  }
}

export default MultipleMessage;
