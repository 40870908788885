import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { ItemButton } from '@akiunlocks/perseus-ui-components';

import SidebarPanel from 'components/Sidebar/components/SidebarPanel';
import SidebarItem from 'components/Sidebar/components/SidebarPanel/SidebarItem';
import { useRootStore } from '../../../../../store';

const Delete = observer(({ show }) => {
  const { editor } = useRootStore();

  return (
    <SidebarPanel show={show}>
      <SidebarItem>
        <ItemButton
          icon="trash3"
          iconSize={16}
          label="Delete"
          variant="light"
          className="mb-2 w-100 justify-content-center text-danger border border-danger"
          onClick={editor.deleteActiveObject}
        />
      </SidebarItem>
    </SidebarPanel>
  );
});

Delete.propTypes = {
  /** Whether the delete button must be shown or not. */
  show: PropTypes.bool,
};

Delete.defaultProps = {
  show: true,
};

export default Delete;
