import { addTimestamp } from 'utils/url';

/**
 * Replaces image source of an object and render canvas on success.
 * @param {object} object - Canvas object.
 * @param {string} imageUrl - Url of the new image.
 * @return {Promise<void>}
 */
export function replaceObjectImageSrc(object, imageUrl) {
  return new Promise(resolve => {
    // add timestamp to force reload
    object.setSrc(addTimestamp(imageUrl), () => {
      object.canvas.renderAll();
      resolve();
    });
  });
}

/**
 * Given an image file, gets its height and width.
 * @param {File} file - Image file.
 * @return {Promise<object>} Object with height and width.
 */
export function getImageSizeFromFile(file) {
  const img = new Image();
  const objectURL = URL.createObjectURL(file);
  img.src = objectURL;
  return new Promise((resolve, reject) => {
    img.onload = () => {
      URL.revokeObjectURL(objectURL);
      const width = img.naturalWidth;
      const height = img.naturalHeight;
      resolve({ width, height });
    };
    img.onerror = reject;
  });
}
