import React, { useEffect, useMemo, useState } from 'react';
import { Button, Col, Modal, Row, Select, Stack } from '@akiunlocks/perseus-ui-components';

import { DEFAULT_INTERSTITIAL_BANNER_SIZE } from 'constants/canvas';
import { PRODUCT_SUBTYPES } from 'constants/compositions';

import { checkSizeOnDuplicateChange } from 'components/ProjectPreview/DuplicateCompositionModal/utilities';

import Sizes from '../../Sizes';
import NameInput from 'components/Sizes/NameInput/NameInput';
import useNameInput from 'components/Sizes/NameInput/useNameInput';

const DuplicateCompositionModal = ({ show, handleClose, handleDuplicate, sizes, projectSizes, selectedSize }) => {
  const projectSizesList = useMemo(
    () =>
      projectSizes.map(ps => ({
        label: ps.name,
        value: ps._id,
      })),
    [projectSizes],
  );
  const [duplicateSize, setDuplicateSize] = useState(null);
  const [duplicateInterstitial, setDuplicateInterstitial] = useState(false);
  const [destinationSize, setDestinationSize] = useState(null);
  const [inProgress, setInProgress] = useState(false);
  const { name, inputEvents, reset, validationError } = useNameInput({ projectSizes });
  const selectedSizeProductSubtype = selectedSize?.productSubtype;

  const onClose = () => {
    reset();
    handleClose();
  };

  useEffect(() => {
    if (show) {
      if (selectedSizeProductSubtype === PRODUCT_SUBTYPES.INTERSTITIAL_BANNER) {
        setDestinationSize(DEFAULT_INTERSTITIAL_BANNER_SIZE);
      }
    }
  }, [selectedSizeProductSubtype, show]);

  useEffect(() => {
    const projectSize = projectSizes.find(ps => ps._id === selectedSize?._id);
    const duplicateSize = projectSize ? { label: projectSize.name, value: projectSize._id } : null;
    setDuplicateSize(duplicateSize);
    setDuplicateInterstitial(projectSize?.productSubtype === PRODUCT_SUBTYPES.INTERSTITIAL_BANNER);
  }, [projectSizes, selectedSize]);

  const onDuplicateChange = event => {
    const { size, reset, isInterstitial } = checkSizeOnDuplicateChange(
      duplicateSize?.value,
      event.target.value,
      projectSizes,
    );

    if (reset) {
      setDestinationSize(size);
    }
    setDuplicateSize(event.target.option);
    setDuplicateInterstitial(isInterstitial);
  };

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Duplicate composition</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Stack gap={3}>
          <NameInput name={name} inputEvents={inputEvents} validationError={validationError} />
          <Select
            id="duplicate-id"
            options={projectSizesList}
            label="Select a composition to duplicate"
            defaultValue={duplicateSize}
            onChange={onDuplicateChange}
          />
          {!duplicateInterstitial && (
            <Sizes data={sizes} value={destinationSize} id="destination-id" onChange={setDestinationSize} />
          )}
        </Stack>
      </Modal.Body>
      <Modal.Footer>
        <Row className="flex justify-content-between w-100">
          <Col>
            <Button variant="transparent" className="border border-secondary" onClick={onClose}>
              Cancel
            </Button>
          </Col>
          <Col className="saveButton">
            <Button
              disabled={duplicateSize === null || destinationSize === null || !!validationError}
              loading={inProgress}
              onClick={async () => {
                setInProgress(true);
                const data = {
                  id: duplicateSize.value,
                  name,
                  size: destinationSize,
                };
                const successful = await handleDuplicate(data);
                // this if condition is to prevent updating the state after the component is unmounted
                // we should manage this with request statuses
                if (!successful) {
                  setInProgress(false);
                }
              }}
              variant="primary"
            >
              Duplicate
            </Button>
          </Col>
        </Row>
      </Modal.Footer>
    </Modal>
  );
};

export default DuplicateCompositionModal;
