import React from 'react';
import PropTypes from 'prop-types';
import { Col, Form, Row } from 'react-bootstrap';

import LabeledInput from '../../LabeledInput';

const PositionForm = ({ left, onLeftChange, onTopChange, top }) => {
  return (
    <Form.Group>
      <Form.Label>Position</Form.Label>
      <Row>
        <Col>
          <LabeledInput
            id="position-x"
            label="X"
            controlProps={{
              placeholder: '',
              value: left,
              type: 'number',
              step: 1.0,
              onChange: onLeftChange,
            }}
          />
        </Col>
        <Col>
          <LabeledInput
            id="position-y"
            label="Y"
            controlProps={{
              placeholder: '',
              value: top,
              type: 'number',
              step: 1.0,
              onChange: onTopChange,
            }}
          />
        </Col>
      </Row>
    </Form.Group>
  );
};

PositionForm.propTypes = {
  /** Position X */
  left: PropTypes.number,
  /** Action to take on X change */
  onLeftChange: PropTypes.func,
  /** Action to take on Y change */
  onTopChange: PropTypes.func,
  /** Position Y */
  top: PropTypes.number,
};

PositionForm.defaultProps = {
  left: 0,
  onLeftChange: () => {},
  onTopChange: () => {},
  top: 0,
};

export default PositionForm;
