import { getUnrotatedPosition } from './utils/helpers.js';
import { ANIMATIONS_WITH_POSITION, frameKeysToFabricProperties, typesToFrameKeys } from './utils/types.js';

class PrismaKeyframe {
  constructor(props) {
    this.id = props.id || Math.random().toString(36); // TODO: replace it with uuid
    this.easing = props.easing;
    this.layerId = props.layerId;
    this.properties = props.properties;
    this.target = props.target;
    this.time = props.time;
    this.type = props.type;
    this.repeat = !!props.repeat;
  }

  static create(layerId, type, object, time = 0) {
    const keyframe = new PrismaKeyframe({ layerId, type, time });
    keyframe.updateProperties(object);
    return keyframe;
  }

  updateProperties(object) {
    this.properties = typesToFrameKeys[this.type].reduce(
      (acc, property) => ({
        ...acc,
        [property]: object[frameKeysToFabricProperties[property]],
      }),
      {},
    );

    if (ANIMATIONS_WITH_POSITION.includes(this.type)) {
      this.properties = {
        ...this.properties,
        ...getUnrotatedPosition(object),
      };
    }
  }

  clone() {
    return new PrismaKeyframe({
      ...this,
      id: undefined,
      properties: { ...this.properties }, // do this to have a copy of properties values
    });
  }

  /**
   *
   * @param {PrismaKeyframe} keyframe
   */
  compareTypes(keyframe) {
    return typeof keyframe === typeof this;
  }
}

export default PrismaKeyframe;
