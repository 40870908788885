import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import { useRootStore } from 'store';
import { ASPECT_RATIO_TYPE } from 'constants/canvas';
import ToggleIconButton from 'components/ToggleIconButton';

const AspectRatioButton = observer(({ type }) => {
  const { editor } = useRootStore();
  const { pageStore } = editor;

  const currentAspectRatioType = pageStore.getCurrentAspectRatioType();
  return (
    <ToggleIconButton
      className={classnames('icons', { active: currentAspectRatioType === type })}
      icons={[`per-aspect-ratio-${type.toLowerCase()}`, `per-aspect-ratio-${type.toLowerCase()}`]}
      value={currentAspectRatioType === type}
      onClick={() => pageStore.setCurrentAspectRatioType(type)}
      variant="gray-lighter"
    />
  );
});

AspectRatioButton.defaultProps = {
  type: PropTypes.oneOf(Object.values(ASPECT_RATIO_TYPE)),
};

export default AspectRatioButton;
