/**
 * Given an array of promises results (resolve or reject), returns an array of failed and succeeded elements.
 * @param {array} responses - Promises results.
 * @param {string} property - Property present in the resolve response, to get the returned element.
 * @return {array} Array of failed and succeeded elements.
 */
export function getPromisesResults(responses, property = 'data') {
  return responses.reduce(
    (acc, curr) => {
      curr.status === 'rejected' ? acc[0].push(curr.reason) : acc[1].push(curr.value[property]);
      return acc;
    },
    [[], []],
  );
}
