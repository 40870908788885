import {
  ACTIVE_SELECTION,
  PRISMA_CIRCLE,
  PRISMA_GROUP,
  PRISMA_IMAGE,
  PRISMA_MASK,
  PRISMA_RECT,
  PRISMA_TEXT,
} from '@prisma/lib/src/constants';
import { animationTypes } from '@prisma/lib/src/utils/types';

export const LIBRARY_TAB = 'library';
export const LAYERS_TAB = 'layers';
export const EDIT_TAB = 'edit';
export const DEFAULT_TAB = LIBRARY_TAB;
export const DEFAULT_TIME_LINE_TAB = LAYERS_TAB;

export const SHAPE_LAYERS = [PRISMA_RECT, PRISMA_CIRCLE];
export const SELECTABLE_LAYERS = [PRISMA_IMAGE, PRISMA_TEXT, PRISMA_GROUP, PRISMA_MASK, ...SHAPE_LAYERS];
export const MULTI_SELECTABLE_LAYERS = [PRISMA_IMAGE, PRISMA_TEXT, PRISMA_GROUP, ...SHAPE_LAYERS]; // masks can't be multiselected
export const CLIPBOARD_LAYERS = [PRISMA_IMAGE, PRISMA_TEXT, PRISMA_GROUP, ACTIVE_SELECTION, ...SHAPE_LAYERS];
export const ANIMATABLE_LAYERS = [...CLIPBOARD_LAYERS, ...SHAPE_LAYERS];
export const DELETABLE_LAYERS = [PRISMA_IMAGE, PRISMA_TEXT, ...SHAPE_LAYERS];
export const GROUP_DISABLED_ANIMATIONS = [animationTypes.scale, animationTypes.rotation];

// add a value in px if you want cloned elements to have offset
export const CLONE_OFFSET = 0;

export const ANIMATION_DURATION = 20000; // 20 seconds

export const ASSET_TYPE = 'asset';

export const RULER_DROPDOWN_EVENTS = {
  ADD_VERTICAL_LINE: 'addVerticalLine',
  ADD_HORIZONTAL_LINE: 'addHorizontalLine',
  SHOW_LINES: 'showLines',
  HIDE_LINES: 'hideLines',
  LOCK_LINES: 'lockLines',
  UNLOCK_LINES: 'unlockLines',
  CLEAR_LINES: 'clearLines',
};

export const RULER_DROPDOWN_OPTIONS = {
  ADD_VERTICAL_LINE: {
    eventKey: RULER_DROPDOWN_EVENTS.ADD_VERTICAL_LINE,
    icon: 'tb-letter-i',
    title: 'Add vertical line',
  },
  ADD_HORIZONTAL_LINE: {
    eventKey: RULER_DROPDOWN_EVENTS.ADD_HORIZONTAL_LINE,
    icon: 'dash-lg',
    title: 'Add horizontal line',
  },
  SHOW_LINES: {
    eventKey: RULER_DROPDOWN_EVENTS.SHOW_LINES,
    icon: 'eye',
    title: 'Show lines',
  },
  HIDE_LINES: {
    eventKey: RULER_DROPDOWN_EVENTS.HIDE_LINES,
    icon: 'eye-slash',
    title: 'Hide lines',
  },
  LOCK_LINES: {
    eventKey: RULER_DROPDOWN_EVENTS.LOCK_LINES,
    icon: 'lock',
    title: 'Lock lines',
  },
  UNLOCK_LINES: {
    eventKey: RULER_DROPDOWN_EVENTS.UNLOCK_LINES,
    icon: 'unlock',
    title: 'Unlock lines',
  },
  CLEAR_LINES: {
    eventKey: RULER_DROPDOWN_EVENTS.CLEAR_LINES,
    icon: 'trash',
    title: 'Clear lines',
  },
};

export const MAX_ZOOM = 20;
export const MIN_ZOOM = 0.1;
export const ADJUST_MARGIN = 50;
