import { observer } from 'mobx-react';

import { Form, Select } from '@akiunlocks/perseus-ui-components';
import { PRISMA_IMAGE } from '@prisma/lib/src/constants';

import { useRootStore } from 'store';

import { formatSize } from 'utils/formatters';

import { getOptionLabelWithFolderIfSelected } from 'containers/Editor/stores/utilities/assets';

import './AssetFileProperties.scss';

const AssetFileProperties = observer(() => {
  const { editor } = useRootStore();
  const { activeObject, assetStore } = editor;

  if (!activeObject?.type === PRISMA_IMAGE) {
    return null;
  }

  const options = assetStore.getAssetsOptions();
  const selectedAsset = assetStore.getSelectedAsset();

  return (
    <Form.Group>
      <Form.Label>Source asset</Form.Label>
      <div className="properties">
        <div>
          <span className="label">File:</span>
          <div className="w-100">
            <Select
              formatGroupLabel={option => <div className="text-lowercase">{option.label}</div>}
              getOptionLabel={option => {
                const label = getOptionLabelWithFolderIfSelected(option, selectedAsset?.value);
                return <div className="text-dark">{label}</div>;
              }}
              id={`options-${activeObject.id}`}
              onChange={({ target }) => {
                assetStore.replaceActiveAssetSource(target.value);
              }}
              options={options}
              size="xs"
              value={selectedAsset}
            />
          </div>
        </div>
        <div>
          <span className="label">Dimension:</span>
          {activeObject.width}x{activeObject.height}
        </div>
        <div>
          <span className="label">Size:</span>
          {formatSize(selectedAsset?.size)}
        </div>
      </div>
    </Form.Group>
  );
});

export default AssetFileProperties;
