import { DEFAULT_INTERSTITIAL_BANNER_SIZE } from 'constants/canvas';
import { PRODUCT_SUBTYPES } from 'constants/compositions';

/**
 * Calculates the destination size on duplicate change based on the old and new IDs and project sizes.
 *
 * @param {string} oldId - The ID of the old size.
 * @param {string} newId - The ID of the new size.
 * @param {Array} projectSizes - An array of project sizes.
 * @return {Object} - An object containing the calculated destination size, reset flag, and interstitial flag.
 */
export function checkSizeOnDuplicateChange(oldId, newId, projectSizes) {
  const oldSize = projectSizes.find(i => i._id === oldId);
  const newSize = projectSizes.find(i => i._id === newId);
  const isOldInterstitial = oldSize?.productSubtype === PRODUCT_SUBTYPES.INTERSTITIAL_BANNER;
  const isNewInterstitial = newSize.productSubtype === PRODUCT_SUBTYPES.INTERSTITIAL_BANNER;
  const result = { size: null, reset: true, isInterstitial: isNewInterstitial };

  if (!oldSize) {
    // if there is no old size, we reset the destination size depending on the new size
    result.size = isNewInterstitial ? DEFAULT_INTERSTITIAL_BANNER_SIZE : null;
  } else if (isOldInterstitial && !isNewInterstitial) {
    // if the old size is an interstitial and the new size is not, we reset the destination size
    // result.size = null; // don't need to set this, as it's already null
  } else if (!isOldInterstitial && isNewInterstitial) {
    // if the old size is not an interstitial and the new size is, we set the destination size to the default interstitial size
    result.size = DEFAULT_INTERSTITIAL_BANNER_SIZE;
  } else {
    result.reset = false;
  }

  return result;
}
