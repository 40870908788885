import { makeAutoObservable } from 'mobx';
import axios from 'axios';

import { FONTS_JSON_URL } from '@prisma/lib/src/constants/fonts';

import { reportError } from 'utils/log';

class FontStore {
  root = null;

  fonts = [];
  loading = false;

  constructor(editor) {
    makeAutoObservable(this);
    this.editor = editor;
  }

  async fetchFonts() {
    if (this.fonts.length || this.loading) {
      return;
    }
    this.setLoading(true);
    try {
      const response = await axios.get(FONTS_JSON_URL);
      this.setFonts(response.data);
    } catch (error) {
      reportError(error);
    }
    this.setLoading(false);
  }

  setFonts(fonts) {
    this.fonts = fonts;
  }

  setLoading(loading) {
    this.loading = loading;
  }

  getOptions() {
    return this.fonts.map(f => ({ label: f.name, value: f.fileName }));
  }
}

export default FontStore;
