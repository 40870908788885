import { makeAutoObservable } from 'mobx';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';

import { createActiveSelection, getUnrotatedPosition } from '@prisma/lib/src/utils/helpers';
import PrismaKeyframe from '@prisma/lib/src/prismaKeyframe';
import { findLayerById } from '@prisma/lib/src/utils/layers';
import { animationTypes } from '@prisma/lib/src/utils/types';
import { PRISMA_GUIDELINE } from '@prisma/lib/src/constants';

import { DEFAULT_SELECTED_POINT } from 'constants';
import { ANIMATABLE_LAYERS } from 'constants/editor';
import { LOCKS_BY_TYPE } from 'constants/timeline';
import { updateObjectLocks } from 'utils/helpers';

import { calculateNewPositionKeyframes, getKeyframesForNewAnimation } from 'utils/animations';
import { calculateTimeByPoint } from 'components/TimeLineScene/components/Frame/utilities';
import {
  findAnimationByType,
  findClosestKeyframe,
  isKeyframeFar,
  setKeyframesLayerId,
  setKeyframesPositionIfRotation,
  setKeyframeTimesFromCurrentTime,
  filterPastedKeyframes,
  groupKeyframesByType,
} from './utilities';
import { applyUniformScalingIfNeeded, checkIfPositionChanged } from 'components/TimeLineScene/utilities';

class TimeLineSceneStore {
  _timelineCursorPosition = null;
  _selectedPoint = DEFAULT_SELECTED_POINT;
  activeLayer = null;
  _activeAnimation = null;
  _activeKeyframePoint = null;
  activeKeyframeId = null;
  movingKeyframeId = null;
  currentTime = 0;

  tempKeyframeData = {};

  constructor(editor) {
    makeAutoObservable(this);
    this.editor = editor;
  }

  get timelineCursorPosition() {
    return this._timelineCursorPosition;
  }

  get selectedPoint() {
    return this._selectedPoint;
  }

  isMovingKeyframe() {
    return !!this.movingKeyframeId;
  }

  findActiveAnimationKeyframe(keyframeId) {
    return this.activeAnimation?.keyframes?.find(({ id }) => id === keyframeId) || null;
  }

  getTempKeyframeData() {
    return this.tempKeyframeData;
  }

  setTempKeyframeData(tempKeyframeData) {
    this.tempKeyframeData = tempKeyframeData;
  }

  /**
   * Sets the temp keyframe data to be used when moving a keyframe.
   * @param {object} keyframeData
   * @param {object} keyframeData.animation
   * @param {boolean} keyframeData.isMultiSelect
   * @param {string} keyframeData.keyframeId
   * @param {object} keyframeData.point
   */
  startKeyframeSelection(keyframeData) {
    this.setMovingKeyframeId(keyframeData.keyframeId);
    this.setTempKeyframeData(keyframeData);
  }

  finishKeyframeSelectionOnMove() {
    const tempKeyframeData = this.getTempKeyframeData();
    if (
      this.isMovingKeyframe() &&
      !isEmpty(tempKeyframeData) &&
      this.selectedPoint.oldValue !== tempKeyframeData.point
    ) {
      const isSelected = this.editor.selectionStore.isSelectedById(tempKeyframeData.keyframeId);
      const manySelected = this.editor.selectionStore.hasManySelected();
      const isMultiSelect = (isSelected && manySelected) || tempKeyframeData.isMultiSelect;
      this.setTempKeyframeData({ ...tempKeyframeData, isMultiSelect });
      this.finishKeyframeSelection();
    }
  }

  finishKeyframeSelection() {
    if (isEmpty(this.tempKeyframeData)) {
      return;
    }
    const { animation, isMultiSelect, keyframeId, point } = this.tempKeyframeData;

    this.selectedPoint = {
      oldLayer: this.activeLayer,
      oldAnimation: this.activeAnimation,
      oldValue: point,
      newValue: point,
    };
    this.activeAnimation = animation;
    this.setActiveKeyframeId(keyframeId, isMultiSelect);

    this.setTempKeyframeData({});
  }

  /**
   * Given a start time and a point, updates the time of all the current selected keyframes.
   * It is expected that selected components in selection store are keyframes.
   * @param {number} oldTime - Original time.
   * @param {number} point - New point in timeline.
   * @param {number} msInOnPixel - Milliseconds in a pixel.
   * @return {array} Updated keyframes.
   */
  updateSelectedKeyframesTimes(oldTime, point, msInOnPixel) {
    const newTime = calculateTimeByPoint(point, msInOnPixel);

    const keyframes = this.editor.selectionStore.getSelectedComponents();
    const times = keyframes.map(({ time }) => time);
    const minTime = Math.min(...times);

    // keyframe times cannot be lower than 0
    const diff = Math.max(newTime - oldTime, -minTime);

    keyframes.forEach(k => {
      k.time += diff;
    });

    return keyframes;
  }

  getActiveLayer() {
    return this.activeLayer;
  }

  get activeAnimation() {
    return this._activeAnimation;
  }

  getActiveKeyframeId() {
    return this.activeKeyframeId;
  }

  getActiveKeyframe() {
    return this.findActiveAnimationKeyframe(this.activeKeyframeId);
  }

  get activeKeyframePoint() {
    return this._activeKeyframePoint;
  }

  set timelineCursorPosition(timelineCursorPosition) {
    this._timelineCursorPosition = timelineCursorPosition;
  }

  set selectedPoint(selectedPoint) {
    this._selectedPoint = selectedPoint;
  }

  setMovingKeyframeId(movingKeyframeId) {
    this.movingKeyframeId = movingKeyframeId;
  }

  setActiveLayer(activeLayer) {
    this.activeLayer = activeLayer;
  }

  set activeAnimation(activeAnimation) {
    this._activeAnimation = activeAnimation;
  }

  set activeKeyframePoint(activeKeyframePoint) {
    this._activeKeyframePoint = activeKeyframePoint;
  }

  setCurrentTime(currentTime) {
    this.currentTime = Math.round(currentTime);
  }

  setActiveKeyframeId(activeKeyframeId, isMultiSelect) {
    const keyframe = this.findActiveAnimationKeyframe(activeKeyframeId);
    this.setActiveKeyframe(keyframe, isMultiSelect);
  }

  setActiveKeyframe(keyframe, isMultiSelect) {
    const previousKeyframe = this.getActiveKeyframe();
    if (keyframe !== null) {
      this.editor.contextStore.selectKeyframe(keyframe, isMultiSelect);
      this.activeKeyframeId = keyframe.id;
    } else {
      this.editor.contextStore.unselectKeyframe(previousKeyframe);
      this.activeKeyframeId = null;
    }
  }

  setActive(activeConfig) {
    this.setActiveLayer(activeConfig.layer);
    this._activeAnimation = activeConfig.animation;
    this._activeKeyframePoint = activeConfig.keyframePoint;
    this.setActiveKeyframe(activeConfig.keyframe);
  }

  clearActiveKeyframe() {
    this.setActiveKeyframe(null);
  }

  clearActiveAnimation() {
    this.setActiveKeyframe(null);
    this._activeAnimation = null;
    this._activeKeyframePoint = null;
    this.setTempKeyframeData({});
    if (this.editor.selectionStore.hasKeyframesSelected()) {
      this.editor.selectionStore.clearSelectedComponents();
    }
  }

  clearActiveLayer() {
    this.setActiveLayer(null);
    this.clearActiveAnimation();
  }

  deselectKeyframeIfNeeded(activeObject) {
    if (!activeObject) {
      this.clearActiveLayer();
    } else if (
      this.activeLayer?.id !== undefined &&
      activeObject.id !== undefined &&
      activeObject.id !== this.activeLayer.id
    ) {
      this.clearActiveAnimation();
    }
  }

  /**
   * Gets the active keyframe and updates it with the new props.
   * @param {Object} newProps - Props to add.
   * @return {PrismaKeyframe} Keyframe with updated props if found, else null.
   */
  getUpdatedKeyframe(newProps) {
    const currentKeyframe = this.getActiveKeyframe();
    if (!currentKeyframe) {
      return null;
    }

    currentKeyframe.properties = {
      ...currentKeyframe.properties,
      ...newProps,
    };
    return currentKeyframe;
  }

  /**
   * Gets a keyframe close to the specified time and updates it with the new props.
   * @param {array} animation - Animation.
   * @param {number} time - Time to search for keyframes.
   * @param {Object} newProps - Props to add.
   * @return {PrismaKeyframe} Keyframe with updated props if found, else null.
   */
  getUpdatedKeyframeIfClose(animation, time, newProps) {
    if (!animation) {
      return null;
    }

    // Gets the closest keyframe according to the specified time
    let closestKeyframe = findClosestKeyframe(animation.keyframes, time);
    // If the found keyframe is far from the specified time return null
    if (isKeyframeFar(closestKeyframe, time)) {
      return null;
    }

    // Otherwise return the closest keyframe with updated props and time
    closestKeyframe.properties = {
      ...closestKeyframe.properties,
      ...newProps,
    };
    return closestKeyframe;
  }

  /**
   * Updates a keyframe and then adds or edits the layer keyframes with it.
   * @param {PrismaKeyframe} keyframe - Keyframe to edit.
   * @param {Object} original - Original properties.
   * @param {Object} target - Updated Fabric object.
   * @param {Object} newProps - New properties.
   * @param {string} animationType - Type of animation.
   * @param {boolean} isMultiLayer - Flag to indicate if multiple layers are selected.
   * @return {PrismaKeyframe} Keyframe param if present, else the new selected keyframe.
   */
  updateKeyframeAndEditLayer(original, target, newProps, animationType, isMultiLayer) {
    let keyframe;

    if (isMultiLayer) {
      const animation = this.editor.findLayerAnimation(target.id, animationType);
      keyframe = this.getUpdatedKeyframeIfClose(animation, this.currentTime, newProps);
    } else {
      keyframe = this.getUpdatedKeyframe(newProps);
    }

    return this.addOrEditActiveLayerKeyframes(keyframe, original, target, animationType, isMultiLayer);
  }

  /**
   * Pastes keyframes into the selected or active layers.
   *
   * @param {Array} keyframes - The array of keyframes to be pasted.
   */
  pasteKeyframes(keyframes) {
    const { canvas } = this.editor;

    // 1. get the selected layers
    // we prefer the selected components because at the moment has more validations, we'll be working on improve it
    const layersToUpdate = this.editor.selectionStore.hasLayersSelected()
      ? this.editor.selectionStore.getSelectedComponents()
      : [this.activeLayer]; // can we have an active selection here? be careful!

    // 2. If we don't have proper layers selected, we don't do anything
    if (!layersToUpdate.length || !layersToUpdate[0]) {
      return;
    }

    // 3. if it is an active selection discard it to have the correct positions
    const isActiveSelection = !!layersToUpdate[0].target.group;
    if (isActiveSelection) {
      canvas.discardActiveObject();
    }

    let nextActive = {};

    // 4. we will try to paste ALL keyframes in ALL the selected layers, even if that does not make sense to paste in all of them, but is up to the user
    layersToUpdate.forEach(layerToUpdate => {
      // 5. we filter the keyframes to paste, we don't want to paste keyframes that are not compatible with the layer
      const keyframesToPaste = filterPastedKeyframes(keyframes, layerToUpdate).map(k => k.clone());
      if (keyframesToPaste.length === 0) {
        return;
      }

      // 6. we modify the keyframes times to the current time and set the layer id
      setKeyframeTimesFromCurrentTime(keyframesToPaste, this.currentTime);
      setKeyframesLayerId(keyframesToPaste, layerToUpdate.id);

      // 7. we group the keyframes by animation type
      const keyframesByType = groupKeyframesByType(keyframesToPaste);

      // 7. we iterate over the animation types and merge the keyframes into the animation
      keyframesByType.forEach(({ animationType, keyframes }) => {
        // create animation if does not exist
        const layerAnimation = findAnimationByType(layerToUpdate, animationType);
        if (!layerAnimation) {
          layerToUpdate = canvas.addOrEditKeyframes.call(canvas, keyframes, layerToUpdate.id);
        }

        setKeyframesPositionIfRotation(keyframes, layerToUpdate);

        layerToUpdate = canvas.mergeKeyframesInLayer.call(canvas, keyframes, layerToUpdate);
      });

      // 8. we keep track of the last layer and keyframe
      nextActive.layer = layerToUpdate;
      nextActive.keyframe = keyframesToPaste[0];
    });

    if (nextActive.layer && nextActive.keyframe) {
      // 9. we set as active the last layer and keyframe that we pasted
      const animation = findAnimationByType(nextActive.layer, nextActive.keyframe.type);
      const keyframe = animation.keyframes.find(k => k.time === nextActive.keyframe.time);
      this.setActive({
        layer: nextActive.layer,
        animation,
        keyframe,
      });
      canvas.engine?.moveTo(nextActive.keyframe.time);
    }

    // 10 if there was an active selection create it and select it again
    if (isActiveSelection) {
      const selection = createActiveSelection(layersToUpdate.map(l => l.target));
      selection.canvas.setActiveObject(selection);
    }
  }

  /**
   * Sets the active animation and keyframe if the currentTime is near to a keyframe.
   * @param {string} animationType - Type of animation.
   */
  selectKeyframeIfPossible(animationType) {
    this._clearActiveAnimationIfNeeded(animationType);

    if (this.getActiveKeyframeId() === null) {
      const { animation, keyframe } = this._getNearKeyframeAndAnimation(animationType);
      if (animation && keyframe) {
        this._activeAnimation = animation;
        this.setActiveKeyframe(keyframe);
      }
    }
  }

  /**
   * Adds or edit active layer keyframes.
   * @param {PrismaKeyframe} keyframe - Keyframe to edit.
   * @param {Object} original - Original properties.
   * @param {Object} target - Updated Fabric object.
   * @param {string} animationType - Type of animation.
   * @param {boolean} isMultiLayer - Flag to indicate if multiple layers are selected.
   * @return {PrismaKeyframe} Keyframe param if present, else the new selected keyframe.
   */
  addOrEditActiveLayerKeyframes(keyframe, original, target, animationType, isMultiLayer = false) {
    if (keyframe) {
      const layerId = isMultiLayer ? target.id : this.activeLayer.id;
      this._editLayerKeyframes(keyframe, layerId);
      return keyframe;
    }
    return this._autoAddLayerKeyframes(original, target, animationType, isMultiLayer);
  }

  /**
   * Applies position to a layer or group of layers in an active selection
   * @param {Object} original - Original properties.
   * @param {Object} target - Updated Fabric object. Can be an active selection.
   * @param {boolean} isMultiLayer - Flag to indicate if multiple layers are selected.
   * @param {Object} store - Store of the target depending on the type.
   */
  applyPosition(original, target, isMultiLayer, store = null) {
    if (store) {
      store.updateObjectsProperties?.(target);
    }
    const newProps = getUnrotatedPosition(target);
    this.updateKeyframeAndEditLayer(original, target, newProps, animationTypes.position, isMultiLayer);
  }

  /**
   * Applies scaling to a layer or group of layers in an active selection
   * @param {Object} original - Original properties.
   * @param {Object} target - Updated Fabric object. Can be an active selection.
   * @param {string} action - Action applied to the target. It can be scale, scaleX or scaleY.
   * @param {boolean} isMultiLayer - Flag to indicate if multiple layers are selected.
   * @param {Object} store - Store of the target depending on the type.
   */
  applyScaling(original, target, action, isMultiLayer, store = null) {
    const originalScale = { scaleX: original.scaleX, scaleY: original.scaleY };
    applyUniformScalingIfNeeded(target, action, originalScale);

    // new scale props
    const newProps = { scaleX: target.scaleX, scaleY: target.scaleY };
    const objTarget = cloneDeep(target);

    if (store) {
      store.updateObjectsProperties?.(target);
    }

    let layer = findLayerById(this.editor.canvas.layers, target.id);
    const positionChanged = checkIfPositionChanged(original, objTarget);
    const groupPositionAnimation = layer.animations.find(a => a.isGroupAnimation && a.type === animationTypes.position);

    if (positionChanged && groupPositionAnimation) {
      // copy the group position animation to the layer, because we will need it in updatePositionForScaling method
      layer = this._editLayerKeyframes(groupPositionAnimation.keyframes, layer.id);
    }

    const keyframe = this.updateKeyframeAndEditLayer(original, objTarget, newProps, animationTypes.scale, isMultiLayer);

    // we always apply the position animation
    this.updatePositionForScaling(original, objTarget, keyframe, layer);
  }

  /**
   * Updates/adds a position animation when scaling changes the position.
   * @param {Object} original - Original properties.
   * @param {Object} target - Updated Fabric object.
   * @param {Object} layer - Layer.
   * @param {PrismaKeyframe} keyframeScale - Scale keyframe.
   */
  updatePositionForScaling(original, target, keyframeScale, layer) {
    const { position, scale } = animationTypes;

    const positionAnimation = this.editor.findLayerAnimation(layer.id, position);
    let updatedKeyframes;

    if (positionAnimation) {
      // we check for a near keyframe to edit
      const updatedKeyframePosition = this.getUpdatedKeyframeIfClose(
        positionAnimation,
        keyframeScale.time,
        getUnrotatedPosition(target),
      );
      // If no keyframe is near, create a new one
      updatedKeyframes =
        updatedKeyframePosition || PrismaKeyframe.create(layer.id, position, layer.target, keyframeScale.time);
    } else {
      // If there is no position animation, we have to add it
      const scaleAnimation = this.editor.findLayerAnimation(layer.id, scale);
      updatedKeyframes = calculateNewPositionKeyframes(layer, scaleAnimation, keyframeScale, original, target);
    }

    this._editLayerKeyframes(updatedKeyframes, layer.id);
  }

  /**
   * Clears the active animation if it doesn't correspond with animationType param
   * @param {string} animationType - Type of animation.
   */
  _clearActiveAnimationIfNeeded(animationType) {
    const activeKeyframeId = this.getActiveKeyframeId();

    if (activeKeyframeId !== null) {
      const keyframe = this.findActiveAnimationKeyframe(activeKeyframeId);
      if (keyframe?.layerId !== this.activeLayer.id || keyframe?.type !== animationType) {
        // If a keyframe from a different layer or animation is selected, unselect it
        this.clearActiveAnimation();
      }
    }
  }

  /**
   * Gets a near keyframe based on currentTime and the active layer.
   * @param {string} animationType - Type of animation.
   * @return {Object} Object with keyframe and animation if found, else undefined.
   */
  _getNearKeyframeAndAnimation(animationType) {
    const result = { animation: undefined, keyframe: undefined };
    const animation = findAnimationByType(this.activeLayer, animationType);
    if (!animation) {
      return result;
    }

    result.animation = animation;
    const keyframe = animation.keyframes.find(k => !isKeyframeFar(k, this.currentTime));
    if (keyframe) {
      result.keyframe = keyframe;
    }

    return result;
  }

  /**
   * Edits the keyframes of the layer by id.
   * @param {PrismaKeyframe|Array} keyframes - One or more keyframes to edit.
   * @param {number} layerId - Layer id.
   * @return {Object} The updated layer.
   */
  _editLayerKeyframes(keyframes, layerId) {
    const { canvas } = this.editor;
    return canvas.addOrEditKeyframes.call(canvas, keyframes, layerId);
  }

  /**
   * Adds new keyframes to the active layer if single layer, or to the target if multi layers.
   * @param {Object} original - Original properties.
   * @param {Object} target - Updated Fabric object.
   * @param {string} animationType - Type of animation.
   * @param {boolean} isMultiLayer - Flag to indicate if multiple layers are selected.
   * @return {PrismaKeyframe} The new keyframe.
   */
  _autoAddLayerKeyframes(original, target, animationType, isMultiLayer) {
    const layerId = target.id;
    const animation = findAnimationByType(this.editor.getEditorLayerByTarget(target), animationType);
    const keyframes = [];

    if (animation) {
      keyframes.push(PrismaKeyframe.create(layerId, animationType, target, this.currentTime));
    } else {
      keyframes.push(...getKeyframesForNewAnimation(target, animationType, this.currentTime, original));
    }

    const updatedLayer = this._editLayerKeyframes(keyframes, layerId);
    const selectedKeyframe = keyframes.find(({ time }) => time === this.currentTime);

    // if multi layer, we don't need to set active layer
    if (!isMultiLayer) {
      this.setActive({
        layer: updatedLayer,
        animation: findAnimationByType(updatedLayer, animationType),
        keyframe: selectedKeyframe,
      });
    }

    return selectedKeyframe;
  }

  /**
   * Sets object locked properties in scene tab, depending on the object type or if it is animatable.
   * @param {object} object - Fabric object.
   */
  setObjectLocksIfNeeded(object) {
    const locksByType = LOCKS_BY_TYPE[object.type];
    if (locksByType) {
      object.set(locksByType);
    } else if (!ANIMATABLE_LAYERS.includes(object.type) && object.type !== PRISMA_GUIDELINE) {
      updateObjectLocks(object, true, true);
    }
  }
}

export default TimeLineSceneStore;
