import LogRocket from 'logrocket';

/**
 * Returns proper function to log errors
 * @returns {object} - Current function to log error
 */
const getErrorLogger = () => {
  return console.log;
};

/**
 * Log error in Honeybadger or browser console
 * @param {Error|string} error - The actual caught error
 * @param {object} [context] - Extra details to the error.
 */
export const reportError = (error, context) => {
  getErrorLogger()(error, context);
};

/**
 * Setup LogRocket
 */
export const initLogRocket = () => {
  const { REACT_APP_LOG_ROCKET_API_KEY, REACT_APP_PERSEUS_URL } = process.env;
  if (REACT_APP_LOG_ROCKET_API_KEY) {
    LogRocket.init(REACT_APP_LOG_ROCKET_API_KEY, {
      mergeIframes: true,
      parentDomain: REACT_APP_PERSEUS_URL,
    });
  }
};
