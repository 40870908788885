import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Select } from '@akiunlocks/perseus-ui-components';

import { sizeRegex } from './utils';

const Sizes = ({
  data,
  defaultValue,
  disabled,
  id,
  isLoading,
  label,
  onChange,
  placeholder,
  value: valueFromProps,
}) => {
  // controlled components have a value prop
  const isControlled = typeof valueFromProps != 'undefined';
  // uncontrolled components can have a defaultValue prop
  const hasDefaultValue = typeof defaultValue != 'undefined';

  // if defaultValue is specified, use it as the initial state
  const [internalValue, setInternalValue] = useState(hasDefaultValue ? defaultValue : null);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState(data);
  const value = isControlled ? valueFromProps : internalValue;

  useEffect(() => {
    setOptions(data);
  }, [data]);

  useEffect(() => {
    const found = options.find(item => item.value === valueFromProps);
    if (!found && sizeRegex.test(valueFromProps)) {
      const newOption = { value: valueFromProps, label: valueFromProps };
      setOptions([newOption, ...data]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueFromProps]);

  const internalOnChange = value => {
    if (onChange) {
      onChange(value);
    }
    if (!isControlled) {
      setInternalValue(value);
    }
  };

  const onInputChange = value => {
    setInputValue(value);
    if (sizeRegex.test(value)) {
      // if the input is a valid size, we add it to the options
      setOptions([{ value, label: value }, ...data]);
    }
  };

  return (
    <Select
      id={id}
      inputValue={inputValue}
      isDisabled={disabled}
      isLoading={isLoading}
      label={label}
      onChange={({ target }) => internalOnChange(target.value)}
      onInputChange={onInputChange}
      options={options}
      placeholder={placeholder}
      value={sizeRegex.test(value) ? { value, label: value } : null}
    />
  );
};

Sizes.propTypes = {
  /** Array of options for the select component */
  data: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ),
  /** The default value to be selected */
  defaultValue: PropTypes.string,
  /** Whether to disable the select or not */
  disabled: PropTypes.bool,
  /** Unique ID for the select component */
  id: PropTypes.string.isRequired,
  /** Whether to show the loader or not */
  isLoading: PropTypes.bool,
  /** Label for the select component */
  label: PropTypes.string,
  /** Callback function when selection changes */
  onChange: PropTypes.func,
  /** Placeholder text for the select input */
  placeholder: PropTypes.string,
  /** The selected value */
  value: PropTypes.string,
};

Sizes.defaultProps = {
  data: [],
  defaultValue: undefined,
  disabled: false,
  isLoading: false,
  label: 'Select or type and press enter',
  onChange: () => {},
  placeholder: 'Select or type...',
  value: undefined,
};

export default Sizes;
