import { makeAutoObservable, toJS } from 'mobx';

import { Notification, notify } from '@akiunlocks/perseus-ui-components';

import {
  PrismaText,
  PrismaImage,
  PrismaGroup,
  PrismaKeyframe,
  PrismaRect,
  PrismaCircle,
  PrismaCanvas,
} from '@prisma/lib';
import { DEFAULT_SCALING_MODE, SCALING_MODE } from '@prisma/lib/src/constants/images';
import { findLayerById } from '@prisma/lib/src/utils/layers';
import { eventsToKeys } from '@prisma/lib/src/utils/types';
import {
  ACTIVE_SELECTION,
  LAYER_TYPES,
  PRISMA_CIRCLE,
  PRISMA_GROUP,
  PRISMA_IMAGE,
  PRISMA_MASK,
  PRISMA_RECT,
  PRISMA_TEXT,
} from '@prisma/lib/src/constants';
import { createActiveSelection } from '@prisma/lib/src/utils/helpers';

import { ASPECT_RATIO_TYPE, CANVAS, CANVAS_CONTAINER_ID, SELECTION } from 'constants/canvas';
import { DEFAULT_TAB, EDIT_TAB, LAYERS_TAB } from 'constants/editor';
import { DEFAULT_CIRCLE, DEFAULT_RECT } from 'constants/shapes';
import { DEFAULT_TEXTBOX } from 'constants/textbox';

import { align, alignMany } from 'containers/Editor/stores/utilities/alignment';
import { replaceObjectImageSrc } from 'components/Sidebar/utilities';
import {
  distributeObjects,
  getDistributeSpacing,
  placeObjectsInContainer,
} from 'containers/Editor/stores/utilities/distribution';
import { FF_ASSET_POSITION } from 'utils/featureFlag';
import { setEditable, updateObjectLocks } from 'utils/helpers';
import { getDefaultUnitsByProductSubtype } from 'utils/responsive';
import { addTimestamp } from 'utils/url';
import {
  findGroup,
  findMaskInGroup,
  getGroups,
  getRetinaScalingFactor,
  setMultiAssetsDragImage,
  splitLayersByLevel,
} from './utilities';
import { getObjectsToArrange } from './utilities/activeSelection';
import { isSingleGroup, objectsAreFromSameGroup } from './utilities/groups';
import { drawMasks, getMasks } from './utilities/masks';

import ActiveSelectionStore from './ActiveSelection.store';
import AssetStore from './Asset.store';
import CanvasActionsStore from './CanvasActions.store';
import CircleStore from './Circle.store';
import ClipboardStore from './Clipboard.store';
import ContextStore from './Context.store';
import FontStore from './Font.store';
import GroupStore from './Group.store';
import GuidelineStore from './Guideline.store';
import HistoryStore from './History.store';
import ImageStore from './Image.store';
import MaskStore from './Mask.store';
import PageStore from './Page.store';
import RectStore from './Rect.store';
import SelectionStore from './Selection.store';
import TextboxStore from './Textbox.store';
import TimeLineSceneStore from './TimeLineScene.store';
import ChatStore from './Chat/Chat.store';
import { PRODUCT_SUBTYPES } from 'constants/compositions';
import Page from 'models/Page';
import { replaceAspectRatioInPage } from 'utils/aspectRatios';

class EditorStore {
  _canvas = null;
  _engine = null;
  _activeObject = null;
  _activeTab = DEFAULT_TAB;
  dragOffsetX = 0;
  dragOffsetY = 0;
  _layers = [];
  draggedOver = false;
  draggedItem = null;
  initialized = false;
  savingProject = false;

  layersProperties = [];
  canvasLoading = false;
  isInterstitial = false;

  boundary = SELECTION;

  constructor(rootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
    this.activeSelectionStore = new ActiveSelectionStore(this);
    this.assetStore = new AssetStore(this);
    this.canvasActionsStore = new CanvasActionsStore(this);
    this.circleStore = new CircleStore(this);
    this.clipboardStore = new ClipboardStore(this);
    this.contextStore = new ContextStore(this);
    this.fontStore = new FontStore(this);
    this.groupStore = new GroupStore(this);
    this.guidelineStore = new GuidelineStore(this);
    this.historyStore = new HistoryStore(this);
    this.imageStore = new ImageStore(this);
    this.maskStore = new MaskStore(this);
    this.pageStore = new PageStore(this);
    this.rectStore = new RectStore(this);
    this.selectionStore = new SelectionStore(this);
    this.textboxStore = new TextboxStore(this);
    this.timelineSceneStore = new TimeLineSceneStore(this);

    this.chatStore = new ChatStore(this);

    this.objectStoreMap = {
      [PRISMA_TEXT]: this.textboxStore,
      [PRISMA_IMAGE]: this.imageStore,
      [PRISMA_MASK]: this.maskStore,
      [PRISMA_GROUP]: this.groupStore,
      [PRISMA_RECT]: this.rectStore,
      [PRISMA_CIRCLE]: this.circleStore,
    };
  }

  setInitialized = initialized => {
    this.initialized = initialized;
  };

  set activeObject(activeObject) {
    this._activeObject = activeObject;

    this.objectStoreMap[activeObject?.type]?.initialize(this._activeObject);

    this.handleMasking();

    if (this._activeObject) {
      this.activeTab = EDIT_TAB;
    }
    return this._activeObject;
  }

  getActiveStore = () => {
    return this.getObjectStore(this._activeObject);
  };

  getObjectStore = object => {
    const type = object?.isGroup ? PRISMA_GROUP : this._activeObject?.type;
    return this.objectStoreMap[type];
  };

  /**
   * Handles the masking of objects on the canvas.
   * If a group is selected, it will handle the mask for the group and not for its children.
   */
  handleMasking() {
    drawMasks(this._canvas.getObjects(), this._canvas.getActiveObjects());
  }

  discardObjectFromSelection = object => {
    if (object && this._activeObject?.isType(ACTIVE_SELECTION) && this._activeObject?.getObjects().length > 1) {
      this._activeObject.removeWithUpdate(object);
      this._canvas.renderAll();
    }
  };

  setActiveObject = (object, isMultiselect) => {
    if (object) {
      if (isMultiselect && this._activeObject) {
        // if the active object is already an active selection we add it to the selection
        if (this._activeObject.isType(ACTIVE_SELECTION)) {
          // as it is an active selection we have to call the addWithUpdate instead of add according to fabric docs
          // http://fabricjs.com/docs/fabric.Canvas.html#add
          this._activeObject.addWithUpdate(object);

          // addWithUpdate has a bug that it does not call the selection:updated event, will be fixed in version 6
          // https://github.com/fabricjs/fabric.js/pull/7858/
          this.canvas.fire('selection:updated', {
            // leaving them as comments because I'm not sure if at some point not sending this will cause issues
            // e: event,
            // selected: this._activeObject,
          });
        } else {
          // we create a new active selection with the current active object and the new object
          const selection = createActiveSelection([this._activeObject, object]);
          this._canvas.setActiveObject(selection);
        }
      } else {
        this._canvas.setActiveObject(object);
      }
      updateObjectLocks(object, !object.selectable);
    } else {
      this._canvas.discardActiveObject();
      this.activeObject = null;
    }
    this._canvas.renderAll();
  };

  isActiveObjectMovementLocked() {
    return this.activeObject?.lockMovementX || this.activeObject?.lockMovementY;
  }

  setActiveObjectLockMovement(lockMovementX, lockMovementY) {
    this.activeObject?.set({ lockMovementX, lockMovementY });
  }

  get activeObject() {
    return this._activeObject;
  }

  _setActiveObjects = options => {
    this.activeObject = this._canvas.getActiveObject();
    let activeObjects = this._canvas.getActiveObjects();
    let layersToSelect;

    this.isGroupSelected = !!this._activeObject?.isGroup;
    if (this.isGroupSelected && !isSingleGroup(activeObjects)) {
      // the active selection can be a group, but it also can have more than one group.
      // or one or more groups mixed with not grouped layers
      // so we have to remove isGroup property
      this.isGroupSelected = false;
      this._activeObject.isGroup = false;
    }

    if (activeObjects.length > 1 && !this.isGroupSelected && !this.activeObject.customActiveSelection) {
      layersToSelect = this._handleMultipleActiveObjects(activeObjects);
    } else {
      layersToSelect = this._handleSingleActiveObject();
    }

    if (options?.e) {
      // if event exists is because a mouse event caused the selection so we have to select the objects
      this.contextStore.selectLayersFromCanvas(
        this._layers.filter(layer => layersToSelect.find(obj => obj.id === layer.id)),
      );
    }
  };

  _handleMultipleActiveObjects = activeObjects => {
    const [firstLevel, otherLevel] = splitLayersByLevel(activeObjects);

    let toAdd, layersToSelect;
    if (firstLevel.length) {
      // if we have groups, we add all their children to the array
      toAdd = firstLevel.reduce((acc, obj) => {
        if (obj.type === PRISMA_GROUP) {
          return [...acc, obj, ...obj.objects];
        }
        return [...acc, obj];
      }, []);
      layersToSelect = firstLevel;
    } else {
      // if we don't have layers in the firstLevel we have only group children because you can't select a child and a first level layer
      toAdd = otherLevel;
      layersToSelect = toAdd;
    }
    // discard active selection to make objects have their updated properties
    this._canvas.discardActiveObject();
    const selection = createActiveSelection(toAdd);
    selection.customActiveSelection = true; // we add this temporal value so next time we don't enter in this condition
    this._canvas.setActiveObject(selection);
    this._canvas.requestRenderAll();
    this.activeSelectionStore.initialize(selection);

    return layersToSelect;
  };

  _handleSingleActiveObject = () => {
    const isLayersTab = this.contextStore.getTimelineTab() === LAYERS_TAB;
    if (this._activeObject?.type === PRISMA_GROUP && isLayersTab) {
      const group = this._activeObject;
      const canvas = this._canvas;
      canvas.discardActiveObject();
      const selection = createActiveSelection([group, ...group.objects]);
      selection.isGroup = true;
      updateObjectLocks(selection, !group.selectable);
      canvas.setActiveObject(selection);
      canvas.requestRenderAll();
      this.activeSelectionStore.initialize(selection);
      return [group];
    }
    return this._canvas.getActiveObjects();
  };

  _setLayers = () => {
    this._layers = [...this._canvas.layers];
  };

  get canvas() {
    return this._canvas;
  }

  get layers() {
    return toJS(this._layers);
  }

  isCanvasLoading() {
    return this.canvasLoading === true;
  }

  setCanvasLoading(loading) {
    this.canvasLoading = loading;
  }

  getReversedLayers() {
    return this._layers ? this._layers.slice().reverse() : [];
  }

  get engine() {
    return this._engine;
  }

  set canvas(canvas) {
    if (this._canvas) {
      this._canvas.off('selection:created', this._setActiveObjects);
      this._canvas.off('selection:updated', this._setActiveObjects);
      this._canvas.off('selection:cleared', this._setActiveObjects);
      this._canvas.off(eventsToKeys.onLayersChange, this._setLayers);
    }

    this._canvas = canvas;
    this._engine = canvas.engine;
    this._activeObject = null;

    this._canvas.on('selection:created', this._setActiveObjects);
    this._canvas.on('selection:updated', this._setActiveObjects);
    this._canvas.on('selection:cleared', this._setActiveObjects);
    this._canvas.on(eventsToKeys.onLayersChange, this._setLayers);
  }

  set activeTab(tab) {
    return (this._activeTab = tab);
  }

  get activeTab() {
    return this._activeTab;
  }

  loadCanvas = projectSize => {
    this.canvas = new PrismaCanvas(CANVAS_CONTAINER_ID, {
      width: projectSize.width,
      height: projectSize.height,
      isInterstitial: projectSize.productSubtype === PRODUCT_SUBTYPES.INTERSTITIAL_BANNER,
    });
  };

  loadProjectSize = (projectSize, callback = () => {}) => {
    this.setInitialized(false);
    const { projectAssetsBaseUrl } = this.rootStore.projects;
    this.pageStore.loadProjectSize(projectSize);
    this.isInterstitial = projectSize?.productSubtype === PRODUCT_SUBTYPES.INTERSTITIAL_BANNER;
    this.canvas.loadFromPrismaJSON(projectSize, projectAssetsBaseUrl, () => {
      const notLoadedFonts = [
        ...new Set(
          this.canvas.layers
            .filter(l => l.target.type === PRISMA_TEXT && !l.target.fontLoaded)
            .map(l => l.target.fontFamily),
        ),
      ];
      this.notifyNotLoadedFonts(notLoadedFonts);
      this.setLayersProperties(this.canvas.toObject().layers); // set initial layer properties
      this.setInitialized(true);
      callback();
    });
  };

  notifyNotLoadedFonts(fonts) {
    if (!fonts?.length) {
      return;
    }
    fonts.sort();
    let message = 'Could not load';
    if (fonts.length === 1) {
      message = `${message} font ${fonts[0]}`;
    } else {
      const list = `${fonts.slice(0, -1).join(', ')} and ${fonts[fonts.length - 1]}`;
      message = `${message} fonts ${list}`;
    }
    notify(message, Notification.TYPE.WARNING);
  }

  reloadProjectSize(projectSize) {
    if (projectSize) {
      this.setCanvasLoading(true);
      this.canvas.dispose();
      this.loadCanvas(projectSize);
      this.loadProjectSize(projectSize, () => {
        this.setCanvasLoading(false);
      });
    }
  }

  updateActiveTimeLineTab = tab => {
    // TODO: for a better UX we should remove this active object to null. However, at the moment if you are in scenes tab,
    // with an object selected, you move to layers tabs and modify the object, it affects the animation at that time.
    // we remove the events when changing tabs, but something else is happening
    this.setActiveObject(null);

    // we always load layer properties because animations don't change every property.
    this.loadLayersProperties();

    if (tab !== LAYERS_TAB) {
      // move to last saved position
      this.canvas.engine.moveTo(this.timelineSceneStore.currentTime);
    }
    this.canvas.renderAll();
  };

  setDraggedOver(isDraggedOver) {
    return (this.draggedOver = isDraggedOver);
  }

  setDraggedItem(item) {
    return (this.draggedItem = item);
  }

  disableActiveObjectEdition() {
    if (this.activeObject) {
      updateObjectLocks(this.activeObject, true);
      this.canvas.renderAll();
    }
  }

  getObjectPositionInCanvas(params, objectProps) {
    const {
      left = this._canvas.width / 2 - objectProps.width / 2,
      top = this._canvas.height / 2 - objectProps.height / 2,
    } = params;
    return { left, top };
  }

  getCurrentProjectSizeDefaultUnits = () => {
    const { projectSize } = this.rootStore.projectSizes;
    return getDefaultUnitsByProductSubtype(projectSize?.productSubtype);
  };

  addRect = params => {
    const { left, top } = this.getObjectPositionInCanvas(params, DEFAULT_RECT);
    const rect = new PrismaRect({
      top,
      left,
      ...DEFAULT_RECT,
      ...this.getCurrentProjectSizeDefaultUnits(),
    });
    this._addToLayersAndSetActive(rect);
    this.activeTab = EDIT_TAB;
  };

  addCircle = params => {
    const { left, top } = this.getObjectPositionInCanvas(params, DEFAULT_CIRCLE);
    const circle = new PrismaCircle({
      top,
      left,
      ...DEFAULT_CIRCLE,
      ...this.getCurrentProjectSizeDefaultUnits(),
    });
    this._addToLayersAndSetActive(circle);
    this.activeTab = EDIT_TAB;
  };

  addTextbox = params => {
    const { left, top } = this.getObjectPositionInCanvas(params, DEFAULT_TEXTBOX);
    const text = new PrismaText('Type something', {
      top,
      left,
      ...DEFAULT_TEXTBOX,
      ...this.getCurrentProjectSizeDefaultUnits(),
    });

    this._addToLayersAndSetActive(text);
    text.enterEditing();
    text.selectAll();
    this.activeTab = EDIT_TAB;
  };

  addImage = params => {
    const { left = 0, top = 0, imageUrl, ...otherParams } = params;
    const { projectAssetsBaseUrl } = this.rootStore.projects;

    return new Promise(resolve => {
      PrismaImage.fromURL(
        addTimestamp(imageUrl), // add a dummy query param to force the reload
        image => {
          image.left = left;
          image.top = top;
          image.imageUrl = imageUrl;
          this._addToLayersAndSetActive(image);
          this.activeTab = EDIT_TAB;
          resolve();
        },
        {
          baseUrl: projectAssetsBaseUrl,
          retinaScalingFactor: getRetinaScalingFactor(imageUrl),
          scalingMode: FF_ASSET_POSITION ? DEFAULT_SCALING_MODE : SCALING_MODE.STRETCH,
          uniformScaling: true,
          ...this.getCurrentProjectSizeDefaultUnits(),
          ...otherParams,
        },
      );
    });
  };

  /**
   * Adds assets from selectionStore to the canvas
   * @param {number} left
   * @param {number} top
   */
  addSelectedAssets = async (left, top) => {
    const assets = this.selectionStore.getSelectedComponents();
    if (!assets.length || !this.selectionStore.isAssetType(assets[0])) {
      return;
    }

    this.setCanvasLoading(true);
    await Promise.all(
      assets.map(async ({ imageUrl }) => {
        await this.addImage({
          imageUrl,
          left,
          top,
        });
      }),
    );
    this.setCanvasLoading(false);
  };

  /**
   * Adds an object to the canvas layers and sets it as active. Also adds the
   * layer to the selection store.
   * @param {object} obj - Target object
   */
  _addToLayersAndSetActive = obj => {
    const layer = this._canvas.addLayer(obj, this.pageStore.getNextLayerId());
    this._canvas.setActiveObject(obj);
    this.selectionStore.addSelectedComponent(layer);
  };

  deleteObject = object => {
    if (object) {
      this.canvas.removeLayer(this.activeObject);
      // when we remove active selections the selection is not removed from canvas unless we discard the active object
      this.canvas.discardActiveObject();
      this.setActiveObject(null);
      this.canvas.renderAll();
    }
  };

  deleteActiveObject = () => {
    this.deleteObject(this.activeObject);
  };

  onDragStart = event => {
    const draggedItemType = event.target.getAttribute('data-type');
    if (!draggedItemType) {
      return;
    }

    if (draggedItemType === PRISMA_IMAGE) {
      const draggedItemId = event.target.getAttribute('data-id');
      const isMultiSelect = event.metaKey || event.shiftKey;
      this.selectionStore.addAssetsToSelection([draggedItemId], isMultiSelect);

      if (this.selectionStore.getSelectedComponents().length > 1) {
        setMultiAssetsDragImage(event);
      }
    }

    event.dataTransfer.setData('text/plain', draggedItemType);

    const boundingClientRect = event.target.getBoundingClientRect();
    this.draggedItem = event.target;
    this.dragOffsetX = event.clientX - boundingClientRect.left;
    this.dragOffsetY = event.clientY - boundingClientRect.top;
  };

  onDragEnd = event => {
    this.draggedItem = null;
  };

  exportToJSON = () => {
    const json = this._canvas.toObject();
    let hasAnimation = false;

    const pages = this.pageStore.pages.map(page => {
      if (page.id === this.pageStore.getCurrentPage().id) {
        // override with initial properties
        let layers = json.layers.map(layer => {
          const initialLayer = this.layersProperties.find(({ id }) => layer.id === id);
          let properties = initialLayer ? initialLayer.properties : layer.properties;
          // clone the properties to avoid changing the original object
          // visible and selectable props are only for the editor, should always be saved as true
          properties = { ...properties, visible: true, selectable: true };
          if (!hasAnimation && layer.animations?.length) {
            hasAnimation = true;
          }
          return { ...layer, properties };
        });
        const newPage = new Page({ ...page, layers });

        if (this.isInterstitial) {
          if (ASPECT_RATIO_TYPE.BIG !== this.pageStore.getCurrentAspectRatioType()) {
            replaceAspectRatioInPage(newPage, ASPECT_RATIO_TYPE.BIG);
          }
          return newPage;
        }

        return newPage;
      }
      return page;
    });

    return {
      animationExists: hasAnimation,
      pages,
    };
  };

  groupActiveObjects = () => {
    const canvas = this._canvas;
    const activeSelection = canvas.getActiveObject();

    if (!activeSelection) {
      return;
    }

    const selectedObjects = (activeSelection?.getObjects && activeSelection.getObjects()) || [activeSelection];

    // if any group, mask, or layer in a group is selected, do nothing
    if (
      getGroups(selectedObjects).length ||
      getMasks(selectedObjects).length ||
      selectedObjects.some(({ groupId }) => groupId)
    ) {
      return;
    }

    const options = this.getCurrentProjectSizeDefaultUnits();
    PrismaGroup.fromActiveSelection(activeSelection, options, group => {
      // add mask first to have less z-index than group
      const mask = findMaskInGroup(group);
      if (mask) {
        canvas.addLayer(mask, this.pageStore.getNextLayerId());
        // update object ids after mask was added
        group.maskId = mask.id;
        group.updateObjectIds();
      }
      // add group layer
      canvas.addLayer(group, this.pageStore.getNextLayerId());

      // update layer properties and layersProperties
      const newProps = { groupId: group.id, maskId: mask?.id };
      group.objects.forEach(object => {
        this.updatePropertiesByLayerId(object.id, newProps);
      });

      canvas.setActiveObject(group);
      this.activeTab = EDIT_TAB;
      // link group and mask ids
      group.objects.forEach(object => {
        object.groupId = group.id;
        if (object.type !== PRISMA_MASK) {
          object.maskId = mask?.id;
        }
      });

      canvas.sortLayersIndex();
    });
  };

  ungroupActiveObject = () => {
    const canvas = this._canvas;

    const selection = canvas.getActiveObject();
    if (!selection.isGroup) {
      return;
    }

    const group = findGroup(selection.getObjects());
    if (!group) {
      return;
    }

    group.objects.forEach(object => {
      object.groupId = null;
      object.maskId = null;
      object.clipPath = null;
      this.updatePropertiesByLayerId(object.id, { groupId: null, maskId: null });
    });

    const mask = findMaskInGroup(group);
    if (mask) {
      canvas.removeLayer(mask);
    }

    canvas.removeLayer(group, false); // do not remove children
    canvas.discardActiveObject();

    canvas.sortLayersIndex();
  };

  setMaskLockedPosition() {
    if (this.activeObject?.type !== PRISMA_MASK) {
      return;
    }
    this.activeObject.set('lockedPosition', this.maskStore.lockedPosition);
    this._canvas.updateMaskAnimations(this.activeObject);
  }

  setLayersProperties(layersProperties) {
    this.layersProperties = layersProperties;

    this.layersProperties.forEach(layer => {
      this.updatePropertiesByLayerId(layer.id, layer.properties);
    });
  }

  /**
   * Updates a layer from layersProperties, using the properties from an object.
   * This method is used to update the group properties only.
   * @param {object} layer - Layer from layersProperties to be updated.
   * @param {object} object - Object to take the updated properties.
   */
  addUpdatedGroupProperties(layer, object) {
    if (object.type === PRISMA_GROUP) {
      // group object will always have the updated objectIds
      layer.properties.objectIds = object.objectIds;
    }
    layer.properties.maskId = object.maskId;
    layer.properties.groupId = object.groupId;
  }

  addLayerToLayersProperties(layer) {
    const layerToAdd = this.canvas.toObject().layers.find(({ id }) => layer.id === id);
    if (layerToAdd) {
      this.addUpdatedGroupProperties(layerToAdd, layer);
      this.layersProperties.push(layerToAdd);
      this.canvas.engine.addOrUpdateLayerProps(layerToAdd);
      this.updateGroupLayerPropertiesIfNeeded(layer);
    }
  }

  deleteLayerFromLayersProperties(layer) {
    const layerToDelete = this.canvas.toObject().layers.find(({ id }) => layer.id === id);
    if (layerToDelete) {
      this.layersProperties = this.layersProperties.filter(l => l.id !== layerToDelete.id);
      this.canvas.engine.deleteLayerProps(layerToDelete);
      this.updateGroupLayerPropertiesIfNeeded(layer);
    }
  }

  updateGroupLayerPropertiesIfNeeded(layer) {
    if (!layer.groupId) {
      return;
    }
    // if layer belongs to a group, we get the group layer
    const groupLayer = this.canvas.toObject().layers.find(({ id }) => layer.groupId === id);
    if (groupLayer) {
      // we find the layer properties of the found group
      const index = this.layersProperties.findIndex(l => l.id === groupLayer.id);
      if (index !== -1) {
        this.layersProperties[index] = groupLayer;
      }
      this.canvas.engine.addOrUpdateLayerProps(groupLayer);
    }
  }

  loadLayersProperties() {
    if (!this.canvasHasObjects()) {
      return;
    }

    this._canvas.getObjects().forEach(object => {
      const initialLayer = this.layersProperties.find(({ id }) => object.id === id);
      if (initialLayer) {
        const { properties } = initialLayer;
        object.setOptions({
          ...properties,
          left: properties.x + object.offsetLeft,
          top: properties.y + object.offsetTop,
        });
        object.setCoords();
      }
    });

    this._canvas.renderAll();
  }

  /**
   * Refreshes layers properties based on canvas layers
   */
  refreshLayersProperties() {
    this.layersProperties = [];
    this.layers.forEach(layer => {
      this.addLayerToLayersProperties(layer.target);
    });
  }

  /**
   * Toggles the layer lock in the canvas and in the properties
   * We set the selectables properties in the target so we see the changes in the canvas,
   * but also in the properties so we can change layers and the state is preserved, and the undo/redo works
   *
   * @param {number} layerId
   * @param {boolean} selectable
   */
  toggleLayerLock(layerId, selectable) {
    const props = {};
    setEditable(props, selectable);
    this.updatePropertiesByLayerId(layerId, props, { cascade: true, canvasUpdate: true });
  }

  toggleLayerVisibility(layerId, visible) {
    this.updatePropertiesByLayerId(layerId, { visible }, { cascade: true, canvasUpdate: true });
  }

  /**
   * Updates the properties of a layer in the canvas and in the properties
   * @param {number} layerId
   * @param {object} properties
   * @param {object} options
   * @param {boolean} options.cascade - If true, it will update the properties of the objects in the layer
   * @param {boolean} options.canvasUpdate - If true, it will update the properties in the canvas. Sometimes this function is
   * already called from the canvas update so we don't need to update it again because it can cause side effect with active selection or groups.
   */
  updatePropertiesByLayerId(layerId, properties, options = { cascade: false, canvasUpdate: false }) {
    const layer = this.canvas.layers.find(({ id }) => id === layerId);
    if (!layer) {
      return;
    }
    if (layer.type === PRISMA_GROUP && options.cascade) {
      layer.target.objects.forEach(object => {
        this.updatePropertiesByLayerId(object.id, properties, options);
      });
    }
    // we update the properties that will be saved
    layer.properties = { ...layer.properties, ...properties };

    // we update the canvas
    if (options.canvasUpdate) {
      Object.entries(properties).forEach(([key, value]) => {
        layer.target.set(key, value);
      });
    }

    this.updateLayersPropertiesByLayerId(layerId, properties);
  }

  /**
   * Updates the properties of a layer in the properties
   * @param {number} layerId
   * @param {object} properties
   */
  updateLayersPropertiesByLayerId(layerId, properties) {
    // we update the layerProperties that at some point we will deprecate
    const index = this._findLayerPropIndex(layerId);
    this.layersProperties[index].properties = { ...this.layersProperties[index].properties, ...properties };

    // update engine props
    this.canvas.engine.addOrUpdateLayerProps(this.layersProperties[index]);
  }

  canvasHasObjects() {
    return !!this._canvas?.getObjects().length;
  }

  getCanvasLayers() {
    return this._canvas.toObject().layers;
  }

  _findLayerPropIndex(layerId) {
    return this.layersProperties.findIndex(({ id }) => layerId === id);
  }

  setSavingProject(savingProject) {
    this.savingProject = savingProject;
  }

  /**
   * Updates project size in the database
   * @param {string} projectSizeId
   */
  async saveProjectSize(projectSizeId) {
    this.setSavingProject(true);
    this.canvas.engine.pause();
    const exportedJSON = this.exportToJSON();

    try {
      await this.rootStore.projectSizes.updateProjectSize(projectSizeId, exportedJSON);
      this.rootStore.projectSizes.setUnsavedChanges(false);
    } finally {
      this.setSavingProject(false);
    }
  }

  removeComponents(objects) {
    if (objects?.length) {
      objects.forEach(object => {
        this.removeComponent(object);
      });
    }
  }

  removeComponent(object) {
    if (object instanceof PrismaKeyframe) {
      const { currentTime } = this.timelineSceneStore;
      const updatedLayer = this.canvas.removeKeyframe(object, currentTime);
      this.canvas.engine.seek(currentTime); // refresh the canvas
      this.timelineSceneStore.setActiveLayer(updatedLayer);
      this.timelineSceneStore.clearActiveAnimation();
    } else if (LAYER_TYPES.includes(object.type) || object.type === ACTIVE_SELECTION) {
      this.deleteObject(object.target || object);
    }
    // we can add other selected objects here
  }

  /**
   * Returns the editor layer from a layer object by matching the ids.
   * This layer can be the canvas layer or even an editor layer
   *
   * @param {object} layer
   * @return {object} Editor layer
   */
  getEditorLayerByTarget(layer) {
    if (layer.target) {
      return layer; // it is already an editor layer
    }
    return this._layers.find(({ id }) => id === layer.id);
  }

  /**
   * Finds animation in a layer by type.
   * @param {number} layerId - Layer id.
   * @param {string} animationType - Animation type.
   * @return {array} Animation list if found, else undefined.
   */
  findLayerAnimation(layerId, animationType) {
    const layer = this.canvas.layers.find(l => l.id === layerId);
    return layer?.animations.find(({ type }) => type === animationType);
  }

  /**
   * Resets group size if it is present in canvas layers
   * @param {number|undefined} groupId - Group id.
   */
  resetGroupSizeIfExists(groupId) {
    if (!groupId) {
      return;
    }
    const group = this.canvas.layers.find(({ id }) => id === groupId)?.target;
    if (group) {
      PrismaGroup.resetSize(group);
    }
  }

  setBoundary(boundary) {
    this.boundary = boundary;
  }

  setAlignment(activeObject, alignmentType) {
    if (activeObject.isGroup || activeObject.type !== ACTIVE_SELECTION) {
      align(activeObject, alignmentType);
    } else {
      const objectsToAlign = getObjectsToArrange(activeObject);
      alignMany(objectsToAlign, alignmentType, this.boundary);
    }
    this.canvas.renderAll();
  }

  /**
   * Given an active selection, spaces objects evenly, horizontally or vertically, within the selection or the canvas.
   * @param {object} activeSelection - Active selection.
   * @param {string} distributionType - Vertical or horiontal.
   * @param {string} boundary - Selection or canvas.
   */
  distributeObjects(activeSelection, distributionType, boundary) {
    const isCanvasBoundary = boundary === CANVAS;
    const isSingleGroup = activeSelection.isGroup;
    const groups = getGroups(activeSelection.getObjects());

    let container = isCanvasBoundary ? this.canvas._banner : activeSelection;
    if (isCanvasBoundary && !isSingleGroup && objectsAreFromSameGroup(activeSelection.getObjects())) {
      // if activeSelection is not a group, and all the objects are from same group
      // use group limits as container, or use canvas if group not found
      const groupLayer = findLayerById(this.canvas.layers, activeSelection.getObjects()[0].groupId);
      container = groupLayer?.target || this.canvas._banner;
    }

    const shouldPlaceObjectsInContainer = isCanvasBoundary || isSingleGroup;
    const shouldResetGroupSize = isCanvasBoundary && isSingleGroup; // single group distributed to canvas

    const objects = getObjectsToArrange(activeSelection);

    if (shouldPlaceObjectsInContainer) {
      // discard active object
      this.canvas.discardActiveObject();

      // move objects
      placeObjectsInContainer(objects, container, distributionType);

      if (shouldResetGroupSize) {
        this.resetGroupSizeIfExists(groups[0].id);
      }

      // create selection again
      const selection = createActiveSelection(objects);
      this.canvas.setActiveObject(selection);
    }

    const spacing = getDistributeSpacing(objects, container, distributionType);
    distributeObjects(objects, spacing, distributionType);

    if (isSingleGroup) {
      this.contextStore.selectLayer(groups[0]);
    }

    this.canvas.renderAll();
  }

  /**
   * Returns the list of keyframes for all the keyframe ids.
   * @param {Array} keyframeIds - List of keyframe ids.
   * @return {Array} List of keyframes.
   */
  getKeyframesByIds(keyframeIds) {
    let keyframes = [];
    this.layers.forEach(layer => {
      layer.animations.forEach(animation => {
        keyframes = [...keyframes, ...animation.keyframes.filter(keyframe => keyframeIds.includes(keyframe.id))];
      });
    });
    return keyframes;
  }

  /**
   * Gets a list of assets by ids
   * @param {Array} assetIds - List of asset ids.
   * @return {Array} List of assets.
   */
  getAssetsByIds(assetIds) {
    return this.rootStore.projects.assets.filter(a => assetIds.includes(a._id));
  }

  /**
   * Replaces new asset into the layer and updates layers properties.
   * @param {object} layer - Layer to be updated.
   * @param {object} newAsset - New asset.
   * @return {Promise}
   */
  async replaceAssetInLayer(layer, newAsset) {
    const { projectAssetsBaseUrl } = this.rootStore.projects;

    // get current dimensions to keep layer size after replace
    const originalWidth = layer.target.getScaledWidth();
    const originalHeight = layer.target.getScaledHeight();

    await replaceObjectImageSrc(layer.target, `${projectAssetsBaseUrl}${newAsset.imageUrl}`);

    const { height, width } = layer.target;
    newAsset.width = width;
    newAsset.height = height;
    newAsset.retinaScalingFactor = getRetinaScalingFactor(newAsset.imageUrl);
    newAsset.scaleX = originalWidth / width;
    newAsset.scaleY = originalHeight / height;

    layer.target.setOptions(newAsset);
    layer.properties = { ...layer.properties, ...newAsset };
    this.updateLayersPropertiesByLayerId(layer.id, { ...newAsset });

    this.canvas.renderAll();
  }

  async loadPageIntoCanvas(page) {
    const { projectAssetsBaseUrl } = this.rootStore.projects;
    await new Promise(resolve => {
      this.canvas.loadFromSnapshot({ pages: [page] }, projectAssetsBaseUrl, () => {
        this.refreshLayersProperties();
        this.updateActiveTimeLineTab(this.contextStore.timelineTab);
        resolve();
      });
    });
  }
}

export default EditorStore;
