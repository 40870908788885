import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import getValue from 'lodash/get';

import { Input, InputGroup } from '@akiunlocks/perseus-ui-components';
import { UNITS } from '@prisma/lib/src/constants/objects';
import { animationTypes, frameKeysToFabricProperties } from '@prisma/lib/src/utils/types';

import { USE_RESPONSIVE } from 'utils/featureFlag';

import './AnimationInput.scss';

const ANIMATIONS_LABELS = {
  [frameKeysToFabricProperties.objectOpacity]: '',
  [frameKeysToFabricProperties.rotation]: '',
  [frameKeysToFabricProperties.scaleX]: 'X',
  [frameKeysToFabricProperties.scaleY]: 'Y',
  [frameKeysToFabricProperties.x]: 'X',
  [frameKeysToFabricProperties.y]: 'Y',
  x: 'X',
  y: 'Y',
};

const ANIMATIONS_UNITS = {
  [animationTypes.opacity]: UNITS.PERCENTAGE,
  [animationTypes.position]: UNITS.PIXELS,
  [animationTypes.rotation]: '°',
  [animationTypes.scale]: UNITS.PERCENTAGE,
};

const ANIMATIONS_PARAMS = {
  [frameKeysToFabricProperties.objectOpacity]: { step: 1, min: 0, max: 100 },
  [frameKeysToFabricProperties.rotation]: { step: 1, min: -359, max: 360 },
  [frameKeysToFabricProperties.scaleX]: { step: 1, min: 1 },
  [frameKeysToFabricProperties.scaleY]: { step: 1, min: 1 },
};

const AnimationInput = ({ animationType, disabled, id, inputType, onChange, placeholder, property, unit, value }) => {
  const [internalValue, setInternalValue] = useState(value);
  // reference used to avoid setting internal value on input change
  const skipNextRef = useRef(false);

  useEffect(() => {
    if (skipNextRef.current) {
      skipNextRef.current = false;
      return;
    }
    setInternalValue(value);
  }, [value]);

  const controlProps = {
    disabled,
    name: property,
    placeholder,
    type: inputType,
    value: internalValue,
    onKeyDown: e => {
      if (e.key === 'Enter') {
        onChange(e.target.value);
      }
    },
    onChange: e => {
      const newValue = e.target.value;
      skipNextRef.current = true;
      setInternalValue(newValue);
      if (newValue.trim() !== '') {
        onChange(newValue);
      }
    },
    onBlur: () => {
      if (!internalValue) {
        // to prevent empty input
        setInternalValue(value);
      }
    },
    onClick: e => e.stopPropagation(), // to prevent the layer to be selected
    ...getValue(ANIMATIONS_PARAMS, property, {}),
  };

  const unitValue = unit || ANIMATIONS_UNITS[animationType];

  return (
    <span className="animation-input">
      <div className="label">{ANIMATIONS_LABELS[property]}</div>
      {USE_RESPONSIVE ? (
        <InputGroup
          className="value-responsive"
          items={[
            { controlProps, type: 'input' },
            ...(unitValue
              ? [
                  {
                    content: unitValue,
                    disabled: true,
                    type: 'button',
                    variant: 'secondary-alt',
                  },
                ]
              : []),
          ]}
          size="xs"
        />
      ) : (
        <>
          <Input className="value" controlProps={controlProps} id={id} label="" size="xs" />
          <div className="unit">{unitValue}</div>
        </>
      )}
    </span>
  );
};

AnimationInput.propTypes = {
  /** Flag to indicate if input is disabled */
  disabled: PropTypes.bool,
  /** Input id */
  id: PropTypes.string.isRequired,
  /** Input type (text, number, etc) */
  inputType: PropTypes.string,
  /** Action to call on input change */
  onChange: PropTypes.func,
  /** Input placeholder */
  placeholder: PropTypes.string,
  /** Input property to be modified */
  property: PropTypes.string,
  /** Animation unit. If it is undefined, it is implied from ANIMATIONS_UNITS.  */
  unit: PropTypes.string,
  /** Input value */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

AnimationInput.defaultProps = {
  disabled: false,
  inputType: 'text',
  onChange: () => {},
  placeholder: '',
  unit: undefined,
  value: '',
};

export default AnimationInput;
