import { fabric } from 'fabric';

import {
  addFabricProperty,
  calculateKeyframesFromAnimaton,
  getNearKeyframes,
  moveRotationAtTheEnd,
} from './utils/animations.js';
import easeLinear, { easingList } from './utils/easings.js';
import { ANIMATIONS_WITH_POSITION, animationTypes, statuses, typesToFrameKeys } from './utils/types.js';
import { createActiveSelection } from './utils/helpers.js';

class AnimationEngine {
  animConfig = {
    prevTickTime: null,
    requestRef: null,
    initialTick: 0,
    startTickTime: 0,
    status: statuses.pause,
  };

  objects = [];
  layersProps = {};
  keyframes = [];
  animationDuration = 0;

  constructor({ onAnimationBegin, onTick, onAnimationEnd, onAnimationPaused, renderAll, ease }) {
    this.onAnimationBegin = onAnimationBegin;
    this.onAnimationEnd = onAnimationEnd;
    this.onAnimationPaused = onAnimationPaused;
    this.onTick = onTick;
    this.renderAll = () => {
      this._applyClipPathToMaskedObjects();
      renderAll();
    };
    this.ease = ease;
  }

  setLayersProps(layers) {
    this.layersProps = {};
    layers.forEach(({ id, properties }) => {
      this.layersProps[id] = properties;
    });
  }

  addOrUpdateLayerProps(layer) {
    this.layersProps[layer.id] = layer.properties;
  }

  deleteLayerProps(layer) {
    delete this.layersProps?.[layer.id];
  }

  calculateKeyframesForAnimation = (newLayers, moveToStart = false) => {
    const newKeyframes = [];

    newLayers.forEach(layer => {
      layer.animations.forEach(anim => {
        newKeyframes.push(...calculateKeyframesFromAnimaton(anim, layer));
      });
    });

    newKeyframes.sort((a, b) => a.time - b.time);

    this.keyframes = newKeyframes;
    this.animationDuration = this.keyframes
      .map(k => k.time)
      .reduce((prev, current) => (prev > current ? prev : current), 0);

    this.objects = newLayers.map(l => l.target);

    // initial render to apply masks
    this.renderAll();

    if (moveToStart) {
      this.seek(0);
    }

    return newKeyframes;
  };

  getUsedFrameTypesByLayer = _keyframes => {
    let usedIds = {};
    _keyframes.forEach(el => {
      if (usedIds[el.layerId]) {
        usedIds[el.layerId].add(el.type);
      } else {
        usedIds = { ...usedIds, [el.layerId]: new Set([el.type]) };
      }
    });
    const idKeys = Object.keys(usedIds);
    idKeys.forEach(key => {
      const types = [...usedIds[key]];
      // this is needed to apply the rotation after any other animation
      moveRotationAtTheEnd(types);
      usedIds[key] = types;
    });
    return usedIds;
  };

  calculateNewProps = (currentTime, keyframes) => {
    const { current, previous, next } = keyframes;
    const { type } = Object.values(keyframes).find(kf => kf?.type);
    const updatedProps = {};

    if (type === animationTypes.hide) {
      // For hide animation, return the value of the current or the previous if they are present,
      // else get the props from the stored object
      const keyframe = current || previous;
      const hide = keyframe ? keyframe.properties?.hide : this.layersProps[next.layerId]?.hide;
      // Sometimes we can't get the value of the hide property, if that happens, don't change the current value.
      // This is a workaround until we find what is causing the missing property.
      if (typeof hide === 'boolean') {
        addFabricProperty(updatedProps, typesToFrameKeys[type][0], hide);
      }
    } else if (current) {
      // If there is a current keyframe we don't need to calculate anything
      typesToFrameKeys[current.type].forEach(valueKey => {
        addFabricProperty(updatedProps, valueKey, current.properties[valueKey]);
      });
    } else if (!next) {
      // If there is no next keyframe we use the value of the previous
      typesToFrameKeys[previous.type].forEach(valueKey => {
        addFabricProperty(updatedProps, valueKey, previous.properties[valueKey]);
      });
    } else if (!previous) {
      // If the current time is before the first keyframe we use the layer props
      const layerProps = { ...this.layersProps[next.layerId] };
      layerProps.x += next.target.offsetLeft;
      layerProps.y += next.target.offsetTop;
      typesToFrameKeys[next.type].forEach(valueKey => {
        addFabricProperty(updatedProps, valueKey, layerProps[valueKey]);
      });
    } else {
      // Calculate the value depending on the selected easing
      const timeBetweenFrames = next.time - previous.time;
      const timeSincePrevious = currentTime - previous.time;
      typesToFrameKeys[previous.type].forEach(valueKey => {
        const diffVal = next.properties[valueKey] - previous.properties[valueKey];
        const easingFunction = previous.easing === easingList.easeLinear ? easeLinear : this.ease[previous.easing];
        const easing = +easingFunction(
          timeSincePrevious,
          previous.properties[valueKey],
          diffVal,
          timeBetweenFrames,
        ).toFixed(4);
        addFabricProperty(updatedProps, valueKey, easing);
      });
    }

    return updatedProps;
  };

  seek = currentTime => {
    if (!this.keyframes.length || !Object.keys(this.layersProps).length) {
      return;
    }

    // save objects in active selection to use later
    const objectsInActiveSelection = this.objects.filter(o => o.group);
    if (objectsInActiveSelection.length) {
      // discard active selection to have the correct positions
      objectsInActiveSelection[0].canvas.discardActiveObject();
    }

    // this step we can do on add keyframe so to not have it here!!!!!!! or it's ok?
    const keyframeIdsInUse = this.getUsedFrameTypesByLayer(this.keyframes);
    const layerIds = Object.keys(keyframeIdsInUse);
    layerIds.forEach(layerId => {
      const object = this.objects.find(o => o.id === Number(layerId));

      let appliedPosition = null;
      let angle = object.angle;

      keyframeIdsInUse[layerId].forEach(frameType => {
        const filteredKeyframes = this.keyframes.filter(k => k.type === frameType && k.layerId == layerId);
        const keyframes = getNearKeyframes(currentTime, filteredKeyframes);

        const { previous, current, next } = keyframes;
        const initialKeyframe = previous || current || next;
        const { properties, target } = initialKeyframe;
        const props = this.calculateNewProps(currentTime, keyframes);
        target.set(props);

        if (frameType === animationTypes.position) {
          appliedPosition = props;
          angle = target.angle;
        }

        if (ANIMATIONS_WITH_POSITION.includes(frameType)) {
          const left = appliedPosition ? appliedPosition.left : properties.x;
          const top = appliedPosition ? appliedPosition.top : properties.y;
          target.set({ left, top });

          if (frameType === animationTypes.rotation) {
            angle = props.angle;
          }

          // anchor point is given by originX, originY and centeredRotation properties
          // it is centered by default
          target.set({ angle: 0 });
          target.rotate(angle);
        }

        target.setCoords();
      });
    });

    // if there was an active selection create it and select it again
    if (objectsInActiveSelection?.length) {
      const selection = createActiveSelection(objectsInActiveSelection);
      selection.canvas.setActiveObject(selection);
    }

    this.renderAll();
  };

  tick = now => {
    // in order to have now as 0 for animation calculations
    if (!this.animConfig.initialTick) {
      this.animConfig.initialTick = now;
    }
    const currentAnimTime = now + this.animConfig.startTickTime;
    /**
     * Now start not from 0ms but from performance.now()
     * value that is why we need to save initial value and this of it as 0.
     * If the processor is under a heavy workload nowToZero could be greater
     * than animationDuration, for that reason we need to cap nowToZero.
     */
    const nowToZero = Math.min(currentAnimTime - this.animConfig.initialTick, this.animationDuration);

    if (!this.animConfig.prevTickTime || currentAnimTime - this.animConfig.prevTickTime >= 10) {
      this.animConfig.prevTickTime = currentAnimTime;
      this.onTick?.(nowToZero);
      this.seek(nowToZero);
    }
    // end of animation
    if (nowToZero >= this.animationDuration) {
      cancelAnimationFrame(this.animConfig.requestRef);
      this.animConfig = {
        ...this.animConfig,
        status: statuses.finished,
        prevTickTime: null,
        startTickTime: 0,
      };
      this.onAnimationEnd?.(nowToZero);
      return;
    }
    this.animConfig.requestRef = requestAnimationFrame(this.tick);
  };

  play = () => {
    if (this.animConfig.status === statuses.finished) {
      this.animConfig.initialTick = 0;
      // updateTimeline
      // setLeft(0);
    }
    this.animConfig.status = statuses.play;
    this.onAnimationBegin?.();
    this.animConfig.requestRef = requestAnimationFrame(this.tick);
  };

  pause = roundMilliseconds => {
    if (this.animConfig.status !== statuses.finished) {
      cancelAnimationFrame(this.animConfig.requestRef);
      let startTickTime = this.animConfig.prevTickTime - this.animConfig.initialTick;
      if (roundMilliseconds) {
        // it rounds the next integer multiple of roundMilliseconds so the animation does not jump back
        startTickTime = Math.ceil(startTickTime / roundMilliseconds) * roundMilliseconds;
        this.onTick?.(startTickTime);
      }
      this.animConfig = {
        ...this.animConfig,
        status: statuses.pause,
        startTickTime,
        initialTick: 0,
        prevTickTime: null,
      };
      this.onAnimationPaused?.();
    }
  };

  stop = () => {
    cancelAnimationFrame(this.animConfig.requestRef);
    this.animConfig = {
      ...this.animConfig,
      status: statuses.finished,
      startTickTime: 0,
      initialTick: 0,
      prevTickTime: null,
    };
    // can'return 0 from event becaue of conditions inside fabric
    //http://fabricjs.com/docs/fabric.js.html#line323
    // this.onTick?.(0.00000001);
    this.moveTo(0.00000001);
    this.onAnimationEnd?.();
  };

  moveTo = time => {
    if (this.animConfig.status === statuses.play) {
      cancelAnimationFrame(this.animConfig.requestRef);
      this.animConfig.requestRef = requestAnimationFrame(this.tick);
      this.animConfig = {
        ...this.animConfig,
        prevTickTime: null,
        initialTick: 0,
      };
    }
    this.animConfig = {
      ...this.animConfig,
      // if time is greater or equal to animationDuartion we set startTickTime to 0
      // to start from the begining on play
      startTickTime: time >= this.animationDuration ? 0 : time,
    };
    if (time === 0) {
      this.onTick?.(0.0000001);
    } else {
      this.onTick?.(time);
    }
    this.seek(time);
  };

  // private methods

  _applyClipPathToMaskedObjects = () => {
    const maskedObjects = this.objects.filter(({ maskId }) => !!maskId);
    maskedObjects.forEach(object => {
      const mask = this.objects.find(({ id }) => id === object.maskId);
      if (mask) {
        object.clipPath = new fabric.Rect({
          absolutePositioned: true,
          angle: mask.angle,
          fill: '',
          height: mask.height,
          left: mask.left,
          originX: mask.originX,
          originY: mask.originY,
          scaleX: mask.scaleX,
          scaleY: mask.scaleY,
          top: mask.top,
          width: mask.width,
          strokeWidth: 0,
        });
      }
    });
  };
}

export default AnimationEngine;
