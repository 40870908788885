/* eslint-disable */
import { fabric } from 'fabric';

import { DEFAULT_BACKGROUND_COLOR, PER_PRIMARY } from './constants/canvas.js';
import { PRISMA_CLOSE_BUTTON, PRISMA_IMAGE, PRISMA_LANDSCAPE, PRODUCT_SUBTYPES } from './constants/index.js';

import { checkClickPlaceholder, fabricClone } from './utils/helpers.js';
import { recalculateAnimationsValues } from './utils/layers.js';
import { eventsToKeys, perseusToPrismaMapping } from './utils/types.js';
import { getRelativeScale, getRelativeLeft, getRelativeTop, getBestAspectRatio } from './utils/responsive.js';

import AnimationEngine from './animationEngineV2.js';
import PrismaCloseButton from './prismaCloseButton.js';
import PrismaLandscape from './prismaLandscape.js';

const DASH_LENGTH = 7;
const LINE_WIDTH = 2;

const PrismaStaticCanvas = fabric.util.createClass(fabric.Canvas, {
  type: 'prisma-static-canvas',

  // current layers
  layers: [],
  // current keyframes
  keyframes: [],

  // all pages
  pages: [],
  engine: null,
  clickPlaceholder: 'perseus_placeholder_click_id_canvas',

  clickable: true,
  clickCb: undefined,
  isLandscape: false,

  containerElement: undefined,

  initialize: function (elementId, options, config = {}) {
    const {
      openAsPopup,
      clickable = true,
      clickCb,
      isLandscape = false,
      onClose,
      keepOnClose = false,
      responsive = {},
    } = config;
    const { adaptToWindow, builtHeight, builtWidth } = responsive;
    if (adaptToWindow) {
      const { innerWidth, innerHeight } = window;
      options.width = innerWidth;
      options.height = innerHeight;
    }
    this.callSuper('initialize', elementId, {
      selection: false,
      preserveObjectStacking: true,
      ...options,
    });

    this.clickable = clickable;
    this.clickCb = clickCb;
    this.onClose = onClose;
    this.keepOnClose = keepOnClose;
    this.isLandscape = isLandscape;

    this.containerElement = document.getElementById(elementId)?.parentElement;

    if (builtHeight) {
      this.builtHeight = builtHeight;
    }
    if (builtWidth) {
      this.builtWidth = builtWidth;
    }
    if (openAsPopup && this.containerElement) {
      Object.assign(this.containerElement.style, {
        display: 'block',
        maxWidth: '100%',
        height: 'auto',
        margin: '0 auto',
        position: 'fixed',
        top: '0',
        left: '0',
        right: '0',
        bottom: '0',
        zIndex: '9999',
      });
    }
  },

  getInitialPage: function (json) {
    // TODO: refactor when implementing pages, events, and actions.
    // for now we only have one page
    return json.pages?.[0] || {};
  },

  /**
   * It returns the layers of the page, if the project is an interstitial banner and has unlocked aspect ratios,
   * it will return the layers of the aspect ratio that best fits the device.
   *
   * @param {object} json project size json
   * @param {object} page page object
   * @returns {array} layers
   */
  setBuiltSizeAndGetLayers: function (json, page) {
    if (json.productSubtype === PRODUCT_SUBTYPES.INTERSTITIAL_BANNER && page.aspectRatios.length > 0) {
      // it is interstitial and it has unlocked aspect ratios
      const aspect = getBestAspectRatio(this.width, this.height, page.aspectRatios);
      if (aspect.width !== this.builtWidth && aspect.height !== this.builtHeight) {
        this.builtWidth = aspect.width;
        this.builtHeight = aspect.height;
        return aspect.layers;
      }
    }
    return page.layers || [];
  },

  getInitialObjects: function (json, baseUrl) {
    if (json.clickPlaceholder) {
      this.clickPlaceholder = json.clickPlaceholder;
    }

    const initialPage = this.getInitialPage(json);
    const layers = this.setBuiltSizeAndGetLayers(json, initialPage);

    return layers.map(layer => {
      const { properties, type } = layer;
      const { height, heightUnit, leftUnit, originX, originY, scaleX, scaleY, topUnit, width, widthUnit, x, y } =
        properties;
      const animations = recalculateAnimationsValues(layer, this.builtWidth, this.builtHeight, this.width, this.height);
      const isText = type === 'text';

      return {
        ...properties,
        id: layer.id,
        name: layer.name,
        selectable: false,
        borderColor: PER_PRIMARY,
        borderDashArray: [DASH_LENGTH, DASH_LENGTH],
        borderScaleFactor: LINE_WIDTH,
        hasControls: false,
        lockMovementY: false,
        lockMovementX: false,
        hoverCursor: 'default',
        left: getRelativeLeft(x, originX, this.width, this.builtWidth, leftUnit),
        leftUnit,
        top: getRelativeTop(y, originY, this.height, this.builtHeight, topUnit),
        topUnit,
        scaleX: isText ? scaleX : getRelativeScale(scaleX, this.width, this.builtWidth, widthUnit),
        scaleY: isText ? scaleY : getRelativeScale(scaleY, this.height, this.builtHeight, heightUnit),
        width: isText ? getRelativeScale(width, this.width, this.builtWidth, widthUnit) : width,
        height: isText ? getRelativeScale(height, this.height, this.builtHeight, heightUnit) : height,
        widthUnit,
        heightUnit,
        animations,
        type: perseusToPrismaMapping[layer.type] || layer.type,
        baseUrl: layer.type === PRISMA_IMAGE || layer.type === 'image' ? baseUrl : undefined,
      };
    });
  },

  loadFromPrismaJSON: function (serialized, baseUrl, callback) {
    if (!serialized) {
      return;
    }

    const json = typeof serialized === 'string' ? JSON.parse(serialized) : fabricClone(serialized);

    const data = {
      objects: this.getInitialObjects(json, baseUrl),
    };

    this.loadFromJSON(data, () => {
      this.backgroundColor = DEFAULT_BACKGROUND_COLOR;

      const objects = this.getObjects();
      const enableCanvasClick = this.clickPlaceholder === 'canvas';

      if (enableCanvasClick) {
        this.defaultCursor = 'pointer';
        this.on('mouse:up', event => {
          const objectsToIgnore = [PRISMA_CLOSE_BUTTON, PRISMA_LANDSCAPE];
          // if the clicked object is in the ignore list, or a layer with a placeholder, we should not trigger the canvas click
          if (objectsToIgnore.includes(event.target?.type) || checkClickPlaceholder(event.target)) {
            return;
          }
          this.clickCb && this.clickCb(this.clickPlaceholder);
        });
      }

      const layers = objects.map(o => {
        if (checkClickPlaceholder(o)) {
          o.hoverCursor = 'pointer';
          o.on('mouseup', () => this.clickCb && this.clickCb(o.clickPlaceholder));
        } else if (enableCanvasClick) {
          o.hoverCursor = 'pointer';
        }

        return {
          id: o.id,
          title: '',
          target: o,
          type: o.type,
          animations: o.animations,
          properties: o.toObject(),
        };
      });

      this.engine = new AnimationEngine({
        onTick: tick => this.fire(eventsToKeys.onTick, this.layers),
        onAnimationBegin: () => this.fire(eventsToKeys.onAnimationBegin),
        onAnimationEnd: () => this.fire(eventsToKeys.onAnimationEnd),
        onAnimationPaused: () => this.fire(eventsToKeys.onAnimationPause),
        renderAll: this.renderAll.bind(this),
        ease: fabric.util.ease,
      });
      this.setLayers(layers);

      if (this.isLandscape) {
        this.add(new PrismaLandscape({ height: this.height, width: this.width }));
      }

      // add close button for interstitials
      if (json.productSubtype === PRODUCT_SUBTYPES.INTERSTITIAL_BANNER) {
        PrismaCloseButton.load({ width: this.width }, closeButton => {
          closeButton.on('mouseup', () => {
            if (!this.keepOnClose) {
              if (this.containerElement) {
                const children = Array.from(this.containerElement.children);
                this.dispose();
                children?.forEach(child => child?.remove());
              } else {
                this.dispose();
              }
            }
            if (this.onClose) {
              this.onClose();
            }
          });
          this.add(closeButton);
          this.renderAll();
          callback && callback();
        });
      } else {
        this.renderAll();
        callback && callback();
      }
    });
  },

  hasAnimation() {
    return !!this.keyframes.length;
  },

  setLayers: function (layers) {
    this.layers = layers;
    this.keyframes = this.engine.calculateKeyframesForAnimation(layers, true);
    // For static canvas the layers will be set only once, so we can set the layer props for the engine here
    this.engine.setLayersProps(layers);

    setTimeout(() => {
      this.fire(eventsToKeys.onLayersChange, this.layers);
    });
  },

  /**
   * Downloads the current canvas as an image.
   *
   * @param {string} name - The name of the downloaded image file.
   * @param {string} [format='jpeg'] - The format of the downloaded image file. Defaults to 'jpeg'.
   */
  download(name, format = 'jpeg') {
    const image = this.toDataURL({ format });

    let xhr = new XMLHttpRequest();
    xhr.responseType = 'blob';
    xhr.onload = function () {
      let a = document.createElement('a');
      a.href = window.URL.createObjectURL(xhr.response);
      a.download = `${name}.${format}`;
      a.style.display = 'none';
      document.body.appendChild(a);
      a.click();
      a.remove();
    };
    xhr.open('GET', image);
    xhr.send();
  },

  /**
   * Takes a snapshot of the canvas and returns it as a data URL.
   * @param {string} [format='jpeg'] - The format of the image. Defaults to 'jpeg' if not provided.
   */
  snapshot(format = 'jpeg') {
    return this.toDataURL({ format });
  },
});

fabric.PrismaStaticCanvas = PrismaStaticCanvas;

export default PrismaStaticCanvas;
