export const CONFIRM_TITLES = {
  PROJECT_ARCHIVED: 'Project Archive',
  PROJECT_ASSET_DELETE: 'Asset Delete',
  PROJECT_ASSET_MOVE: 'Asset Move',
  PROJECT_ASSET_REPLACE: 'Asset Replace',
  PROJECT_ASSET_UPLOAD: 'Asset Upload',
  PROJECT_DUPLICATED: `Duplicate Project`,
  PROJECT_FOLDER_DELETE: 'Folder Delete',
  PROJECT_SIZE_ARCHIVED: 'Composition Archive',
  PROJECT_SIZE_CHANGE: 'Change composition',
  PROJECT_SIZE_REDIRECT: 'Redirect from Editor page',
};

const CONTINUE_MESSAGE = '\nAre you sure you want to continue?';

export const CONFIRM_BODIES = {
  PROJECT_ARCHIVED: `Project '{project}' will be archived.`,
  PROJECT_ARCHIVED_WITH_PERSONALIZE: `Project '{project}' and the linked creative in Personalize will be archived.`,
  PROJECT_ASSET_DELETE: `Asset '{name}' will be deleted.${CONTINUE_MESSAGE}`,
  PROJECT_ASSET_MOVE: `The '{name}' resource will be overwritten.${CONTINUE_MESSAGE}`,
  PROJECT_ASSET_REPLACE: `Asset '{name}' will be replaced.${CONTINUE_MESSAGE}`,
  PROJECT_ASSET_UPLOAD: `The '{name}' resource will be overwritten.${CONTINUE_MESSAGE}`,
  PROJECT_DUPLICATED: `You are about to duplicate project '{project}'.${CONTINUE_MESSAGE}`,
  PROJECT_FOLDER_DELETE: `Folder '{name}' will be deleted.${CONTINUE_MESSAGE}`,
  PROJECT_SIZE_ARCHIVED: `Composition '{name}' will be archived.${CONTINUE_MESSAGE}`,
  PROJECT_SIZE_REDIRECT_SAVE: `Composition '{name}' has unsaved data.${CONTINUE_MESSAGE}`,
};

const OPERATION_CANNOT_BE_UNDONE = 'This operation cannot be undone.';

export const CONFIRM_NOTES = {
  PROJECT_ARCHIVED: [OPERATION_CANNOT_BE_UNDONE],
  PROJECT_ASSET_DELETE: ['The asset will be removed from all compositions.', OPERATION_CANNOT_BE_UNDONE],
  PROJECT_ASSET_MOVE: ['The composition will be automatically saved.'],
  PROJECT_ASSET_REPLACE: ['The asset will be replaced in all compositions.', OPERATION_CANNOT_BE_UNDONE],
  PROJECT_ASSET_UPLOAD: ['The composition will be automatically saved.'],
};

export const NOTIFY_MESSAGES = {
  DUPLICATE_COMPOSITION_ERROR: 'Error duplicating composition',
  DUPLICATE_COMPOSITION_SUCCESS: 'Composition duplicated successfully',
  ERROR: 'Server Error',
  PROJECT_ARCHIVED: 'Project archived',
  PROJECT_CREATED: 'Project created',
  PROJECT_DUPLICATED: 'Project duplicated',
  PROJECT_EXISTS: 'Project with the same name already exists',
  PROJECT_MOVE_FROM_FAVORITES: 'Project removed from favorites',
  PROJECT_MOVE_TO_FAVORITES: 'Project added to favorites',
  PROJECT_RENAMED: 'Project renamed',
  PROJECT_SAVED_ERROR: 'Error saving the project',
  PROJECT_SIZE_CREATED: 'Composition created',
};
