import React from 'react';
import { Input } from '@akiunlocks/perseus-ui-components';

const enterCharCode = 13;

const CustomInput = ({
  defaultValue = '',
  id,
  keyPressPayload = null,
  label,
  onChange,
  onDiscard = () => {},
  onKeyPress,
  placeholder,
}) => {
  return (
    <Input
      id={id}
      label={label}
      controlProps={{
        defaultValue,
        placeholder,
        autoFocus: true,
        onBlur: onDiscard,
        onChange: ({ target: { value } }) => onChange(value),
        onKeyDown: ({ keyCode }) => {
          if (keyCode === enterCharCode) {
            onKeyPress(keyPressPayload);
          } else if (keyCode === 27) {
            onDiscard(keyPressPayload);
          }
        },
      }}
    />
  );
};

export default CustomInput;
