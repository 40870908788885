import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { notify, Notification } from '@akiunlocks/perseus-ui-components';

import { useRootStore } from 'store';
import { reportError } from 'utils/log';
import { validateProjectName } from 'utils/validations';

import { getExportAlerts } from './utilities';
import ExportToPerseusModalView from './ExportToPerseusModalView';

const ExportToPerseusModal = observer(
  ({ exportInProgress, handleClose, isRender, setExportInProgress, onSuccessfulExport, showExportModal }) => {
    const { projects, projectSizes: projectSizesStore } = useRootStore();
    const { exportToPerseus, getPerseusProject, project } = projects;

    const [loadingProjectName, setLoadingProjectName] = useState(false);

    const [perseusName, setPerseusName] = useState('');
    const [perseusNameError, setPerseusNameError] = useState(null);

    const { projectSizes } = projectSizesStore;

    const perseusHashId = project?.perseusHashId;

    useEffect(() => {
      setPerseusNameError(null);
    }, [perseusName]);

    useEffect(() => {
      const loadPerseusName = async () => {
        let name;

        if (perseusHashId) {
          setLoadingProjectName(true);
          const perseusProjectData = await getPerseusProject(perseusHashId);
          name = perseusProjectData.name;
          setLoadingProjectName(false);
        } else if (project?.name) {
          name = project.name;
        }
        if (name) {
          setPerseusName(name);
        }
      };

      if (showExportModal) {
        loadPerseusName();
      } else {
        setPerseusName('');
      }
    }, [showExportModal, getPerseusProject, perseusHashId, project?.name]);

    const handleExport = async () => {
      setExportInProgress(true);

      const newName = perseusName.trim();
      const validation = validateProjectName(newName);
      if (!validation.valid) {
        setPerseusNameError(validation.error);
        setExportInProgress(false);
        return;
      }

      try {
        const {
          message,
          data: { hashId },
        } = await exportToPerseus(newName, isRender);
        onSuccessfulExport(hashId, newName);

        notify(message, Notification.TYPE.SUCCESS);
      } catch (e) {
        notify(e.message, Notification.TYPE.ERROR);
        reportError(e);
      }

      setExportInProgress(false);
    };

    if (!project) {
      return null;
    }

    return (
      <ExportToPerseusModalView
        alerts={getExportAlerts(project, projectSizes, isRender)}
        exportInProgress={exportInProgress}
        handleClose={handleClose}
        handleExport={handleExport}
        isRender={isRender}
        loading={loadingProjectName}
        perseusId={perseusHashId}
        perseusName={perseusName}
        perseusNameError={perseusNameError}
        setPerseusName={setPerseusName}
        show={showExportModal}
      />
    );
  },
);

export default ExportToPerseusModal;
