import { fabric } from 'fabric';

import { easingList } from './easings.js';
import { animationTypes } from './types.js';

export function generateUniqueId() {
  const timestamp = Date.now().toString(36); // Convert current timestamp to base 36 string
  const random = Math.random().toString(36).substring(2, 5); // Generate a random base 36 string

  return `${timestamp}${random}`;
}

export function getEmptyAnimation(type) {
  return {
    isGroupAnimation: false,
    type,
    scene: 1,
    repeat: 1,
    repeatInfinitely: false,
    easing: easingList.easeLinear,
    keyframes: [],
  };
}

/**
 * Calculates the original unrotated position of an object using the rotation center as the pivot point.
 * @param {object} object - Canvas object.
 * @returns {object} Object with unrotated position values.
 */
export function getUnrotatedPosition(object) {
  const { angle, left, top } = object;

  const rotationCenter = object.getCenterPoint(); // TODO: allow different anchor points
  const centerX = rotationCenter.x;
  const centerY = rotationCenter.y;

  const radians = (angle * Math.PI) / 180;
  const unrotatedLeft = centerX + (left - centerX) * Math.cos(-radians) - (top - centerY) * Math.sin(-radians);
  const unrotatedTop = centerY + (left - centerX) * Math.sin(-radians) + (top - centerY) * Math.cos(-radians);

  return {
    x: parseFloat(unrotatedLeft.toFixed(2)),
    y: parseFloat(unrotatedTop.toFixed(2)),
  };
}

/**
 * Gets the origin coordinates of an object, based on the object's dimensions.
 * @param {object} object - Object in the canvas.
 * @param {string} originX - Horizontal origin: 'left', 'center' or 'right'.
 * @param {string} originY - Vertical origin: 'top', 'center' or 'bottom'.
 * @returns {object} Object with unrotated position values.
 */
export function getOriginPosition(object, originX, originY) {
  return object.translateToOriginPoint(object.getCenterPoint(), originX, originY);
}

/**
 * Resets object properties corresponding to a given animation type.
 * @param {object} object - Canvas object.
 * @param {object} properties - Original object properties.
 * @param {string} animationType - Animation type to reset.
 */
export function resetObjectPropertiesByType(object, properties, animationType) {
  switch (animationType) {
    case animationTypes.position:
      // TODO: ENG-4486 check correct position in groups
      object.set({
        left: properties.x + object.offsetLeft,
        top: properties.y + object.offsetTop,
      });
      break;
    case animationTypes.opacity:
      object.set({
        objectOpacity: properties.objectOpacity,
      });
      break;
    case animationTypes.scale:
      object.set({
        scaleX: properties.scaleX,
        scaleY: properties.scaleY,
      });
      break;
    case animationTypes.rotation: {
      const unrotatedPosition = getUnrotatedPosition(object);
      object.setOptions({ left: unrotatedPosition.x, top: unrotatedPosition.y, angle: 0 });
      object.rotate(properties.rotation);
      break;
    }
    case animationTypes.hide:
      object.set({
        hide: properties.hide,
      });
      break;
    default:
      break;
  }
}

/**
 * Gets object position relative to an active selection.
 * @param {object} object - Canvas object.
 * @param {object} activeSelection - Active selection containing the object.
 * @return {object} Object with left and top.
 */
export function getPositionFromActiveSelection(object, activeSelection) {
  const selection = activeSelection || object.group;
  if (!selection) {
    return { left: 0, top: 0 };
  }
  return {
    left: selection.left + (selection.width / 2 + object.left),
    top: selection.top + (selection.height / 2 + object.top),
  };
}

/**
 * Gets positions of a list of objects relative to an active selection.
 * @param {Array} objects - Canvas objects.
 * @param {object} activeSelection - Active selection containing the object.
 * @return {object} Objects with left and top.
 */
export function getPositionsFromActiveSelection(objects, activeSelection) {
  return objects.reduce((acc, obj) => {
    acc[obj.id] = getPositionFromActiveSelection(obj, activeSelection);
    return acc;
  }, {});
}

/**
 * Creates an active selection from a list of objects.
 * There must be at least one object in the list.
 * @param {array} objects - Canvas objects.
 * @return {object} New fabric ActiveSelection
 */
export function createActiveSelection(objects) {
  const { canvas } = objects[0];
  return new fabric.ActiveSelection(objects, { canvas });
}

/**
 * Clones an object using fabric clone method.
 * @param {object} object - Fabric object.
 * @return {object} Cloned object.
 */
export function fabricClone(object) {
  return fabric.util.object.clone(object);
}

/**
 * Checks if an object has a custom click placeholder.
 * @param {object} object - Prisma object.
 * @return {boolean} True if the object has a custom click placeholder, false otherwise.
 */
export function checkClickPlaceholder(object) {
  return !!object?.clickPlaceholder && object.clickPlaceholder !== `perseus_placeholder_click_id_${object?.id}`;
}
