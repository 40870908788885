import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';
import { Card } from 'react-bootstrap';

import { PRISMA_GROUP, PRISMA_MASK } from '@prisma/lib/src/constants';
import { Accordion } from '@akiunlocks/perseus-ui-components';

import { useRootStore } from '../../../../store';
import { addAnimationIdentifier, addGroupIdentifier, mapGroupObjectsToLayers, shouldShowAnimations } from './utilities';

import { ICON_SIZE } from 'constants';

import ToggleIconButton from '../../../ToggleIconButton';
import EditInput from '../EditInput';
import LayerAccordion from '.';
import ToggleAnimation from './ToggleAnimation';
import ToggleGroup from './ToggleGroup';
import AnimationsList from './AnimationsList';

import './layerAccordion.scss';

const AccordionItem = observer(
  ({
    activeKeyframe,
    activeLayer,
    currentTimeLineValue,
    editLayer,
    hasMultipleSelected,
    isAnimatable,
    isDraggable,
    isGroupChild,
    layer,
    selectLayer,
    selectedLayersId,
  }) => {
    const { editor, layerAccordionStore } = useRootStore();
    const { handlers } = layerAccordionStore;

    const dragItemId = layerAccordionStore.getDragItemId();
    const dragOverId = layerAccordionStore.getDragOverId();

    const [animations, setAnimations] = useState(layer.animations);
    const layers = editor.getReversedLayers();

    useEffect(() => {
      // this is just to open the animation when it is added
      if (layer.animations?.length !== animations?.length && !layerAccordionStore.isAnimationOpen(layer.id)) {
        const { groupId } = layer.properties;
        if (groupId && !layerAccordionStore.isGroupOpen(groupId)) {
          // if the animation is added to a child and the group is hidden, show the group
          layerAccordionStore.addOpened(addGroupIdentifier(groupId));
        }
        layerAccordionStore.addOpened(addAnimationIdentifier(layer.id));
      }
      setAnimations(layer.animations);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [layer.animations]);

    const isDragItemBeforeOver = layerAccordionStore.isBefore(dragItemId, dragOverId, layers);
    const isDraggingOver =
      dragItemId !== dragOverId &&
      layer.id === dragOverId &&
      layerAccordionStore.isDropAllowed(dragItemId, dragOverId, editor.canvas.layers); // use all layers;

    const isGroup = layer.type === PRISMA_GROUP;
    const isMask = layer.type === PRISMA_MASK;
    const showAnimations = isAnimatable && shouldShowAnimations(layer.animations);

    const cssClasses = classnames({
      selected: selectedLayersId.includes(layer.id),
      dragged: layer.id === dragItemId,
      'over-bottom': isDraggingOver && (isDragItemBeforeOver || isGroupChild) && !isGroup,
      'over-top': isDraggingOver && !isDragItemBeforeOver && !isGroupChild,
    });

    if (!isGroupChild && layer.target.groupId) {
      return null;
    }

    return (
      <div>
        <Card
          onDragStart={e => {
            // stop propagation to avoid conflicts with assets tree
            e.stopPropagation();
            layerAccordionStore.setDragItemId(layer.id);
          }}
          onDragEnter={() => {
            layerAccordionStore.setDragOverId(layer.id);
          }}
          draggable={isDraggable && !isMask}
          key={layer.id + 'layeraccordion'}
          className={cssClasses}
          onClick={e => {
            // allowing shift too to work in the same way that canvas does
            selectLayer(layer, e.shiftKey || e.ctrlKey || e.metaKey);
          }}
        >
          <Card.Header
            className={classnames({
              'ps-5': isGroupChild,
              'ps-10': isGroupChild && !isMask,
            })}
          >
            <div className="left-block">
              <div className="layer-controls">
                {isGroup && <ToggleGroup layerId={layer.id} />}
                {!isGroup && <div className="white-space" />}
              </div>
              <div className={classnames('title-wrapper', { mask: isMask })}>
                <EditInput
                  disabled={isMask}
                  layerType={layer.type}
                  placeholder="Type new name"
                  onChange={v => {
                    editLayer(layer, v);
                  }}
                  value={layer.name}
                />
              </div>
              <div className="layer-controls">
                <ToggleIconButton
                  key="unlock"
                  icons={['unlock', 'lock']}
                  className="layer-control"
                  onClick={() => {
                    handlers.lock(layer.target);
                  }}
                  value={!layer.target.selectable}
                  iconSize={ICON_SIZE}
                  tooltipMessage={`${layer.target.selectable ? 'Lock' : 'Unlock'} canvas edition`}
                />
                {!isMask && (
                  <ToggleIconButton
                    icons={['eye', 'eye-slash']}
                    key="visible"
                    className="layer-control"
                    onClick={() => {
                      handlers.hide(layer.target);
                    }}
                    iconSize={ICON_SIZE}
                    value={!layer.target.visible}
                  />
                )}
              </div>
            </div>
            {!editor.contextStore.getIsLayersTabSelected() && !isMask && (
              <ToggleAnimation
                disabled={!showAnimations}
                isOpen={layerAccordionStore.isAnimationOpen(layer.id)}
                layerId={layer.id}
              />
            )}
          </Card.Header>
          <Accordion.Collapse eventKey={addAnimationIdentifier(layer.id)}>
            <Card.Body className="layer-body">
              {showAnimations && (
                <AnimationsList
                  activeKeyframe={activeKeyframe}
                  activeLayer={activeLayer}
                  currentTimeLineValue={currentTimeLineValue}
                  hasMultipleSelected={hasMultipleSelected}
                  layer={layer}
                />
              )}
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        {layer.type === PRISMA_GROUP && (
          <Accordion.Collapse eventKey={addGroupIdentifier(layer.id)}>
            <LayerAccordion
              activeKeyframe={activeKeyframe}
              activeLayer={activeLayer}
              currentTimeLineValue={currentTimeLineValue}
              editLayer={editLayer}
              isAnimatable={isAnimatable}
              isDraggable={isDraggable}
              isGroupChild
              layers={mapGroupObjectsToLayers(layer, layers, dragOverId)}
              selectLayer={selectLayer}
              selectedLayersId={selectedLayersId}
            />
          </Accordion.Collapse>
        )}
      </div>
    );
  },
);

export default AccordionItem;
