import { PRISMA_GROUP, PRISMA_IMAGE, PRISMA_MASK } from '../constants/index.js';
import PrismaKeyframe from '../prismaKeyframe.js';
import { getRelativeLeft, getRelativeScale, getRelativeTop } from './responsive.js';
import { ANIMATIONS_WITH_POSITION, animationTypes } from './types.js';

/**
 * Checks if name contains '@Nx'.
 * @param {string} name - String to check.
 * @return {RegExpMatchArray} Array if '@Nx' was found in the string, else false.
 */
export function matchRetinaScaling(name) {
  return name.match(/@(\d+)x/);
}

/**
 * Validates for a duplicated name in a list, and renames if needed.
 * This function will be called recursively until it finds a non duplicated name.
 * @param {string} name - Name to check.
 * @param {array} existingNames - Existing names.
 * @param {number} counter - Counter to add as incremental suffix.
 * @return {string} Generated name.
 */
export function validateDuplicatesAndRenameIfNeeded(name, existingNames, counter = 0) {
  const newName = counter === 0 ? name : `${name} (${counter})`;
  if (!existingNames.includes(newName)) {
    return newName;
  }
  return validateDuplicatesAndRenameIfNeeded(name, existingNames, counter + 1);
}

/**
 * Generates layer name based on object properties and existing layers.
 * @param {object} object - Canvas object.
 * @param {array} layers - Existing layers.
 * @return {string} Generated layer name.
 */
export function generateLayerName(object, layers) {
  if (object.type === PRISMA_MASK) {
    return object.label;
  }
  const suffix = layers.reduce((acc, layer) => (layer.type === object.type ? acc + 1 : acc), 1);
  let name = `${object.label || object.type} ${suffix}`;
  // get config with all types of objects
  if (object.type === PRISMA_IMAGE) {
    name = `${object.imageUrl.match(/([^\\/]+)(?=\.\w+$)/)?.[0]} ${suffix}`;
    const retinaScalingRegex = matchRetinaScaling(name);
    if (retinaScalingRegex) {
      name = name.replace(retinaScalingRegex[0], ''); // removes '@Nx' from the name
    }
  }
  return validateDuplicatesAndRenameIfNeeded(
    name,
    layers.map(l => l.name),
  );
}

export function findLayerById(layers, layerId) {
  return layers.find(({ id }) => id === layerId);
}

/**
 * Given a layer, updates the group and mask ids in the layer properties and in the object.
 * @param {object} layer - Layer to be updated.
 * @param {number|null} groupId - New group id.
 * @param {number|null} maskId - New mask id.
 */
function updateGroupAndMaskInLayer(layer, groupId, maskId) {
  const { properties, target } = layer;
  target.groupId = groupId;
  target.maskId = maskId;
  if (!maskId) {
    target.clipPath = null;
  }
  properties.groupId = groupId;
  properties.maskId = maskId;
}

/**
 * Adds object to group and updates properties.
 * @param {object} object - Object to add.
 * @param {object} groupLayer - Layer where the object is added.
 * @param {number} beforeId - Insert the new object before the object with this id.
 */
function addObjectToGroup(object, groupLayer, beforeId = 0) {
  const { properties, target } = groupLayer;
  target.addObject(object, beforeId);
  properties.objectIds = target.objectIds;
}

/**
 * Removes object from group and updates properties.
 * @param {object} object - Object to remove.
 * @param {object} groupLayer - Layer from where the object is removed.
 */
function removeObjectFromGroup(object, groupLayer) {
  const { properties, target } = groupLayer;
  target.removeObject(object);
  properties.objectIds = target.objectIds;
}

/**
 * Moves selectedObj into overObj group.
 * @param {object} selectedLayer - Selected layer.
 * @param {object} overLayer - Layer over the selected one is dropped.
 * @param {array} layers - Canvas layers.
 * @return {array} Updated groups.
 */
function moveObjectToGroup(selectedLayer, overLayer, layers) {
  const selectedObj = selectedLayer.target;
  const overObj = overLayer.target;

  const overGroupLayer = findLayerById(layers, overObj.groupId);
  if (!overGroupLayer) {
    return [];
  }

  // add groupId and maskId to object and update properties
  const newGroupId = overObj.groupId;
  const newMaskId = overObj.type === PRISMA_MASK ? overObj.id : overObj.maskId;
  updateGroupAndMaskInLayer(selectedLayer, newGroupId, newMaskId);

  addObjectToGroup(selectedObj, overGroupLayer, overObj.id);

  return [overGroupLayer.target];
}

/**
 * Moves selectedObj outside its group .
 * @param {object} selectedLayer - Selected layer.
 * @param {array} layers - Canvas layers.
 * @return {array} Updated groups.
 */
function moveObjectOutsideGroup(selectedLayer, layers) {
  const selectedObj = selectedLayer.target;

  const selectedGroupLayer = findLayerById(layers, selectedObj.groupId);
  if (!selectedGroupLayer) {
    return [];
  }

  updateGroupAndMaskInLayer(selectedLayer, null, null);

  removeObjectFromGroup(selectedObj, selectedGroupLayer);

  return [selectedGroupLayer.target];
}

/**
 * Moves objects inside the same group.
 * @param {object} selectedLayer - Selected layer.
 * @param {object} overLayer - Layer over the selected one is dropped.
 * @param {array} layers - Canvas layers.
 * @return {array} Updated groups.
 */
function moveObjectsInTheSameGroup(selectedLayer, overLayer, layers) {
  const selectedObj = selectedLayer.target;
  const groupLayer = findLayerById(layers, selectedObj.groupId);
  const group = groupLayer?.target;
  if (!group) {
    return [];
  }

  const newIds = group.objectIds.filter(id => id !== selectedObj.id);
  const index = newIds.indexOf(overLayer.id);
  if (index !== -1) {
    newIds.splice(index, 0, selectedObj.id);
  }
  const newObjects = [];
  newIds.forEach(id => {
    newObjects.push(group.objects.find(o => o.id === id));
  });
  group.setObjects(newObjects);

  groupLayer.properties.objectIds = newIds;

  return [group];
}

/**
 * Moves objects between different groups.
 * @param {object} selectedLayer - Selected layer.
 * @param {object} overLayer - Layer over the selected one is dropped.
 * @param {array} layers - Canvas layers.
 * @return {array} Updated groups.
 */
function moveObjectsBetweenDifferentGroups(selectedLayer, overLayer, layers) {
  const selectedObj = selectedLayer.target;
  const overObj = overLayer.target;

  const selectedGroupLayer = findLayerById(layers, selectedObj.groupId);
  const overGroupLayer = findLayerById(layers, overObj.groupId);
  if (!selectedGroupLayer || !overGroupLayer) {
    return [];
  }

  // update groupId and maskId to object and update properties
  const newGroupId = overObj.groupId;
  const newMaskId = overObj.type === PRISMA_MASK ? overObj.id : overObj.maskId;
  updateGroupAndMaskInLayer(selectedLayer, newGroupId, newMaskId);

  addObjectToGroup(selectedObj, overGroupLayer, overObj.id);

  removeObjectFromGroup(selectedObj, selectedGroupLayer);

  return [selectedGroupLayer.target, overGroupLayer.target];
}

/**
 * Handles change of layers order if groups are involved.
 * @param {object} selectedLayer - Selected layer.
 * @param {object} overLayer - Layer over the selected one is dropped.
 * @param {array} layers - Canvas layers.
 * @return {array} Updated groups.
 */
export function handleChangeGroupZOrder(selectedLayer, overLayer, layers) {
  const selectedObj = selectedLayer?.target;
  const overObj = overLayer?.target;

  if (!selectedObj || !overObj) {
    return [];
  }

  const selectedIsGroup = selectedObj.type === PRISMA_GROUP;
  const selectedIsInGroup = !!selectedObj.groupId;
  const overIsInGroup = !!overObj.groupId;
  const isObjectToGroup = !selectedIsGroup && !selectedIsInGroup && overIsInGroup;
  const objectsAreInTheSameGroup = selectedIsInGroup && overIsInGroup && selectedObj.groupId === overObj.groupId;
  const objectsAreInDifferentGroup = selectedIsInGroup && overIsInGroup && selectedObj.groupId !== overObj.groupId;

  if (isObjectToGroup) {
    return moveObjectToGroup(selectedLayer, overLayer, layers);
  }

  if (selectedIsInGroup && !overIsInGroup) {
    return moveObjectOutsideGroup(selectedLayer, layers);
  }

  if (objectsAreInTheSameGroup) {
    return moveObjectsInTheSameGroup(selectedLayer, overLayer, layers);
  }

  if (objectsAreInDifferentGroup) {
    return moveObjectsBetweenDifferentGroups(selectedLayer, overLayer, layers);
  }

  return [];
}

/**
 * Recalculates values for animations that were built for a specific banner size.
 * @param {object} layer - Layer.
 * @param {number} width - Original width of the banner where animations were built.
 * @param {number} height - Original height of the banner where animations were built.
 * @param {number} newWidth - New banner width.
 * @param {number} newHeight - New banner height.
 * @return {array} Recalculated animations.
 */
export function recalculateAnimationsValues(layer, width, height, newWidth, newHeight) {
  const { properties } = layer;
  const { leftUnit, topUnit, widthUnit, heightUnit, originX, originY } = properties;
  return layer.animations.map(a => {
    const keyframes = a.keyframes.map(keyframe => {
      const keyframeProps = { ...keyframe.properties };
      if (ANIMATIONS_WITH_POSITION.includes(a.type)) {
        keyframeProps.x = getRelativeLeft(keyframeProps.x, originX, newWidth, width, leftUnit);
        keyframeProps.y = getRelativeTop(keyframeProps.y, originY, newHeight, height, topUnit);
      }
      if (a.type === animationTypes.scale) {
        keyframeProps.scaleX = getRelativeScale(keyframeProps.scaleX, newWidth, width, widthUnit);
        keyframeProps.scaleY = getRelativeScale(keyframeProps.scaleY, newHeight, height, heightUnit);
      }
      return new PrismaKeyframe({ ...keyframe, properties: keyframeProps });
    });
    return { ...a, keyframes };
  });
}

/**
 * Recalculates values that were built for a specific banner size.
 * This function will update layer properties and animations.
 * @param {array} layers - Original layers.
 * @param {number} width - Original width of the banner where layers were built.
 * @param {number} height - Original height of the banner where layers were built.
 * @param {number} newWidth - New banner width.
 * @param {number} newHeight - New banner height.
 * @return {array} Recalculated layers.
 */
export function recalculateValuesInLayers(layers, width, height, newWidth, newHeight) {
  return layers.map(layer => {
    const { properties, type } = layer;
    const {
      height: heightProp,
      heightUnit,
      leftUnit,
      originX,
      originY,
      scaleX,
      scaleY,
      topUnit,
      width: widthProp,
      widthUnit,
      x,
      y,
    } = properties;
    const animations = recalculateAnimationsValues(layer, width, height, newWidth, newHeight);
    const isText = type === 'text';

    return {
      ...layer,
      properties: {
        ...properties,
        x: getRelativeLeft(x, originX, newWidth, width, leftUnit),
        y: getRelativeTop(y, originY, newHeight, height, topUnit),
        scaleX: isText ? scaleX : getRelativeScale(scaleX, newWidth, width, widthUnit),
        scaleY: isText ? scaleY : getRelativeScale(scaleY, newHeight, height, heightUnit),
        width: isText ? getRelativeScale(widthProp, newWidth, width, widthUnit) : widthProp,
        height: isText ? getRelativeScale(heightProp, newHeight, height, heightUnit) : heightProp,
      },
      animations,
    };
  });
}

/**
 * Recalculates values that were built for a specific banner size.
 * This function will update layer properties and animations.
 * @param {array} layers - Original layers.
 * @param {object} aspectRatio - Original aspect ratio.
 * @param {object} newAspectRatio - New aspect ratio.
 * @return {array} Recalculated layers.
 */
export function recalculateValuesInLayersWithAspectRatio(layers, aspectRatio, newAspectRatio) {
  return recalculateValuesInLayers(
    layers,
    aspectRatio.width,
    aspectRatio.height,
    newAspectRatio.width,
    newAspectRatio.height,
  );
}

/**
 * Recalculates values that were built for a specific banner size, and are set as percentage.
 * This function will update layer properties and animations.
 * @param {array} pages - Original pages.
 * @param {number} width - Original width of the banner where pages were built.
 * @param {number} height - Original height of the banner where pages were built.
 * @param {number} newWidth - New banner width.
 * @param {number} newHeight - New banner height.
 * @return {array} Recalculated pages.
 */
export function recalculateValues(pages, width, height, newWidth, newHeight) {
  return pages.map(page => {
    const { layers } = page;
    const recalculatedLayers = recalculateValuesInLayers(layers, width, height, newWidth, newHeight);
    return { ...page, layers: recalculatedLayers };
  });
}

/**
 * Given the from layers and the to layers, it propagates the layers that have been added and it deletes
 * the ones that have been removed. The ones that have been added are added at the end and are recalculated.
 * @param {array} fromLayers - Layers from where to propagate.
 * @param {array} toLayers - Layers where to propagate.
 * @param {object} aspectRatio - Original aspect ratio.
 * @param {object} newAspectRatio - New aspect ratio.
 * @return {array} Updated layers.
 */
export function propagateLayersAndRecalculate(fromLayers, toLayers, aspectRatio, newAspectRatio) {
  let addedLayers = [];
  const existingLayers = [];

  fromLayers.forEach(fromLayer => {
    const toLayer = toLayers.find(({ id }) => id === fromLayer.id);
    if (!toLayer) {
      addedLayers.push(fromLayer);
    } else {
      // const propertiesToPropagate = ['groupId', 'maskId', 'objectIds'];
      const propertiesToPreserve = [
        'height',
        'heightUnit',
        'leftUnit',
        'originX',
        'originY',
        'scaleX',
        'scaleY',
        'topUnit',
        'width',
        'widthUnit',
        'x',
        'y',
      ];
      // here is where we should decide which properties have to be kept and which ones have to be replaced.
      // This is a UX decision and we haven't asked users, but if we have to change something, this is the place.
      const existingLayer = {
        ...fromLayer,
        animations: toLayer.animations,
      };
      existingLayer.properties = {
        ...fromLayer.properties,
        ...propertiesToPreserve.reduce((acc, prop) => {
          acc[prop] = toLayer.properties[prop];
          return acc;
        }, {}),
      };

      existingLayers.push(existingLayer);
    }
  });
  addedLayers = recalculateValuesInLayersWithAspectRatio(addedLayers, aspectRatio, newAspectRatio);

  // we remove the layers that have been removed
  const filtered = existingLayers.filter(toLayer => fromLayers.some(({ id }) => id === toLayer.id));
  return [...filtered, ...addedLayers];
}
