import React from 'react';
import { observer } from 'mobx-react';

import { ItemButton } from '@akiunlocks/perseus-ui-components';

import { getParentFolderFromImageUrl } from 'containers/Editor/stores/utilities/assets';

import AssetsTree from 'components/AssetsTree';
import Confirm from 'components/Confirm';

import AssetFileInput from '../AssetFileInput';
import useAssetLibrary from './AssetLibrary.hooks';

import './AssetLibrary.scss';

const AssetLibrary = observer(({ onUpload }) => {
  const {
    addFolder,
    assetToReplace,
    assetsTreeRef,
    confirmationModal,
    editNode,
    loading,
    onReplaceAsset,
    replaceRef,
    setAssetToReplace,
    uploadRef,
  } = useAssetLibrary();

  const actions = [
    {
      disabled: loading,
      iconName: 'bi-image-add',
      iconSize: 16,
      id: 'add-asset',
      label: 'Add asset',
      onClick: () => {
        uploadRef.current?.click();
      },
    },
    {
      disabled: loading,
      iconName: 'bi-folder-plus',
      iconSize: 16,
      id: 'add-folder',
      label: 'Add folder',
      onClick: () => {
        const newFolder = addFolder(); // add folder to root
        if (newFolder) {
          editNode(newFolder.model.id, assetsTreeRef.current);
        }
      },
    },
  ];

  return (
    <>
      <AssetFileInput multiple onChange={onUpload} ref={uploadRef} />
      <AssetFileInput
        onChange={({ target }) => {
          const folder = getParentFolderFromImageUrl(assetToReplace.imageUrl);
          onReplaceAsset(assetToReplace, target.files[0], folder);
        }}
        ref={replaceRef}
      />
      <div className="asset-library-actions">
        {actions.map(({ disabled, iconName, iconSize, id, label, onClick }) => (
          <ItemButton
            className="action"
            disabled={disabled}
            icon={iconName}
            iconSize={iconSize}
            key={id}
            label={label}
            onClick={onClick}
            size="xs"
            variant="outline-secondary"
          />
        ))}
      </div>
      <AssetsTree
        width="100%"
        height={256}
        onReplace={asset => {
          setAssetToReplace(asset);
          replaceRef.current?.click();
        }}
        ref={assetsTreeRef}
      />
      <Confirm {...confirmationModal} />
    </>
  );
});

export default AssetLibrary;
