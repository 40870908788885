import React from 'react';
import { observer } from 'mobx-react';

import { useRootStore } from 'store';
import SidebarPanel from '../../../SidebarPanel';
import SidebarItem from 'components/Sidebar/components/SidebarPanel/SidebarItem';

const CanvasProperties = observer(() => {
  const { projectSizes } = useRootStore();
  const { projectSize } = projectSizes;

  return (
    <SidebarPanel title="Canvas">
      <SidebarItem>
        <div className="fs-8">
          <span className="me-1">Size:</span>
          {projectSize?.getDisplaySize()}
        </div>
      </SidebarItem>
    </SidebarPanel>
  );
});

export default CanvasProperties;
