/* eslint-disable object-shorthand */
/* eslint-disable func-names */
import { fabric } from 'fabric';

import { PRISMA_GUIDELINE } from './constants/index.js';
import { LABEL_FONT_SIZE, LABEL_OPTIONS, LABEL_STROKE_WIDTH, LINE_OPTIONS } from './constants/guidelines.js';

const PrismaGuideline = fabric.util.createClass(fabric.Line, {
  type: PRISMA_GUIDELINE,

  initialize: function (points, options = {}) {
    this.id = Math.random().toString(36);
    this.tooltip = null;
    this.locked = false;

    this.callSuper('initialize', points, {
      ...options,
      ...LINE_OPTIONS,
      hasBorders: false,
      lockRotation: true,
      lockScalingX: true,
      lockScalingY: true,
      lockScalingFlip: true,
      hasControls: false,
    });
  },

  showTooltip: function (label, options = { text: {}, container: {} }) {
    this.clearTooltip();

    const zoom = this.canvas.getZoom();
    const text = new fabric.Text(label, {
      ...options.text,
      ...LABEL_OPTIONS,
      fontSize: LABEL_FONT_SIZE / zoom,
      strokeWidth: LABEL_STROKE_WIDTH / zoom,
    });
    this.tooltip = new fabric.Group([text], {
      selectable: false,
      ...options.container,
    });
    this.canvas.add(this.tooltip);
    this.canvas.renderAll();
  },

  clearTooltip: function () {
    if (this.tooltip) {
      this.canvas.remove(this.tooltip);
      this.tooltip = null;
    }
  },

  _setCursor: function (cursor) {
    this.hoverCursor = cursor;
    this.moveCursor = cursor;
  },
});

fabric.PrismaGuideline = PrismaGuideline;

export default PrismaGuideline;
