import React from 'react';

import { Icon, Image } from '@akiunlocks/perseus-ui-components';

import { useRootStore } from 'store';
import { ASSET, FOLDER } from 'constants/assets';

const RenderDragPreview = ({ id, isDragging, mouse, offset }) => {
  const { editor, projects } = useRootStore();
  const { projectAssetsBaseUrl } = projects;

  const selectedAssetsIds = editor.selectionStore.getSelectedAssetsIds();

  if (!isDragging || !id || !selectedAssetsIds.length) {
    return null;
  }

  const firstAsset = editor.selectionStore.getSelectedComponents()[0];

  let top = 0;
  let left = 0;
  if (mouse) {
    top = mouse.y;
    left = mouse.x;
  } else if (offset) {
    top = offset.y;
    left = offset.x;
  }
  const style = { top, left: left - 60 };

  return (
    <div className="drag-preview" style={style}>
      {selectedAssetsIds.length === 1 && (
        <div>
          {firstAsset.type === ASSET && (
            <Image
              alt={`${firstAsset.name} image`}
              className="asset-thumbnail"
              draggable={false}
              src={`${projectAssetsBaseUrl}${firstAsset.imageThumbnailUrl}?id=${firstAsset._id}`}
            />
          )}
          {firstAsset.type === FOLDER && <Icon className="me-1" name="bi-folder-open" />}
          {firstAsset.name}
        </div>
      )}
      {selectedAssetsIds.length > 1 && <div>Multiple assets...</div>}
    </div>
  );
};

export default RenderDragPreview;
