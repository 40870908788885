import { useEffect, useRef } from 'react';
import { observer } from 'mobx-react';
import { Row, Col } from 'react-bootstrap';

import { ACTIVE_SELECTION, PRISMA_GROUP } from '@prisma/lib/src/constants';

import { useRootStore } from 'store';

import { ANIMATABLE_LAYERS, LAYERS_TAB } from 'constants/editor';

import { TIMELINE_PADDING } from './constants';

import LayerAccordion from './components/LayerAccordion';
import TimelineCurrentTime from './TimelineCurrentTime';
import TimeLineHeader from './components/TimeLineHeader';
import TimeLineRow from './components/TimeLineRow';
import { getTimelineStep } from './utilities';

import useTimelineScene from './TimeLineScene.hooks';

import './TimeLineScene.scss';

export const TimeLineScene = observer(({ isDraggable, left, setLeft, zoomConfig }) => {
  const timeLineHeaderRef = useRef();
  const handlerRef = useRef();
  const {
    getKeyframeEvents,
    handleNameChange,
    handleLayerDrop,
    handleKeyframeRemove,
    handleKeyframeAdd,
    handleChangeProp,
    handleEasingChange,
    handleRepeatChange,
    handleKeyframeMove,
    handleScroll,
  } = useTimelineScene({ isDraggable, zoomConfig, left, timeLineHeaderRef });

  const { editor } = useRootStore();
  const { timelineSceneStore, selectionStore } = editor;
  const { activeLayer, activeAnimation } = timelineSceneStore;
  const layersTabSelected = editor?.contextStore.getTimelineTab() === LAYERS_TAB;
  const selectedLayersId = selectionStore.getSelectedLayers().map(({ id }) => id);
  const hasActiveLayer = !!activeLayer;

  useEffect(() => {
    return () => {
      timelineSceneStore.clearActiveLayer();
      selectionStore.clearSelectedComponents();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const activeObjectType = editor?.canvas?.getActiveObject()?.type;
    const selectedObject = activeLayer?.target;
    if (layersTabSelected || !selectedObject || !activeObjectType || activeObjectType === ACTIVE_SELECTION) {
      return;
    }
    timelineSceneStore.setObjectLocksIfNeeded(selectedObject);
  }, [editor, hasActiveLayer, activeAnimation, layersTabSelected, activeLayer, timelineSceneStore]);

  useEffect(() => {
    const { activeObject } = editor;
    const isActiveSelection = activeObject?.type === ACTIVE_SELECTION;
    if (!activeObject?.id && !isActiveSelection) {
      return;
    }

    if (!isActiveSelection) {
      // single layer
      const layer = editor.layers.find(({ id }) => id === activeObject?.id);
      timelineSceneStore.setActiveLayer(layer);

      // If there is no activeObject or if it changes and a keyframe from another object is selected, we must deselect the keyframe
      timelineSceneStore.deselectKeyframeIfNeeded(activeObject);
    }

    // If the activeObject changes, override the default object events to be able to change the keyframe values from the canvas
    if (activeObject && editor.canvas) {
      const objectStore = isActiveSelection ? editor.activeSelectionStore : editor.objectStoreMap[activeObject.type];
      if (layersTabSelected) {
        objectStore?.removeEvents(activeObject, ['moving', 'rotating', 'scaling', 'modified']);
        return;
      }

      objectStore?.initialize(activeObject);
      objectStore?.addEvents(activeObject, getKeyframeEvents(objectStore, isActiveSelection));
      if (activeObject.type === PRISMA_GROUP) {
        activeObject.getObjects().forEach(object => {
          if (ANIMATABLE_LAYERS.includes(object.type)) {
            objectStore?.addEvents(object, getKeyframeEvents(objectStore, isActiveSelection));
          }
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editor.activeObject, layersTabSelected]);

  useEffect(() => {
    if (!editor?.canvas) return;
    const handleTick = v => {
      if (isNaN(v)) {
        return;
      }
      editor.timelineSceneStore.setCurrentTime(v);
      setLeft(v / zoomConfig.msInOnPixel + TIMELINE_PADDING);
    };
    editor.canvas.on('animation:tick', handleTick);
    return () => {
      editor.canvas.off('animation:tick', handleTick);
    };
  }, [editor.canvas, editor.timelineSceneStore, setLeft, zoomConfig]);

  const seek = value => {
    const time = value < TIMELINE_PADDING ? TIMELINE_PADDING : value;
    const roundedValue = getTimelineStep((time - TIMELINE_PADDING) * zoomConfig.msInOnPixel);
    const left = roundedValue / zoomConfig.msInOnPixel + TIMELINE_PADDING;
    setLeft(left);
    timelineSceneStore.setCurrentTime(roundedValue);
    editor?.canvas?.engine?.moveTo(roundedValue);
  };

  const toggleLock = object => {
    const updatedSelectable = !object.selectable;
    editor.toggleLayerLock(object.id, updatedSelectable);
  };

  const toggleOpacity = object => {
    editor.toggleLayerVisibility(object.id, !object.visible);
  };

  const onVerticalScroll = e => {
    const { scrollTop } = e.target;
    if (handlerRef?.current) {
      handlerRef.current.style.top = `${scrollTop}px`;
    }
  };

  return (
    <div className="time-line-wrapper">
      <Row className="time-line-row header">
        <Col className="time-line-col">
          <TimelineCurrentTime className="current-time" />
          <div className="layers" />
        </Col>
        <Col className="time-line-col">
          <div className="seekbar">
            <TimeLineHeader seek={seek} ref={timeLineHeaderRef} zoomConfig={zoomConfig} />
          </div>
          <div className="timelines" />
        </Col>
      </Row>
      <Row className="time-line-row scroll-row" onScroll={onVerticalScroll}>
        <Col className="time-line-col">
          <div className="layers">
            <LayerAccordion
              activeKeyframe={timelineSceneStore.getActiveKeyframe()}
              activeLayer={activeLayer}
              currentTimeLineValue={zoomConfig.msInOnPixel * (left - TIMELINE_PADDING)}
              editLayer={handleNameChange}
              handlers={{
                drop: handleLayerDrop,
                easingChange: handleEasingChange,
                hide: handleChangeProp(toggleOpacity),
                keyframeAdd: handleKeyframeAdd,
                keyframeRemove: handleKeyframeRemove,
                lock: handleChangeProp(toggleLock),
                repeatChange: handleRepeatChange,
              }}
              hasMultipleSelected={selectionStore.getSelectedComponents().length > 1}
              isAnimatable={!layersTabSelected}
              isDraggable={isDraggable}
              layers={editor.getReversedLayers()}
              selectLayer={editor.contextStore.selectLayer}
              selectedLayersId={selectedLayersId}
            />
          </div>
        </Col>
        <Col className="time-line-col">
          <div className="timelines">
            <TimeLineRow
              handleKeyframeMove={handleKeyframeMove}
              isAnimatable={!layersTabSelected}
              isDraggable={isDraggable}
              left={left}
              onHorizontalScroll={handleScroll}
              ref={handlerRef}
              seek={seek}
              selectedLayersId={selectedLayersId}
              zoomConfig={zoomConfig}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
});
