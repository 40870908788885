export const PRISMA_BANNER = 'prisma-banner';

export const BORDER_STROKE_WIDTH = 1;

export const MARGINS = {
  BOTTOM: 'bottom',
  LEFT: 'left',
  RIGHT: 'right',
  TOP: 'top',
};

export const PER_PRIMARY = '#fd9c52';

export const DEFAULT_BACKGROUND_COLOR = '#ffffff';

export const ALIGN = {
  CENTER: 'center',
  LEFT: 'left',
  MIDDLE: 'middle',
  RIGHT: 'right',
  TOP: 'top',
  BOTTOM: 'bottom',
};

export const HORIZONTAL_ALIGN = {
  LEFT: ALIGN.LEFT,
  CENTER: ALIGN.CENTER,
  RIGHT: ALIGN.RIGHT,
};

export const VERTICAL_ALIGN = {
  TOP: ALIGN.TOP,
  MIDDLE: ALIGN.MIDDLE,
  BOTTOM: ALIGN.BOTTOM,
};
