import PrismaKeyframe from '@prisma/lib/src/prismaKeyframe';
import { animationTypes, frameKeysToFabricProperties, typesToFrameKeys } from '@prisma/lib/src/utils/types';
import { getNearKeyframes } from '@prisma/lib/src/utils/animations';
import { fabricClone } from '@prisma/lib/src/utils/helpers';

import { MODIFIED_ACTIONS } from 'constants/objects';

/**
 * Based on the updated scaling keyframe, calculates the original object at that time, taking into account a possible rotation.
 * @param {Object} target - Object with the updated position.
 * @param {Object} original - Object with the original position.
 * @param {Object} keyframeScaling - Updated keyframe
 * @param {Object} activeLayer - Active layer
 * @param {boolean} firstKeyframeEdited - Flag to indicate if first keyframe was edited.
 * @return {Object} Object with updated properties.
 */
function calculateOriginalObject(target, original, keyframeScaling, activeLayer, firstKeyframeEdited) {
  const originalObject = fabricClone(target);
  originalObject.setOptions({
    ...original,
    scaleX: keyframeScaling.properties.scaleX,
    scaleY: keyframeScaling.properties.scaleY,
  });

  const rotationAnimation = activeLayer.animations.find(({ type }) => type === animationTypes.rotation);
  if (!firstKeyframeEdited && rotationAnimation) {
    const { previous, current, next } = getNearKeyframes(keyframeScaling.time, rotationAnimation.keyframes);
    const firstKeyframeRotation = previous || current || next;
    originalObject.setOptions({
      angle: firstKeyframeRotation.properties.rotation,
      left: firstKeyframeRotation.properties.x,
      top: firstKeyframeRotation.properties.y,
    });
  }

  return originalObject;
}

/**
 * Based on the updated keyframe and the changes in the position, calculates a list of new position keyframes.
 * @param {Object} activeLayer - Active layer
 * @param {Object} activeAnimation - Active animation
 * @param {Object} updatedKeyframe - Updated keyframe
 * @param {Object} original - Object with the original position
 * @param {Object} target - Object with the updated position
 * @return {Array} Array with position keyframes
 */
export function calculateNewPositionKeyframes(activeLayer, activeAnimation, updatedKeyframe, original, target) {
  const firstKeyframe = activeAnimation.keyframes[0];
  const firstKeyframeEdited = updatedKeyframe === firstKeyframe;

  const endTime = firstKeyframeEdited
    ? activeAnimation.keyframes[activeAnimation.keyframes.length - 1].time
    : updatedKeyframe.time;

  const originalObject = calculateOriginalObject(target, original, firstKeyframe, activeLayer, firstKeyframeEdited);

  return [
    PrismaKeyframe.create(
      activeLayer.id,
      animationTypes.position,
      firstKeyframeEdited ? target : originalObject,
      firstKeyframe.time,
    ),
    PrismaKeyframe.create(
      activeLayer.id,
      animationTypes.position,
      firstKeyframeEdited && updatedKeyframe.time !== 0 ? originalObject : target,
      endTime,
    ),
  ];
}

/**
 * Given a list of animatinos, retruns those that are not inherited from a group
 * @param {array} animations A list of animations
 */
export const filterGroupAnimations = animations => {
  if (!animations) {
    return [];
  }
  return animations.filter(({ isGroupAnimation }) => !isGroupAnimation);
};

/**
 * Calculates keyframes for a new animation.
 * @param {Object} object - Base fabric object to create the keyframes.
 * @param {string} type - Animation type.
 * @param {number} time - Cursor time.
 * @param {Object} original - Original properties.
 * @return {Array} Array with new keyframes.
 */
export function getKeyframesForNewAnimation(object, type, time, original) {
  if (type === animationTypes.hide || time === 0 || !original) {
    return [PrismaKeyframe.create(object.id, type, object, time)];
  }

  // TODO: in the future this could be a dynamic keyframe, meaning that if the time is 0 it will always take the layerProperties
  const firstKeyframe = PrismaKeyframe.create(object.id, type, object, 0);
  typesToFrameKeys[type].forEach(valueKey => {
    firstKeyframe.properties[valueKey] = original[frameKeysToFabricProperties[valueKey]];
  });

  return [firstKeyframe, PrismaKeyframe.create(object.id, type, object, time)];
}

/**
 * Fires modified event with drag action for the passed object.
 * @param {Object} object - Active object to trigger modified event.
 * @param {Object} original - Object with the original position
 * @return {Array} Array with new keyframes.
 */
export function fireModifiedDragEvent(object, original) {
  object.fire('modified', {
    // required to add keyframes in the scene tab
    target: object,
    action: MODIFIED_ACTIONS.DRAG,
    transform: { target: object, original },
  });
}

/**
 * Given an object and the previous originX and originY,
 * recalculates position animation keyframes and updates the object layer.
 * @param {object} object - Object in the canvas.
 * @param {string} previousOriginX - Previous origin X.
 * @param {string} previousOriginY - Previous origin Y.
 */
export function recalculatePositionAnimations(object, previousOriginX, previousOriginY) {
  const { canvas } = object;
  const layer = canvas.layers.find(({ id }) => id === object.id);

  // get scale animation to use later
  const scaleAnimation = layer.animations.find(a => a.type === animationTypes.scale);
  // add easing and target because they are used in engine to calculate values
  const scaleKeyframes = scaleAnimation?.keyframes.map(k => ({ ...k, easing: scaleAnimation.easing, target: object }));

  const newAnimations = layer.animations.map(a => {
    if (a.type !== animationTypes.position) {
      return a;
    }
    const keyframes = a.keyframes.map(keyframe => {
      const properties = { ...keyframe.properties };
      const { x, y } = properties;

      // work with a cloned object to not update the selected one
      let clonedObject = fabricClone(object);

      if (scaleAnimation) {
        // if scale animation is involved, get the scale values at this time
        // and apply them to the object
        const nearKeyframes = getNearKeyframes(keyframe.time, scaleKeyframes);
        const { scaleX, scaleY } = canvas.engine.calculateNewProps(keyframe.time, nearKeyframes);
        clonedObject.setOptions({ left: x, top: y, scaleX, scaleY });
      }

      // get the position using the new origins
      const newPosition = clonedObject.translateToGivenOrigin(
        { x, y },
        previousOriginX,
        previousOriginY,
        clonedObject.originX,
        clonedObject.originY,
      );

      // set the new position to the keyframes
      properties.x = newPosition.x;
      properties.y = newPosition.y;
      return new PrismaKeyframe({ ...keyframe, properties });
    });

    return { ...a, keyframes };
  });

  canvas.editLayer(layer.id, {
    animations: newAnimations,
  });
}
