import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Button, Modal } from '@akiunlocks/perseus-ui-components';

import './Confirm.scss';

const Confirm = ({ title, body, notes, handleClose, handleAccept, showModal, loading }) => {
  const notesLength = notes ? notes.length : 0;
  const notesText = notesLength > 1 ? 'Notes' : 'Note';

  return (
    <Modal show={showModal} onHide={() => handleClose(!showModal)}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <h6 className="confirm-modal-body">{body}</h6>
        </Row>
        {!!notesLength && (
          <div className="mt-2 fs-7">
            <strong className="me-1">{notesText}:</strong>
            {notesLength === 1 && notes[0]}
            {notesLength > 1 && (
              <ul className="m-0">
                {notes.map((note, index) => (
                  <li key={`note-${index}`} className="list-group-item fs-7">
                    {note}
                  </li>
                ))}
              </ul>
            )}
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Row className="flex justify-content-between w-100">
          <Col>
            <Button
              variant="transparent"
              className="border border-secondary"
              disabled={loading}
              onClick={() => handleClose(!showModal)}
            >
              Cancel
            </Button>
          </Col>
          <Col className="saveButton">
            <Button variant="primary" loading={loading} onClick={handleAccept}>
              Confirm
            </Button>
          </Col>
        </Row>
      </Modal.Footer>
    </Modal>
  );
};

export default Confirm;
