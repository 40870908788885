import { UNITS } from '@prisma/lib/src/constants/objects';

import { PRODUCT_SUBTYPES } from 'constants/compositions';

export function getUnitValue(value, bannerSize, unit) {
  let unitValue;
  if (unit === UNITS.PERCENTAGE) {
    unitValue = (value * 100) / bannerSize;
  } else {
    unitValue = (value * bannerSize) / 100;
  }
  return parseFloat(unitValue.toFixed(2));
}

/**
 * Gets default units depending on product subtype.
 * Default units are percentage for interstitials, and pixels for everything else.
 * @param {string} productSubtype - Product subtype.
 * @return {object} Object with left, top, height, and width units.
 */
export function getDefaultUnitsByProductSubtype(productSubtype) {
  const unit = productSubtype === PRODUCT_SUBTYPES.INTERSTITIAL_BANNER ? UNITS.PERCENTAGE : UNITS.PIXELS;
  return {
    leftUnit: unit,
    topUnit: unit,
    heightUnit: unit,
    widthUnit: unit,
  };
}
