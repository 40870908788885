export const perseusPath = '/api/v1/perseus/export';

export const perseusMethods = ['checkLiveActivationVersion', 'create', 'deleteCompositeSet', 'get'];

export const perseusClient = client => {
  const connection = client.get('connection');

  client.use(perseusPath, connection.service(perseusPath), {
    methods: perseusMethods,
  });
};
