import React from 'react';
import { observer } from 'mobx-react';
import { Form } from 'react-bootstrap';

import PrismaIconButton from '../../PrismaIconButton';
import useSidebar from 'components/Sidebar/Sidebar.hooks';

const GroupsPanel = observer(() => {
  const { group, isGroupSelected, isTimelineLayersTab, ungroup } = useSidebar();

  return (
    <Form.Group className="my-2 d-flex">
      <PrismaIconButton
        disabled={!isTimelineLayersTab}
        iconSize={16}
        icon={isGroupSelected ? 'FaObjectUngroup' : 'FaObjectGroup'}
        variant="primary-alt"
        onClick={isGroupSelected ? ungroup : group}
        tooltipMessage={isGroupSelected ? 'Ungroup' : 'Group'}
      />
    </Form.Group>
  );
});

export default GroupsPanel;
