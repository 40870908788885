import { Notification, notify } from '@akiunlocks/perseus-ui-components';

import { useRootStore } from 'store';

import { NOTIFY_MESSAGES } from 'utils';

const useSaveProject = () => {
  const { editor } = useRootStore();

  const saveProject = async projectSizeId => {
    try {
      await editor.saveProjectSize(projectSizeId);
    } catch (e) {
      notify(NOTIFY_MESSAGES.PROJECT_SAVED_ERROR, Notification.TYPE.ERROR);
    }
  };

  return { saveProject };
};

export default useSaveProject;
