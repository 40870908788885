import React from 'react';
import { FormLabel } from 'react-bootstrap';
import { Select } from '@akiunlocks/perseus-ui-components';

import './LabeledSelect.scss';

const LabeledSelect = ({ id, label, options, value, onChange = () => {} }) => {
  return (
    <div className="labeled-select">
      <FormLabel htmlFor={id}>{label}</FormLabel>
      <Select id={id} onChange={onChange} value={value} options={options} size="xs" />
    </div>
  );
};

export default React.memo(LabeledSelect);
