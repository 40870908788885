import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Notification, notify } from '@akiunlocks/perseus-ui-components';

import { useRootStore } from 'store';

import { NOTIFY_MESSAGES } from 'utils';
import { validateProjectName } from 'utils/validations';
import { STATUS } from 'containers/Projects/utils';
import { PROJECT_TYPES } from 'constants/project';

const useAddProjectModal = ({ setStatus }) => {
  const navigate = useNavigate();

  const {
    users: { user },
    projects: { addProject },
    sizes: { getSizes },
  } = useRootStore();

  const [newProjectErrors, setNewProjectErrors] = useState({});
  const [newProjectName, setNewProjectName] = useState('');
  const [newProjectType, setNewProjectType] = useState(PROJECT_TYPES.RICH_MEDIA_PERSONALIZED);
  const [showAddProjectModal, setShowAddProjectModal] = useState(false);
  const [addNewProjectLoading, setAddNewProjectLoading] = useState(false);

  useEffect(() => {
    getSizes();
  }, [getSizes]);

  useEffect(() => {
    setNewProjectErrors({});
  }, [newProjectName]);

  const handleAddModalClose = () => {
    setNewProjectName('');
    setNewProjectType(PROJECT_TYPES.RICH_MEDIA_PERSONALIZED);
    setShowAddProjectModal(false);
    setNewProjectErrors({});
  };

  const handleAddModalCreate = async () => {
    const projectName = newProjectName.trim();

    const validation = validateProjectName(projectName);
    if (!validation.valid) {
      setNewProjectErrors({ name: validation.error });
      return;
    }

    await setAddNewProjectLoading(true);
    await setStatus(STATUS.LOADING);

    try {
      const newProject = await addProject({
        favorite: false,
        userId: user._id,
        name: projectName,
        type: newProjectType,
      });

      notify(NOTIFY_MESSAGES.PROJECT_CREATED, Notification.TYPE.SUCCESS, { autoClose: true });

      navigate(`/projects/${newProject._id}/preview`);
    } catch (e) {
      notify(
        e?.message?.includes('duplicate key') ? NOTIFY_MESSAGES.PROJECT_EXISTS : NOTIFY_MESSAGES.ERROR,
        Notification.TYPE.ERROR,
        { autoClose: true },
      );
      await setStatus(STATUS.SUCCESS);
    }

    setAddNewProjectLoading(false);
  };

  return {
    newProjectErrors,
    newProjectName,
    setNewProjectName,
    setNewProjectType,
    showAddProjectModal,
    handleAddModalClose,
    handleAddModalCreate,
    addNewProjectLoading,
    setShowAddProjectModal,
  };
};

export default useAddProjectModal;
