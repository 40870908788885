import Editor from './containers/Editor';
import Projects from './containers/Projects';
import ProjectPreview from './containers/ProjectPreview';
import { Unauthorized } from 'auth/401';
import useAuthorizedRoutes from 'auth/hooks/useAuthorizedRoutes';
import { Navigate } from 'react-router-dom';
import { ROLES } from 'auth/constants/roles';
import { UNAUTHORIZED } from 'auth/constants/routes';

const Routes = () => {
  const routes = useAuthorizedRoutes([
    {
      roles: ROLES.CREATE,
      path: '/',
      element: <Navigate to="/projects" replace />,
    },
    {
      roles: ROLES.CREATE,
      path: '/projects',
      element: <Projects />,
    },
    {
      roles: ROLES.CREATE,
      path: '/projects/:projectId/preview',
      element: <ProjectPreview />,
    },
    {
      roles: ROLES.CREATE,
      path: '/projects/:projectId/sizes/:projectSizeId',
      element: <Editor />,
    },
    {
      path: UNAUTHORIZED,
      element: <Unauthorized />,
    },
    {
      path: '*',
      element: <Navigate to="/" replace />,
    },
  ]);

  return routes;
};

export default Routes;
