export const easingList = {
  easeLinear: 'easeLinear',
  easeInBounce: 'easeInBounce',
  easeInOutBounce: 'easeInOutBounce',
  easeOutBounce: 'easeOutBounce',
  easeInQuad: 'easeInQuad',
  easeOutQuad: 'easeOutQuad',
  easeInOutQuad: 'easeInOutQuad',
  easeInSine: 'easeInSine',
  easeOutSine: 'easeOutSine',
  easeInOutSine: 'easeInOutSine',
  easeInExpo: 'easeInExpo',
  easeOutExpo: 'easeOutExpo',
  easeInOutExpo: 'easeInOutExpo',
  easeInCirc: 'easeInCirc',
  easeOutCirc: 'easeOutCirc',
  easeInOutCirc: 'easeInOutCirc',
  easeInCubic: 'easeInCubic',
  easeOutCubic: 'easeOutCubic',
  easeInOutCubic: 'easeInOutCubic',
  easeInQuart: 'easeInQuart',
  easeOutQuart: 'easeOutQuart',
  easeInOutQuart: 'easeInOutQuart',
  easeInQuint: 'easeInQuint',
  easeOutQuint: 'easeOutQuint',
  easeInOutQuint: 'easeInOutQuint',
  easeInElastic: 'easeInElastic',
  easeOutElastic: 'easeOutElastic',
  easeInOutElastic: 'easeInOutElastic',
  easeInBack: 'easeInBack',
  easeOutBack: 'easeOutBack',
  easeInOutBack: 'easeInOutBack',
};

function easeLinear(t, b, c, d) {
  return (c * t) / d + b;
}

export default easeLinear;
