/* eslint-disable object-shorthand */
/* eslint-disable func-names */
import { fabric } from 'fabric';

import { PRISMA_RECT } from './constants/index.js';
import { PRISMA_SHAPE } from './prismaShape.js';

export const PrismaRect = fabric.util.createClass(fabric.Rect, {
  ...PRISMA_SHAPE,

  label: 'Rectangle',
  type: PRISMA_RECT,

  uniformRadius: true,

  initialize: function (options) {
    this.callSuper('initialize', {
      ...options,
      strokeUniform: true, // keep border strokeWidth on scaling
    });
  },

  toObject: function () {
    return {
      ...PRISMA_SHAPE.toObject(this),
      rx: this.rx,
      ry: this.ry,
      uniformRadius: this.uniformRadius,
    };
  },

  clone() {
    return new PrismaRect({
      ...this.toObject(),
      ...PRISMA_SHAPE.getCloneProperties(this),
    });
  },
});

PrismaRect.fromObject = function (object, callback) {
  const rect = new PrismaRect(object);
  return callback && callback(rect);
};

fabric.PrismaRect = PrismaRect;

export default PrismaRect;
