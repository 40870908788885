/* eslint-disable no-param-reassign */
/* eslint-disable object-shorthand */
/* eslint-disable func-names */
import { fabric } from 'fabric';

import { PRISMA_OBJECT, getObjectProps } from './prismaObject.js';
import { PRISMA_GROUP, PRISMA_MASK } from './constants/index.js';
import { fabricClone } from './utils/helpers.js';

export const PrismaGroup = fabric.util.createClass(fabric.Rect, {
  ...PRISMA_OBJECT,

  label: 'Group',
  type: PRISMA_GROUP,

  objects: [], // TODO eng: use fabric.Group?
  objectIds: [],

  initialize: function (options = {}, objects = []) {
    this.callSuper('initialize', {
      ...options,
      fill: '',
      strokeWidth: 0,
    });

    this.setMask(options);

    // do not update ids because mask does not have id yet
    this.setObjects([...objects, this.mask], false);
  },

  toObject: function () {
    return {
      ...getObjectProps(this),
      objectIds: this.objects.map(({ id }) => id),
      maskId: this.maskId,
    };
  },

  isEmpty: function () {
    return this.objects.length === 1 && this.objects[0].type === PRISMA_MASK;
  },

  setMask: function (options) {
    this.mask = new fabric.PrismaMask({ ...options });
    this.clipPath = fabricClone(this.mask);
  },

  setObjects: function (objects, updateIds = true) {
    this.objects = objects;
    this.objects.forEach(object => {
      object.clipPath = this.clipPath;
    });
    if (updateIds) {
      this.updateObjectIds();
    }
  },

  /**
   * Adds an object to the group. It will be added before the last object which is the mask.
   * @param {*} object
   * @param {number} beforeId - Optional id to add the object right before the object with that id (if exists).
   */
  addObject: function (object, beforeId = 0) {
    let index = this.objects.length - 1;
    if (beforeId && this.objectIds.includes(beforeId)) {
      index = this.objectIds.indexOf(beforeId);
    }
    this.objects.splice(index, 0, object);
    this.updateObjectIds();
  },

  /**
   * Removes an object from the group, except for the mask.
   * @param {*} object
   */
  removeObject: function (object) {
    if (object.type === PRISMA_MASK) {
      return;
    }
    this.objects = this.objects.filter(o => o.id !== object.id);
    this.updateObjectIds();
  },

  getObjects: function () {
    return this.objects;
  },

  updateObjectIds: function () {
    this.objectIds = this.objects.map(({ id }) => id);
  },
});

/**
 * Resets the size of the group to the size of the objects inside it.
 * @param {PrismaGroup} group
 */
PrismaGroup.resetSize = function (group) {
  const { originX, originY } = group;
  const tempGroup = new fabric.Group(
    group.objects.map(o => fabricClone(o)),
    // apply same origins to get correct position
    { originX, originY },
  );
  const { height, left, top, width } = tempGroup;
  group.height = height;
  group.left = left;
  group.top = top;
  group.width = width;
  // as we reset size, scaling is also reset
  group.scaleX = 1;
  group.scaleY = 1;

  // the group has been modified
  group.canvas.fire('layerPropertiesModified', { layerId: group.id, properties: { height, left, top, width } });
};

PrismaGroup.fromActiveSelection = function (activeSelection, options, callback) {
  const { height, left, scaleX, scaleY, top, width } = activeSelection;
  const resizedHeight = height * (scaleY || 1);
  const resizedWidth = width * (scaleX || 1);
  const group = new PrismaGroup(
    {
      height: resizedHeight,
      left,
      top,
      width: resizedWidth,
      ...options,
    },
    (activeSelection?.getObjects && activeSelection.getObjects()) || [activeSelection],
  );
  return callback && callback(group);
};

PrismaGroup.fromObject = function (object, callback) {
  const group = new PrismaGroup(object);
  return callback && callback(group);
};

fabric.PrismaGroup = PrismaGroup;

export default PrismaGroup;
