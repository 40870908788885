import { makeAutoObservable } from 'mobx';

import { recalculateValuesInLayersWithAspectRatio } from '@prisma/lib/src/utils/layers';
import { ASPECT_RATIO, ASPECT_RATIO_TYPE } from 'constants/canvas';
import { getAspectRatioAndOpposite, replaceAspectRatioInPage } from 'utils/aspectRatios';

import AspectRatio from 'models/AspectRatio';
import Page from 'models/Page';

class PageStore {
  currentPageIndex = 0;
  currentAspectRatioType = ASPECT_RATIO_TYPE.BIG;
  currentAspectRatioLocked = true; // this shouldn't be needed but mobx is not reacting to length changes in our array
  pages = [];

  constructor(editor) {
    makeAutoObservable(this);
    this.editor = editor;
  }

  getPages() {
    return this.pages;
  }

  getUnlockedCurrentAspectRatio() {
    return this.pages[this.currentPageIndex].aspectRatios.find(aspectRatio => aspectRatio.current);
  }

  getCurrentAspectRatioType() {
    return this.currentAspectRatioType;
  }

  getCurrentPage() {
    return this.pages[this.currentPageIndex];
  }

  setCurrentPageIndex(page = 0) {
    this.currentPageIndex = page;
  }

  setCurrentAspectRatioLocked(locked) {
    this.currentAspectRatioLocked = locked;
  }

  updateCurrentPageLayers(layers) {
    this.pages[this.currentPageIndex].layers = layers;
  }

  updateCurrentPage(page) {
    this.pages[this.currentPageIndex] = page;
  }

  getLayers() {
    return this.pages[this.currentPageIndex]?.layers;
  }

  addPage(page) {
    this.pages.push(page);
  }

  isAspectRatioLocked() {
    return this.getCurrentPage().aspectRatios?.length === 0;
  }

  /**
   * Loads the project size and updates the relevant properties.
   * Used when we load the project for the first time. It is the initialization
   *
   * @param {Object} projectSize - The project size object containing pages and other properties.
   */
  loadProjectSize(projectSize) {
    this.updateProjectSize(projectSize);

    this.currentPageIndex = 0;
    this.currentAspectRatioType = ASPECT_RATIO_TYPE.BIG;

    this.setCurrentAspectRatioLocked(this.isAspectRatioLocked());
    if (!this.currentAspectRatioLocked) {
      // if it is unlocked, we set the current flag to the BIG aspect ratio
      this.getCurrentPage().aspectRatios.find(({ type }) => type === ASPECT_RATIO_TYPE.BIG).current = true;
    }
  }

  /**
   * Updates the project size and adjusts the canvas aspect ratio if necessary.
   * It updates the loaded project size.
   *
   * @param {Object} projectSize - The project size object containing pages and other properties.
   */
  updateProjectSize(projectSize) {
    this.pages = projectSize.pages;
    this.setCurrentAspectRatioLocked(this.isAspectRatioLocked());

    const currentAspectRatio = this.getUnlockedCurrentAspectRatio();
    if (currentAspectRatio && currentAspectRatio.type !== this.currentAspectRatioType) {
      // this means that it is unlocked and it is different from what we are showing in the canvas
      const aspectRatioToSet = ASPECT_RATIO[currentAspectRatio.type];
      this.editor.canvas.resizeCanvas(aspectRatioToSet.width, aspectRatioToSet.height);
      this.currentAspectRatioType = currentAspectRatio.type;
    }
  }

  /**
   * Gives the next layer id with the pages that have loaded. If the canvas is updated, it may not have the current
   * page latest layers. But we'll know it when we implement more than one page.
   * @returns {number} Next layer id.
   */
  getNextLayerId() {
    let maxId = 0;
    this.pages.forEach(page => {
      page.layers.forEach(layer => {
        if (layer.id > maxId) {
          maxId = layer.id;
        }
      });
    });
    return maxId + 1;
  }

  /**
   * Retrieves the page corresponding to the specified aspect ratio type.
   * If the current aspect ratio type matches the new aspect ratio type,
   * it returns the current page. Otherwise, it creates a new page with
   * the updated aspect ratio.
   *
   * @param {ASPECT_RATIO_TYPE} newAspectRatioType - The new aspect ratio type to be set.
   * @returns {Page} The page object with the specified aspect ratio type.
   */
  getPageByAspectRatioType(newAspectRatioType) {
    if (this.currentAspectRatioType === newAspectRatioType) {
      return this.getCurrentPage();
    }
    const page = new Page(this.getCurrentPage());
    replaceAspectRatioInPage(page, newAspectRatioType);
    return page;
  }

  /**
   * Sets the current aspect ratio type for the editor and updates the canvas accordingly.
   *
   * @param {ASPECT_RATIO_TYPE} newAspectRatioType - The new aspect ratio type to be set.
   */
  async setCurrentAspectRatioType(newAspectRatioType) {
    // 1. get the page with the new aspect ratio to render
    const page = this.getPageByAspectRatioType(newAspectRatioType);

    // 2. update store with page and the new aspect ratio
    this.updateCurrentPage(page);
    this.currentAspectRatioType = newAspectRatioType;

    // 3. resize canvas in the editor
    const aspectRatio = ASPECT_RATIO[newAspectRatioType];
    this.editor.canvas.resizeCanvas(aspectRatio.width, aspectRatio.height);

    // 4. load the new page in the canvas
    await this.editor.loadPageIntoCanvas(page);

    // 5. update the aspect ratio in the history store
    if (this.isAspectRatioLocked()) {
      this.editor.historyStore.updateAspectRatio(newAspectRatioType);
    }
    this.editor.historyStore.setSkipNext(true);
  }

  /**
   * Changes the aspect ratio lock state.
   * @param {string} selectedAspectRatio - The selected aspect ratio type.
   */
  async changeAspectRatioLocked(selectedAspectRatio) {
    if (this.isAspectRatioLocked()) {
      // let's duplicate the layers and recalculate the values to unlock it
      const { aspectRatio, oppositeAspectRatio } = getAspectRatioAndOpposite(this.getCurrentAspectRatioType());
      const currentAspectRatio = new AspectRatio(aspectRatio);
      const newAspectRatio = new AspectRatio(oppositeAspectRatio);

      const newLayers = recalculateValuesInLayersWithAspectRatio(this.getLayers(), currentAspectRatio, newAspectRatio);
      newAspectRatio.setLayers(newLayers);
      newAspectRatio.setCurrent(false);

      currentAspectRatio.setLayers([]); // we don't need to save them because it is what we have in the canvas
      currentAspectRatio.setCurrent(true);

      this.getCurrentPage().aspectRatios = [newAspectRatio, currentAspectRatio];
      this.editor.canvas.fire('history');
    } else {
      // This is when are going to lock again the aspect ratio
      if (this.currentAspectRatioType === selectedAspectRatio) {
        // if the selected is the one we are showing in the canvas, we just remove the aspect ratios
        this.getCurrentPage().aspectRatios = [];
        this.editor.canvas.fire('history');
      } else {
        // this means that the selected aspect ratio is the one we are not showing in the canvas
        const page = this.getCurrentPage();
        const aspectRatioToUse = page.aspectRatios.find(aspectRatio => aspectRatio.type === selectedAspectRatio);

        // 1. Recalculate the layers of the selected aspect ratio into the canvas we are showing
        const newLayers = recalculateValuesInLayersWithAspectRatio(
          aspectRatioToUse.layers,
          aspectRatioToUse,
          this.getUnlockedCurrentAspectRatio(),
        );
        // 2. we remove the aspect ratios, now it is locked
        page.aspectRatios = [];

        // 3. Update the page and store with the recalculated layers
        page.layers = newLayers;
        this.updateCurrentPage(page);

        // 4. Reload canvas with the recalculated layers, easier that updating every layer
        await this.editor.loadPageIntoCanvas(page);
      }
    }
    this.setCurrentAspectRatioLocked(!this.currentAspectRatioLocked);
  }
}

export default PageStore;
