/* eslint-disable */
import { fabric } from 'fabric';

import { PRISMA_OBJECT, getObjectProps } from './prismaObject.js';
import { getFontUrl, loadFontIfNeeded } from './utils/fonts.js';
import { VERTICAL_ALIGN } from './constants/canvas.js';
import { ADJUST_OPTIONS } from './constants/text.js';
import { getPositionFromActiveSelection } from './utils/helpers.js';

const MINIMUM_FONT_SIZE = 1;

export const PrismaText = fabric.util.createClass(fabric.Textbox, {
  ...PRISMA_OBJECT,

  label: 'Text',
  type: 'prisma-text',
  strokeWidth: 0,
  splitByGrapheme: false,

  verticalAlign: VERTICAL_ALIGN.TOP,
  stateProperties: fabric.Text.prototype.stateProperties?.concat('verticalAlign'),
  cacheProperties: fabric.Text.prototype.cacheProperties?.concat('verticalAlign'),

  initialize: function (text, options) {
    this.callSuper('initialize', text, {
      ...options,
      objectCaching: false,
    });
    this._initializeControls();
  },

  getAdjustOption: function () {
    if (this.maxLines) {
      return ADJUST_OPTIONS.LINES;
    }
    if (this.maxHeight) {
      return ADJUST_OPTIONS.TEXTBOX;
    }
    return ADJUST_OPTIONS.FONT_SIZE;
  },

  recalculateFontSize: function () {
    if (this.adjustText()) {
      this.canvas.renderAll();
      return true;
    }
    return false;
  },

  /**
   * Recalculates the font size based on the max number of lines.
   * @returns {boolean} - True if the font size was recalculated, false otherwise.
   */
  adjustText: function () {
    if (this.getAdjustOption() === ADJUST_OPTIONS.LINES) {
      const fontSize = this.fontSize;
      while (this._textLines.length > this.maxLines && this.fontSize > MINIMUM_FONT_SIZE) {
        this.set('fontSize', this.fontSize - 0.5);
      }
      if (this.fontSize !== fontSize) {
        return true;
      }
    } else if (this.getAdjustOption() === ADJUST_OPTIONS.TEXTBOX) {
      const fontSize = this.fontSize;
      while (this.calcTextHeight(true) > this.maxHeight && this.fontSize > MINIMUM_FONT_SIZE) {
        this.set('fontSize', this.fontSize - 0.5);
      }
      if (this.fontSize !== fontSize) {
        this.set('height', this.maxHeight); // reset because the font size events can change the height.
        return true;
      }
    }
    return false;
  },

  /**
   * Get the number of text lines.
   * @returns {number} - The number of text lines.
   */
  getNumberOfTextLines: function () {
    return this._textLines.length;
  },

  /**
   * Check if the text is into the next line.
   * If the max number of lines is set and the current number of lines is greater than the max number of lines,
   * the max number of lines is set to undefined and the method returns true.
   * @returns {boolean} - True if the text is into the next line, false otherwise.
   */
  isIntoNextLine: function () {
    if (this.maxLines && this.getNumberOfTextLines() > this.maxLines) {
      this.maxLines = undefined;
      return true;
    }
    return false;
  },

  /**
   * Calculate text box height
   *
   * @param {boolean} resizing - If true, returns the calculated height. If false, returns the maximum of the calculated height and this.height.
   * @return {number} - The calculated height of the text box.
   */
  calcTextHeight(resizing = false) {
    let height = 0;
    const { length } = this._textLines;
    for (let i = 0; i < length; i++) {
      const lineHeight = this.getHeightOfLine(i);
      height += i === length - 1 ? lineHeight / this.lineHeight : lineHeight;
    }

    if (resizing) {
      return height;
    }
    return Math.max(height, this.height);
  },

  _scaleObject: function (eventData, transform, x, y) {
    const target = transform.target;
    const localPoint = fabric.controlsUtils.getLocalPoint(transform, transform.originX, transform.originY, x, y);
    const strokeWidthPadding = target.strokeWidth / (target.strokeUniform ? target.scaleY : 1);
    const strokeHeightPadding = target.strokeWidth / (target.strokeUniform ? target.scaleX : 1);
    const multiplier = transform.originX === 'center' && transform.originY === 'center' ? 2 : 1;
    const oldHeight = target.height;
    const oldWidth = target.width;
    const newHeight = Math.abs((localPoint.y * multiplier) / target.scaleY) - strokeWidthPadding;
    const newWidth = Math.abs((localPoint.x * multiplier) / target.scaleX) - strokeHeightPadding;
    target.set('height', Math.max(newHeight, target.calcTextHeight(true)));
    target.set('width', Math.max(newWidth, 0));
    target._increaseMaximums();
    return oldHeight !== newHeight || oldWidth !== newWidth;
  },

  _changeHeight: function (eventData, transform, x, y) {
    const target = transform.target;
    const localPoint = fabric.controlsUtils.getLocalPoint(transform, transform.originX, transform.originY, x, y);
    const strokePadding = target.strokeWidth / (target.strokeUniform ? target.scaleY : 1);
    const multiplier = transform.originX === 'center' && transform.originY === 'center' ? 2 : 1;
    const oldHeight = target.height;
    const newHeight = Math.abs((localPoint.y * multiplier) / target.scaleY) - strokePadding;
    target.set('height', Math.max(newHeight, target.calcTextHeight(true)));
    target._increaseMaximums();
    return oldHeight !== newHeight;
  },

  _initializeControls: function () {
    const configuration = {
      mt: {
        coords: {
          x: 0,
          y: -0.5,
        },
        actionHandler: this._changeHeight,
      },
      mb: {
        coords: {
          x: 0,
          y: 0.5,
        },
        actionHandler: this._changeHeight,
      },
      tl: {
        coords: {
          x: -0.5,
          y: -0.5,
        },
        actionHandler: this._scaleObject,
      },
      tr: {
        coords: {
          x: 0.5,
          y: -0.5,
        },
        actionHandler: this._scaleObject,
      },
      br: {
        coords: {
          x: 0.5,
          y: 0.5,
        },
        actionHandler: this._scaleObject,
      },
      bl: {
        coords: {
          x: -0.5,
          y: 0.5,
        },
        actionHandler: this._scaleObject,
      },
    };
    for (const [key, config] of Object.entries(configuration)) {
      this.controls[key] = new fabric.Control({
        ...config.coords,
        actionHandler: fabric.controlsUtils.wrapWithFireEvent(
          'resizing',
          fabric.controlsUtils.wrapWithFixedAnchor(config.actionHandler),
        ),
        cursorStyleHandler: fabric.controlsUtils.scaleSkewCursorStyleHandler,
        actionName: 'resizing',
      });
    }
  },

  /**
   * Get the total line height.
   * @return {number} - The total height of all lines.
   */
  _getTotalLineHeights() {
    return this._textLines.reduce((total, line, index) => {
      return total + this.getHeightOfLine(index);
    }, 0);
  },

  /**
   * Get the top offset.
   *
   * @private
   * @return {number} - The top offset based on the vertical alignment.
   */
  _getTopOffset() {
    if (!this.height) {
      return 0;
    }
    switch (this.verticalAlign) {
      case VERTICAL_ALIGN.MIDDLE:
        return -this._getTotalLineHeights() / 2;
      case VERTICAL_ALIGN.BOTTOM:
        return this.height / 2 - this._getTotalLineHeights();
      default:
        return -this.height / 2;
    }
  },

  /**
   * Get the selection start offset Y.
   *
   * @return {number} - The Y offset of the selection start based on the vertical alignment.
   */
  _getSelectionStartOffsetY() {
    if (!this.height) {
      return 0;
    }
    switch (this.verticalAlign) {
      case VERTICAL_ALIGN.MIDDLE:
        return this.height / 2 - this._getTotalLineHeights() / 2;
      case VERTICAL_ALIGN.BOTTOM:
        return this.height - this._getTotalLineHeights();
      default:
        return 0;
    }
  },

  /**
   * Increase the max number of lines based on the current number of lines.
   * If the max number of lines is set and the current number of lines is greater than the max number of lines,
   * the max number of lines is set to the current number of lines.
   * This method is used to increase the max number of lines when the text is resized.
   *
   * It also updates the max height if it is set.
   *
   * @returns {void}
   * @private
   */
  _increaseMaximums: function () {
    if (this.maxLines && this.maxLines < this.getNumberOfTextLines()) {
      this.set('maxLines', this.getNumberOfTextLines());
    }
    if (this.maxHeight) {
      this.set('maxHeight', this.height);
    }
  },

  /**
   * Returns index of a character corresponding to where an object was clicked
   * Overrides the default implementation of fabric.Textbox
   *
   * @param {Event} e - Event object
   * @return {number} - Index of a character where the click occurred.
   *
   */
  getSelectionStartFromPointer(e) {
    const mouseOffset = this.getLocalPointer(e);
    const { scaleX = 1, scaleY = 1 } = this;
    const { length } = this._textLines;
    let prevWidth = 0;
    let width = 0;
    let height = 0;
    let charIndex = 0;
    let lineIndex = 0;
    let lineLeftOffset;
    let line;
    let jlen;

    let startY = this._getSelectionStartOffsetY();
    for (let i = 0; i < length; i++) {
      if (startY + height <= mouseOffset.y) {
        height += this.getHeightOfLine(i) * scaleY;
        lineIndex = i;
        if (i > 0) {
          charIndex += this._textLines[i - 1].length + this.missingNewlineOffset(i - 1);
        }
      } else {
        break;
      }
    }

    lineLeftOffset = this._getLineLeftOffset(lineIndex);

    width = lineLeftOffset * scaleX;

    line = this._textLines[lineIndex];
    jlen = line.length;

    for (let j = 0; j < jlen; j++) {
      prevWidth = width;
      const { kernedWidth = 0 } = this.__charBounds?.[lineIndex][j] || {};
      width += kernedWidth * scaleX;
      if (width <= mouseOffset.x) {
        charIndex++;
      } else {
        break;
      }
    }

    return this._getNewSelectionStartFromOffset(mouseOffset, prevWidth, width, charIndex, jlen);
  },

  clone() {
    return new PrismaText(this.text, {
      ...this.toObject(),
      ...this.getCloneProperties(),
    });
  },

  getCloneProperties() {
    const ref = this;
    const { offsetLeft, offsetTop } = ref;
    const { left, top } = ref.group ? getPositionFromActiveSelection(ref) : ref;
    return { left, top, offsetLeft, offsetTop };
  },

  toObject: function () {
    return {
      ...getObjectProps(this),
      charSpacing: this.charSpacing,
      fill: this.fill,
      fontFamily: this.fontFamily,
      fontFileName: this.fontFileName,
      fontSize: this.fontSize,
      fontStyle: this.fontStyle,
      fontWeight: this.fontWeight,
      lineHeight: this.lineHeight,
      linethrough: this.linethrough,
      maskId: this.maskId,
      maxHeight: this.maxHeight,
      maxLines: this.maxLines,
      overline: this.overline,
      shadow: this.shadow || null,
      text: this.text,
      textAlign: this.textAlign,
      underline: this.underline,
      verticalAlign: this.verticalAlign,
      textPlaceholder: this.textPlaceholder || `perseus_placeholder_text_id_${this.id}`,
    };
  },
});

PrismaText.fromObject = function (object, callback) {
  // if textPlaceholder is not defined could be because it is and old project that we are watching in the editor
  // so we need to use the text property
  // if textPlaceholder is defined but it is a perseus_placeholder, we need to use the text property
  // because it means it has not been overwritten
  // otherwise we use the textPlaceholder
  let text = object.text;
  if (object.textPlaceholder && !object.textPlaceholder.includes('perseus_placeholder')) {
    text = object.textPlaceholder;
  }
  const fontData = { name: object.fontFamily, text, url: getFontUrl(object) };
  loadFontIfNeeded(fontData, loaded => {
    const afterLoad = textObject => {
      textObject.adjustText();
      return callback && callback(textObject);
    };
    return fabric.Object._fromObject('PrismaText', { ...object, text, fontLoaded: loaded }, afterLoad, 'text');
  });
};

fabric.PrismaText = PrismaText;

export default PrismaText;
