import React from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';

import { Unauthenticated } from 'auth/Unauthenticated';
import useAuthorizedRoles from 'auth/hooks/useAuthorizedRoles';
import { UNAUTHORIZED } from 'auth/constants/routes';

/**
 * Checks routes, if the user is not allowed to access to some of them, replaces
 * it with a Navigate component to redirect. Then calls useRoutes hook.
 * @see https://reactrouter.com/docs/en/v6/api#useroutes
 *
 * @param {array} routes List of all posible routes.
 * @param {JSX.Element} routes[].element Component to render if the user has the right roles.
 * @param {string} routes[].path Route path.
 * @param {string} routes[].roles Allowed roles.
 */
export default function useAuthorizedRoutes(routes) {
  const { keycloak } = useKeycloak();

  const allRoles = routes.flatMap(({ roles }) => {
    return roles || [];
  });

  const authorizedRoles = useAuthorizedRoles([...new Set(allRoles)]);

  const addAuthorizedRoutes = routes => {
    return routes.map(({ element, path, roles, ...rest }) => {
      const route = { ...rest, element, path };

      if (!keycloak.authenticated) {
        // unauthenticated page is showed and then login is required
        // we don't redirect here so keycloak can go back to the same url after login
        route.element = <Unauthenticated />;
      } else if (roles) {
        const isAuthorized = roles.some(r => authorizedRoles.includes(r));
        if (isAuthorized) {
          route.element = element;
        } else {
          route.element = <Navigate to={UNAUTHORIZED} />;
        }
      }

      return route;
    });
  };

  return useRoutes(addAuthorizedRoutes(routes));
}
