/* eslint-disable no-underscore-dangle */

import { UNITS } from './constants/objects.js';
import { getPositionFromActiveSelection } from './utils/helpers.js';
import { FABRIC_OBJECT_PROPERTIES } from './utils/types.js';

const { OPACITY, OBJECT_OPACITY, HIDE } = FABRIC_OBJECT_PROPERTIES;

export const PRISMA_OBJECT = {
  offsetTop: 0,
  offsetLeft: 0,
  hide: false,
  // objectOpacity controls the opacity of the object when hide is false. We don't
  // use the opacity property directly because it is also changed by the hide property
  objectOpacity: 100,

  // units for positions and size
  // default depends on product type/subtype, we set pixels here for backwards compatibility
  // for new objects, unit will be sent at object creation
  leftUnit: UNITS.PIXELS,
  topUnit: UNITS.PIXELS,
  widthUnit: UNITS.PIXELS,
  heightUnit: UNITS.PIXELS,

  setOffset(offset) {
    const { offsetTop, offsetLeft } = offset;
    this.left = this.left - this.offsetLeft + offsetLeft;
    this.top = this.top - this.offsetTop + offsetTop;
    this.offsetTop = offsetTop;
    this.offsetLeft = offsetLeft;
  },

  /**
   * Sets one or more properties and their dependencies.
   * @param {string|object} key - The name of the property if it is one, or an object with properies.
   * @param {any} value - The value of the property.
   * @return {object} This object.
   */
  set(key, value) {
    if (typeof key === 'object') {
      Object.entries(key).forEach(([k, v]) => this._setWithDependencies(k, v));
    } else {
      this._setWithDependencies(key, value);
    }
    return this;
  },

  setUnit(prop, unit) {
    this.set(`${prop}Unit`, unit);
  },

  /**
   * Sets options properties and their dependencies.
   * @param {object} options - Properties options.
   */
  setOptions(options) {
    this.set(options);
    this._initGradient(options.fill, 'fill');
    this._initGradient(options.stroke, 'stroke');
    this._initPattern(options.fill, 'fill');
    this._initPattern(options.stroke, 'stroke');
  },

  /**
   * Modified version of set method, where the dependant properties are also set.
   * @param {string} key - The name of the property.
   * @param {any} value - The value of the property.
   */
  _setWithDependencies(key, value) {
    const props = { [key]: value };
    if (key === HIDE) {
      props[OPACITY] = value ? 0 : this.objectOpacity / 100;
    } else if (key === OBJECT_OPACITY && !this.hide) {
      props[OPACITY] = value / 100;
    }
    this.callSuper('set', props);
  },

  // eslint-disable-next-line no-underscore-dangle
  _render(ctx) {
    this.callSuper('_render', ctx);

    const isActiveSelection = !!this.group;

    // if object has a mask asociated, get correct clipPath position
    const maskLayer = this.canvas.layers.find(l => l.target.id === this.maskId);
    if (maskLayer && this.clipPath) {
      let { angle, left, scaleX, scaleY, top } = maskLayer.target;

      if (isActiveSelection) {
        const position = getPositionFromActiveSelection(maskLayer.target);
        angle += this.group.angle;
        left = position.left;
        scaleX *= this.group.scaleX;
        scaleY *= this.group.scaleY;
        top = position.top;
      }

      this.clipPath.angle = angle;
      this.clipPath.left = left;
      this.clipPath.scaleX = scaleX;
      this.clipPath.scaleY = scaleY;
      this.clipPath.top = top;
      this.clipPath.originX = maskLayer.target.originX;
      this.clipPath.originY = maskLayer.target.originY;
    }
  },
};

export function getObjectProps(ref) {
  return {
    id: ref.id,
    x: Math.round(ref.left - ref.offsetLeft),
    leftUnit: ref.leftUnit,
    y: Math.round(ref.top - ref.offsetTop),
    topUnit: ref.topUnit,
    width: ref.width,
    widthUnit: ref.widthUnit,
    height: ref.height,
    heightUnit: ref.heightUnit,
    scaleX: ref.scaleX,
    scaleY: ref.scaleY,
    angle: ref.angle,
    rotation: ref.angle,
    opacity: ref.opacity,
    objectOpacity: ref.objectOpacity,
    hide: ref.hide,
    selectable: ref.selectable,
    evented: ref.evented,
    hoverCursor: ref.hoverCursor,
    groupId: ref.groupId,
    visible: ref.visible,
    clickPlaceholder: ref.clickPlaceholder || `perseus_placeholder_click_id_${ref.id}`,
    originX: ref.originX,
    originY: ref.originY,
  };
}
