export const PRISMA_CIRCLE = 'prisma-circle';
export const PRISMA_CLOSE_BUTTON = 'prisma-close-button';
export const PRISMA_GROUP = 'prisma-group';
export const PRISMA_GUIDELINE = 'prisma-guideline';
export const PRISMA_IMAGE = 'prisma-image';
export const PRISMA_LANDSCAPE = 'prisma-landscape';
export const PRISMA_MASK = 'prisma-mask';
export const PRISMA_RECT = 'prisma-rect';
export const PRISMA_TEXT = 'prisma-text';

export const PRISMA_MASK_NAME = 'Mask';

export const ACTIVE_SELECTION = 'activeSelection';

// not including masks as is not a normal layer
export const LAYER_TYPES = [PRISMA_IMAGE, PRISMA_TEXT, PRISMA_GROUP, PRISMA_RECT, PRISMA_CIRCLE];

export const PRODUCT_SUBTYPES = {
  INTERSTITIAL_BANNER: 'INTERSTITIAL_BANNER',
};
