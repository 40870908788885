import React from 'react';
import { observer } from 'mobx-react';

import { useRootStore } from 'store';

import { FRAME_TOP, KEYFRAME_CLASS_NAME } from './constants';
import { HALF_KEYFRAME_WIDTH } from 'constants';
import { getPointFromKeyframe } from './utilities';

import './Keyframe.scss';

const Keyframe = observer(({ keyframe, msInOnPixel, onMouseDown, onMouseUp }) => {
  const { editor } = useRootStore();
  const isSelected = editor.selectionStore.isSelected(keyframe);
  const point = getPointFromKeyframe(keyframe, msInOnPixel) + HALF_KEYFRAME_WIDTH;
  const showLoopIcon = keyframe.repeat;

  return (
    <>
      {/* As the polygon is rotated its real width will be ~12px */}
      <g transform={`translate(${point + 0.5},${FRAME_TOP}) rotate(45)`}>
        <rect
          className={KEYFRAME_CLASS_NAME}
          fill={isSelected ? '#1fa2a9' : '#f99e47'}
          onMouseDown={e => {
            e.stopPropagation();
            const { ctrlKey, metaKey, shiftKey } = e;
            onMouseDown(keyframe.id, point - HALF_KEYFRAME_WIDTH, { ctrlKey, metaKey, shiftKey });
          }}
          onMouseUp={onMouseUp}
          data-id={keyframe.id}
        />
      </g>

      {showLoopIcon && (
        <path
          className="loop-icon"
          transform={`translate(${point - 137}, ${2 + FRAME_TOP})`}
          fill="none"
          stroke="#FFFFFF"
          d="M134.5,4.25a2.5,2.5 0 1,0 5,0a2.5,2.5 0 1,0 -5,0M137.5,3.25l1.5,1.5l-1.5,1.5M138.5,5.75l-1.5,-1.5l1.5,-1.5"
        ></path>
      )}
    </>
  );
});

export default Keyframe;
