import { SENDER_TYPE } from 'constants/chat';
import Message from './Message';

class ButtonMessage extends Message {
  constructor(message) {
    super(SENDER_TYPE.BOT, message.text); // it is always a bot
    this.buttons = message.buttons;
  }
}

export default ButtonMessage;
