import PrismaIconButton from '../Sidebar/components/PrismaIconButton/PrismaIconButton';

const ToggleIconButton = ({ icons = ['eye', 'eye-fil'], onClick, value = false, ...props }) => {
  return (
    <PrismaIconButton
      icon={icons[Number(value)]}
      className="layer-control"
      onClick={onClick}
      iconSize={15}
      {...props}
    />
  );
};

export default ToggleIconButton;
