import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { FormLabel } from 'react-bootstrap';

import { InputGroup, Stack } from '@akiunlocks/perseus-ui-components';
import { UNITS } from '@prisma/lib/src/constants/objects';

import { useCanvas } from 'containers/Editor/hooks/useCanvas';

import './PercentageInput.scss';

const PercentageInput = ({ className, controlProps, direction, gap, id, label, prop, size, unit, onUnitChange }) => {
  const { getRelativeValue } = useCanvas(prop);
  const { value } = controlProps;
  const [internalValue, setInternalValue] = useState(value);
  // reference used to avoid setting internal value on input change
  const skipNextRef = useRef(false);

  const inputValue = useMemo(() => {
    return unit === UNITS.PIXELS ? value : getRelativeValue(value, unit);
  }, [getRelativeValue, unit, value]);

  useEffect(() => {
    if (skipNextRef.current) {
      skipNextRef.current = false;
      return;
    }
    setInternalValue(inputValue);
  }, [inputValue]);

  return (
    <Stack className={classnames('percentage-input-wrapper', size, className)} direction={direction} gap={gap}>
      <FormLabel className="label" htmlFor={id}>
        {label}
      </FormLabel>
      <InputGroup
        items={[
          {
            controlProps: {
              ...controlProps,
              value: internalValue,
              onChange: e => {
                let newValue = e.target.value;
                skipNextRef.current = true;
                setInternalValue(newValue);
                if (newValue.trim() !== '') {
                  if (unit === UNITS.PIXELS) {
                    controlProps.onChange(e, unit);
                  } else {
                    controlProps.onChange({ target: { value: getRelativeValue(newValue, UNITS.PIXELS) } }, unit);
                  }
                }
              },
              onBlur: () => {
                if (!internalValue) {
                  // to prevent empty input
                  setInternalValue(inputValue);
                }
              },
              className: controlProps.className,
            },
            id,
            type: 'input',
          },
          {
            content: unit,
            onClick: () => {
              const newUnit = unit === UNITS.PIXELS ? UNITS.PERCENTAGE : UNITS.PIXELS;
              setInternalValue(getRelativeValue(internalValue, newUnit));
              onUnitChange(prop, newUnit);
            },
            type: 'button',
            variant: 'primary-alt',
          },
        ]}
        size={size}
      />
    </Stack>
  );
};

PercentageInput.propTypes = {
  /** Additional class */
  className: PropTypes.string,
  /** Input control props */
  controlProps: PropTypes.object,
  /** Stack direction */
  direction: PropTypes.oneOf(['horizontal', 'vertical']),
  /** Stack gap */
  gap: PropTypes.number,
  /** Input id */
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Input label */
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /** Prop */
  prop: PropTypes.oneOf(['left', 'top', 'width', 'height']),
  /** Input and label size */
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg']),
  /** Unit */
  unit: PropTypes.oneOf(Object.values(UNITS)),
};

PercentageInput.defaultProps = {
  className: '',
  controlProps: {},
  direction: 'horizontal',
  gap: 1,
  id: '',
  label: '',
  size: 'xs',
  unit: UNITS.PIXELS,
};

export default PercentageInput;
