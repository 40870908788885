import { useEffect, useState } from 'react';

import { useRootStore } from '../../../store';
import { PAGE_SIZE_OPTIONS, STATUS } from '../utils';

const useFilters = () => {
  const {
    users: { user },
    projects: { getProjects, setProjects },
  } = useRootStore();

  const [page, setPage] = useState(0);
  const [status, setStatus] = useState(STATUS.LOADING);
  const [projectsList, setProjectsList] = useState([]);
  const [pageLimit, setPageLimit] = useState(PAGE_SIZE_OPTIONS[2].value);

  const [filters, setFilters] = useState({
    userId: user._id,
    $limit: pageLimit,
    $skip: pageLimit * page,
  });

  useEffect(() => {
    getProjects(filters);

    return () => {
      setProjects({});
    };
  }, [getProjects, filters, setProjects]);

  useEffect(() => {
    if (projectsList.length) {
      setStatus(STATUS.SUCCESS);
    }
  }, [projectsList, setStatus]);

  useEffect(() => {
    setStatus(STATUS.LOADING);
    setFilters(prev => ({
      ...prev,
      $limit: pageLimit,
      $skip: pageLimit * page,
    }));
  }, [page, pageLimit, setStatus]);

  const searchInputOnChange = async ({ target: { value } }) => {
    const { name, ...rest } = filters;

    await setStatus(STATUS.LOADING);
    setPage(0);
    setFilters({
      ...rest,
      ...(value ? { name: value } : {}),
    });
  };

  const activeFavoriteFilter = async () => {
    const { favorite, ...rest } = filters;

    await setStatus(STATUS.LOADING);
    setPage(0);
    setFilters({
      ...rest,
      ...(!favorite ? { favorite: true } : {}),
    });
  };

  return {
    status,
    setPage,
    filters,
    pageLimit,
    setStatus,
    projectsList,
    setPageLimit,
    setProjectsList,
    searchInputOnChange,
    activeFavoriteFilter,
  };
};

export default useFilters;
