import { PROJECT_TYPES } from 'constants/project';

export const PRODUCT_TYPES = {
  DISPLAY: 'DISPLAY',
  DOOH: 'DOOH',
  RICH_MEDIA: 'RICH_MEDIA',
};

export const PRODUCT_TYPE_ENABLED = {
  [PRODUCT_TYPES.DISPLAY]: PROJECT_TYPES.RICH_MEDIA_PERSONALIZED,
  [PRODUCT_TYPES.RICH_MEDIA]: PROJECT_TYPES.RICH_MEDIA_PERSONALIZED,
  [PRODUCT_TYPES.DOOH]: PROJECT_TYPES.DOOH,
};

export const PRODUCT_TYPE_OPTIONS = [
  { label: 'Display', value: PRODUCT_TYPES.DISPLAY },
  { label: 'Rich media', value: PRODUCT_TYPES.RICH_MEDIA },
  { label: 'DOOH', value: PRODUCT_TYPES.DOOH },
];

export const PRODUCT_SUBTYPES = {
  ANIMATED_BANNER: 'ANIMATED_BANNER',
  BANNER: 'BANNER',
  INTERSTITIAL_BANNER: 'INTERSTITIAL_BANNER',
  STATIC_BANNER: 'STATIC_BANNER',
};

export const PRODUCT_SUBTYPE_OPTIONS = {
  [PRODUCT_TYPES.DISPLAY]: [
    { label: 'Static banner', value: PRODUCT_SUBTYPES.STATIC_BANNER },
    {
      info: 'Display Animated compositions cannot be personalized',
      label: 'Animated banner',
      value: PRODUCT_SUBTYPES.ANIMATED_BANNER,
    },
  ],
  [PRODUCT_TYPES.RICH_MEDIA]: [
    { info: 'Rich Media Banners require personalization', label: 'Banner', value: PRODUCT_SUBTYPES.BANNER },
    { label: 'Interstitial', value: PRODUCT_SUBTYPES.INTERSTITIAL_BANNER },
  ],
  [PRODUCT_TYPES.DOOH]: [{ label: 'Static banner', value: PRODUCT_SUBTYPES.STATIC_BANNER }],
};
