export const CANVAS_CONTAINER_ID = 'art-board';

export const BOTTOM = 'bottom';
export const CENTER = 'center';
export const END = 'end';
export const HEIGHT = 'height';
export const LEFT = 'left';
export const MIDDLE = 'middle';
export const START = 'start';
export const TOP = 'top';
export const WIDTH = 'width';

export const SCALE_X = 'scaleX';
export const SCALE_Y = 'scaleY';

export const SELECTION = 'Selection';
export const CANVAS = 'Canvas';
export const GROUP = 'Group';

export const BOUNDARIES = [
  { label: SELECTION, value: SELECTION },
  { label: CANVAS, value: CANVAS },
];

export const BOUNDARIES_GROUP = [
  { label: SELECTION, value: SELECTION },
  { label: GROUP, value: CANVAS },
];

export const DISTRIBUTE_VERTICAL = 'distribute-vertical';
export const DISTRIBUTE_HORIZONTAL = 'distribute-horizontal';

export const ALIGN_HORIZONTAL = 'align-horizontal';
export const ALIGN_VERTICAL = 'align-vertical';

export const ASPECT_RATIO_TYPE = {
  BIG: 'BIG',
  SMALL: 'SMALL',
};

export const ASPECT_RATIO = {
  [ASPECT_RATIO_TYPE.BIG]: {
    width: 384,
    height: 615,
    type: ASPECT_RATIO_TYPE.BIG,
  },
  [ASPECT_RATIO_TYPE.SMALL]: {
    width: 320,
    height: 460,
    type: ASPECT_RATIO_TYPE.SMALL,
  },
};

export const DEFAULT_INTERSTITIAL_BANNER_SIZE = `${ASPECT_RATIO[ASPECT_RATIO_TYPE.BIG].width}x${
  ASPECT_RATIO[ASPECT_RATIO_TYPE.BIG].height
}`;
