import { PROJECT_TYPES } from 'constants/project';
import { PRODUCT_TYPES, PRODUCT_SUBTYPES, PRODUCT_SUBTYPE_OPTIONS } from 'constants/compositions';

/**
 * Determines whether a product should be disabled based on its configuration.
 * @param {string} projectType - The project type.
 * @param {string} productType - The product type.
 * @param {boolean} editingInterstitial - Whether an interstitial banner is being edited.
 * @return {{disabled: boolean, reason: string}} An object containing the disabled status and the reason.
 */
export function checkDisabledProduct(projectType, productType, editingInterstitial) {
  if (editingInterstitial) {
    return {
      disabled: true,
      reason: 'Interstitials cannot change type',
    };
  }

  const isDOOHProject = projectType === PROJECT_TYPES.DOOH;
  const isDOOHProduct = productType === PRODUCT_TYPES.DOOH;

  const result = {
    disabled: isDOOHProject ? !isDOOHProduct : isDOOHProduct,
    reason: '',
  };

  if (result.disabled) {
    result.reason = isDOOHProject ? 'This is a DOOH project' : 'This is a Non-DOOH project';
  }

  return result;
}

/**
 * Determines whether a product subtype should be disabled based on its configuration.
 * @param {string} productSubtype - The product subttype.
 * @param {boolean} editing - Whether is an edition.
 * @param {boolean} editingInterstitial - Whether an interstitial banner is being edited.
 * @return {{disabled: boolean, reason: string}} An object containing the disabled status and the reason.
 */
export function checkDisabledProductSubtype(productSubtype, editing, editingInterstitial) {
  if (editingInterstitial) {
    return {
      disabled: true,
      reason: 'Interstitials cannot change type',
    };
  } else if (editing && productSubtype === PRODUCT_SUBTYPES.INTERSTITIAL_BANNER) {
    return {
      disabled: true,
      reason: 'Composition cannot be turned into an interstitial',
    };
  }

  return {
    disabled: false,
    reason: '',
  };
}

/**
 * Returns the default product type based on the given project type.
 * @param {string} projectType - The type of the project.
 * @return {string} The default product type.
 */
export function getDefaultType(projectType) {
  return projectType === PROJECT_TYPES.DOOH ? PRODUCT_TYPES.DOOH : PRODUCT_TYPES.DISPLAY;
}

/**
 * Returns the default subtype for the given product type.
 * @param {string} productType - The type of the product.
 * @return {string} The default subtype for the given product type.
 */
export function getDefaultSubtype(productType) {
  return PRODUCT_SUBTYPE_OPTIONS[productType][0].value;
}
