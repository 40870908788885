import { Link } from 'react-router-dom';
import React, { useCallback } from 'react';
import { Icon, Popover } from '@akiunlocks/perseus-ui-components';

import { getFormattedDate } from '../utils';
import { DotsMenu } from '../../../components/Projects';
import CustomInput from '../../../components/CustomInput';

const useTable = ({ archive, duplicate, handleRedirect, rename, setCurrentProjectAsFavorite }) => {
  const getTableRow = useCallback(
    (item, key) => ({
      key,
      ...item,
      dots: (
        <DotsMenu
          confirmArchive={archive.handleOpen}
          handleDuplicate={duplicate.handleOpen}
          handleRedirect={handleRedirect}
          handleRename={rename.handleRename}
          project={item}
        />
      ),
      updatedAt: <div className="project-updatedAt">{getFormattedDate(item.updatedAt)}</div>,
      name: (
        <div className="project-name">
          {item.rename && (
            <CustomInput
              defaultValue={rename.newName}
              id="renameProject"
              keyPressPayload={item}
              label="Project Name"
              onChange={rename.setNewName}
              onDiscard={rename.handleCancel}
              onKeyPress={rename.handleAccept}
              placeholder="Project Name"
            />
          )}
          {!item.rename && <Link to={`/projects/${item._id}/preview`}>{item.name}</Link>}
        </div>
      ),
      perseusId: item.perseusHashId || (
        <Popover content="Not pushed to Personalized" id="id-not-available">
          <div className="perseus-id-not-found">ID not available</div>
        </Popover>
      ),
      setFavorite: (
        <Icon
          size="20"
          color="green"
          name={item.favorite ? 'star-fill' : 'star'}
          onClick={() => setCurrentProjectAsFavorite(item)}
        />
      ),
    }),
    [
      archive.handleOpen,
      duplicate.handleOpen,
      handleRedirect,
      rename.handleAccept,
      rename.handleCancel,
      rename.handleRename,
      rename.newName,
      rename.setNewName,
      setCurrentProjectAsFavorite,
    ],
  );

  return {
    getTableRow,
  };
};
export default useTable;
