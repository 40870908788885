import React from 'react';

import { HALF_KEYFRAME_WIDTH } from '../../../../constants/timeline';
import { FRAME_BOTTOM, FRAME_TOP } from './constants';
import Keyframe from './Keyframe';
import { getPointFromKeyframe } from './utilities';

import './FrameWrapper.scss';

const getPointsTemplate = (prev, next, isEven = true) => {
  const edgePoints = [
    `${prev},${FRAME_BOTTOM} ${next},${FRAME_BOTTOM} ${next},${FRAME_TOP}`,
    `${prev},${FRAME_TOP} ${prev},${FRAME_BOTTOM} ${next},${FRAME_TOP}`,
  ];
  const betweenPoints = [
    `${prev},${FRAME_BOTTOM} ${prev},${FRAME_TOP} ${next},${FRAME_BOTTOM}`,
    `${prev},${FRAME_TOP} ${next},${FRAME_BOTTOM} ${next},${FRAME_TOP}`,
  ];
  return isEven ? edgePoints : betweenPoints;
};

const PolygonPair = ({ prev, next, isEven }) => {
  const [firstPolygonPoints, secondPolygonPoints] = getPointsTemplate(prev, next, isEven);
  return (
    <>
      <polygon opacity=".50" fill="#F99E47" points={firstPolygonPoints} />
      <polygon opacity=".10" fill="#F99E47" points={secondPolygonPoints} />
    </>
  );
};

export const Frame = ({ keyframes, onPointMouseDown, onPointMouseUp, zoomConfig }) => {
  const { msInOnPixel, width } = zoomConfig;

  return (
    <svg width={width + 20} height="14px" xmlns="http://www.w3.org/2000/svg">
      {keyframes.map((k, index) => {
        const point = getPointFromKeyframe(k, msInOnPixel);
        const nextKeyframe = keyframes[index + 1];
        const nextPoint = getPointFromKeyframe(nextKeyframe, msInOnPixel);
        return (
          nextKeyframe && (
            <PolygonPair
              key={k.id}
              prev={point + HALF_KEYFRAME_WIDTH}
              next={nextPoint + HALF_KEYFRAME_WIDTH}
              isEven={index % 2 === 0}
            />
          )
        );
      })}
      {keyframes.map(k => {
        return (
          <Keyframe
            key={k.id}
            keyframe={k}
            onMouseDown={onPointMouseDown}
            onMouseUp={onPointMouseUp}
            msInOnPixel={msInOnPixel}
          />
        );
      })}
    </svg>
  );
};
