export const TEMPLATES_FOLDER = 'https://eyeview-tmp.s3-us-west-2.amazonaws.com/prisma/templates/';

// This is not used by the chatbot. It's just to know how to process the user input before sending it.
export const MESSAGE_TYPE = {
  DEFAULT: 'DEFAULT', // A normal message that should be sent as it is.
  CUT_AND_INPAINT: 'CUT_AND_INPAINT', // The content of the message should be sent as a slot event.
  AD_FROM_SCRATCH: 'AD_FROM_SCRATCH', // The content of the message should be sent as a slot event.
};

export const SENDER_TYPE = {
  BOT: 'BOT',
  USER: 'USER',
};

export const DISPLAY_TYPE = {
  CAROUSEL: 'carousel',
};

export const EVENT_TYPE = {
  SLOT: 'slot',
};

export const SLOTS = {
  WIDTH: 'image_width',
  HEIGHT: 'image_height',
  IMAGE_PATH: 'image_path',
  MASK_PATH: 'mask_path',
  USER_PROMPT: 'user_prompt',
  GENERATE_IMAGE_PROMPT: 'generate_image_prompt',
};

export const EMPTY_SLOTS = Object.values(SLOTS).reduce((acc, name) => ({ ...acc, [name]: null }), {});

export const CODES = {
  [MESSAGE_TYPE.AD_FROM_SCRATCH]: '/ad_from_scratch',
  [MESSAGE_TYPE.CUT_AND_INPAINT]: '/cut_and_inpaint',
};
