import { ANCHORS_X, ANCHORS_Y, UNITS } from '../constants/objects.js';

/**
 * Given a left value that was built for a specific banner width,
 * gets the relative value to a new banner width, depending on originX and the unit of the value.
 * If unit is in pixels, calculates the new left using originX.
 * If unit is in percentage, calculates the value based on banners relation.
 * @param {number} builtLeft - Original left.
 * @param {string} originX - Horizontal origin: 'left', 'center' or 'right'.
 * @param {number} bannerWidth - New banner width.
 * @param {number} builtBannerWidth - Original banner width.
 * @param {string} unit - Pixels or percentage.
 * @return {number} Relative left to the new banner width.
 */
export function getRelativeLeft(builtLeft, originX, bannerWidth, builtBannerWidth, unit) {
  if (unit === UNITS.PERCENTAGE) {
    const relation = bannerWidth / builtBannerWidth;
    return Math.round(builtLeft * relation);
  }
  let left = builtLeft;
  if (originX === ANCHORS_X.CENTER) {
    // get anchor position for built width
    left -= builtBannerWidth / 2;
    // get actual position for new banner width
    left += bannerWidth / 2;
  } else if (originX === ANCHORS_X.RIGHT) {
    // get anchor position for built width
    left = builtBannerWidth - builtLeft;
    // get actual position for new banner width
    left = bannerWidth - left;
  }
  return Math.round(left);
}

/**
 * Given a top value that was built for a specific banner height,
 * gets the relative value to a new banner height, depending on originY and the unit of the value.
 * If unit is in pixels, calculates the new top using originY.
 * If unit is in percentage, calculates the value based on banners relation.
 * @param {number} builtTop - Original top.
 * @param {string} originY - Vertical origin: 'top', 'center' or 'bottom'.
 * @param {number} bannerHeight - New banner height.
 * @param {number} builtBannerHeight - Original banner height.
 * @param {string} unit - Pixels or percentage.
 * @return {number} Relative top to the new banner height.
 */
export function getRelativeTop(builtTop, originY, bannerHeight, builtBannerHeight, unit) {
  if (unit === UNITS.PERCENTAGE) {
    const relation = bannerHeight / builtBannerHeight;
    return Math.round(builtTop * relation);
  }
  let top = builtTop;
  if (originY === ANCHORS_Y.CENTER) {
    // get anchor position for built height
    top -= builtBannerHeight / 2;
    // get actual position for new banner height
    top += bannerHeight / 2;
  } else if (originY === ANCHORS_Y.BOTTOM) {
    // get anchor position for built height
    top = builtBannerHeight - builtTop;
    // get actual position for new banner height
    top = bannerHeight - top;
  }
  return Math.round(top);
}

/**
 * Given a scale value that was built for a specific banner size,
 * gets the relative value to a new banner size, depending on the unit of the value.
 * If unit is in percentage, return the calculated value based on banners relation.
 * Else, returns the same value.
 * @param {number} builtScale - Original scale.
 * @param {number} bannerSize - New banner size.
 * @param {number} builtBannerSize - Original banner size.
 * @param {string} unit - Pixels or percentage.
 * @return {number} Relative scale value to the new banner size.
 *  */
export function getRelativeScale(builtScale, bannerSize, builtBannerSize, unit) {
  if (unit === UNITS.PERCENTAGE) {
    const relation = bannerSize / builtBannerSize;
    return builtScale * relation;
  }
  return builtScale;
}

/**
 * Calculates the best aspect ratio based on the device width and height.
 *
 * @param {number} deviceWidth - The width of the device screen.
 * @param {number} deviceHeight - The height of the device screen.
 * @param {array} aspectRatio - An array of aspect ratio objects.
 * @returns {object} - The aspect ratio object with the closest match to the device's aspect ratio.
 */
export function getBestAspectRatio(deviceWidth, deviceHeight, aspectRatios) {
  const deviceRelation = deviceWidth / deviceHeight;
  let bestAspectRatio;
  aspectRatios.forEach(aspectRatio => {
    if (!bestAspectRatio) {
      bestAspectRatio = aspectRatio;
    } else {
      const bestRelation = bestAspectRatio.width / bestAspectRatio.height;
      const relationTwo = aspectRatio.width / aspectRatio.height;
      if (Math.abs(bestRelation - deviceRelation) > Math.abs(relationTwo - deviceRelation)) {
        bestAspectRatio = aspectRatio;
      }
    }
  });
  return bestAspectRatio;
}
