import AspectRatio from './AspectRatio';

class Page {
  constructor(page = {}) {
    this.id = page.id || 1;
    this.name = page.name || 'Start';
    this.aspectRatios = (page.aspectRatios || []).map(aspectRatio => new AspectRatio(aspectRatio));
    this.layers = page.layers || [];
    this.scenes = page.scenes || [];
  }

  isLocked() {
    return this.aspectRatios.length === 0;
  }
}

export default Page;
