import { useCallback, useState } from 'react';

import { useRootStore } from 'store';

import { STATUS } from 'containers/Projects/utils';
import { NOTIFY_MESSAGES } from 'utils';
import { validateProjectName } from 'utils/validations';
import { redirectToPersonalizeUpload } from 'utils/parentWindow';
import { notifyError, notifySuccess } from 'utils/notifications';

const useDotsMenu = ({ filters, setStatus }) => {
  const {
    projectSizes: { cloneProjectSizes },
    projects: {
      cancelProjectRenameInput,
      copyProjectAssets,
      duplicateProject,
      getProjects,
      moveProjectToArchive,
      showProjectRenameInput,
      updateProjectName,
    },
  } = useRootStore();

  const [projectNewName, setProjectNewName] = useState(null);
  const [selectedProject, setSelectedProject] = useState(null);
  const [showArchiveProjectModal, setShowArchiveProjectModal] = useState(false);
  const [showDuplicateProjectModal, setShowDuplicateProjectModal] = useState(false);

  const handleRename = useCallback(
    async ({ _id, name }) => {
      await setStatus(STATUS.LOADING);

      await setProjectNewName(name);
      await showProjectRenameInput(_id);

      setStatus(STATUS.SUCCESS);
    },
    [setStatus, showProjectRenameInput],
  );

  const handleRenameCancel = useCallback(
    async ({ _id }) => {
      await cancelProjectRenameInput(_id);
    },
    [cancelProjectRenameInput],
  );

  const handleRenameAccept = useCallback(
    async item => {
      const projectName = projectNewName.trim();

      const validation = validateProjectName(projectName);
      if (!validation.valid) {
        notifyError(validation.error);
        return;
      }

      await setStatus(STATUS.LOADING);

      try {
        await updateProjectName({
          _id: item._id,
          name: projectName,
        });

        notifySuccess(NOTIFY_MESSAGES.PROJECT_RENAMED);

        getProjects(filters);
      } catch (e) {
        notifyError(e?.message?.includes('duplicate key') ? NOTIFY_MESSAGES.PROJECT_EXISTS : NOTIFY_MESSAGES.ERROR);

        setStatus(STATUS.SUCCESS);
      }
    },
    [setStatus, updateProjectName, projectNewName, getProjects, filters],
  );

  const rename = {
    newName: projectNewName,
    setNewName: setProjectNewName,
    handleRename,
    handleAccept: handleRenameAccept,
    handleCancel: handleRenameCancel,
  };

  const handleDuplicateOpen = useCallback(
    item => {
      setSelectedProject(item);
      setShowDuplicateProjectModal(!showDuplicateProjectModal);
    },
    [showDuplicateProjectModal],
  );

  const handleDuplicateAccept = useCallback(async () => {
    await setStatus(STATUS.LOADING);

    try {
      const duplicatedProject = await duplicateProject(selectedProject, filters.userId);
      await cloneProjectSizes(selectedProject._id, duplicatedProject._id);
      await copyProjectAssets(selectedProject._id, duplicatedProject._id);

      notifySuccess(NOTIFY_MESSAGES.PROJECT_DUPLICATED);

      getProjects(filters);
    } catch (e) {
      notifyError(NOTIFY_MESSAGES.ERROR);

      setStatus(STATUS.SUCCESS);
    }

    setSelectedProject(null);
    setShowDuplicateProjectModal(false);
  }, [selectedProject, cloneProjectSizes, copyProjectAssets, duplicateProject, filters, getProjects, setStatus]);

  const duplicate = {
    showModal: showDuplicateProjectModal,
    setShowModal: setShowDuplicateProjectModal,
    handleOpen: handleDuplicateOpen,
    handleAccept: handleDuplicateAccept,
  };

  const handleArchiveOpen = useCallback(
    item => {
      setSelectedProject(item);
      setShowArchiveProjectModal(!showArchiveProjectModal);
    },
    [showArchiveProjectModal],
  );

  const handleArchiveAccept = useCallback(async () => {
    await setStatus(STATUS.LOADING);
    const result = await moveProjectToArchive(selectedProject);
    if (result.error) {
      notifyError(result.message);
    } else {
      notifySuccess(NOTIFY_MESSAGES.PROJECT_ARCHIVED);
      getProjects(filters);
    }
    setStatus(STATUS.SUCCESS);
    setSelectedProject(null);
    setShowArchiveProjectModal(false);
  }, [selectedProject, filters, getProjects, moveProjectToArchive, setStatus]);

  const archive = {
    showModal: showArchiveProjectModal,
    setShowModal: setShowArchiveProjectModal,
    handleOpen: handleArchiveOpen,
    handleAccept: handleArchiveAccept,
  };

  const handleRedirect = useCallback(({ perseusHashId }) => {
    redirectToPersonalizeUpload(perseusHashId);
  }, []);

  return {
    archive,
    duplicate,
    handleRedirect,
    rename,
    selectedProject,
  };
};

export default useDotsMenu;
