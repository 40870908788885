import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { IconButton } from '@akiunlocks/perseus-ui-components';

import './PrismaIconButton.scss';

const PrismaIconButton = ({
  icon,
  iconSize,
  variant,
  rounded,
  className,
  tooltipMessage,
  tooltipPosition,
  ...rest
}) => {
  const buttonsClasses = classnames('prisma-icon-button', className);
  return (
    <IconButton
      {...rest}
      icon={icon}
      iconSize={iconSize}
      variant={variant}
      className={buttonsClasses}
      tooltipMessage={tooltipMessage}
      tooltipPosition={tooltipPosition}
    />
  );
};

PrismaIconButton.propTypes = {
  /** Name of icon in kebab-case, see Bootstrap Icons page */
  icon: PropTypes.string.isRequired,
  /** Modify size only if required */
  iconSize: PropTypes.number,
  /** Name of the variant, see Bootstrap Icons page */
  variant: PropTypes.string,
  /** If true button will appear as a circular button */
  rounded: PropTypes.bool,
  /** Additional className */
  className: PropTypes.string,
  /** Message to show in the tooltip */
  tooltipMessage: PropTypes.string,
  /** The tooltip position */
  tooltipPosition: PropTypes.string,
};

PrismaIconButton.defaultProps = {
  iconSize: 16,
  rounded: false,
  className: '',
  tooltipMessage: '',
  tooltipPosition: 'top',
};

export default PrismaIconButton;
