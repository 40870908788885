export const sizesPath = '/api/v1/sizes';

export const sizesMethods = ['find', 'get', 'create', 'patch'];

export const sizesClient = client => {
  const connection = client.get('connection');

  client.use(sizesPath, connection.service(sizesPath), {
    methods: sizesMethods,
  });
};
