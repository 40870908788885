import React, { useEffect } from 'react';

import { initLogRocket } from './utils/log';

import './App.scss';
import '@akiunlocks/perseus-ui-components/dist/index.css';
import { useKeycloak } from '@react-keycloak/web';
import Routes from 'routes';
import { useRootStore } from 'store';
import { postBlurMessage, postFocusMessage } from 'utils/parentWindow';

function App() {
  const { keycloak, initialized } = useKeycloak();
  const { users: usersStore } = useRootStore();

  useEffect(() => {
    // There is a bug that affects our animation easing dropdown. When it is open, right padding is applied to the body,
    // this causes the canvas to resize and deselect the active layer. A workaround for the moment is to set padding to 0.
    // https://github.com/JedWatson/react-select/issues/5342
    document.body.classList.add('body-padding');

    initLogRocket();

    window.addEventListener('focus', postFocusMessage);
    window.addEventListener('blur', postBlurMessage);

    return () => {
      window.removeEventListener('focus', postFocusMessage);
      window.removeEventListener('blur', postBlurMessage);
    };
  }, []);

  useEffect(() => {
    const loadUserInfo = async () => {
      try {
        const userInfo = await keycloak.loadUserInfo();
        usersStore.setUserInfo(userInfo);
      } catch (e) {
        console.error('An error has occurred while loading user info', e);
      }
    };

    if (initialized && keycloak.authenticated) {
      usersStore.setAccessToken(keycloak.token);
      loadUserInfo();
    }
  }, [initialized, keycloak.authenticated, keycloak.token, usersStore, keycloak]);

  return <Routes />;
}

export default App;
