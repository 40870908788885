import PropTypes from 'prop-types';
import React from 'react';
import { ButtonGroup } from '@akiunlocks/perseus-ui-components';

import PrismaIconButton from '../PrismaIconButton';

const ExclusiveButtons = ({ allowUnselect, buttons, className, propValue, onChange = () => {} }) => {
  return (
    <ButtonGroup className={className}>
      {buttons.map(({ icon, tooltip, value }) => (
        <PrismaIconButton
          key={value}
          active={propValue === value}
          icon={icon}
          onClick={() => onChange(allowUnselect && value === propValue ? undefined : value)}
          tooltipMessage={tooltip}
          variant="primary-alt"
        />
      ))}
    </ButtonGroup>
  );
};

ExclusiveButtons.propTypes = {
  /* Allow the user to unselect the button */
  allowUnselect: PropTypes.bool,
  /* Array of buttons to render */
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string,
      tooltip: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  /* Additional className */
  className: PropTypes.string,
  /* Function to call when the button is clicked */
  onChange: PropTypes.func,
  /* The value of the button that is active */
  propValue: PropTypes.any,
};

ExclusiveButtons.defaultProps = {
  allowUnselect: false,
  buttons: [],
  className: '',
  onChange: () => {},
  propValue: undefined,
};

export default ExclusiveButtons;
