import { animationTypes } from '@prisma/lib/src/utils/types';
import { PRISMA_GROUP, PRISMA_MASK } from '@prisma/lib/src/constants';
import { getNearKeyframes } from '@prisma/lib/src/utils/animations';
import { getPositionFromActiveSelection, getUnrotatedPosition } from '@prisma/lib/src/utils/helpers';
import { matchRetinaScaling } from '@prisma/lib/src/utils/layers';

import { CLONE_OFFSET, LAYERS_TAB, GROUP_DISABLED_ANIMATIONS } from 'constants';

import { TIMELINE_STEP } from 'components/TimeLineScene/constants';

/**
 * Finds a single mask from an array of objects
 * @param {array} objects - Objects
 * @return {object} Mask object if found, else undefined
 */
export function findMask(objects) {
  return objects?.find(({ type }) => type === PRISMA_MASK);
}

/**
 * Finds a single mask object in a group
 * @param {object} group - Group object
 * @return {object} Mask object if found, else undefined
 */
export function findMaskInGroup(group) {
  return group?.objects?.find(({ type }) => type === PRISMA_MASK);
}

/**
 * Finds a single group from an array of objects
 * @param {array} objects - Objects
 * @return {object} Group object if found, else undefined
 */
export function findGroup(objects) {
  return objects?.find(({ type }) => type === PRISMA_GROUP);
}

/**
 * Gets groups from an array of objects
 * @param {array} objects - Objects
 * @return {array} Array of groups
 */
export function getGroups(objects) {
  if (!objects) {
    return [];
  }
  return objects.filter(({ type }) => type === PRISMA_GROUP);
}

/**
 * Gets group layers.
 * @param {object} groupLayer - Group layer.
 * @param {array} layers - All the layers.
 * @param {boolean} includeMask - Whether to include the mask child or not.
 * @return {array} Array of layers.
 */
export function getGroupLayers(groupLayer, layers, includeMask = false) {
  if (groupLayer?.type !== PRISMA_GROUP) {
    return [];
  }
  const group = groupLayer.target;
  const childrenIds = includeMask ? group.objectIds : group.objectIds.filter(id => id !== group.maskId);
  return layers.filter(({ id }) => childrenIds.includes(id));
}

/**
 * Given a group and its clone, sets the correct properties for the cloned mask.
 * @param {object} group - Group object.
 * @param {object} clonedGroup - Cloned group object.
 */
export function setClonedMaskProperties(group, clonedGroup) {
  const originalMask = findMaskInGroup(group);
  const newMask = findMaskInGroup(clonedGroup);
  const isActiveSelection = !!originalMask.group;
  const { left, top } = isActiveSelection ? getPositionFromActiveSelection(originalMask) : originalMask;
  newMask.left = left + CLONE_OFFSET;
  newMask.top = top + CLONE_OFFSET;
  newMask.width = originalMask.width;
  newMask.height = originalMask.height;
  newMask.scaleX = originalMask.scaleX;
  newMask.scaleY = originalMask.scaleY;
  newMask.angle = originalMask.angle;
}

/**
 * Given a coordinate and an array of group objects, finds the object that matches with the coordinate.
 * @param {object} point - Point {x,y}
 * @param {object} groupObjects - Array of objects
 * @return {object} Object if found, else undefined
 */
export function findObjectInGroup(point, groupObjects) {
  // get targets matching the point (exclude group)
  const targets = groupObjects.filter(obj => {
    const { tl, tr, br, bl } = obj.oCoords;
    const vertices = [tl, tr, br, bl];
    return ![PRISMA_GROUP].includes(obj.type) && pointIsInPolygon(point, vertices);
  });

  // check for regular elements first
  const objects = targets.filter(obj => obj.type !== PRISMA_MASK);
  if (objects.length) {
    // get element with higher index (last in array)
    return objects[objects.length - 1];
  }

  // return mask if it is inside the coordinates, else undefined
  return targets.find(({ type }) => type === PRISMA_MASK);
}

/**
 * Checks if point is inside a polygon (array of points)
 * https://stackoverflow.com/questions/22521982/check-if-point-is-inside-a-polygon/29915728#29915728
 * @param {object} point - Point {x,y}
 * @param {array} vertices - Array of points
 * @return {boolean} True if it is inside
 */
export function pointIsInPolygon(point, vertices) {
  const { x, y } = point;

  let isInside = false;
  for (let i = 0, j = vertices.length - 1; i < vertices.length; j = i++) {
    const xi = vertices[i].x;
    const yi = vertices[i].y;
    const xj = vertices[j].x;
    const yj = vertices[j].y;

    // eslint-disable-next-line no-mixed-operators
    const intersect = yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
    if (intersect) {
      isInside = !isInside;
    }
  }

  return isInside;
}

/**
 * Filters pasted keyframes and returns a valid array that can be pasted in the destination layer.
 * @param {*} keyframes - Keyframes from clipboard.
 * @param {object} destinationLayer - Destination layer.
 * @return {array} Valid keyframes to paste
 */
export function filterPastedKeyframes(keyframes, destinationLayer) {
  let keyframesToPaste = [];
  if (!destinationLayer || destinationLayer.type === PRISMA_MASK || !Array.isArray(keyframes) || !keyframes.length) {
    return keyframesToPaste;
  }

  // if destination layer is group and pasted keyframes are disabled, filter them
  keyframesToPaste = keyframes.filter(keyframe => {
    if (destinationLayer.type !== PRISMA_GROUP) {
      return true;
    }
    return !GROUP_DISABLED_ANIMATIONS.includes(keyframe.type);
  });

  return keyframesToPaste;
}

/**
 * Finds animation in a layer. Omits group animations.
 * @param {object} layer - Layer.
 * @param {string} animationType - Animation type.
 * @return {object} Animation if exists, else undefined.
 */
export function findAnimationByType(layer, animationType) {
  return layer?.animations?.find(a => a.type === animationType && !a.isGroupAnimation);
}

/**
 * Calculates and set keyframe times based on current time.
 * @param {array} keyframes - Keyframes.
 * @param {number} currentTime - Current position time.
 */
export function setKeyframeTimesFromCurrentTime(keyframes, currentTime) {
  if (!keyframes?.length) {
    return;
  }
  // sort by time
  keyframes.sort((a, b) => a.time - b.time);
  // recalculate times based on current position
  const timeDiff = Math.round(currentTime) - keyframes[0].time;
  keyframes.forEach(keyframe => {
    keyframe.time = keyframe.time + timeDiff;
  });
}

/**
 * Sets layer id to the keyframes sent as param.
 * @param {array} keyframes - Keyframes.
 * @param {number} layerId - New layer id.
 */
export function setKeyframesLayerId(keyframes, layerId) {
  keyframes?.forEach(keyframe => {
    keyframe.layerId = layerId;
  });
}

/**
 * Sets position properties to keyframes if type is rotation.
 * @param {array} keyframes - Keyframes.
 * @param {object} layer - Current layer.
 */
export function setKeyframesPositionIfRotation(keyframes, layer) {
  if (keyframes[0]?.type === animationTypes.rotation) {
    const positionAnimation = findAnimationByType(layer, animationTypes.position);
    if (positionAnimation) {
      // if position animation is found, use the position of the nearest position keyframe (already unrotated)
      keyframes.forEach(keyframe => {
        const { previous, current, next } = getNearKeyframes(keyframe.time, positionAnimation.keyframes);
        const positionKeyframe = current || previous || next;
        keyframe.properties = {
          ...keyframe.properties,
          ...positionKeyframe.properties,
        };
      });
    } else {
      // if no position animation, use the unrotated position of the object in the layer
      const unrotatedPosition = getUnrotatedPosition(layer.target);
      keyframes.forEach(keyframe => {
        keyframe.properties = {
          rotation: keyframe.properties.rotation,
          ...unrotatedPosition,
        };
      });
    }
  }
}

/**
 * Gets the closest keyframe according to the specified time.
 * @param {array} keyframes - Keyframes.
 * @param {number} time - Time to search closest keyframe.
 * @return {object} Closest keyframe.
 */
export function findClosestKeyframe(keyframes, time) {
  return keyframes.reduce((prev, curr) => (Math.abs(curr.time - time) < Math.abs(prev.time - time) ? curr : prev), {
    time: Infinity,
  });
}

/**
 * Checks if the keyframe falls near to specified time.
 * @param {object} keyframe - Keyframe.
 * @param {number} time - Time to compare.
 * @param {number} gap - Gap between the keyframe and the specified time.
 * @return {object} True if the keyframe is near, else false.
 */
export function isKeyframeFar(keyframe, time, gap = TIMELINE_STEP - 1) {
  return keyframe.time < time - gap || keyframe.time > time + gap;
}

/**
 * Returns the timeline tab name for a given scene number or the layers tab if no scene number is provided.
 * @param {number} sceneNumber
 * @returns
 */
export function getTimelineTabName(sceneNumber) {
  if (!sceneNumber) {
    return LAYERS_TAB;
  }
  return `scene_${sceneNumber}`;
}

/**
 * Returns the retina scaling factor.
 * @param {string} imageUrl - URL of the image
 * @returns {number} Retina scaling factor
 */
export function getRetinaScalingFactor(imageUrl) {
  const splitImageUrl = imageUrl.split('/');
  const filename = splitImageUrl[splitImageUrl.length - 1];
  const matchRegex = matchRetinaScaling(filename);
  return matchRegex ? Number(matchRegex[1]) : 1;
}

/**
 * Returns the keyframes grouped by animation type.
 * @param {array} keyframes - Keyframes
 * @returns {array} Keyframes grouped by animation type
 */
export function groupKeyframesByType(keyframes) {
  return keyframes.reduce((acc, keyframe) => {
    const { type } = keyframe;
    const animationType = acc.find(({ animationType }) => animationType === type);
    if (animationType) {
      animationType.keyframes.push(keyframe);
    } else {
      acc.push({ animationType: type, keyframes: [keyframe] });
    }
    return acc;
  }, []);
}

/**
 * Splits the given layers into two groups based on whether they have a groupId or not, meaning that they are in the first level, or they are children of a group.
 * @param {Array} objects - The layers to split.
 * @returns {Array} An array of two arrays, the first containing layers without a groupId, the second containing layers with a groupId.
 */
export function splitLayersByLevel(objects) {
  return objects.reduce(
    (acc, obj) => {
      if (obj.groupId) {
        acc[1].push(obj);
      } else {
        acc[0].push(obj);
      }
      return acc;
    },
    [[], []],
  );
}

/**
 * Creates and sets dataTransfer drag image for multiple assets drag and drop.
 * @param {object} event - Drag event.
 */
export function setMultiAssetsDragImage(event) {
  const { backgroundColor, width } = window.getComputedStyle(event.target);

  // create drag image
  const dragImage = document.createElement('div');
  dragImage.textContent = 'Multiple assets...';
  dragImage.style.backgroundColor = backgroundColor;
  dragImage.style.width = width;
  dragImage.style.paddingLeft = '0.5rem';

  // set body overflow hidden to avoid scrolling
  const { overflow } = window.getComputedStyle(document.body);
  document.body.style.overflow = 'hidden';
  document.body.appendChild(dragImage);
  event.dataTransfer.setDragImage(dragImage, 0, 10);

  // reset overflow property and delete image
  setTimeout(() => {
    document.body.style.overflow = overflow;
    document.body.removeChild(dragImage);
  }, 0);
}

/**
 * Updates the properties of layers that are in pages using a given imageUrl with the data of a new image.
 * @param {array} pages - Pages.
 * @param {string} imageUrl - Image url to be replaced in properties.
 * @param {object} newImage - New image properties.
 * @return {boolean} True if any layer was updated, else false.
 */
export function replaceImageinPages(pages, imageUrl, newImage) {
  let updated = false;
  pages.forEach(({ layers }) => {
    if (replaceImageInLayersProperties(layers, imageUrl, newImage)) {
      updated = true;
    }
  });
  return updated;
}

/**
 * Updates the properties of layers using a given imageUrl with the data of a new image.
 * @param {array} layers - Layers.
 * @param {string} imageUrl - Image url to be replaced in properties.
 * @param {object} newImage - New image properties.
 * @return {boolean} True if any layer was updated, else false.
 */
export function replaceImageInLayersProperties(layers, imageUrl, newImage) {
  const layersToReplace = layers.filter(({ properties }) => properties.imageUrl === imageUrl);
  if (!layersToReplace.length) {
    return false;
  }
  layersToReplace.forEach(({ properties }) => {
    properties.imageUrl = newImage.imageUrl;
    if (newImage.height && newImage.width) {
      properties.height = newImage.height;
      properties.width = newImage.width;
    }
    if (newImage.timestamp) {
      properties.timestamp = newImage.timestamp;
    }
  });
  return true;
}

/**
 * Removes image layers using a given imageUrl from a list of pages.
 * If the layer to remove was the last item of a group, also removes the group.
 * @param {array} pages - Pages.
 * @param {string} imageUrl - Image url to find the layers to remove.
 */
export function removeImageLayerFromPages(pages, imageUrl) {
  pages.forEach(page => {
    page.layers = removeImageLayerFromLayers(page.layers, imageUrl);
  });
}

/**
 * Removes image layers using a given imageUrl from a list of layers.
 * If the layer to remove was the last item of a group, also removes the group.
 * @param {array} layers - Layers.
 * @param {string} imageUrl - Image url to find the layers to remove.
 * @return {array} Updated layers.
 */
export function removeImageLayerFromLayers(layers, imageUrl) {
  const layerIdsToRemove = layers.filter(l => l.properties.imageUrl === imageUrl).map(l => l.id);
  if (!layerIdsToRemove.length) {
    return layers;
  }
  // remove layers by image url
  let updatedLayers = layers.filter(l => !layerIdsToRemove.includes(l.id));
  // if layer was part of a group, remove objectId from properties
  updatedLayers
    .filter(l => l.properties.objectIds)
    .forEach(l => {
      l.properties.objectIds = l.properties.objectIds.filter(id => !layerIdsToRemove.includes(id));
    });
  // get empty groups
  const emptyGroupIds = updatedLayers
    .filter(l => l.properties.objectIds && l.properties.objectIds.length === 1)
    .map(l => l.id);
  // remove empty groups and their masks
  updatedLayers = updatedLayers.filter(
    l => !emptyGroupIds.includes(l.id) && !emptyGroupIds.includes(l.properties.groupId),
  );
  return updatedLayers;
}

/**
 * Given an object, gets the left and top offset.
 * If offset is not found in the object, gets the one from canvas (for example for active selections).
 * @param {object} object - Object.
 * @return {object} { offsetLeft, offsetTop }
 */
export function getObjectOffset(object) {
  const offsetLeft = object.offsetLeft || object.canvas?.offsetLeft || 0;
  const offsetTop = object.offsetTop || object.canvas?.offsetTop || 0;
  return { offsetLeft, offsetTop };
}

/**
 * Counts the number of layers in all pages of a project size.
 * @param {object} projectSize
 * @returns {number} Number of layers in the project size.
 */
export function countLayersInProjectSize(projectSize) {
  return projectSize.pages.reduce((acc, { layers }) => acc + layers.length, 0);
}
