import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Dropdown } from '@akiunlocks/perseus-ui-components';

import { useRootStore } from 'store';

import { RULER_DROPDOWN_OPTIONS } from '../../../../constants';

import './ArtBoardControls.scss';

const RulerDropdown = observer(({ onSelect }) => {
  const { editor } = useRootStore();
  const { guidelineStore } = editor;

  const options = [
    RULER_DROPDOWN_OPTIONS.ADD_VERTICAL_LINE,
    RULER_DROPDOWN_OPTIONS.ADD_HORIZONTAL_LINE,
    guidelineStore.hidden ? RULER_DROPDOWN_OPTIONS.SHOW_LINES : RULER_DROPDOWN_OPTIONS.HIDE_LINES,
    guidelineStore.locked ? RULER_DROPDOWN_OPTIONS.UNLOCK_LINES : RULER_DROPDOWN_OPTIONS.LOCK_LINES,
    RULER_DROPDOWN_OPTIONS.CLEAR_LINES,
  ];

  return (
    <Dropdown
      className="dropdown"
      icon="pencil-fill"
      iconSize={8}
      items={options}
      onSelect={onSelect}
      size="xs"
      toggleVariant="secondary"
    />
  );
});

RulerDropdown.propTypes = {
  /** Action to call on dropdown option select */
  onSelect: PropTypes.func,
};

RulerDropdown.defaultProps = {
  onSelect: () => {},
};

export default RulerDropdown;
