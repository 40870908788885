/**
 * Given a value in bytes, formats it to its corresponding order of magnitude.
 * @param {number} bytes - Value in bytes.
 * @return {string} Formatted value.
 */
export function formatSize(bytes) {
  if (bytes === 0) {
    return '0.00 B';
  }
  const e = Math.floor(Math.log(bytes) / Math.log(1024));
  return (bytes / Math.pow(1024, e)).toFixed(2) + ' ' + ' kMGTP'.charAt(e) + 'B';
}
