import { makeAutoObservable, runInAction, toJS } from 'mobx';

import { SERVICES } from '@prisma/api';
import { Notification, notify } from '@akiunlocks/perseus-ui-components';
import { recalculateValues } from '@prisma/lib/src/utils/layers';

import { countLayersInProjectSize, removeImageLayerFromPages, replaceImageinPages } from './utilities';
import Page from 'models/Page';
import ProjectSize from 'models/ProjectSize';

class ProjectSizesStore {
  _service;
  _perseusService;
  _projectSize = null;
  _projectSizes = [];
  _projectSizesList = [];
  _unsavedChanges = false;

  constructor(root) {
    makeAutoObservable(this);
    this.root = root;
    this._service = root.restClient.service(SERVICES.projectSizes);
    this._perseusService = root.restClient.service(SERVICES.perseus);
  }

  get projectSize() {
    return toJS(this._projectSize);
  }

  get projectSizes() {
    return toJS(this._projectSizes);
  }

  get unsavedChanges() {
    return this._unsavedChanges;
  }

  setUnsavedChanges = unsavedChanges => {
    return (this._unsavedChanges = unsavedChanges);
  };

  setProjectSizes = projectSizes => {
    return (this._projectSizes = projectSizes);
  };

  get projectSizesList() {
    return toJS(this._projectSizesList);
  }

  getProjectSize = async _id => {
    this.root.editor.historyStore.clearHistory();

    const projectSize = await this._service.get(_id);

    runInAction(() => {
      this._projectSize = new ProjectSize(projectSize);
    });
  };

  setProjectSize = projectSize => {
    runInAction(() => {
      this._projectSize = projectSize ? new ProjectSize(projectSize) : projectSize; // sometimes projectSize is null
    });
  };

  getProjectSizes = async query => {
    const projectSizes = await this._service.find({ query });

    runInAction(() => {
      this._projectSizes = projectSizes;
    });
  };

  addProjectSizes = async data => {
    data.pages = [new Page()];
    return this._service.create(data);
  };

  updateProjectSize = async (_id, data) => {
    const projectSize = await this._service.patch(_id, data);

    runInAction(() => {
      this._projectSize = new ProjectSize(projectSize);
    });
  };

  cloneProjectSizes = async (projectId, duplicateId) => {
    return this._service.clone(
      {
        projectId: duplicateId,
      },
      { query: { projectId } },
    );
  };

  cloneProjectSize = async data => {
    // get and update responsive values in pages before duplicate
    const projectSize = await this._service.get(data.id);
    const { width, height } = projectSize;
    const sizeParts = data.size.split('x');
    const newHeight = Number(sizeParts[1]);
    const newWidth = Number(sizeParts[0]);
    data.pages = recalculateValues(projectSize.pages, width, height, newWidth, newHeight);

    return this._service.duplicate(data);
  };

  /**
   * Copies size from a source project to a destination project.
   * @param {string} sizeId - The id of the size that will be copied.
   * @param {string} sourceProjectId - The id of the source project from which size will be copied.
   * @param {string} destinationProjectId - The id of the destination project to which size will be copied.
   * @return {boolean} - Returns true if the copy was successful, false otherwise.
   */
  copyProjectSize = async (sizeId, sourceProjectId, destinationProjectId) => {
    try {
      // copy project size in mongo, and get the assets to copy and destination folder
      const { assetsData } = await this._service.copy({ sizeId, destinationProjectId });
      // copy assets to new folder in S3 and in project
      await this.root.projects.copyProjectAssets(sourceProjectId, destinationProjectId, assetsData);
    } catch (e) {
      notify('An error has occurred while copying composition. Please, try again.', Notification.TYPE.ERROR);
      return false;
    }
    return true;
  };

  /**
   * Imports project size from a source project to the destination project size.
   * @param {string} sourceSizeId - The id of the size that will be imported.
   * @param {string} sourceProjectId - The id of the source project from which size will be imported.
   * @param {string} destinationSizeId - The id of the destination size to which size will be imported.
   * @param {string} destinationProjectId - The id of the destination project to which size will be imported.
   * @return {boolean} - Returns true if the copy was successful, false otherwise.
   */
  importProjectSize = async (sourceSizeId, sourceProjectId, destinationSizeId, destinationProjectId) => {
    try {
      const { assetsData, result } = await this._service.importProjectSize({
        sourceSizeId,
        destinationSizeId,
        destinationProjectId,
      });
      const project = await this.root.projects.copyProjectAssets(sourceProjectId, destinationProjectId, assetsData);
      this.root.projects.setProject(project);
      this.root.projects.assets = project.assets;
      this.setProjectSize(result);
      return result;
    } catch (e) {
      notify('An error has occurred while importing composition. Please, try again.', Notification.TYPE.ERROR);
      return false;
    }
  };

  deleteProjectSize = async (id, perseusHashId) => {
    if (perseusHashId) {
      const activationVersionCheck = await this._perseusService.checkLiveActivationVersion({
        compositionId: id,
        perseusHashId,
      });
      if (activationVersionCheck.error === true) {
        return { error: true, message: activationVersionCheck.message }; // api error
      }
      if (activationVersionCheck.data === true) {
        return { error: true, message: 'This composition has an activation version LIVE and cannot be archived' };
      }
    }
    return this._service.patch(id, {
      deletedAt: new Date(),
    });
  };

  getTotalSize = async id => {
    const res = await this._service.getTotalSize({ id });
    return res?.totalSize || 0;
  };

  /**
   * For all the project sizes, updates the image layers using a given imageUrl with new image properties, then saves the project size.
   * @param {array} projectSizes - Project sizes to update.
   * @param {string} imageUrl - Current image url.
   * @param {object} newAsset - New asset properties.
   */
  updateImageLayersAndSaveProjectSizes = async (projectSizes, imageUrl, newAsset) => {
    // replace asset in all compositions and save
    return Promise.all(
      projectSizes.map(async size => {
        const updated = replaceImageinPages(size.pages, imageUrl, newAsset);
        if (updated) {
          await this._service.patch(size._id, size);
        }
      }),
    );
  };

  /**
   * Given a list of moved assets, and compositions, update the old image urls with the new ones.
   * @param {array} movedAssets - Moved assets. Each one should have the oldImageUrl and imageUrl properties.
   * @param {array} projectSizes - Project sizes to update.
   * @return {Promise}
   */
  replaceMovedAssetsInCompositions = (movedAssets, projectSizes) => {
    return Promise.all(
      projectSizes.map(async size => {
        const updatedArray = [];
        movedAssets.forEach(({ imageUrl, oldImageUrl }) => {
          const newAsset = { imageUrl };
          const updated = replaceImageinPages(size.pages, oldImageUrl, newAsset);
          updatedArray.push(updated);
        });
        if (updatedArray.some(updated => updated)) {
          await this._service.patch(size._id, size);
        }
      }),
    );
  };

  /**
   * For all the project sizes, removes the image layers using a given imageUrl, then saves the project size.
   * @param {array} projectSizes - Project sizes to update.
   * @param {string} imageUrl - Image url to remove.
   */
  removeImageLayerAndSaveProjectSizes = async (projectSizes, imageUrl) => {
    // remove asset in all compositions and save
    return Promise.all(
      projectSizes.map(async size => {
        const countBefore = countLayersInProjectSize(size);
        removeImageLayerFromPages(size.pages, imageUrl);
        if (countLayersInProjectSize(size) !== countBefore) {
          await this._service.patch(size._id, size);
        }
      }),
    );
  };
}

export default ProjectSizesStore;
