import React from 'react';
import { observer } from 'mobx-react';
import { Form, RadioButtonMatrix } from '@akiunlocks/perseus-ui-components';
import { ANCHORS_X, ANCHORS_Y } from '@prisma/lib/src/constants/objects';

import { useRootStore } from 'store';

import { getAnchorPosition } from 'utils/helpers';

import GroupStore from 'containers/Editor/stores/Group.store';
import MaskStore from 'containers/Editor/stores/Mask.store';

import PercentageInput from 'components/Sidebar/components/PercentageInput/PercentageInput';

import useActiveObjectProperties from '../useActiveObjectProperties';
import useGroupProperties from '../GroupProperties/GroupProperties.hooks';
import useMaskProperties from '../MaskProperties/MaskProperties.hooks';

import './AnchorPoints.scss';

const AnchorPoints = observer(() => {
  const { editor } = useRootStore();
  const { canvas } = editor;
  const store = editor.getActiveStore();

  const objectProperties = store instanceof GroupStore ? useGroupProperties() : useActiveObjectProperties(store);
  const { onPositionXChanged, onPositionYChanged, onUnitChange } = objectProperties;
  const { onAnchorChange } = store instanceof MaskStore ? useMaskProperties() : objectProperties;

  const left = store.unrotatedLeft;
  const originX = store.originX;
  const originY = store.originY;
  const top = store.unrotatedTop;

  const { x, y } = getAnchorPosition({
    originX,
    originY,
    left,
    top,
    canvas,
  });

  return (
    <Form.Group>
      <Form.Label>Reference Point</Form.Label>
      <div className="anchors-position">
        <RadioButtonMatrix
          name="anchor-points"
          onChange={onAnchorChange}
          x={originX}
          xValues={Object.values(ANCHORS_X)}
          y={originY}
          yValues={Object.values(ANCHORS_Y)}
        />
        <div className="values">
          <PercentageInput
            id="origin-x"
            label="X"
            prop="left"
            unit={store.leftUnit}
            controlProps={{
              placeholder: '',
              value: x,
              type: 'number',
              step: 1.0,
              onChange: onPositionXChanged,
            }}
            onUnitChange={onUnitChange}
          />
          <PercentageInput
            id="origin-y"
            label="Y"
            prop="top"
            unit={store.topUnit}
            controlProps={{
              placeholder: '',
              value: y,
              type: 'number',
              step: 1.0,
              onChange: onPositionYChanged,
            }}
            onUnitChange={onUnitChange}
          />
        </div>
      </div>
    </Form.Group>
  );
});

export default AnchorPoints;
