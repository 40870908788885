export const projectSizesPath = '/api/v1/projectSizes';

export const projectSizesMethods = [
  'find',
  'get',
  'create',
  'patch',
  'clone',
  'copy',
  'duplicate',
  'getTotalSize',
  'importProjectSize',
];

export const projectSizesClient = client => {
  const connection = client.get('connection');

  client.use(projectSizesPath, connection.service(projectSizesPath), {
    methods: projectSizesMethods,
  });
};
