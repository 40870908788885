import { forwardRef, useEffect } from 'react';
import { Form } from 'react-bootstrap';

export const FormControlInput = forwardRef(({ defaultValue, handleEnter, placeholder, type }, ref) => {
  useEffect(() => {
    ref.current.focus();
  }, [ref]);

  return (
    <Form.Control
      as="input"
      className="accordion-title-input"
      defaultValue={defaultValue}
      onKeyDown={handleEnter}
      placeholder={placeholder}
      ref={ref}
      type={type}
    />
  );
});
