import { action, makeAutoObservable, toJS } from 'mobx';
import { SERVICES } from '@prisma/api';
class SizesStore {
  _service;
  _sizes = [];

  constructor(root) {
    makeAutoObservable(this);

    this._service = root.restClient.service(SERVICES.sizes);
  }

  get sizes() {
    // return sizes sorted by width
    return toJS(this._sizes).sort((a, b) => parseInt(a.value) - parseInt(b.value));
  }

  getSizes = async () => {
    const sizes = await this._service.find();

    action(() => {
      this._sizes = sizes.map(({ name }) => ({
        value: name,
        label: name,
      }));
    })();
  };
}

export default SizesStore;
