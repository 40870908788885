import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { FormLabel, Stack } from 'react-bootstrap';

import { Input } from '@akiunlocks/perseus-ui-components';

import './LabeledInput.scss';

const LabeledInput = ({ className, controlProps, direction, gap, id, label, size }) => {
  const { value } = controlProps;
  const [internalValue, setInternalValue] = useState(value);

  useEffect(() => {
    setInternalValue(value);
  }, [value]);

  return (
    <Stack className={classnames('labeled-input-wrapper', size, className)} direction={direction} gap={gap}>
      <FormLabel className="label" htmlFor={id}>
        {label}
      </FormLabel>
      <Input
        id={id}
        size={size}
        controlProps={{
          ...controlProps,
          value: internalValue,
          onChange: e => {
            const newValue = e.target.value;
            setInternalValue(newValue);
            if (newValue.trim() !== '') {
              controlProps.onChange(e);
            }
          },
          onBlur: () => {
            if (!internalValue) {
              // to prevent empty input
              setInternalValue(value);
            }
          },
          className: classnames('labeled-input', controlProps.className),
        }}
      />
    </Stack>
  );
};

LabeledInput.propTypes = {
  /** Additional class */
  className: PropTypes.string,
  /** Input control props */
  controlProps: PropTypes.object,
  /** Stack direction */
  direction: PropTypes.oneOf(['horizontal', 'vertical']),
  /** Stack gap */
  gap: PropTypes.number,
  /** Input id */
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Input label */
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /** Input and label size */
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg']),
};

LabeledInput.defaultProps = {
  className: '',
  controlProps: {},
  direction: 'horizontal',
  gap: 2,
  id: '',
  label: '',
  size: 'xs',
};

export default React.memo(LabeledInput);
