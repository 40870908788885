import { PRISMA_MASK } from '@prisma/lib/src/constants';
import { getGroups, splitLayersByLevel } from '../utilities';

/**
 * Gets masks from an array of objects
 * @param {array} objects - Objects
 * @return {array} Array of masks
 */
export function getMasks(objects) {
  if (!objects) {
    return [];
  }
  return objects.filter(({ type }) => type === PRISMA_MASK);
}

/**
 * Gets the mask IDs from the given layers.
 * @param {Array} layersToFindMasks - The layers to find mask IDs in.
 * @returns {Set} A set of mask IDs.
 */
export function getMaskIds(layersToFindMasks) {
  return layersToFindMasks.reduce((maskIds, obj) => {
    if (obj.maskId) {
      maskIds.add(obj.maskId);
    } else if (obj.type === PRISMA_MASK) {
      maskIds.add(obj.id);
    }
    return maskIds;
  }, new Set());
}

/**
 * Sets the border of a mask.
 * @param {Object} mask - The mask to set the border of.
 * @param {boolean} showMask - Whether to show the mask or not.
 */
export function setMaskBorders(mask, showMask) {
  if (mask && showMask) {
    mask.setOptions({ borderColor: 'green', borderDashArray: [10, 10], borderScaleFactor: 1.5 });
  }
}

/**
 * Sets the visibility of objects in a group.
 * @param {Object} group - The group to set the visibility of objects in.
 * @param {boolean} showObjects - Whether to show the objects or not.
 */
export function setObjectsVisibility(group, showObjects) {
  if (group) {
    group.objects.forEach(obj => {
      obj.clipPath = showObjects ? null : group.clipPath;
    });
  }
}

/**
 * Draws the masks for the active layers, and also shows the layers even if they are outside layers.
 * Removes the mask borders if the mask is not selected, and hides the layers if the mask is not selected.
 */
export function drawMasks(allLayers, activeLayers) {
  // we split layers because if we have a group selected, we will have their children also selected, but we don't need to handle children masks
  const [firstLevel, otherLevel] = splitLayersByLevel(activeLayers);

  const maskIds = getMaskIds(firstLevel.length ? firstLevel : otherLevel);
  const allMasks = getMasks(allLayers);
  const allGroups = getGroups(allLayers);
  allMasks.forEach(mask => {
    const maskIsInSelection = maskIds.has(mask.id);
    setMaskBorders(mask, maskIsInSelection);
    const relatedGroup = allGroups.find(({ id }) => id === mask.groupId);
    setObjectsVisibility(relatedGroup, maskIsInSelection);
  });
}
