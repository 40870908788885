export const ASSET = 'asset';
export const FOLDER = 'folder';

export const ROOT_NODE = {
  id: '0',
  name: 'Assets',
  isRoot: true,
  type: FOLDER,
  children: [],
};

// similar to the one in api
export const FOLDERS = {
  ASSETS: 'assets/',
  THUMBNAILS: 'thumbnails/',
};

export const FILE_EXISTS = 'File already exists';
export const FOLDER_EXISTS = 'Folder already exists';
