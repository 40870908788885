import React from 'react';
import { Link } from 'react-router-dom';

export const Unauthorized = () => {
  return (
    <div className="m-8">
      <h1>401</h1>
      <h3>You are not authorized to access this page</h3>
      <Link to="/">Home</Link>
    </div>
  );
};
