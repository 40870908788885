// For more information about this file see https://dove.feathersjs.com/guides/cli/client.html
// eslint-disable-next-line import/no-extraneous-dependencies
// eslint-disable-next-line import/no-extraneous-dependencies
import rest from '@feathersjs/rest-client';
import { feathers } from '@feathersjs/feathers';
import authenticationClient from '@feathersjs/authentication-client';

// eslint-disable-next-line import/no-extraneous-dependencies
import axios from 'axios';

import { s3Client, s3Path } from './services/api/v1/s3/s3.shared.js';
import { perseusClient, perseusPath } from './services/api/v1/perseus/perseus.shared.js';
import { sizesClient, sizesPath } from './services/api/v1/sizes/sizes.shared.js';
import { projectsClient, projectsPath } from './services/api/v1/projects/projects.shared.js';
import { projectSizesClient, projectSizesPath } from './services/api/v1/projectSizes/projectSizes.shared.js';

/**
 * Returns a  client for the api app.
 *
 * @param connection The REST or Socket.io Feathers client connection
 * @param authenticationOptions Additional settings for the authentication client
 * @see https://dove.feathersjs.com/api/client.html
 * @returns The Feathers client application
 */
export const createClient = (connection, authenticationOptions = {}) => {
  const client = feathers();

  client.configure(connection);
  client.configure(authenticationClient(authenticationOptions));
  client.set('connection', connection);

  client.configure(s3Client);
  client.configure(sizesClient);
  client.configure(perseusClient);
  client.configure(projectsClient);
  client.configure(projectSizesClient);

  // Add authorization header on every request by using default authentication options
  // https://feathersjs.com/api/authentication/client.html#options
  client.hooks({
    before: {
      all: [
        async hookContext => {
          const { options } = client.authentication;
          const token = await client.authentication.getAccessToken();
          if (token) {
            const ctx = hookContext;
            ctx.params.headers = {
              [options.header]: `${options.scheme} ${token}`,
            };
          }
        },
      ],
    },
  });

  return client;
};

export const createRestClient = (baseUrl = undefined) => {
  const restClient = rest(baseUrl);
  return createClient(restClient.axios(axios));
};

export const SERVICES = {
  S3: s3Path,
  sizes: sizesPath,
  perseus: perseusPath,
  projects: projectsPath,
  projectSizes: projectSizesPath,
};
