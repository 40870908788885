import { CDN_URL, FONTS } from '../constants/fonts.js';

/**
 * Gets the font url from a given object.
 * @param {object} object - Data object.
 * @param {string} object.fontFileName - If font file name is defined, get it from CDN.
 * @param {string} object.fontFamily - If font file name is not present, try to get the url from FONTS list.
 * @return {string|undefined} Font url, or undefined if not found.
 */
export function getFontUrl(object) {
  if (object.fontFileName) {
    return `${CDN_URL}${object.fontFileName}`;
  }
  // this is for backwards compatibility only
  return FONTS.find(f => f.name === object.fontFamily)?.url;
}

/**
 * Loads font to the document.
 * @param {object} fontData - Font data object.
 * @param {string} fontData.name - Font name.
 * @param {string} fontData.text - Text to apply the font once loaded.
 * If there is no text, the font does not need to be loaded.
 * @param {string} fontData.url - Font url.
 * @param {function} callback - Function to be called after font is loaded.
 */
export function loadFontIfNeeded(fontData, callback) {
  if (!fontData.text) {
    callback(true);
    return;
  }
  const fontFile = new FontFace(fontData.name, `url(${fontData.url})`);
  document.fonts.add(fontFile);
  fontFile.load().finally(() => {
    const loaded = fontFile.status === 'loaded';
    callback(loaded);
  });
}
