/**
 * Calculates and returns the maximum width and height available based on the provided reference element.
 * @param {Object} containerRef - The React reference object to the container element.
 * @return {Object} An object containing the 'maxWidth' and 'maxHeight'.
 */
export function getMaxWidthAndHeight(containerRef) {
  const width = containerRef?.current?.clientWidth;
  const height = containerRef?.current?.clientHeight;
  const remInPx = parseInt(getComputedStyle(document.documentElement).fontSize, 10);
  return {
    maxWidth: width ? width - remInPx : undefined,
    maxHeight: height ? height - 4 * remInPx : undefined,
  };
}
