import { useCallback, useEffect, useRef, useState } from 'react';
import debounce from 'lodash/debounce';

import { createActiveSelection, fabricClone } from '@prisma/lib/src/utils/helpers';
import { ACTIVE_SELECTION, PRISMA_GROUP, PRISMA_GUIDELINE } from '@prisma/lib/src/constants';

import { useRootStore } from 'store';

import { DEFAULT_TIME_LINE_TAB, LAYERS_TAB, MAX_ZOOM, MIN_ZOOM } from 'constants';
import { findGroup } from './stores/utilities';
import { updateObjectLocks } from 'utils/helpers';
import { clip } from 'utils/numbers';

const REDRAW_DELAY = 100;

const useThrottle = (fn, wait) => {
  const timerId = useRef();
  const lastArgs = useRef();

  return useCallback(
    function (...args) {
      const waitFunc = () => {
        if (lastArgs.current) {
          fn.apply(this, lastArgs.current);
          lastArgs.current = null;
          timerId.current = setTimeout(waitFunc, wait);
        } else {
          timerId.current = null;
        }
      };

      if (!timerId.current) {
        fn.apply(this, args);
      } else {
        lastArgs.current = args;
      }

      if (!timerId.current) {
        timerId.current = setTimeout(waitFunc, wait);
      }
    },
    [fn, wait],
  );
};

const useEditor = () => {
  const { editor } = useRootStore();
  const { fontStore, timelineSceneStore } = editor;
  const timelineTab = editor.contextStore.getTimelineTab();
  const [lastPos, setLastPos] = useState(null);

  useEffect(() => {
    fontStore.fetchFonts();
    return () => {
      editor.canvas?.engine?.stop();
      editor.canvas?.discardActiveObject();
      editor.contextStore.setTimelineTab(DEFAULT_TIME_LINE_TAB);
      editor.timelineSceneStore.setCurrentTime(0);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const configureObjectLocks = useCallback(() => {
    if (editor.canvas && editor.activeObject && timelineTab !== DEFAULT_TIME_LINE_TAB) {
      if (editor.activeObject.type === ACTIVE_SELECTION) {
        const objects = editor.activeObject.getObjects();
        // if any group or group item, discard all
        if (findGroup(objects) || objects.some(o => o.groupId)) {
          updateObjectLocks(editor.activeObject, true);
          editor.canvas.discardActiveObject();
          editor.canvas.renderAll();
        } else {
          editor.activeObject.setOptions({
            lockRotation: true,
          });
        }
      } else {
        timelineSceneStore.setObjectLocksIfNeeded(editor.activeObject);
      }
    }
  }, [editor.canvas, editor.activeObject, timelineTab, timelineSceneStore]);

  const removeOverlayGuideline = useCallback(() => {
    if (editor.activeObject && editor.canvas.overlayImage && editor.activeObject.type === PRISMA_GUIDELINE) {
      const guideline = editor.canvas.overlayImage
        .getObjects()
        .find(o => o.type === PRISMA_GUIDELINE && o.id === editor.activeObject.id);
      if (guideline) {
        editor.canvas.overlayImage.removeWithUpdate(guideline);
      }
    }
  }, [editor.activeObject, editor.canvas]);

  const startDraggingCanvas = useCallback(
    e => {
      if (e.buttons === 4) {
        editor.canvas.setCursor('grabbing');
        setLastPos({ x: e.clientX, y: e.clientY });
      }
    },
    [editor.canvas],
  );

  const handleMouseDown = useCallback(
    ({ e }) => {
      // set the corresponding object lock when clicking on an object
      configureObjectLocks();

      // remove guideline from overlay images when it is clicked
      removeOverlayGuideline();

      // set last position when dragging with middle mouse button
      startDraggingCanvas(e);
    },
    [configureObjectLocks, removeOverlayGuideline, startDraggingCanvas],
  );

  const showGuidelineTooltip = useCallback(() => {
    if (editor.activeObject && editor.activeObject.type === PRISMA_GUIDELINE && !editor.activeObject.locked) {
      editor.activeObject.showTooltip();
    }
  }, [editor.activeObject]);

  const dragCanvas = useCallback(
    e => {
      if (e.buttons === 4 && lastPos) {
        editor.canvas.setCursor('grabbing');
        const viewport = editor.canvas.viewportTransform;
        viewport[4] += e.clientX - lastPos.x;
        viewport[5] += e.clientY - lastPos.y;
        editor.canvas.requestRenderAll();
        setLastPos({ x: e.clientX, y: e.clientY });
      }
    },
    [editor.canvas, lastPos],
  );

  const handleMouseMove = useCallback(
    ({ e }) => {
      // show tooltip when moving guidelines
      showGuidelineTooltip();

      // move canvas when dragging with middle mouse button
      dragCanvas(e);
    },
    [dragCanvas, showGuidelineTooltip],
  );

  const drawOverlayGuideline = useCallback(
    isClick => {
      if (isClick && editor.activeObject && editor.activeObject.type === PRISMA_GUIDELINE) {
        if (editor.canvas.overlayImage) {
          // clone guideline before adding as overlay because position is affected by the group
          editor.canvas.overlayImage.addWithUpdate(fabricClone(editor.activeObject));
        }
        editor.activeObject.clearTooltip();
        editor.canvas.discardActiveObject();
      }
    },
    [editor.activeObject, editor.canvas],
  );

  const stopDraggingCanvas = useCallback(() => {
    if (lastPos) {
      editor.canvas.redrawRulers();
      editor.canvas.setCursor('default');
      // recalculate new interaction for all objects
      editor.canvas.setViewportTransform(editor.canvas.viewportTransform);
      setLastPos(null);
    }
  }, [editor.canvas, lastPos]);

  const handleMouseUp = useCallback(
    ({ isClick }) => {
      // add guideline to overlay images and hide tooltip when the mouse is released
      drawOverlayGuideline(isClick);

      // clear last position when mouse is released
      stopDraggingCanvas();
    },
    [drawOverlayGuideline, stopDraggingCanvas],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedRedrawRuler = useCallback(
    debounce(() => {
      editor.canvas.redrawRulers();
    }, REDRAW_DELAY),
    [editor.canvas],
  );

  const handleMouseWheel = useCallback(
    ({ e }) => {
      // zoom canvas when scrolling
      const delta = e.deltaY;
      let zoom = editor.canvas.getZoom() * 0.999 ** delta;
      zoom = clip(zoom, MIN_ZOOM, MAX_ZOOM);
      editor.canvas.zoomToPoint({ x: e.offsetX, y: e.offsetY }, zoom);
      e.preventDefault();
      e.stopPropagation();
      debouncedRedrawRuler();
    },
    [debouncedRedrawRuler, editor.canvas],
  );

  useEffect(() => {
    if (!editor.canvas) {
      return;
    }

    editor.canvas.on('mouse:down', handleMouseDown);
    editor.canvas.on('mouse:move', handleMouseMove);
    editor.canvas.on('mouse:up', handleMouseUp);
    editor.canvas.on('mouse:wheel', handleMouseWheel);
    return () => {
      editor.canvas.off('mouse:down', handleMouseDown);
      editor.canvas.off('mouse:move', handleMouseMove);
      editor.canvas.off('mouse:up', handleMouseUp);
      editor.canvas.off('mouse:wheel', handleMouseWheel);
    };
  }, [editor.canvas, handleMouseDown, handleMouseMove, handleMouseUp, handleMouseWheel]);

  useEffect(() => {
    if ([ACTIVE_SELECTION, PRISMA_GROUP].includes(editor.activeObject?.type)) {
      editor.isGroupSelected = false;
      editor.canvas?.discardActiveObject();
      editor.canvas?.renderAll();
    }
  }, [timelineTab, editor]);

  const addLayerToLayersProperties = useCallback(
    event => {
      // update properties only when they change in layers tab
      if (editor.initialized) {
        editor.addLayerToLayersProperties(event.target);
        if (timelineTab !== LAYERS_TAB) {
          editor?.canvas?.engine?.moveTo(editor.timelineSceneStore.currentTime);
        }
      }
    },
    [timelineTab, editor],
  );

  const updateLayersProperties = useCallback(() => {
    // update properties only when they change in layers tab
    if (timelineTab === LAYERS_TAB) {
      // we overwrite layer properties because we are in layers tabs and the canvas is the source of truth
      editor.setLayersProperties(editor.canvas.toObject(true).layers);
    }
  }, [timelineTab, editor]);

  const updateLayersPropertiesByLayerId = useCallback(
    (layerId, properties) => {
      editor.updatePropertiesByLayerId(layerId, properties);
    },
    [editor],
  );

  const deleteLayerFromLayersProperties = useCallback(
    event => {
      editor.deleteLayerFromLayersProperties(event.target);
    },
    [editor],
  );

  const removeMultipleGuidelinesFromSelection = useCallback(
    ({ selected }) => {
      if (!selected?.length) {
        return;
      }

      const newSelection = selected.filter(({ type }) => type !== PRISMA_GUIDELINE);
      // if there are no guidelines in the selection, return
      if (newSelection.length === selected.length) {
        return;
      }

      // if the selection only contains guidelines, return
      if (!newSelection.length) {
        // if there are more than one guideline selected, discard all
        if (selected.length > 1) {
          editor.canvas.discardActiveObject();
        }
        return;
      }

      // create a new active selection without guidelines
      editor.canvas.discardActiveObject();
      editor.canvas.setActiveObject(createActiveSelection(newSelection));
    },
    [editor.canvas],
  );

  useEffect(() => {
    editor.canvas?.on({
      'object:added': addLayerToLayersProperties,
      'object:modified': updateLayersProperties,
      'object:removed': deleteLayerFromLayersProperties,
      'selection:created': removeMultipleGuidelinesFromSelection,
      history: updateLayersProperties,
      layerPropertiesModified: updateLayersPropertiesByLayerId,
    });
    return () => {
      editor.canvas?.off({
        'object:added': addLayerToLayersProperties,
        'object:modified': updateLayersProperties,
        'object:removed': deleteLayerFromLayersProperties,
        'selection:created': removeMultipleGuidelinesFromSelection,
        history: updateLayersProperties,
        layerPropertiesModified: updateLayersPropertiesByLayerId,
      });
    };
  }, [
    editor.canvas,
    updateLayersProperties,
    addLayerToLayersProperties,
    deleteLayerFromLayersProperties,
    updateLayersPropertiesByLayerId,
    removeMultipleGuidelinesFromSelection,
  ]);

  const setDragOver = useCallback(
    isDraggedOver => {
      editor.setDraggedOver(isDraggedOver);
    },
    [editor],
  );

  const throttledHandleDragOver = useThrottle(setDragOver, 200);
  const handleDragOver = event => {
    if (event.dataTransfer.files.length) {
      return;
    }
    if (!editor.draggedItem) {
      throttledHandleDragOver(true);
    }
  };
  const handleDragLeave = () => {
    if (!editor.draggedItem) {
      throttledHandleDragOver(false);
    }
  };
  const handleDrop = event => {
    event.preventDefault();
    throttledHandleDragOver(false);
  };

  return {
    editor,
    handleDrop,
    handleDragOver,
    handleDragLeave,
  };
};

export default useEditor;
