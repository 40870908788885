import { useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { Icon } from '@akiunlocks/perseus-ui-components';
import {
  LAYER_TYPES,
  PRISMA_CIRCLE,
  PRISMA_GROUP,
  PRISMA_IMAGE,
  PRISMA_MASK,
  PRISMA_RECT,
  PRISMA_TEXT,
} from '@prisma/lib/src/constants';

import useClickOutside from 'utils/useClickOutside';

import { FormControlInput } from './FormControlinput';

import './editInput.scss';

const layerTypeIcons = {
  [PRISMA_TEXT]: 'fonts',
  [PRISMA_IMAGE]: 'image',
  [PRISMA_GROUP]: 'folder',
  [PRISMA_MASK]: 'tb-marquee2',
  [PRISMA_RECT]: 'square',
  [PRISMA_CIRCLE]: 'circle',
};
const EditInput = ({ disabled, inputType, layerType, onChange, placeholder, value }) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef();

  const handleClickOutside = () => {
    onChange(ref.current.value);
    setIsOpen(false);
  };

  useClickOutside(ref, handleClickOutside);

  if (isOpen) {
    return (
      <FormControlInput
        defaultValue={value}
        handleEnter={e => {
          if (e.key === 'Enter') {
            handleClickOutside();
          }
        }}
        placeholder={placeholder}
        ref={ref}
        type={inputType}
      />
    );
  }

  return (
    <div
      className="edit-input-block"
      onDoubleClick={() => {
        if (!disabled) {
          setIsOpen(true);
        }
      }}
    >
      {layerType && <Icon name={layerTypeIcons[layerType]} className="type-icon" iconsize={20} />}
      <span className="layer-name">{value}</span>
    </div>
  );
};

EditInput.propTypes = {
  /** Flag to indicate if input is disabled */
  disabled: PropTypes.bool,
  /** Input type (text, number, etc) */
  inputType: PropTypes.string,
  /** Layer type */
  layerType: PropTypes.oneOf([...LAYER_TYPES, PRISMA_MASK]),
  /** Action to call on input change */
  onChange: PropTypes.func,
  /** Input placeholder */
  placeholder: PropTypes.string,
  /** Input value */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

EditInput.defaultProps = {
  disabled: false,
  inputType: 'text',
  layerType: undefined,
  onChange: () => {},
  placeholder: '',
  value: '',
};

export default EditInput;
