/* eslint-disable object-shorthand */
/* eslint-disable func-names */
/* eslint-disable no-underscore-dangle */
import { fabric } from 'fabric';

import { PRISMA_OBJECT, getObjectProps } from './prismaObject.js';
import { fabricClone } from './utils/helpers.js';
import { ALIGN } from './constants/canvas.js';
import { DEFAULT_SCALING_MODE, SCALING_MODE } from './constants/images.js';
import { PRISMA_IMAGE } from './constants/index.js';
import { CUSTOM_CONTROLS } from './controls/defaultControls.js';

export const PrismaImage = fabric.util.createClass(fabric.Image, {
  ...PRISMA_OBJECT,

  type: PRISMA_IMAGE,

  imagePositionX: ALIGN.LEFT,
  imagePositionY: ALIGN.TOP,
  uniformScaling: false, // default is true for new objects, we will send this param at object creation
  retinaScalingFactor: 1,
  scalingMode: DEFAULT_SCALING_MODE,
  timestamp: 0,

  initialize: function (element, options) {
    this.callSuper('initialize', element, options);
    const { name, retinaScalingFactor } = options;

    if (!name && retinaScalingFactor) {
      // loaded for the first time
      this.scaleX /= retinaScalingFactor;
      this.scaleY /= retinaScalingFactor;
    }
    this._initializeControls();
  },

  _initializeControls: function () {
    // custom controls are currently needed for active selection with images so each image scale in its own mode
    Object.entries(CUSTOM_CONTROLS).forEach(([key, config]) => {
      this.controls[key] = new fabric.Control(config);
    });
  },

  /**
   * Renders the fill for the image based on the scaling mode.
   *
   * @param {CanvasRenderingContext2D} ctx - The canvas rendering context.
   *
   * If the scaling mode is not set or is set to 'stretch', the function calls the parent's _renderFill method.
   * If the scaling mode is set to 'crop', the function calls the _renderCrop method.
   * If the scaling mode is set to 'fit' or 'fit_to_100', the function calls the _renderFit method.
   */
  _renderFill: function (ctx) {
    if (!this.scalingMode || this.scalingMode === SCALING_MODE.STRETCH) {
      // Call the original _renderFill method as stretch is the default for fabric
      this.callSuper('_renderFill', ctx);
      return;
    }

    const elementToDraw = this._element;
    if (!elementToDraw) {
      return;
    }
    const imageRatio = this.width / this.height;
    const aspectRatio = this.getScaledWidth() / this.getScaledHeight();

    if (this.scalingMode === SCALING_MODE.CROP) {
      this._renderCrop(ctx, elementToDraw, aspectRatio, imageRatio);
      return;
    }

    if (this.scalingMode === SCALING_MODE.FIT || this.scalingMode === SCALING_MODE.FIT_TO_100) {
      this._renderFit(ctx, elementToDraw, aspectRatio, imageRatio);
    }
  },

  /**
   * Renders the image with a cropping effect based on the aspect ratio and image position.
   *
   * @param {CanvasRenderingContext2D} ctx - The canvas rendering context.
   * @param {HTMLImageElement} elementToDraw - The image element to draw.
   * @param {number} aspectRatio - The aspect ratio to use for cropping.
   * @param {number} imageRatio - The image ratio to use for cropping.
   *
   * The function calculates the new width and height based on the aspect ratio and image ratio.
   * It then calculates the x and y coordinates to crop the image based on the image position.
   * Finally, it draws the image on the canvas with the calculated dimensions and coordinates.
   */
  _renderCrop: function (ctx, elementToDraw, aspectRatio, imageRatio) {
    let newWidth;
    let newHeight;
    if (aspectRatio >= imageRatio) {
      newWidth = this.width;
      newHeight = this.width / aspectRatio;
    } else {
      newWidth = this.height * aspectRatio;
      newHeight = this.height;
    }
    // default is top left so is 0, 0
    let cropX = 0;
    let cropY = 0;

    if (this.imagePositionX === ALIGN.CENTER) {
      cropX = (this.width - newWidth) / 2;
    } else if (this.imagePositionX === ALIGN.RIGHT) {
      cropX = this.width - newWidth;
    }

    if (this.imagePositionY === ALIGN.MIDDLE) {
      cropY = (this.height - newHeight) / 2;
    } else if (this.imagePositionY === ALIGN.BOTTOM) {
      cropY = this.height - newHeight;
    }

    ctx.drawImage(
      elementToDraw,
      Math.max(cropX, 0),
      Math.max(cropY, 0),
      Math.max(1, newWidth),
      Math.max(1, newHeight),
      -this.width / 2,
      -this.height / 2,
      Math.max(0, this.width),
      Math.max(0, this.height),
    );
  },

  /**
   * Renders the image with a fitting effect based on the aspect ratio, image ratio, scaling mode, and image position.
   *
   * @param {CanvasRenderingContext2D} ctx - The canvas rendering context.
   * @param {HTMLImageElement} elementToDraw - The image element to draw.
   * @param {number} aspectRatio - The aspect ratio to use for fitting.
   * @param {number} imageRatio - The image ratio to use for fitting.
   *
   * The function calculates the new width and height based on the aspect ratio, image ratio, and scaling mode.
   * It then calculates the x and y coordinates to position the image based on the image position.
   * Finally, it draws the image on the canvas with the calculated dimensions and coordinates.
   */
  _renderFit: function (ctx, elementToDraw, aspectRatio, imageRatio) {
    let newWidth;
    let newHeight;
    // FIT_TO_100 behaves like FIT when the image is scaled to less than 100%
    if (this.scalingMode === SCALING_MODE.FIT_TO_100 && this.scaleX >= 1 && this.scaleY >= 1) {
      newHeight = this.getScaledHeight();
      newWidth = this.getScaledWidth();
    } else if (aspectRatio < imageRatio) {
      newWidth = this.width;
      newHeight = this.width / aspectRatio;
    } else {
      newWidth = this.height * aspectRatio;
      newHeight = this.height;
    }

    let x = -this.width / 2;
    let y = -this.height / 2;

    if (this.imagePositionX === ALIGN.CENTER) {
      // x = -(this.width / (aspectRatio / imageRatio)) / 2; // WORKS for else
      x = -((this.width * this.width) / newWidth) / 2;
    } else if (this.imagePositionX === ALIGN.RIGHT) {
      // RIGHT
      // keeping comments because it could help, it did help me to get to the right formula
      // x = (this.getScaledWidth() - this.width - this.getScaledWidth() / 2) / this.scaleX; // works for else
      // x = (this.getScaledWidth() / 2 - this.width) / this.scaleX; // works for else
      // x = this.width / 2 - (imageRatio * this.width) / aspectRatio; // works for else
      // x = this.width / 2 - ((this.width / this.height) * this.width) / aspectRatio; // works for else
      // x = this.width / 2 - (this.width * this.width) / newWidth; // WORKS
      // x = this.width * (1 / 2 - this.width / newWidth); // WORKS
      x = -((this.width * this.width) / newWidth - this.width / 2);
    }

    if (this.imagePositionY === ALIGN.MIDDLE) {
      y = -((this.height * this.height) / newHeight) / 2;
    } else if (this.imagePositionY === ALIGN.BOTTOM) {
      y = -((this.height * this.height) / newHeight - this.height / 2);
    }

    ctx.drawImage(
      elementToDraw,
      0,
      0,
      Math.max(1, newWidth),
      Math.max(1, newHeight),
      x,
      y,
      Math.max(0, this.width),
      Math.max(0, this.height),
    );
  },

  toObject: function () {
    return {
      ...getObjectProps(this),
      crossOrigin: 'Anonymous',
      imagePlaceholder: this.imagePlaceholder || `perseus_placeholder_image_id_${this.id}`,
      imagePositionX: this.imagePositionX,
      imagePositionY: this.imagePositionY,
      imageUrl: this.imageUrl,
      maskId: this.maskId,
      retinaScalingFactor: this.retinaScalingFactor,
      scalingMode: this.scalingMode,
      timestamp: this.timestamp,
      uniformScaling: this.uniformScaling,
    };
  },
});

PrismaImage.fromURL = function (url, callback, imgOptions) {
  return fabric.util.loadImage(
    `${imgOptions.baseUrl}${url}`,
    (img, isError) => {
      if (callback) {
        callback(new PrismaImage(img, imgOptions), isError);
      }
    },
    null,
    imgOptions && imgOptions.crossOrigin,
  );
};

PrismaImage.fromObject = function (_object, callback) {
  const object = fabricClone(_object);
  const imageUrl = `${object.imageUrl}?t=${object.timestamp || 0}`;
  const path = object.imagePlaceholder.includes('perseus_placeholder') ? imageUrl : object.imagePlaceholder;
  const src = `${object.baseUrl}${path}`;
  fabric.util.loadImage(
    src,
    (img, isError) => {
      if (isError) {
        if (callback) {
          callback(null, true);
        }
        return;
      }
      fabric.Image.prototype._initFilters.call(object, object.filters, filters => {
        object.filters = filters || [];
        fabric.Image.prototype._initFilters.call(object, [object.resizeFilter], ([resizeFilter]) => {
          object.resizeFilter = resizeFilter;
          fabric.util.enlivenObjectEnlivables(object, object, () => {
            const image = new fabric.PrismaImage(img, object);
            callback(image, false);
          });
        });
      });
    },
    null,
    object.crossOrigin,
  );
};

fabric.PrismaImage = PrismaImage;

export default PrismaImage;
