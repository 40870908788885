export const s3Path = '/api/v1/upload';

export const s3Methods = [
  'copyAssets',
  'create', // create asset
  'createFolder', // create folder
  'createFromUrl', // create asset from url
  'find',
  'get',
  'getAssetDataFromFileName',
  'getAssets',
  'moveAsset',
  'moveFolder',
  'remove',
];

export const s3Client = client => {
  const connection = client.get('connection');

  client.use(s3Path, connection.service(s3Path), {
    methods: s3Methods,
  });
};
