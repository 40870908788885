/**
 * Sends a message to the parent page.
 * @param {string} hashId - Message id.
 * @param {string} data - Message content.
 */
function postMessage(id, data = {}) {
  window.parent.postMessage({ event_id: id, ...data }, '*');
}

/**
 * Sends a message to the parent page to redirect to Perseus Personalize Upload page.
 * @param {string} hashId - Id of the perseus project.
 */
export function redirectToPersonalizeUpload(hashId) {
  postMessage('prisma_export', { hashId });
}

/**
 * Sends a message to the parent page to redirect to Perseus Personalize Review page.
 * @param {string} hashId - Id of the perseus project.
 * @param {string} perseusName - Name of the perseus project.
 */
export function redirectToPersonalizeReview(hashId, perseusName) {
  postMessage('prisma_render', { hashId, perseusName });
}

/**
 * Sends a message to the parent to indicate focus event.
 */
export function postFocusMessage() {
  postMessage('focus');
}

/**
 * Sends a message to the parent to indicate blur event.
 */
export function postBlurMessage() {
  postMessage('blur');
}

/**
 * Sends a message to the parent with project info.
 * @param {string} name - Project name.
 * @param {string} id - Project id.
 * @param {number} size - Project size.
 */
export function postProjectInfo(name, id, size) {
  postMessage('project', { name, id, size });
}
