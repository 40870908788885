import React from 'react';

import ExclusiveButtons from '../ExclusiveButtons';

const BUTTONS = [
  { icon: 'text-left', tooltip: 'Align left', value: 'left' },
  { icon: 'text-center', tooltip: 'Align center', value: 'center' },
  { icon: 'text-right', tooltip: 'Align right', value: 'right' },
  { icon: 'justify', tooltip: 'Justify', value: 'justify' },
];

const TextAlign = ({ className, textAlign, onChange }) => {
  return <ExclusiveButtons buttons={BUTTONS} className={className} propValue={textAlign} onChange={onChange} />;
};

export default React.memo(TextAlign);
