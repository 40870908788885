export const RULER_TYPE = {
  TOP: 'top',
  LEFT: 'left',
};

export const RULER_SIZE = 15;

export const HASH_MARK_CONFIG = { stroke: '#8d9091', strokeWidth: 1 };

export const TEXT_CONFIG = { fill: '#8d9091', fontFamily: 'Arial', fontSize: 10, left: 0, top: 0 };

export const DEFAULT_RULER_SIZE = 2000;

export const ALLOWED_STEPS_PX = [5, 10, 20, 50, 100];
