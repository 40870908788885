/**
 * Given a node and the original style object, gets the style for a tree with hidden root.
 * @param {object} node - Node.
 * @param {object} style - Original style object.
 * @return {object} The new style object.
 */
export function getHiddenRootStyle(node, style) {
  if (node.level === 0) {
    return { display: 'none' };
  }
  return {
    ...style,
    paddingLeft: style.paddingLeft ? style.paddingLeft - node.tree.indent : 0,
  };
}
