import { makeObservable, observable } from 'mobx';

import { DEFAULT_SCALING_MODE, SCALING_MODE } from '@prisma/lib/src/constants/images';
import { ALIGN } from '@prisma/lib/src/constants/canvas';

import ObjectStore from './Object.store';
import { FF_ASSET_POSITION } from 'utils/featureFlag';

const FF_DEFAULT_SCALING_MODE = FF_ASSET_POSITION ? DEFAULT_SCALING_MODE : SCALING_MODE.STRETCH;

class ImageStore extends ObjectStore {
  imagePositionX = ALIGN.LEFT;
  imagePositionY = ALIGN.TOP;
  scalingMode = FF_DEFAULT_SCALING_MODE;

  constructor() {
    super();
    makeObservable(this, {
      ...this.props,
      imagePositionX: observable,
      imagePositionY: observable,
      scalingMode: observable,
    });
  }

  initialize(image) {
    super.initialize(image);

    this.imagePositionX = image.imagePositionX || ALIGN.LEFT;
    this.imagePositionY = image.imagePositionY || ALIGN.TOP;
    this.scalingMode = image.scalingMode || FF_DEFAULT_SCALING_MODE;
  }
}

export default ImageStore;
