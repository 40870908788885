import React from 'react';
import { Icon } from '@akiunlocks/perseus-ui-components';

const RedirectBody = () => {
  return (
    <div className="d-flex align-items-center gap-2">
      <Icon size="15" name="check-circle-fill" color="var(--per-success)" />
      Composition copied successfully!
    </div>
  );
};

export default RedirectBody;
