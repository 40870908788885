import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { Notification } from '@akiunlocks/perseus-ui-components';

import App from './App';
import { StoreProvider } from './store';
import reportWebVitals from './reportWebVitals';
import KeycloakProvider from 'auth/KeycloakProvider';

import { history } from './browser-history';

import './styles/index.scss';

const root = document.getElementById('root');
ReactDOM.render(
  <React.StrictMode>
    <StoreProvider>
      <KeycloakProvider>
        <BrowserRouter history={history}>
          <DndProvider backend={HTML5Backend}>
            <App />
          </DndProvider>
        </BrowserRouter>
        <Notification.Container limit={2} draggable={false} newestOnTop />
      </KeycloakProvider>
    </StoreProvider>
  </React.StrictMode>,
  root,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
