import { useCallback } from 'react';
import { useRootStore } from 'store';

import useActiveObjectProperties from '../useActiveObjectProperties';

const useImageProperties = () => {
  const { editor } = useRootStore();
  const { imageStore } = editor;

  const {
    onAlignChange,
    onAngleChange,
    onHeightChange,
    onHideChange,
    onLeftChange,
    onLockProportionsChange,
    onOpacityChange,
    onPositionXChanged,
    onPositionYChanged,
    onTopChange,
    onWidthChange,
    setAttribute,
  } = useActiveObjectProperties(imageStore);

  const onImagePositionChange = useCallback(
    (imagePositionX, imagePositionY) => {
      setAttribute('imagePositionX', imagePositionX);
      setAttribute('imagePositionY', imagePositionY);
    },
    [setAttribute],
  );

  const onSetScalingMode = useCallback(scalingMode => setAttribute('scalingMode', scalingMode), [setAttribute]);

  return {
    imageStore,
    onAlignChange,
    onAngleChange,
    onHeightChange,
    onHideChange,
    onImagePositionChange,
    onLeftChange,
    onLockProportionsChange,
    onOpacityChange,
    onPositionXChanged,
    onPositionYChanged,
    onSetScalingMode,
    onTopChange,
    onWidthChange,
  };
};

export default useImageProperties;
