const ENVIRONMENT = process.env.REACT_APP_ENV;

export const ENV = {
  DEV: 'development',
  STAGING: 'staging',
  PRODUCTION: 'production',
};

/**
 * Allows an element to be returned if the environment is in the white list of approved environments.
 * @param {array} env A list of approved environments to return the feature ['development', 'staging', 'production']
 * @param {element} enabledElement The element to be returned if ok
 * @param {element} disabledElement The element to be returned if not a valid environment. Defaults to nothing.
 */
export const envFeature = (env, enabledElement, disabledElement = null) => {
  return env.includes(ENVIRONMENT) ? enabledElement : disabledElement;
};

// FF: percentage, anchor points, pixel/percentage inputs
// export const USE_RESPONSIVE = envFeature([ENV.DEV, ENV.STAGING], true, false);
// export const FF_ASSET_POSITION = USE_RESPONSIVE && envFeature([ENV.DEV, ENV.STAGING], true, false);
// export const FF_RENDER_NON_PERSONALIZED = envFeature([ENV.DEV, ENV.STAGING], true, false);

// TODO: remove these constants and all their occurrences once everything is tested in production
export const USE_RESPONSIVE = true;
export const FF_ASSET_POSITION = USE_RESPONSIVE && true;
export const FF_RENDER_NON_PERSONALIZED = true;
