import React from 'react';

import { useRootStore } from 'store';

import { LAYERS_TAB } from 'constants/editor';

import { formatMilliseconds } from 'components/TimeLineScene/utilities';

const TimelineCurrentTime = ({ className }) => {
  const { editor } = useRootStore();
  const { contextStore, timelineSceneStore } = editor;
  const { currentTime } = timelineSceneStore;

  const time = contextStore.getTimelineTab() === LAYERS_TAB ? '' : formatMilliseconds(currentTime);

  return <div className={className}>{time}</div>;
};

export default TimelineCurrentTime;
