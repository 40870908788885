import React from 'react';
import PropTypes from 'prop-types';
import { Select } from '@akiunlocks/perseus-ui-components';

const SelectBody = ({ destinationProject, disabled, isDOOH, projectsList, setDestinationProject }) => {
  return (
    <div>
      <Select
        id="destination-id"
        isDisabled={disabled}
        label="Destination project"
        onChange={({ target }) => {
          setDestinationProject(target.option);
        }}
        options={projectsList}
        value={destinationProject}
      />
      <div className="ms-1 mt-3 fs-7">
        <strong className="me-1">Notes:</strong>
        <ul>
          <li>This composition can only be copied to {isDOOH ? 'DOOH' : 'Non-DOOH'} projects.</li>
          <li>Assets with duplicated names will be renamed.</li>
        </ul>
      </div>
    </div>
  );
};

const optionProp = PropTypes.shape({
  label: PropTypes.string,
  value: PropTypes.string,
});

SelectBody.propTypes = {
  /** Selected project */
  destinationProject: optionProp,
  /** Whether the Select component is disabled or not */
  disabled: PropTypes.bool,
  /** List of projects for the options in the Select component. */
  projectsList: PropTypes.arrayOf(optionProp),
  /** Function to set the selected project. */
  setDestinationProject: PropTypes.func,
};

SelectBody.defaultProps = {
  destinationProject: null,
  disabled: false,
  projectsList: [],
  setDestinationProject: () => {},
};

export default SelectBody;
