class AspectRatio {
  constructor(aspectRatio) {
    this.height = aspectRatio.height;
    this.width = aspectRatio.width;
    this.layers = aspectRatio.layers || [];
    this.type = aspectRatio.type;
  }

  setLayers(layers) {
    this.layers = layers;
  }

  setCurrent(current) {
    this.current = current;
  }
}
export default AspectRatio;
