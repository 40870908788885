import { useHotkeys } from 'react-hotkeys-hook';
import { useParams } from 'react-router-dom';

import { useRootStore } from '../../store';
import useSaveProject from 'utils/useSaveProject';
import { COMBINATIONS, HOTKEYS } from '../../constants/hotkeys';

const useEditorHotkeys = () => {
  const { editor } = useRootStore();
  const { saveProject } = useSaveProject();
  const { clipboardStore, contextStore } = editor;
  const { projectSizeId } = useParams();

  useHotkeys([HOTKEYS.DELETE, HOTKEYS.BACKSPACE], () => contextStore.deleteSelected());
  useHotkeys(COMBINATIONS.COPY, () => clipboardStore.copySelectedComponents());
  useHotkeys(COMBINATIONS.PASTE, () => contextStore.paste());
  useHotkeys(COMBINATIONS.SAVE, () => saveProject(projectSizeId), { preventDefault: true });
  useHotkeys(COMBINATIONS.GROUP, () => editor.groupActiveObjects(), { preventDefault: true });
  useHotkeys(COMBINATIONS.UNGROUP, () => editor.ungroupActiveObject(), { preventDefault: true });
};
export default useEditorHotkeys;
