import React from 'react';
import PropTypes from 'prop-types';

import { Icon, Popover, RadioButton } from '@akiunlocks/perseus-ui-components';

import './CompositionModal.scss';

const RadioOption = ({ checkDisabledFn, group, onChange, option, selectedValue }) => {
  const { info, label, value } = option;
  const { disabled, reason } = checkDisabledFn(value);
  const id = `${group}-${value}`;

  return (
    <Popover id={`${id}-tooltip`} content={reason}>
      <span>
        <RadioButton
          checked={selectedValue === value}
          disabled={disabled}
          id={id}
          inline
          label={
            <div className="label-container">
              {label}
              {info && (
                <Popover id={`${id}-info-tooltip`} content={disabled ? '' : info}>
                  <Icon className="d-flex" color="var(--per-primary)" name="question-circle" role="img" size={13} />
                </Popover>
              )}
            </div>
          }
          onChange={() => onChange(value)}
        />
      </span>
    </Popover>
  );
};

RadioOption.propTypes = {
  /** Function to determine if the option is disabled */
  checkDisabledFn: PropTypes.func,
  /** The group name for the radio button */
  group: PropTypes.string,
  /** Function to call when the radio button is selected */
  onChange: PropTypes.func,
  /** The radio option to display */
  option: PropTypes.shape({
    /** Label for the radio button */
    label: PropTypes.string.isRequired,
    /** Value of the radio button */
    value: PropTypes.string.isRequired,
    /** Additional info for the radio button */
    info: PropTypes.string,
  }).isRequired,
  /** The currently selected value in the group */
  selectedValue: PropTypes.string,
};

RadioOption.defaultProps = {
  checkDisabledFn: () => ({ disabled: false, reason: '' }),
  group: 'default-group',
  onChange: () => {},
  selectedValue: undefined,
};

export default RadioOption;
