import { SENDER_TYPE } from 'constants/chat';
import Message from './Message';

class TemplateMessage extends Message {
  constructor(message) {
    super(SENDER_TYPE.BOT, message.text);
    this.imageUrl = message.imageUrl;
    // this.width = message.width;
    // this.height = message.height;
    this.projectId = message.projectId;
    this.sizeId = message.sizeId;
  }
}

export default TemplateMessage;
