import { easingList } from '@prisma/lib/src/utils/easings';
import { PRISMA_GROUP } from '@prisma/lib/src/constants';

export const KEYFRAME_WIDTH = 12;
export const HALF_KEYFRAME_WIDTH = KEYFRAME_WIDTH / 2;

export const ANIMATION_IDENTIFIER = '-animation';
export const GROUP_IDENTIFIER = '-group';

export const ICON_SIZE = 14;

export const DEFAULT_SELECTED_POINT = {
  oldLayer: null,
  oldAnimation: null,
  oldValue: null,
  newValue: null,
};

export const LOCKS_BY_TYPE = {
  // TODO: implement rotation and scaling for groups
  [PRISMA_GROUP]: {
    lockRotation: true,
    lockScalingX: true,
    lockScalingY: true,
    lockScalingFlip: true,
  },
};

export const easingOptions = [
  { value: easingList.easeLinear, label: 'Ease linear' },
  { value: easingList.easeInSine, label: 'Ease in' },
  { value: easingList.easeOutSine, label: 'Ease out' },
  { value: easingList.easeInOutSine, label: 'Ease-in-out' },
  { value: easingList.easeInQuad, label: 'Ease in quad' },
  { value: easingList.easeOutQuad, label: 'Ease out quad' },
  { value: easingList.easeInOutQuad, label: 'Ease-in-out quad' },
  { value: easingList.easeInCubic, label: 'Ease in cubic' },
  { value: easingList.easeOutCubic, label: 'Ease out cubic' },
  { value: easingList.easeInOutCubic, label: 'Ease-in-out cubic' },
  { value: easingList.easeInQuart, label: 'Ease in quart' },
  { value: easingList.easeOutQuart, label: 'Ease out quart' },
  { value: easingList.easeInOutQuart, label: 'Ease-in-out quart' },
  { value: easingList.easeInQuint, label: 'Ease in quint' },
  { value: easingList.easeOutQuint, label: 'Ease out quint' },
  { value: easingList.easeInOutQuint, label: 'Ease-in-out quint' },
  { value: easingList.easeInExpo, label: 'Ease in expo' },
  { value: easingList.easeOutExpo, label: 'Ease out expo' },
  { value: easingList.easeInOutExpo, label: 'Ease-in-out expo' },
  { value: easingList.easeInCirc, label: 'Ease in circ' },
  { value: easingList.easeOutCirc, label: 'Ease out circ' },
  { value: easingList.easeInOutCirc, label: 'Ease-in-out circ' },
  { value: easingList.easeInBack, label: 'Ease in back' },
  { value: easingList.easeOutBack, label: 'Ease out back' },
  { value: easingList.easeInOutBack, label: 'Ease-in-out back' },
  { value: easingList.easeInElastic, label: 'Ease in elastic' },
  { value: easingList.easeOutElastic, label: 'Ease out elastic' },
  { value: easingList.easeInOutElastic, label: 'Ease-in-out elastic' },
  { value: easingList.easeInBounce, label: 'Ease in bounce' },
  { value: easingList.easeInOutBounce, label: 'Ease-in-out bounce' },
  { value: easingList.easeOutBounce, label: 'Ease out bounce' },
];
