import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import { Button, Col, Row, Modal, Icon } from '@akiunlocks/perseus-ui-components';

import { ASPECT_RATIO_TYPE } from 'constants';

import './SelectAspectRatioModal.scss';

const SelectAspectRatioModal = ({ handleModalClose, handleModalConfirm, showModal }) => {
  const [selectedAspectRatio, setSelectedAspectRatio] = useState(null);

  useEffect(() => {
    setSelectedAspectRatio(null);
  }, [showModal]);

  return (
    <Modal show={showModal} onHide={handleModalClose}>
      <Modal.Header closeButton>
        <Modal.Title>Lock</Modal.Title>
      </Modal.Header>
      <Modal.Body className="aspect-ratio-modal">
        <h6 className="fw-bold">Choose the stage that will be mirrored to the other.</h6>
        <div className="fs-7">Any differences between stages will be discarded (this action can be undone later).</div>
        <div className="icons">
          <Icon
            className={classnames('aspect-ratio', { active: selectedAspectRatio === ASPECT_RATIO_TYPE.SMALL })}
            name="per-aspect-ratio-small"
            onClick={() => setSelectedAspectRatio(ASPECT_RATIO_TYPE.SMALL)}
            size={36}
          />
          <Icon
            className={classnames('question', { hide: !!selectedAspectRatio })}
            name="patch-question-fill"
            size={26}
          />
          <span
            className={classnames('arrow', { [`${selectedAspectRatio?.toLowerCase()}`]: selectedAspectRatio })}
          ></span>
          <Icon
            className={classnames('aspect-ratio', { active: selectedAspectRatio === ASPECT_RATIO_TYPE.BIG })}
            name="per-aspect-ratio-big"
            onClick={() => setSelectedAspectRatio(ASPECT_RATIO_TYPE.BIG)}
            size={36}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Row className="flex justify-content-between w-100">
          <Col>
            <Button variant="transparent" className="border border-secondary" onClick={handleModalClose}>
              Cancel
            </Button>
          </Col>
          <Col className="saveButton">
            <Button
              variant="primary"
              onClick={() => handleModalConfirm(selectedAspectRatio)}
              disabled={!selectedAspectRatio}
              tooltipMessage={selectedAspectRatio ? '' : 'Please, select a stage'}
            >
              Confirm
            </Button>
          </Col>
        </Row>
      </Modal.Footer>
    </Modal>
  );
};

export default SelectAspectRatioModal;
