import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import { Icon, Popover, Select, Spinner } from '@akiunlocks/perseus-ui-components';

import { CANVAS_CONTAINER_ID } from 'constants/canvas';
import { CONFIRM_BODIES, CONFIRM_TITLES } from 'utils';

import { useRootStore } from 'store';

import Confirm from 'components/Confirm';
import CompositionModal from 'components/ProjectPreview/CompositionModal';
import DuplicateCompositionModal from 'components/ProjectPreview/DuplicateCompositionModal';

import ArtBoardControls from './components/ArtBoardControls';
import AspectRatio from './components/AspectRatio/AspectRatio';
import useArtBoard from './ArtBoard.hooks';

import './ArtBoard.scss';

const ArtBoard = observer(() => {
  const { editor, projects, projectSizes: projectSizesStore } = useRootStore();
  const { project } = projects;
  const {
    artBoardName,
    artBoardRef,
    defaultSizes,
    handleCreate,
    selectedSize,
    projectSizes,
    projectSizeId,
    handleDuplicate,
    projectSizesList,
    showAddSizeModal,
    setShowAddSizeModal,
    onProjectSizeSelected,
    showDuplicateSizeModal,
    setShowDuplicateSizeModal,
  } = useArtBoard();
  const [showConfirm, setShowConfirm] = useState(false);
  const [targetSizeId, setTargetSizeId] = useState(null);
  const [selectedOption, setSelectedOption] = useState();

  const isCanvasLoading = editor.isCanvasLoading();

  useEffect(() => {
    setSelectedOption({ value: selectedSize?._id, label: selectedSize?.name });
  }, [selectedSize]);

  return (
    <div
      id={CANVAS_CONTAINER_ID}
      className={classnames('art-board', { loading: isCanvasLoading })}
      ref={artBoardRef}
      key={projectSizeId}
    >
      {isCanvasLoading && (
        <div className="art-board-loader">
          <Spinner variant="primary" />
        </div>
      )}
      <div className="art-board-header">
        <div className="art-board-name">{artBoardName}</div>
        <div className="sizes-wrapper">
          <Select
            id="sizes"
            options={projectSizesList}
            label="Compositions"
            className="sizes-select"
            key={`sizes-${selectedSize?._id}`}
            isLoading={projectSizesList.length === 0}
            onChange={({ target: { value } }) => {
              editor?.canvas?.engine?.pause();
              if (projectSizesStore.unsavedChanges) {
                setTargetSizeId(value);
                setShowConfirm(true);
              } else {
                onProjectSizeSelected(value);
              }
            }}
            value={selectedOption}
            size="sm"
            alignMenuRight
          />
          <div className="sizes-controls">
            <Popover id="add-new-size" content="Add new composition" placement="left">
              <Icon className="add-button" name="cg-add-r" onClick={() => setShowAddSizeModal(true)} />
            </Popover>
            <Popover id="duplicate-size" content="Duplicate composition" placement="left">
              <Icon className="duplicate-button" name="bi-duplicate" onClick={() => setShowDuplicateSizeModal(true)} />
            </Popover>
          </div>
        </div>
        <div className="aspect-ratio-container">
          <AspectRatio />
        </div>
      </div>

      <ArtBoardControls
        artBoardRef={artBoardRef}
        banner={{
          width: selectedSize?.width + 20,
          height: selectedSize?.height + 20,
        }}
      />

      <CompositionModal
        handleClose={() => setShowAddSizeModal(false)}
        handleAccept={handleCreate}
        key={`create-${selectedSize?._id}`}
        projectSizes={projectSizesStore.projectSizes}
        projectType={project?.type}
        saveInProgress={false} // TODO: add loading state
        show={showAddSizeModal}
        sizes={defaultSizes}
      />

      <DuplicateCompositionModal
        sizes={defaultSizes}
        selectedSize={selectedSize}
        projectSizes={projectSizes}
        show={showDuplicateSizeModal}
        handleDuplicate={handleDuplicate}
        key={`duplicate-${selectedSize?._id}`}
        handleClose={() => setShowDuplicateSizeModal(false)}
      />

      <Confirm
        showModal={showConfirm}
        handleClose={setShowConfirm}
        handleAccept={() => {
          onProjectSizeSelected(targetSizeId);
          setShowConfirm(false);
          setTargetSizeId(null);
          projectSizesStore.setUnsavedChanges(false);
        }}
        title={CONFIRM_TITLES.PROJECT_SIZE_CHANGE}
        body={CONFIRM_BODIES.PROJECT_SIZE_REDIRECT_SAVE.replace('{name}', selectedSize?.name)}
      />
    </div>
  );
});

export default ArtBoard;
