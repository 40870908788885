import { useCallback, useEffect, useRef, useState } from 'react';
import { toJS } from 'mobx';

import { useRootStore } from 'store';

import { filterGroupAnimations, getKeyframesForNewAnimation } from 'utils/animations';

const useSidebar = () => {
  const { editor } = useRootStore();
  const { activeObject, assetStore, draggedOver, isGroupSelected, onDragEnd, onDragStart, timelineSceneStore } = editor;
  const { uploadFiles } = assetStore;

  const sidebarRef = useRef(null);

  useEffect(() => {
    const sidebar = sidebarRef.current;
    if (!sidebar) {
      return;
    }

    sidebar.addEventListener('dragstart', onDragStart);
    sidebar.addEventListener('dragend', onDragEnd);

    return () => {
      sidebar.removeEventListener('dragstart', onDragStart);
      sidebar.removeEventListener('dragend', onDragEnd);
    };
  }, [sidebarRef, onDragStart, onDragEnd]);

  const setActiveTab = tab => {
    editor.activeTab = tab === editor.activeTab ? null : tab;
  };

  const addAnimation = (activeObject, type) => {
    const { currentTime } = timelineSceneStore;
    const keyframes = getKeyframesForNewAnimation(activeObject, type, currentTime, activeObject);
    const updatedLayer = editor.canvas.addOrEditKeyframes.call(editor.canvas, keyframes, activeObject.id);
    const animation = updatedLayer.animations.find(a => a.type === type);
    const keyframe = animation.keyframes.find(({ time }) => time === currentTime);
    timelineSceneStore.setActive({
      layer: updatedLayer,
      animation,
      keyframe,
    });
  };

  const [existingAnimations, setExistingAnimations] = useState([]);

  useEffect(() => {
    if (!activeObject) {
      return;
    }
    const hasThisTypeAnimation = () => {
      const activeLayer = toJS(editor.layers).find(l => l.id === activeObject.id);
      if (!activeLayer) {
        return;
      }
      const allAnimationTypes = filterGroupAnimations(activeLayer.animations).map(a => a.type);
      setExistingAnimations(allAnimationTypes);
    };
    hasThisTypeAnimation();
  }, [activeObject, editor.layers]);

  const group = useCallback(() => {
    editor.groupActiveObjects();
  }, [editor]);

  const ungroup = useCallback(() => {
    editor.ungroupActiveObject();
  }, [editor]);

  return {
    uploadFiles,
    sidebarRef,
    draggedOver,
    activeObject,
    setActiveTab,
    existingAnimations,
    isTimelineLayersTab: editor.contextStore.getIsLayersTabSelected(),
    activeTab: editor.activeTab,
    addTextbox: editor.addTextbox,
    addRect: editor.addRect,
    addCircle: editor.addCircle,
    addAnimation,
    isGroupSelected,
    group,
    ungroup,
  };
};

export default useSidebar;
