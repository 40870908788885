import React from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import { useRootStore } from 'store';

import { DISTRIBUTE_VERTICAL, DISTRIBUTE_HORIZONTAL } from 'constants/canvas';

import PrismaIconButton from '../PrismaIconButton';

import Boundaries from '../Boundaries';

import './Distribute.scss';

const DISTRIBUTE_OPTIONS = [
  {
    icon: 'distribute-vertical',
    tooltipMessage: 'Space evenly vertically',
    type: DISTRIBUTE_VERTICAL,
  },
  {
    icon: 'distribute-horizontal',
    tooltipMessage: 'Space evenly horizontally',
    type: DISTRIBUTE_HORIZONTAL,
  },
];

const Distribute = observer(({ className, onClick = () => {}, showBoundaries = false }) => {
  const { editor } = useRootStore();

  const label = showBoundaries ? 'Distribute to' : 'Distribute';
  return (
    <div>
      <div className={classnames('form-label m-0', { 'mb-1': !showBoundaries })}>{label}</div>
      {showBoundaries && <Boundaries />}
      <div className={classnames('distribute-buttons', className)}>
        {DISTRIBUTE_OPTIONS.map(({ icon, tooltipMessage, type }) => (
          <PrismaIconButton
            icon={icon}
            iconSize={16}
            key={type}
            onClick={() => onClick(type, editor.boundary)}
            tooltipMessage={tooltipMessage}
            variant="primary-alt"
          />
        ))}
      </div>
    </div>
  );
});

export default React.memo(Distribute);
