import React from 'react';

import ExclusiveButtons from '../ExclusiveButtons';

const BUTTONS = [
  { icon: 'md-format-underlined', tooltip: 'Underline', value: 'underline' },
  { icon: 'type-strikethrough', tooltip: 'Strikethrough', value: 'linethrough' },
  { icon: 'md-format-overline', tooltip: 'Overline', value: 'overline' },
];

const TextDecoration = ({ className, textDecoration, onChange }) => {
  return (
    <ExclusiveButtons
      allowUnselect
      buttons={BUTTONS}
      className={className}
      propValue={textDecoration}
      onChange={onChange}
    />
  );
};

export default TextDecoration;
